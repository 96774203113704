<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Processing CyberSource Transaction</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div><br />
    <div *ngIf="Messages.length > 0" >
        <div class="row"  *ngFor="let Msg of Messages">
            <div class="col-md-8 col-md-offset-2">
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>
    <div class="row col-md-12 col-sm-12">
        <div class="col-md-2 col-sm-2"></div>
        <div class="col-md-8 col-sm-8">
            <div class="row divBorder">
                <br />
                <div class="col-md-12 col-sm-12">
                    Please wait as we finish processing your transaction.
                </div>
                <br />
            </div>
        </div>
        <div class="col-md-2 col-sm-2"></div>
    </div>
    <div class="row col-md-12 col-sm-12">
        <div class="col-md-10 text-right">
            <!-- BACK BUTTON -->
            <button class="btn btn-primary btn-site" [routerLink]="['/oogchassisflipsearch']"  *ngIf="ShoppingCart.SubHoldType == 'O'">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                Back
            </button>
            <!-- BACK BUTTON -->
            <button class="btn btn-primary btn-site" [routerLink]="['/oogstripstuffsearch']"  *ngIf="ShoppingCart.SubHoldType == 'W'">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                Back
            </button>
            <button class="btn btn-primary btn-site" [routerLink]="['/vgmservicesearch']"  *ngIf="ShoppingCart.SubHoldType == 'V'">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                Back
            </button>
            <button class="btn btn-primary btn-site" [routerLink]="['/importchargeresults']"  *ngIf="ShoppingCart.HoldType != 'E' && ShoppingCart.HoldType != 'S'">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                Back
            </button>
            <!-- BACK BUTTON -->
            <button class="btn btn-primary btn-site" [routerLink]="['/serviceordersearch']"  *ngIf="ShoppingCart.HoldType == 'S'">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                Back
            </button>
        </div>
    </div>
    <br /><br />
</div>

