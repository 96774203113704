<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="border-bottom: 1px solid #0294B5; padding-bottom:7px;">
        <h3 style="text-align: center;"><strong>My Dashboard</strong></h3>
    </div>
    <!-- Accordin Menu section -->
    <div class="row">
        <br />
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <!--  <div class="panel-group" id="accordion">
                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseOne">
                        <div class="row">
                            <div class="col-md-11 no-padding">
                                <h4 class="panel-title">
                                    <span class="glyphicon glyphicon-import"></span>&nbsp;&nbsp;<a href="">IMPORT CHARGES</a>
                                </h4>
                            </div>
                            <div class="col-md-1 glyphicon glyphicon-minus" style="left:40px"></div>
                        </div>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/importchargesearch">Search/Pay Import Charges</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/importaccounthistory">Search Import Invoices</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/importstatements">Create Import Statements</button></div>
                            </div>
                            @*<br />*@
                            @*<div class="row">*@
                                @*<div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary">Ancillary Charges</button></div>*@
                                @*<div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary">Ancillary Invoices</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary">Ancillary Statements</button></div>*@
                            @*</div>*@
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTwo">
                        <div class="row">
                            <div class="col-md-11 no-padding">
                                <h4 class="panel-title">
                                    <span class="glyphicon glyphicon-export"></span>&nbsp;&nbsp;<a href="">EXPORT CHARGES</a>
                                </h4>
                            </div>
                            <div class="col-md-1 glyphicon glyphicon-plus" style="left:40px"></div>
                        </div>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/oogchassisflipsearch">OOG Chassis Flip</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/oogstripstuffsearch">OOG Strip & Stuff</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/vgmservicesearch">Order VGM Service</button></div>                                
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/exportaccounthistory">Search Export Invoices</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseThree">
                        <div class="row">
                            <div class="col-md-11 no-padding">
                                <h4 class="panel-title">
                                    <span class="glyphicon glyphicon-user"></span>&nbsp;&nbsp;<a href="">ACCOUNT MANAGEMENT</a>
                                </h4>
                            </div>
                            <div class="col-md-1 glyphicon glyphicon-plus" style="left:40px"></div>
                        </div>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/registration">Account Maintenance</button></div>
                                <div class="col-md-4"><button type="button" class="btn btn-block btn-site btn-primary" data-custom-click="/ViewRegistration">Company Registrations</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            

<div class="container">
<div class="row justify-content-md-center">
<div class="row">
        <h3 style="margin-top: 0px;color:#333333;">What can I do with TOPS?</h3>
        <p>TOPS is designed to help you optimize the flow of your cargo through APM Terminals. From tracking your cargo to paying for terminal services, TOPS gives you the tools and visibility you need to effectively manage your supply chain.</p>
</div>
<div class="col-md-6 stylepading">
<div class="row rprop"><span style="padding-right:10px;">
    <img src="assets/Images/TOPS_Logo_signature_image.png"/></span>Pay Import Charges</div>
<div class="row stylepading1"><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:0px;"><img class="img-fluid" src="assets/Images/image-PayImportCharges2.png" alt=""></div><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:40px;"><p>Easily look up and pay for pending hold activity charges to avoid unexpected supply chain delays.</p>
          </div></div>
</div>
<div class="col-md-6 stylepading">
<div class="row rprop"><span style="padding-right:10px;">
    <img src="assets/Images/TOPS_Logo_signature_image.png" /></span>Pay Export Charges</div>
<div class="row stylepading1"><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:0px;"><img class="img-fluid" src="assets/Images/image-PayExportCharges.png" alt=""></div><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:40px;"><p>Conveniently look up and pay for pre or post gate entry charges for your export cargo.</p>
          </div></div>
</div>
</div>
</div>
<div class="container">
<div class="row justify-content-md-center">
<div class="col-md-6 stylepading">
<div class="row rprop"><span style="padding-right:10px;">
    <img src="assets/Images/TOPS_Logo_signature_image.png" /></span>Track and Trace</div>
<div class="row stylepading1"><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:0px;"><img class="img-fluid" src="assets/Images/imageTrackandTrace.png" alt=""></div><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:40px;"><p>Easily track and manage your cargo located at APM Terminals with searches by container, bill of lading, or booking number.</p>
          </div></div>
</div>
<div class="col-md-6 stylepading">
<div class="row rprop"><span style="padding-right:10px;">
    <img src="assets/Images/TOPS_Logo_signature_image.png" /></span>Order Services</div>
<div class="row stylepading1"><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:0px;"><img class="img-fluid" src="assets/Images/images-order services New.png" alt=""></div><div class="col-md-3 sizeofimage" style="padding-right: 0px; padding-left:40px;"><p>Order and pay for terminal services online through our Terminal On-Line Payment System (TOPS).</p>
          </div></div>
</div>
</div>
</div>
        <br /><br />
        </div>
        <div class="col-md-1"></div>
    </div>
</div>