import { Component, OnInit,Inject } from '@angular/core';
import { DemurrageHoldCharges } from 'src/app/core/models/ContainerHoldCharges';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-show-containerinfo',
  templateUrl: './show-containerinfo.component.html',
  styleUrls: ['./show-containerinfo.component.css']
})
export class ShowContainerinfoComponent implements OnInit {
  Container: DemurrageHoldCharges;
  constructor(public dialogRef: MatDialogRef<ShowContainerinfoComponent>,
  @Inject(MAT_DIALOG_DATA) public Containerdata: any,) { this.Container = new DemurrageHoldCharges() }

  ngOnInit(): void {
    this.Container=this.Containerdata.Container;   
  }

  closeModal() {
    this.dialogRef.close('cancel');
  }

}
