import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CyberSource,ShoppingCart } from 'src/app/core/models/ShoppingCart';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';

@Component({
  selector: 'app-cybersourceprocessing',
  templateUrl: './cybersourceprocessing.component.html',
  styleUrls: ['./cybersourceprocessing.component.css']
})
export class CybersourceprocessingComponent implements OnInit {
  Decision:any;
  ShowLoader = false;
  HoldType:any;
  TransactionReferenceId:CyberSource;
  Messages: ResponseMessage[]=[];
  ShoppingCart:ShoppingCart;
  constructor(private importchargesService : ImportchargesService,
              private router: Router,
              private ShoppingCartService : ShoppingCartService) { this.ShoppingCart = new ShoppingCart();}

  ngOnInit(): void {
    
    this.Decision = null;
	this.TransactionReferenceId = new CyberSource();
    this.TransactionReferenceId.reference_number = String(this.ShoppingCartService.TransactionReferenceId() || '');
    this.HoldType = this.ShoppingCartService.HoldType();

    if (this.TransactionReferenceId.reference_number === null || this.TransactionReferenceId.reference_number === '' || this.HoldType === null)
    	this.router.navigateByUrl('/landingpage');
	  else
		this.ProcessCyberSourceTransaction();
  }

  ProcessCyberSourceTransaction () {
		// Send the Transaction Reference ID via the CyberSource Web/Mobile Parameters model		
		if (this.HoldType === 'E') {
      		this.ShowLoader = true;
			this.importchargesService.ProcessExportCyberSourceTransaction(this.TransactionReferenceId).subscribe((result) => {
				if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
					this.Messages = result.generalMessages;
					this.ShowLoader = false;
					// Delete the Shopping Cart cookie
					this.ShoppingCartService.DeleteCookie();
				}
				else {
					if (this.IsFieldNotBlank(result.ReturnURL)) {
						this.router.navigateByUrl(result.ReturnURL);
					}
				}
			});
		}
		else if (this.HoldType === 'S') {
			this.importchargesService.ProcessServiceOrderCyberSourceTransaction(this.TransactionReferenceId).subscribe((result) => {
			if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
				this.Messages = result.generalMessages;
				this.ShowLoader = false;
				// Delete the Shopping Cart cookie
				this.ShoppingCartService.DeleteCookie();
				}
				else {
					if (this.IsFieldNotBlank(result.ReturnURL)) {
						this.router.navigateByUrl(result.ReturnURL);
					}
				}
			});
		} else {
			this.importchargesService.ProcessImportCyberSourceTransaction(this.TransactionReferenceId).subscribe((result) => {
			if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {					
				this.Messages = result.generalMessages;
				this.ShowLoader = false;          
				// Delete the Shopping Cart cookie
				this.ShoppingCartService.DeleteCookie();
				}
				else {
					if (this.IsFieldNotBlank(result.ReturnURL)) {
						this.router.navigateByUrl(result.ReturnURL);
					}
				}
			});
		}
	};
  
  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

}
