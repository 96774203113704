<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >

    <div class="row col-md-12 col-sm-12 divTop-border-empty1 noPrint"></div>
    <div class="row col-md-12 col-sm-12 grid-padding" *ngIf="Errors.length > 0 || Messages.length > 0">
        <div class="col-md-12 col-sm-12 alert alert-danger">
            <ul *ngFor="let Msg of Errors">
                <li><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}</li>
            </ul>
        </div>
    </div>
    <br />
    <br />

    <div class="row col-md-12 col-sm-12 " >
        <div class="text-right noPrint">
            <button  class="btn btn-primary btn-sm btn-site text-right" (click) = "print();" ng-print print-element-id="printSection" id="printButton"><span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print</button>
        </div>
    </div>
    <div *ngFor="let row of BookingInvoice" style="page-break-after:always">              

        <div class="row" id="printSection">
            <div class="col-md-12 col-sm-12">
                <div class="col-md-1 col-sm-1"></div>
                <div class="col-md-10 col-sm-10">
                   <div class="text-center img-responsive" *ngIf="row.TerminalLogo != null">
                        <h1>
                            <div style="margin-top: -41px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                            <!-- <img src="data:image/png;base64,assets/Images/Primary_Logo_Positive.png" /> -->
                        </h1>
                    </div>
                    <div class="text-center text-primary" id="TerminalLoc">
                        <h2>
                            {{row.TerminalLoc}}
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Invoice Number :</label></div>
                        <div class="col-md-9 col-sm-9 text-left text-danger disp-Inline"><label >{{row.InvoiceNumber}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Invoice Date :</label></div>
                        <div class="col-md-9 col-sm-3 text-left disp-Inline">
                            <label >{{row.InvoiceDate}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>PO Number :</label></div>
                        <div class="col-md-9 col-sm-9 text-left disp-Inline">
                            <label >{{row.PoNumber}}</label>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-left:0px">
                            <div class="panel panel-default" style="min-height:260px">
                                <div class="panel-heading">
                                    <h4>Bill To: <label class="text-primary">{{row.Client}}</label></h4>
                                </div>
                                <div class="panel-body">
                                    <!-- <p> -->
                                        Address:
                                        <address>
                                            {{row.ClientAddress}} 
                                            {{row.ClientCity}}
                                        </address>
                                        Attn:&nbsp;{{row.ClientContact}}
                                    <!-- </p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-right:0px">
                          <div class="panel panel-default" style="min-height:260px">
                            <div *ngIf="!row.HasOverNightAddress">
                              <div class="panel-heading">
                                <h4>Remit To : <label class="text-primary">{{row.TerminalLoc}}</label></h4>
                              </div>
                              <div class="panel-body">
                                <!-- <p> -->
                                Address:
                                <br />
                                <address>
                                  {{row.TerminalAttn}} <br />
                                  {{row.TerminalAddr}} <br />
                                  {{row.TerminalCity}} <br />
                                </address>
                                <!-- </p> -->
                              </div>
                            </div>
                            <div *ngIf="row.HasOverNightAddress">
                              <div class="panel-heading">
                                <h4>Remit To : </h4>
                              </div>
                              <div class="panel-body-overnightaddress">
                                <label class="text-primary">
                                  USPS Mailing Address
                                </label>
                                <address>
                                  {{row.TerminalLoc}} <br />
                                  {{row.TerminalAddr}} <br />
                                  {{row.TerminalCity}} <br />
                                </address>
                                <label class="text-primary">Overnight Mailing Address</label>
                                <address>
                                  {{row.TerminalOverNightAddr1}} <br />
                                  {{row.TerminalOverNightAddr2}} <br />
                                  {{row.TerminalOverNightAddr3}} <br />
                                  {{row.TerminalOverNightCity}} <br />
                                </address>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 disp-Inline">
                            <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                <label>Booking:</label>
                            </div>
                            <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                <label >{{ row.BookingNumber }}</label>
                            </div>
                        </div>
                        <div class="col-md-8 col-sm-8 disp-Inline">
                            <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                <label>Location:</label>
                            </div>
                            <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                <label >{{row.TerminalName}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 disp-Inline">
                            <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                <label>Line:</label>
                            </div>
                            <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                <label >{{row.ShippingLineName}}</label>
                            </div>
                        </div>
                        <div class="col-md-8 col-sm-8 disp-Inline">
                            <div class="col-md-5 col-ms-4 text-right disp-Inline">
                                <label>Voyage:</label>
                            </div>
                            <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                <label >{{row.VoyageNumber}}</label>
                            </div>
                        </div>
                    </div>                   
                    <div class="row table-responsive">
                        <table class="table table-bordered table-striped" data-st-table="BookingInvoiceItemsCollection" data-st-safe-src="row.BookingInvoiceItems">
                            <thead>
                                <tr>
                                    <th data-st-ratio="30">Charge Type</th>
                                    <th data-st-ratio="10">Charge Quantity</th>
                                    <th data-st-ratio="10">Paid Date</th>
                                    <th data-st-ratio="50">Charge Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let book of row.BookingInvoiceItems">
                                    <td data-st-ratio="30">{{book.HoldName}}</td>
                                    <td data-st-ration="10">{{book.ChargeQty}}</td>
                                    <td data-st-ration="10">{{book.PaidDate  | date:'MM/dd/yyyy'}}</td>
                                    <td data-st-ratio="50">{{book.ChargeAmount | currency}}</td>
                                </tr>
                                <tr *ngIf="!row.BookingInvoiceItems.length">
                                    <td colspan="4">
                                        No results Found.
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4" class="text-right" style="padding-right:63px">Total Invoice : {{row.TotalAmt | currency}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right" style="padding-right:63px">Amount Paid : {{row.PaymentAmt | currency}}</td>
                                </tr>
                                <tr>
                                        <td colspan="4" class="text-right" style="padding-right:63px">Amount Due : {{row.PaymentRemain | currency}}</td>
                                    </tr>
                            </tfoot>
                        </table>
                        <br />
                    </div>
                    <div class="row">
                        <p *ngIf="row.PaymentRemain > 0"><strong><br />Balance Due - PAYABLE UPON RECEIPT</strong></p><br />
                        <p>For bill inquiries: <label >{{row.TerminalPhone}}</label></p><br />
                        <p *ngIf="row.PaymentRemain > 0"><label>Please attach invoice with your payment and indicate invoice number and container number on your check.</label></p><br />
                    </div>
                </div>

                <div class="col-md-1 col-sm-1"></div>
            </div>
        </div>
        <br /><br />


    </div>
    <br /><br />
</div>
