<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" style="background-color: white;"  >
    <div class="row" style="padding-bottom:10px;" >
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Payment Receipt</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div>
    <div *ngIf="Messages.length > 0">
        <div class="row" *ngFor="let Msg of Messages">
            <br />
            <div class="col-md-8 col-md-offset-2">
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>
    <form name="shoppingCartReceipt" novalidate>
        <br />
        <div class="row" *ngIf="Messages.length == 0 && ShoppingCart !=null">
            <!-- @Html.Partial("~/Views/Partial/ShoppingCart/_ShoppingCartDetails.cshtml") -->
            <app-shopping-cart-details [ShoppingCart]="this.ShoppingCart"></app-shopping-cart-details>
        </div>        
        <br /><br />
        <div class="row col-md-12 text-right">
            <div class="col-md-10">
                <!-- BACK BUTTON -->
                <button class="btn btn-primary btn-site btn-margin" [routerLink]="['/landingpage']" >
                    <span class="glyphicon glyphicon-home btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Return Home
                </button>
            </div>
        </div>
    </form>
    <br /><br />
</div>