<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div >
    <div class="row col-md-12 col-sm-12 col-xs-12 ">
        <div class="text-right noPrint">
            <button ng-print print-element-id="printableArea" class="btn btn-primary btn-sm btn-site text-right" (click) ="print();" id="printButton"><span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print</button>
        </div>
    </div>
    <div id="printableArea" *ngFor="let row of PrintInvoice" style="page-break-after:always">
        <table border="0" width="100%" cellspacing="0" cellpadding="0" id="DemurageTable" bgcolor="white"  style="font-size:16px;text-align: center;">
            <tbody>
                <tr>
                    <td valign="top">
                        <br>                       
                            <font size="3" face="Arial"></font>                        
                        <br>
                        <div style="margin-top: -41px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                            <!-- <img class="header" height="60" alt="Container Terminal" ng-src="data:image/jpg;base64,{{row.TerminalLogo}}" align="middle" border="0" style="font-size:16px"> -->
                            <br>
                            <!-- <br> -->
                            <font size="3" face="Arial"><b>&nbsp;&nbsp;{{row.TerminalLoc}}</b></font>
                            <br>
                            <br>  
                            <br>                      
                    </td>
                </tr>
            </tbody>
        </table>
        <table border="0" width="90%" cellspacing="0" cellpadding="0" id="DemurageTable" bgcolor="white"  style="font-size:16px">
            <tbody>
                <tr>
                    <td valign="top">
                        <div id="New Demurrage invoice format_29095" align="center">
                            <table width="95%" style="TABLE-LAYOUT: fixed; BORDER-COLLAPSE: collapse;font-size:16px" cellspacing="0" cellpadding="0" border="0" x:str>
                                <tr>
                                    <td colspan="2"></td>
                                    <td valign="middle" align="center"></td>
                                    <td valign="top">
                                        <table>
                                            <tr>
                                                <td><strong>Invoice Number:</strong></td>
                                                <td><span id="lblInvoiceNumber">{{row.InvoiceNumber}}</span></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Invoice date:</strong></td>
                                                <td><span id="lblInvoiceDate">{{row.InvoiceDate}}</span></td>
                                            </tr>
                                            <tr>
                                                <td width="200"><strong>Your reference number:</strong></td>
                                                <td width="100"><span id="lblReferenceNumber">{{row.ReferenceNumber}}</span></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <table width="95%" style="TABLE-LAYOUT: fixed; BORDER-COLLAPSE: collapse;  font-size:16px" cellspacing="0" cellpadding="0" border="0" x:str>
                                <tr>
                                    <td valign="top">
                                        <table>
                                            <tr>
                                                <td width="80"></td>
                                                <td valign="top" align="right"><b>Bill to:</b></td>
                                                <td valign="top" align="left">
                                                    <span id="lblClientAddress">
                                                        {{row.Client}}<br>
                                                        {{row.ClientAddress}}<br>
                                                        {{row.ClientCity}}<br>
                                                        {{row.ClientCountry}}<br>
                                                        {{row.ClientContact}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td valign="top">
                                      <div *ngIf="!row.HasOverNightAddress">
                                        <table>
                                          <tr>
                                            <td width="80"></td>
                                            <td valign="top"><b>Remit to :</b></td>
                                            <td valign="top">
                                              {{row.TerminalLoc}}<br>
                                              {{row.TerminalAttn}}<br>
                                              {{row.TerminalAddr}}<br>
                                              {{row.TerminalCity}}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                      <div *ngIf="row.HasOverNightAddress">
                                        <table>
                                          <tr>
                                            <td width="80"></td>
                                            <td valign="top"><b>Remit to :</b></td>
                                            <td valign="top">                                             
                                                USPS Mailing Address
                                              <br />
                                              {{row.TerminalLoc}}<br>
                                              {{row.TerminalAddr}}<br>
                                              {{row.TerminalCity}}
                                            </td>
                                            <td width="20"></td>
                                            <td>
                                              Overnight Mailing Address<br />
                                              {{row.TerminalOverNightAddr1}}<br>
                                              {{row.TerminalOverNightAddr2}}<br>
                                              {{row.TerminalOverNightAddr3}}<br>
                                              {{row.TerminalOverNightCity}}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <br>
                            <table style="BORDER-COLLAPSE:collapse;font-size:16px;margin-left:.75cm;margin-right:.5cm" cellspacing="0" cellpadding="0" width="95%" border="1">
                                <tr>
                                    <td colspan="11"><strong> &nbsp;&nbsp;&nbsp;&nbsp;Location :</strong><span id="lblTerminal">{{row.TerminalName}}</span></td>
                                    <td colspan="4"><strong>&nbsp;&nbsp;&nbsp;&nbsp;Demurrage</strong></td>
                                </tr>
                                <tr  align="right">
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Container&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Bill of Lading&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Vessel&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Voyage&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;S/S Line&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;F/A date&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;LFD&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Pickup date&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Holidays&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Size&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Type&nbsp;</strong></td>
                                    <td width="135px" align="center" style="font-size:16px;"><strong>&nbsp;Day Range&nbsp;</strong></td>
                                    <td width="60px" align="center" style="font-size:16px"><strong>&nbsp;$/Day&nbsp;</strong></td>
                                    <td width="60px" align="center" style="font-size:16px;"><strong>&nbsp;# of days&nbsp;</strong></td>
                                    <td align="center" style="font-size:16px"><strong>&nbsp;Charge&nbsp;</strong></td>
                                </tr>
                                <tr *ngFor="let demrow of row.DemurageInvoice" style="font-size:16px">
                                    <td valign="top">&nbsp;{{demrow.ContainerNumber}}&nbsp;</td>
                                    <td valign="top">&nbsp;{{demrow.BillLadingNumber}}&nbsp;</td>
                                    <td valign="top">&nbsp;{{demrow.VesselName}}</td>
                                    <td valign="top">&nbsp;{{demrow.VoyageNumber}}&nbsp;</td>
                                    <td valign="top">&nbsp;{{demrow.ShippingLineName}}&nbsp;</td>
                                    <td valign="top">&nbsp;{{demrow.DateTimeOfFirstAvailability | date:'MM/dd/yyyy'}}&nbsp;</td>
                                    <td valign="top" width="75px">
                                        <table border=1 style="BORDER-COLLAPSE:collapse;font-size:16px" width="75px">
                                            <tr>
                                                <td>Port</td>
                                                <td>Line</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{demrow.PortLastFreeDate | date:'MM/dd/yyyy'}}&nbsp;</td>
                                                <td>&nbsp;{{demrow.LineLastFreeDate | date:'MM/dd/yyyy'}}&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td valign="top">&nbsp;{{demrow.PickupDate| date:'MM/dd/yyyy'}}&nbsp;</td>
                                    <td valign=top>
                                        <table>
                                            <tr>
                                                <td width="35px">&nbsp;{{demrow.Holidays}}&nbsp;</td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td valign="top">&nbsp;{{demrow.ContainerTypeAttribute1}}&nbsp;</td>
                                    <td valign="top">&nbsp;{{demrow.ContainerTypeAttribute2}}&nbsp;</td>
                                    <td valign="top" colspan="4">
                                        <table style="BORDER-COLLAPSE:collapse;font-size:16px" width="100%" border=1>
                                            <tr *ngFor="let inv of demrow.InvoiceTariff">
                                                <td align=center width="135px">&nbsp;{{inv.DateRange}}&nbsp;</td>
                                                <td align=center width="60px">&nbsp;{{inv.AmountPerDay}}&nbsp;</td>
                                                <td align=center width="60px">&nbsp;{{inv.NumberofDays}}&nbsp;</td>
                                                <td align=center>&nbsp;{{inv.TotalCharge}}&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr border=0 style="BORDER-COLLAPSE:collapse; font-size:16px" *ngFor="let demrow of row.DemurageInvoice">
                                    <td colspan="11"><b>Notes:</b>&nbsp;&nbsp;{{demrow.AdjustmentsNotes }}</td>  
                                    <td colspan="15" align="right">
                                        <table>
                                            <tr *ngIf="demrow.PortAdjApplied">
                                                <td>Terminal Adj.</td>
                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                <td>{{demrow.TerminalAdjustment}}</td>
                                            </tr>
                                        </table>
                                        <br>
                                        <table>
                                            <tr *ngIf="demrow.LineAdjApplied">
                                                <td>Line Adj.</td>
                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                <td>{{demrow.LineAdjustment}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr *ngFor="let demrow of row.DemurageInvoice" style="font-size:16px">
                                    <td align="right" colspan="11">&nbsp;</td>
                                    <td align="right" colspan="3">Sub Total </td>
                                    <td align="right">&nbsp;&nbsp;{{demrow.SubTotal}}</td>
                                </tr>
                                <tr *ngIf="row.NetAncillaryCharges != null && row.NetAncillaryCharges.length>0" style="font-size:16px">
                                    <td colspan="14" align="right">Ancillary Charge : {{row.NetAncillaryCharges}}</td>
                                </tr>
                                <tr>
                                    <td align="right" colspan="14"><font size=2><b>Total Due:&nbsp;&nbsp;</b></font></td>
                                    <td align="right">{{row.CurrencyCode}}{{row.TotalAmt}}</td>
                                </tr>
                                <tr>
                                    <td align="right" colspan="14"><font size=2><b>Amount Paid:&nbsp;&nbsp;</b></font></td>
                                    <td align="right">{{row.CurrencyCode}}{{row.PaymentAmt}}</td>
                                </tr>
                                <tr>
                                    <td align="right" colspan="14"><font size=2><b>Amount Due:&nbsp;&nbsp;</b></font></td>
                                    <td align="right">&nbsp;&nbsp;{{row.CurrencyCode}}{{row.PaymentRemain}}</td>
                                </tr>
                            </table>
                            <br>
                            <br>
                            <br>
                            <table style="TABLE-LAYOUT: fixed; WIDTH: 469pt; BORDER-COLLAPSE: collapse;font-size:16px;" cellspacing="0" cellpadding="0" width="624" border="0" x:str>

                                <tr style="HEIGHT: 12.75pt" height="17">
                                    <td colspan="8" height="17" *ngIf="row.PaymentRemain>0">
                                        <strong><br />Balance Due - PAYABLE UPON RECEIPT</strong>
                                    </td>

                                </tr>
                                <tr></tr>
                                <tr style="HEIGHT: 12.75pt" height="17">
                                    <td colspan="8" height="17"> For bill inquiries: {{row.BillEnquires}} </td>

                                </tr>
                                <tr></tr>
                                <tr style="HEIGHT: 12.75pt" height="17">
                                    <td colspan="8" height="17" *ngIf="row.PaymentRemain>0">
                                        <strong>
                                            Please attach invoice with your payment and indicate invoice number and container number on your check.
                                        </strong>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
<br />

    </div>

</div>
