import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { LayoutComponent } from './common/layout/layout.component';
import { HomeComponent } from './common/home/home.component';
import { ExamratesComponent } from './common/home/examrates/examrates.component';
import { FaqComponent } from './common/home/faq/faq.component';
import { ContactComponent } from './common/home/contact/contact.component';
import { PaymentoptionsComponent } from './common/home/paymentoptions/paymentoptions.component';
import { RegistrationComponent } from './common/registration/registration/registration.component';
import { LandingpageComponent } from './core/components/landingpage/landingpage.component';
import { ViewRegistrationComponent } from './core/components/view-registration/view-registration.component';
import { NotificationlistComponent } from './core/components/notificationlist/notificationlist.component';
import { ImportchargesearchComponent } from './core/components/importcharges/importchargesearch/importchargesearch.component';
import { OogchassisflipsearchComponent } from './core/components/exportcharges/oogchassisflipsearch/oogchassisflipsearch.component';
import { TrackandtraceComponent } from './core/components/trackandtrace/trackandtrace.component';
import { ImportChargeResultsComponent } from './core/components/importcharges/importchargeresults/importchargeresults.component';
import { ImportaccounthistoryComponent } from './core/components/importcharges/importaccounthistory/importaccounthistory.component';
import { NewCheckOutConfirmationComponent } from './core/components/importcharges/new-check-out-confirmation/new-check-out-confirmation.component';
import { CheckOutConfirmationComponent } from './core/components/importcharges/check-out-confirmation/check-out-confirmation.component';
import { VgmservicesearchComponent } from './core/components/exportcharges/vgmservicesearch/vgmservicesearch.component';
import { OogstripstuffsearchComponent } from './core/components/exportcharges/oogstripstuffsearch/oogstripstuffsearch.component';
import { ExportaccounthistoryComponent } from './core/components/exportcharges/exportaccounthistory/exportaccounthistory.component';
import { ImportstatementsComponent } from './core/components/importcharges/importstatements/importstatements.component';
import { ExportInvoicePreviewComponent } from './core/components/exportcharges/exportaccounthistory/export-invoice-preview/export-invoice-preview.component';
import { DemuragePreviewComponent } from './core/components/importcharges/importaccounthistory/demurage-preview/demurage-preview.component';
import { ExportbookingholdsconfirmationComponent } from './core/components/exportcharges/exportbookingholdsconfirmation/exportbookingholdsconfirmation.component';
import { CheckoutcompletionComponent } from './core/components/importcharges/checkoutcompletion/checkoutcompletion.component';
import { StatementInvoiceListComponent } from './core/components/importcharges/importstatements/statement-invoice-list/statement-invoice-list.component';
import { CybersourceprocessingComponent } from './core/components/shoppingcart/cybersourceprocessing/cybersourceprocessing.component';
import { ImportInvoicePreviewComponent } from './core/components/importcharges/importaccounthistory/import-invoice-preview/import-invoice-preview.component';
import { DemurrageConfirmationComponent } from './core/components/importcharges/demurrage-confirmation/demurrage-confirmation.component';
import { EIRPrintDetailsComponent } from './core/components/trackandtrace/gate-transaction-results/eirprint-details/eirprint-details.component';

const routes: Routes = [
  { path: "", component: LayoutComponent, pathMatch: "full", children: [{ path: "", component: HomeComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "home", component: HomeComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "examrates", component: ExamratesComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "paymentoptions", component: PaymentoptionsComponent }], canActivate: [AuthGuardService] }
  , { path: "", component: LayoutComponent, children: [{ path: "faq", component: FaqComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "contact", component: ContactComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "registration", component: RegistrationComponent }] }
  , { path: "", component: LayoutComponent, children: [{ path: "landingpage", component: LandingpageComponent, canActivate: [AuthGuardService] }] } 
  , { path: "", component: LayoutComponent, children: [{ path: "ViewRegistration", component: ViewRegistrationComponent, canActivate: [AuthGuardService] }] }  
  , { path: "", component: LayoutComponent, children: [{ path: "notificationlist", component: NotificationlistComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "importchargesearch", component: ImportchargesearchComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "oogchassisflipsearch", component: OogchassisflipsearchComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "trackandtrace", component: TrackandtraceComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "importchargeresults", component: ImportChargeResultsComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "importaccounthistory", component: ImportaccounthistoryComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "newcheckoutconfirmation", component: NewCheckOutConfirmationComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "checkoutconfirmation", component: CheckOutConfirmationComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "vgmservicesearch", component: VgmservicesearchComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "oogstripstuffsearch", component: OogstripstuffsearchComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "exportaccounthistory", component: ExportaccounthistoryComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "importstatements", component: ImportstatementsComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "checkoutcompletion", component: CheckoutcompletionComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "exportbookingholdsconfirmation", component: ExportbookingholdsconfirmationComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "cybersourceprocessing", component: CybersourceprocessingComponent, canActivate: [AuthGuardService] }] }
  , { path: "", component: LayoutComponent, children: [{ path: "demurrageconfirmation", component: DemurrageConfirmationComponent, canActivate: [AuthGuardService] }] }
  , { path: "ExportInvoicePreview", component: ExportInvoicePreviewComponent, canActivate: [AuthGuardService] }
  , { path: "DemurageInvoice", component: DemuragePreviewComponent, canActivate: [AuthGuardService] }
  , { path: "InvoicePreview", component: ImportInvoicePreviewComponent, canActivate: [AuthGuardService] }
  , { path: "StatementInvoiceList", component: StatementInvoiceListComponent, canActivate: [AuthGuardService] }
  , { path: "EIRPrintDetails", component: EIRPrintDetailsComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

