<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="row" style="padding-bottom: 10px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
        <h3><strong>Container Notifications List</strong></h3>
    </div>
    <div class="col-md-4"></div>
</div>
<div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom: 10px"></div>
<div class="row" *ngIf="this.Messages.length > 0" style="margin-bottom: 20px !important;">
    <div *ngFor="let Msg of this.Messages">
        <div [ngClass]="Msg.severity===1?'alert alert-info text-center':Msg.severity===3? 'alert alert-danger text-center':Msg.severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}} 
        </div>
    </div>
</div>
<div class="row" *ngIf="this.NotificationResults.length > 0" style="margin-bottom: 20px !important;">
    <div class="alert alert-danger">
        <ul *ngFor="let Msg of this.NotificationResults">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail +' - not found'}} 
        </ul>
    </div>
</div>
<div style="height:20px;"></div>
<div class="row ">
    <div class="col-md-8 col-sm-8" ></div>
    <!--BUTTON -->
    <div class="col-md-4 col-sm-4">
        <button type="button" class="btn btn-primary btn-site " style="font-size: 20px; margin: 0px 10px 0px 0px;" [routerLink]="['/landingpage']" ><span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size: 20px; padding-right: 5px"></span><b>Back</b></button>
        <button type="button" class="btn btn-primary btn-site " style="font-size: 20px; margin: 0px 10px 0px 0px;" (click)="AddcontainerPopup($event)" [disabled]="this.NotificationCount >= 200"><span class="glyphicon glyphicon-plus" aria-hidden="true" style="font-size: 20px; padding-right: 5px"></span><b>Add</b></button>
        <button type="button" class="btn btn-primary btn-site " style="font-size: 20px; margin: 0px 10px 0px 0px;" (click)="deleteContainers()" [disabled]="this.selectedContainerCollection.length == 0"><span class="glyphicon glyphicon-remove" aria-hidden="true" style="font-size: 20px; padding-right: 5px"></span><b>Delete</b></button>
        <p style="padding-top: 10px">See <a [routerLink]="['/faq']">FAQ </a> for more information about Notifications</p>
    </div>
 
</div>
<div class="row ">
    <div class="col-md-2 col-sm-2"></div>
    <div class="col-md-4 col-sm-4">
        <div>
            <h4 class="text-left"><strong style="color: #FF6218">Import Container List: {{NotificationCount}} of {{MaxNotificationCount}} &nbsp; <span>(Available to Add : {{MaxNotificationCount-NotificationCount}})</span></strong></h4>
        </div>
    </div>
</div>
<div class="row col-md-12 col-sm-12">
    <div class="col-md-8 col-md-offset-2" style="padding-bottom: 15px;">
        <div >
<!-- <div style="text-align:center"> -->
    <div  class="mat-elevation-z8" style ="margin-left: -15px;max-height: 500px;overflow-y:scroll" >
    <table mat-table [dataSource]="dataSource"  *ngIf="this.NotificationList.length > 0" >
            <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->      
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="_select">
                        <mat-checkbox (change)="$event ? masterToggle($event) : null"  (click)="$event.stopPropagation()"   [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"  >
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="_select"> 
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="OnChange($event,row)"  [checked]="selection.isSelected(row)"   >
                        </mat-checkbox>
                    </td>
                </ng-container>

            <!-- Container Column -->
            <ng-container matColumnDef="ContainerNumber">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"> Container </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.ContainerNumber}} </td>
            </ng-container>
        
            <!-- TerminalName Column -->
            <ng-container matColumnDef="TerminalName">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"> Terminal </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TerminalName}} </td>
            </ng-container> 
        
            <!-- email Column -->
            <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext">email </th>
            <td mat-cell *matCellDef="let element" style=" white-space: pre-line;" class="tablebodytext"> {{  element.email }} </td>
            </ng-container>        
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="this.NotificationList.length == 0" class="no-records"> No Results found.</div>  
    </div>
<!-- </div> -->
</div>             
</div>
</div>



