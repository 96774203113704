export class Invoices {
  InvoiceData: InvoiceData[];
  MessageList: []
  generalMessages: string

}
export class InvoiceFilters {
  User_ID: number;
  FromDate: Date;
  ToDate: Date;
  ContainerNum: string;
  InvoiceNum: string;
  StatementNum: string;
  InvoiceAncil: boolean;
}

export class InvoiceData {
  InvoiceId: number;
  InvoiceNumber: string;
  StatementNumber: string;
  ReferenceNumber: string;
  ClientId: number;
  TerminalId: number;
  ContractId: number;
  InvoiceDate: Date;

  BillingAmount: number;

  TerminalDisbursmentAmount: number;

  EquipmentDisbursmentAmount: number;

  BillingNotes: string

  CollectionNotes: string

  InvoiceStatusId: number

  CurrencyId: number

  UserId: number

  Status: boolean

  Contact_ID: number

  UniqueInvoiceId: string
  //fields for the grid 
  Customer: string
  Terminal: string
  TotalAmount: string
  OutstandingBalance: string
  //  OutstandingBalance 
  StatusName: string
  CurrencyCode: string
  PoNumber: string
  ContactId: number
}

