<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<form name="AdditionalInfoForm" [formGroup]="AdditionalInfoForm" novalidate style="display:block !important;" ng-cloak>
    <div  style="min-width:600px;text-align: center;display: block;"  class="modal-header custom-modal-header">      
        <h4 class="modal-title text-center">Additional Information Required!</h4>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="Messages.length > 0">
            <div  *ngFor="let Msg of Messages">
                <div  [ngClass]="Msg.severity===1?'alert alert-info':Msg.severity===3? 'alert alert-danger':Msg.severity===2? 'alert alert-warning' : 'alert alert-success'">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showmessage">
            <div class="col-md-12 col-sm-12">
                <div class="alert alert-danger text-center">{{message}}</div>
            </div>
        </div>
        <div class="row" style="padding-bottom:15px" *ngIf="!showmessage">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">Please select your <strong>Company Type</strong> to continue</div>
            <div class="col-md-2"></div>
        </div>
        <div class="row" *ngIf="!showmessage">
            <div class="row">
                <div class="col-md-5 col-sm-5 text-right"><label>Company Name</label>&nbsp;:</div>
                <div class="col-md-7 col-sm-7 text-primary text-left">{{clients[0].ClientName}}</div>
            </div>
            
            <div class="row" style="padding-top:10px">
                 <div class="col-md-5 col-sm-5 text-right"><span style="color:red">*</span><label>Company Type</label>&nbsp;:</div>
                 <div class="col-md-7 col-sm-7 text-left text-primary"  [ngClass]="{ 'has-error' : submitted && f.companytype.errors }">
                   <select  formControlName="companytype" name="companytype" class="show-tick" required data-select-picker data-selectpicker-options="ClientTypeData" [(ngModel)]="UpdateClient.ClientTypeId">
                                    <option value="">--- Please select ---</option>
                                    <option *ngFor="let option of ClientTypeData" value="{{option.ClientTypeId}}">{{option.ClientTypeDescription}}</option>                                   
                    </select>
                     <span *ngIf="submitted && f.companytype.errors?.required && companytypevalidation" class="help-block">Company Type is Required.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer form-horizontal" >
        <div class="btn-group btn-group-lg" *ngIf="showmessage">
            <button class="btn btn-primary btn-site" (click)="close()">Close</button>
        </div>
        <div class="btn-group btn-group-lg" *ngIf="!showmessage">
            <button class="btn btn-primary btn-site btn-margin" [disabled]="AdditionalInfoForm.invalid" (click)="Submit();">
                Submit</button>
        </div>
    </div>
</form>


