import { Component, OnInit,Input } from '@angular/core';
import { ShoppingCart,PaymentConfirmation } from 'src/app/core/models/ShoppingCart';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { States, ClientType,Countries, UserContactInfo, Clients } from 'src/app/shared/interfaces/login.interface';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2';
@Component({
  selector: 'app-electronic-check-payment',
  templateUrl: './electronic-check-payment.component.html',
  styleUrls: ['./electronic-check-payment.component.css']
})
export class ElectronicCheckPaymentComponent implements OnInit {
  Messages:ResponseMessage[]=[];
  @Input() ShoppingCart:ShoppingCart;
  @Input() SCartPayment:PaymentConfirmation;
  @Input() shoppingCartPayment: FormGroup;
  Countries :Countries[]= [];
  States :States[]= [];
  ShowLoader = false;
  constructor(private formBuilder: FormBuilder,
              private importchargesService : ImportchargesService,) { }

  ngOnInit(): void {
    
    this.Countries = [];
    this.ShowLoader = true;
    this.importchargesService.GetAllCountriesforPayment().subscribe((result) => {      
      this.ShowLoader = false;
        this.Countries = result.Countries;
    });    

    // this.shoppingCartPayment = this.formBuilder.group(
    //   {
    //     firstName: ['', Validators.required],
    //     lastName: ['', Validators.required ],
    //     address1: ['', Validators.required ],
    //     cityLocation: ['', Validators.required ],
    //     zip: ['', Validators.required ],
    //     countries: ['', Validators.required ],
    //     states: ['', Validators.required ],
    //     cardType: ['', Validators.required ],
    //     cNumber: ['', Validators.required ],
    //     cardExpMonth: ['', Validators.required ],
    //     cardExpYear: ['', Validators.required ],
    //     cvv: ['', Validators.required ],
    //   });
  }
  
  GetCoutryStates(){
    var valID = '';
     this.SCartPayment.BillingInfo.State = "";    
    this.Countries.forEach((item:any) => {
      if (this.SCartPayment.BillingInfo.Country != '' && this.SCartPayment.BillingInfo.Country != undefined && this.SCartPayment.BillingInfo.Country != null) {
        if (item.CountryCode == this.SCartPayment.BillingInfo.Country) {
            valID = item.CountryName.toString();
        }
      }
    });
    if(valID != ''){
    this.ShowLoader = true;
    this.importchargesService.GetCoutryStates(valID).subscribe((result) => {    
      this.ShowLoader = false;
      this.States = result.States;
      //$scope.State = { "StateId": '', "StateName": '' };
      });
    }
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.shoppingCartPayment.controls;
  }

  ShowHelpPrompt(Control:string) {
        var PromptTitle, PromptText;
        var PromptType = 'info';

        // switch (Control) {
            // case 'CheckNumber':
              
                PromptTitle = 'Check Number';
                PromptText = 'Please type-in only the last 8 digits of the Check Number';
              
        //     default:
        //         break;
        // }

        swal.fire(PromptTitle, PromptText, 'info');
      }

}
