import { Component, OnInit } from '@angular/core';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportchargesService } from 'src/app/core/services/exportcharges.service';
import { InvoiceItem,BookingInvoiceItems } from 'src/app/core/models/export.model';
@Component({
  selector: 'app-export-invoice-preview',
  templateUrl: './export-invoice-preview.component.html',
  styleUrls: ['./export-invoice-preview.component.css']
})
export class ExportInvoicePreviewComponent implements OnInit {
  ShowLoader = false;
  BookingInvoice : InvoiceItem[]=[];
  Errors: ResponseMessage[]=[];
  Messages: ResponseMessage[]=[];
  BookingInvoiceItemsCollection:BookingInvoiceItems[]=[];
  NoPrint = false;
  constructor(private exportchargesService: ExportchargesService,    ) { }

  ngOnInit(): void {
    let ExportInvoiceFilterList = localStorage.PrinteExpInvoiceFilters;
    // let InvoicesToPrint;     
    // var InvoiceUniqueId = '';    
    if (typeof (ExportInvoiceFilterList) !== 'undefined') {     
    //     ExportInvoiceFilterList.forEach((item:any) => { InvoiceUniqueId += item.UniqueInvoiceId + ','; } );
      this.ShowLoader = true;
      this.exportchargesService.GetBookingInvoiceDataForPrint(ExportInvoiceFilterList).subscribe((result) => {
      this.ShowLoader = false;
      this.BookingInvoice = result.BookingPrintInvoice;    
      });
    }
 }

 print(){
  // this.NoPrint = true;
  window.print();
  // this.NoPrint = false;
 }

}
