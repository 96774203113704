import { Component, OnInit } from '@angular/core';
import { DemurageInvoiceItems, DemurageInvoicePrint } from 'src/app/core/models/DemurageInvoiceItems';
import { ImportchargesService } from 'src/app/core/services/importcharges.service'
@Component({
  selector: 'app-demurage-preview',
  templateUrl: './demurage-preview.component.html',
  styleUrls: ['./demurage-preview.component.css']
})
export class DemuragePreviewComponent implements OnInit {
  PrintInvoice:DemurageInvoiceItems[]=[];
  DemurageInvoicePrint:DemurageInvoicePrint;
  ShowLoader:boolean=false;
  PrintAncillary = localStorage.InvoiceAncil;
  constructor(private ImportchargesService:ImportchargesService) {   }

  ngOnInit(): void {   
    let InvoiceID='' ,ClientID='',TerminalID='';
    let InvoiceFilterList = JSON.parse(localStorage.PrintInvoiceFilters);
    InvoiceFilterList.forEach((val:any) =>  {
      //console.log("Looping InvoiceFilterList values");
      InvoiceID += val.InvoiceId + ',';
      ClientID += val.ClientId + ',';
      TerminalID += val.TerminalId + ',';
    });    

    if (InvoiceID != '' && ClientID != '' && TerminalID != '') {
    this.ShowLoader=true;
    this.ImportchargesService.GetDemurageChargeDataForPrint(InvoiceID, ClientID, TerminalID).subscribe((result) => {
      this.PrintInvoice=result.DemurageInvoice;
      this.ShowLoader = false;  
      });  
    }
    else { console.log("No Invoice ID's"); }  
  }

  print(){    
    window.print();
  }

}
