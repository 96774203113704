<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" style="background-color: white;" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Payment Receipt</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div><br />
    <div class="row col-md-12 col-sm-12" *ngIf="ShoppingCart.PaymentData !=null && ShoppingCart.PaymentData.TransactionReferenceId.length > 0">
        <div class="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">Thank you for your payment.</div>
        <div class="col-md-2 col-sm-2"></div>
    </div><br />
    <div class="row col-md-12 col-sm-12" *ngIf="ShoppingCart.PaymentData !=null && ShoppingCart.PaymentData.TransactionReferenceId.length > 0">
        <div class="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">Your reference number for this transaction is: {{ShoppingCart.PaymentData.TransactionReferenceId}}.</div>
        <div class="col-md-2 col-sm-2"></div>
    </div><br />
    <div class="row col-md-12 col-sm-12" *ngIf="ShoppingCart.generalMessages !=null && ShoppingCart.generalMessages.length > 0">
        <div class="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 alert alert-danger text-left">
            <ul *ngFor="let Msg of ShoppingCart.generalMessages">
                <li><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}</li>
            </ul>
        </div>
        <div class="col-md-2 col-sm-2"></div>
    </div>
    <form name="shoppingCartReceipt" novalidate>
        <br />
        <div class="row" *ngIf="ShoppingCart != null && ShoppingCart.LineItems != null">            
            <app-shopping-cart-details [ShoppingCart]="ShoppingCart"></app-shopping-cart-details>
        </div>
        <br /><br />
        <div class="row col-md-12 text-right">
            <div class="col-md-10">
                <!-- IMPORT SEARCH RESULTS BUTTON -->
                <button class="btn btn-primary btn-site" [routerLink]="['/importchargeresults']">
                    <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Back To Import Search Results
                </button>
                <!-- BACK BUTTON -->
                <button class="btn btn-primary btn-site btn-margin" [routerLink]="['/landingpage']">
                    <span class="glyphicon glyphicon-home btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Return Home
                </button>
            </div>
        </div>
    </form>
    <br /><br />
</div>
