<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="row divBorder" [formGroup]="shoppingCartPayment">
    <div class="col-md-12">
        <br />
        <div class="row">
            <div class="form-group col-md-6 text-primary" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType">
                <label><strong>TOPS User Name</strong></label>
            </div>
            <div class="form-group col-md-6 text-primary" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType">
                <label><strong>Name On Check</strong></label>
            </div>
        </div>
        <div class="row">
            <!-- First Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.firstName.errors && (f.firstName.touched || f.firstName.dirty)}">
                <span class="required-field">*</span><label>First Name</label>
                <input type="text" formControlName="firstName" name="firstName" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.FirstName" ng-maxlength="15" required>
                <span class="required-field" *ngIf="f.firstName.invalid && !f.firstName.pristine">
                    <span *ngIf="f.firstName.errors?.required" class="help-block">Please enter the first name on the check.</span>
                    <span *ngIf="f.firstName.errors?.maxlength" class="help-block">First Name is too long.</span>
                </span>
            </div>
            <!-- Last Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.lastName.errors && (f.lastName.touched || f.lastName.dirty)}">
                <span class="required-field">*</span><label>Last Name</label>
                <input type="text" formControlName="lastName"  name="lastName" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.LastName" ng-maxlength="25" required>
                <span class="required-field" *ngIf="f.lastName.invalid && !f.lastName.pristine">
                    <span *ngIf="f.lastName.errors?.required" class="help-block">Please enter the last name on the check.</span>
                    <span *ngIf="f.lastName.errors?.maxlength" class="help-block">Last Name is too long.</span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 text-primary">
                <label><strong>Address On Check</strong></label>
            </div>
        </div>
        <div class="row">
            <!-- Address1 -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.address1.errors && (f.address1.touched ||f.address1.dirty)}">
                <span class="required-field">*</span><label>Address 1</label>
                <input type="text" formControlName="address1" name="address1" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.Address1" maxlength="100" required>
                <span class="required-field" *ngIf="f.address1.invalid && !f.address1.pristine">
                    <span *ngIf="f.address1.errors?.required" class="help-block">Please enter an address for the check.</span>
                </span>
            </div>
            <!-- Address2 -->
            <div class="form-group col-md-6">
                <label>Address 2</label>
                <input type="text" name="address2" formControlName="address2" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.Address2" maxlength="100">                
            </div>
        </div>
        <div class="row">
            <!-- City/Location -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.cityLocation.errors && (f.cityLocation.touched ||f.cityLocation.dirty)}">
                <span class="required-field">*</span><label>City/Location</label>
                <input type="text" formControlName="cityLocation" name="cityLocation" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.City" maxlength="100" required>
                <span class="required-field" *ngIf="f.cityLocation.invalid && !f.cityLocation.pristine">
                    <span *ngIf="f.cityLocation.errors?.required" class="help-block">Please enter a city for the check.</span>
                </span>
            </div>
            <!-- Zip -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.zip.errors && (f.zip.touched ||f.zip.dirty)}">
                <span class="required-field">*</span><label>Zip</label>
                <input type="text" formControlName="zip"  name="zip" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.PostalCode" maxlength="9" required>
                <span class="required-field" *ngIf="f.zip.invalid && !f.zip.pristine">
                    <span *ngIf="f.zip.errors?.required" class="help-block">Please enter your zip code.</span>
                </span>
            </div>
            
        </div>
        <div class="row">
            <!-- Countries -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.countries.errors && (f.countries.touched ||f.countries.dirty)}">
                <span class="required-field">*</span><label>Country</label>
                <select name="countries" formControlName="countries" class="form-control show-tick" data-select-picker data-selectpicker-options="Countries" [(ngModel)]="SCartPayment.BillingInfo.Country"  (ngModelChange)="GetCoutryStates()" required>
                    <option value=" ">
                        Choose Country...
                    </option>
                    <option *ngFor="let option of Countries" value="{{option.CountryCode}}">{{option.CountryName}}</option>
                </select>
                <span class="required-field" *ngIf="f.countries.invalid && !f.countries.pristine">
                    <span *ngIf="f.countries.errors?.required" class="help-block">Please enter a country for the check.</span>
                </span>
            </div>
            <!-- States -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.states.errors && (f.states.touched ||f.states.dirty)}">
                <span class="required-field">*</span><label>State</label>
                <select name="states" formControlName="states"  class="form-control show-tick" data-select-picker data-selectpicker-options="States" [(ngModel)]="SCartPayment.BillingInfo.State" required>
                    <option value="">Choose State...</option>
                    <option *ngFor="let option of States" value="{{option.StateShort}}">{{option.StateName}}</option>
                </select>
                <span class="required-field" *ngIf="f.states.invalid && !f.states.pristine">
                    <span *ngIf="f.states.errors?.required" class="help-block">Please enter a state for the check.</span>
                </span>
            </div>
           
        </div>
        <div class="row">
            <!-- Phone Number -->   {{SCartPayment.BillingInfo.Phone}}
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.phone.errors }">  <!--&& (f.phone.touched || f.phone.dirty)-->
                <span style="color:red">*</span><label>Phone Number</label>
                <input type="text" formControlName="phone" name="phone" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.Phone" required>
                <span style="color:red" *ngIf="f.phone.invalid && !f.phone.pristine">
                    <span *ngIf="f.phone.errors?.required" class="help-block">Please enter a phone number.</span>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="padding-left:30px; padding-right:30px">
        <hr />
    </div>
    <div class="col-md-7">
        <div class="row">
            <div class="form-group col-md-6 text-primary">
                <label><strong>Check Information</strong></label>
            </div>
        </div>
        <div class="row">
            <!-- Check Number -->
            <div class="form-group col-md-12" [ngClass]="{ 'has-error' : f.cNumber.errors && (f.cNumber.touched || f.cNumber.dirty)}">
                <span style="color:red">*</span><label>Check Number</label><button type="button" class="btn btn-info btn-xs" style="margin-left: 10px" (click)="ShowHelpPrompt('CheckNumber')">?</button>
                <input type="text" formControlName="cNumber" name="cNumber" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.CheckNumber" restrict-input="^[0-9-]*$" maxlength="16" required>
                <span style="color:red" *ngIf="f.cNumber.invalid && !f.cNumber.pristine">
                    <span *ngIf="f.cNumber.errors?.required" class="help-block">Check Number is Required.</span>
                </span>
            </div>
        </div>
        <div class="row">
            <!-- Routing Number -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.routingNumber.errors && (f.routingNumber.touched || f.routingNumber.dirty)}">
                <span style="color:red">*</span><label>Routing Number</label>
                <input type="text" formControlName="routingNumber" name="routingNumber" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.RoutingNumber" pattern="^(\d{9})$" maxlength="16"  required>
                <span style="color:red" *ngIf="f.routingNumber.invalid && !f.routingNumber.pristine">
                    <span *ngIf="f.routingNumber.errors?.required" class="help-block">Routing Number is Required.</span>
                    <span *ngIf="f.routingNumber.invalid && !f.routingNumber.pristine && !f.routingNumber.errors?.required" class="help-block">Please enter a valid routing number.</span>
                </span>
            </div>
            <!-- Checking Account Number -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.cAccountNum.errors && (f.cAccountNum.touched || f.cAccountNum.dirty)}">
                <span style="color:red">*</span><label>Checking Account Number</label>
                <input type="text" formControlName="cAccountNum"  name="cAccountNum" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.AccountNumber" pattern="^(\d{4,17})$" maxlength="17"  required>
                <span style="color:red" *ngIf="f.cAccountNum.invalid && !f.cAccountNum.pristine">
                    <span *ngIf="f.cAccountNum.errors?.required" class="help-block">Checking Acct # is Required.</span>
                    <span *ngIf="f.cAccountNum.invalid && !f.cAccountNum.pristine && !f.cAccountNum.errors?.required" class="help-block">Please enter a valid checking account number.</span>
                </span>
            </div>
        </div>
        <div class="row" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType">
            <!-- Federal Tax Id Number License Number -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.fTaxId.errors && (f.fTaxId.touched || f.fTaxId.dirty)}">
                <span style="color:red">*</span><label>Federal Tax ID Number</label>
                <input type="text" formControlName="fTaxId" name="fTaxId" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.CorporateTaxID"  restrict-input="^[0-9-]*$" maxlength="16" required>
                <span style="color:red" *ngIf="f.fTaxId.invalid && !f.fTaxId.pristine">
                    <span *ngIf="f.fTaxId.errors?.required" class="help-block">Federal Tax ID # is Required.</span>
                </span>
            </div>
        </div>
        <div class="row" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType">
            <!-- Driving License Number -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.dLicNum.errors && (f.dLicNum.touched || f.dLicNum.dirty)}">
                <span style="color:red">*</span><label>Driver License Number</label>
                <input type="text" formControlName="dLicNum" name="dLicNum" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.LicenseNumber" maxlength="16" required>
                <span style="color:red" *ngIf="f.dLicNum.invalid && !f.dLicNum.pristine">
                    <span *ngIf="f.dLicNum.errors?.required" class="help-block">Drivers License # is Required.</span>
                </span>
            </div>
            <!-- License State -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.lstate.errors && (f.lstate.touched || f.lstate.dirty)}">
                <span style="color:red">*</span><label>License State</label>
                <input type="text" formControlName="lstate" name="lstate" class="form-control" [(ngModel)]="SCartPayment.ElectronicCheckInfo.LicenseState" maxlength="2" required>
                <span style="color:red" *ngIf="f.lstate.invalid && !f.lstate.pristine">
                    <span *ngIf="f.lstate.errors?.required" class="help-block">License State is Required.</span>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-5 img-responsive" style="padding-top:50px" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType">
        <img src="/assets/Images/corporatecheck_samle.png" />
        <br /><br />
    </div>
    <div class="col-md-5 img-responsive" style="padding-top:70px" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType">
        <img src="/assets/Images/personal-check-sample.png" />
        <br /><br />
    </div>
</div>