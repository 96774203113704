import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service'
import { TerminalInfo, Terminals } from 'src/app/core/models/Notification.model';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ContainerHoldCharges } from '../../../models/ContainerHoldCharges'
import * as moment from 'moment';
@Component({
  selector: 'app-importchargesearch',
  templateUrl: './importchargesearch.component.html',
  styleUrls: ['./importchargesearch.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ImportchargesearchComponent implements OnInit {
  ImportSearchForm: FormGroup;
  TerminalData: TerminalInfo;
  ShowLoader = false;
  Dateformat: any;
  submitted = false;
  ContainerHoldCharges: ContainerHoldCharges;
  TerminalId:string;
  selectedTerminalName:string; 
  today =  new Date(moment(new Date).format("M/D/YYYY"));
  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private AuthService: AuthService, 
    private cookieService: CookieService,
    private router: Router
  ) {
    this.TerminalData = new TerminalInfo();
    this.GetAllTerminals();       
  }

  ngOnInit(): void {
   
    this.ImportSearchForm = this.formBuilder.group({
      Date: [null, Validators.required],
      TerminalId: ['', Validators.required],
      ContainerNumbers: ['', Validators.required]
    });

   
  }

  get f() { return this.ImportSearchForm.controls; }

  ShowLoaderwheel() {
    this.ShowLoader = true;
  }
  
  HideLoaderWheel() {
    this.ShowLoader = false;
  }

  GetPickUpDate()  {
    return this.ImportSearchForm.get('Date')?.value;
  }

  onSubmit(data: TerminalInfo) {
    this.submitted = true;
    // var x=this.GetPickUpDate();
    // var datePipe=new DatePipe("en-US");
    // this.Dateformat=datePipe.transform(x,'MM-dd-yyyy');
    //check form validation
    if (this.ImportSearchForm.valid) {
      this.ShowLoader = true;
      sessionStorage.setItem("ImportChargeSearchRequest",JSON.stringify(this.TerminalData));
      // this.cookieService.set('pickupdate',this.Dateformat);
      // this.cookieService.set('TerminalId',data.TerminalId);
      // this.cookieService.set('ContainerNumbers',data.ContainerNumbers);      
      this.router.navigateByUrl('/importchargeresults');      
    }
  }

  GetAllTerminals() {
    this.loginService.GetAllTerminals().subscribe((result) => {
      // remove LA from the terminal dropdown
      let terminals = result.Terminals.filter((t:Terminals) => t.Import_FL);
      result.Terminals = terminals;

        this.TerminalData = result;  
        this.TerminalData.TerminalId= '';
        this.TerminalData.User_Id = Number(this.AuthService.User_Id());
        this.TerminalData.Date = this.today;
      });
  }

  changeTerminals(e: any) {
    this.TerminalId = e.target.value;
    this.selectedTerminalName=e.target.selectedOptions[0].innerText;
    this.cookieService.set('TerminalName',this.selectedTerminalName);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    return '';
  }

}

