import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MenuNode, ExampleFlatNode } from 'src/app/shared/interfaces/login.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
export const TREE_DATA: MenuNode[] = [
  {
    name: 'My Dashboard',
    children: [],
    url: '/landingpage',
    is_href: false,
    class: 'glyphicon glyphicon-home'
  },
  {
    name: 'Import Charges',
    children: [{ name: "Search/Pay Charges", url: '/importchargesearch', is_href: true, class: '' }, { name: 'Search Invoice History', url: '/importaccounthistory', is_href: true, class: '' }, { name: 'Create Statements', url: '/importstatements', is_href: true, class: '' }],
    url: '/',
    is_href: true,
    class: 'glyphicon glyphicon-import'
  },
  {
    name: 'Export Charges',
    children: [{ name: 'OOG Chassis Flip', url: '/oogchassisflipsearch', is_href: true, class: '' }, { name: 'Order Weight Service', url: '/vgmservicesearch', is_href: true, class: '' }, { name: 'Search Invoice History', url: '/exportaccounthistory', is_href: true, class: '' }],
    //children: [{ name: 'OOG Chassis Flip', url: '/oogchassisflipsearch', is_href: true, class: '' }, { name: 'OOG Strip & Stuff', url: '/oogstripstuffsearch', is_href: true, class: '' }, { name: 'Order Weight Service', url: '/vgmservicesearch', is_href: true, class: '' }, { name: 'Search Invoice History', url: '/exportaccounthistory', is_href: true, class: '' }],
    //children: [{ name: 'OOG Chassis Flip', url: '/oogchassisflipsearch', is_href: true, class: '' }, { name: 'Order Weight Service', url: '/vgmservicesearch', is_href: true, class: '' }, { name: 'Search Invoice History', url: '/exportaccounthistory', is_href: true, class: '' }],
    url: '/'
    , is_href: true,
    class: 'glyphicon glyphicon-export'
  },
  {
    name: 'Track & Trace',
    children: [],
    url: '/trackandtrace'
    , is_href: false,
    class: 'glyphicon glyphicon-map-marker'
  },
  {
    name: 'Container Notifications',
    children: [],
    url: '/notificationlist'
    , is_href: false,
    class: 'glyphicon glyphicon-bell'
  },
  {
    name: 'Account Management',
    children: [{ name: 'Account Maintenance', url: '/registration', is_href: true, class: '' }, { name: 'Update Password', url: '', is_href: true, class: '' }, { name: 'Company Registrations', url: '/ViewRegistration', is_href: true, class: '' }],
    url: '/'
    , is_href: true,
    class: 'glyphicon glyphicon-user'
  },
];
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements AfterViewInit, OnInit {
  ShowLoader = false;
  lastrouter: string;
  URLName: string;
  loggedIn = false;
  isCollapsed = false;
  sidenav_opened = false;
  over: any;
  activeNode: false;
  ShowUpdate: true;
  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      url: node.url,
      is_href: node.is_href,
      class: node.class,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    public dialog: MatDialog,) {
    this.dataSource.data = TREE_DATA;
    this.authService.customChangeDetector$.subscribe((data: any) => {
      this.loggedIn = data;
    });
    if (this.cookieService.get("loggedIn")) {
      this.loggedIn = this.cookieService.get("loggedIn") == "1";
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit(): void {
    this.URLName = window.location.pathname;
  }

  ngAfterViewInit() {
    TREE_DATA.filter(x => x.is_href).forEach(item => {
      if (item.children != undefined && item.children.filter(y => y.url == this.router.url).length > 0) {
        for (const element of this.treeControl.dataNodes) {
          if (element.expandable && element.name == item.name) {
            this.treeControl.expand(element)
          }
        }
      }
    })
  }

  LeftSideNavClicked(item: any) {
    item.toggle();
  }

  mydasboard() {
    this.router.navigateByUrl('/landingpage');
  }

  treeNodeexpand(node: ExampleFlatNode, isexpand: boolean) {
    if (isexpand) {
      this.treeControl.collapseAll();
      this.treeControl.expand(node);
    }
    else { this.treeControl.collapse(node); }
  }

  changeView(router: string, snav: any) {
    if (router == '') {
      snav.toggle();
      this.router.navigateByUrl(window.location.pathname);
      const dialogRef = this.dialog.open(UpdatePasswordComponent, {
        autoFocus: false,
        position: { 'top': '30px' },
        restoreFocus: false,
        panelClass: 'deletepanelclass'
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    }
  }
}
