import { NA } from "./globalconstants";
import { TranslateService } from "src/app/shared/services/translateservice.service";
import { Injectable } from "@angular/core";
import { ReturnStatement } from "@angular/compiler";
import { environment } from "src/environments/environment";
import * as moment from 'moment';

@Injectable({
  providedIn: "root"
})
export class ValidationMethods {
  constructor(private locale: TranslateService) { }
  isEmptyString(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return false;
    }
    return true;
  }
  handleEmptyString(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return NA;
    }
    return inputstring.trim();
  }

  // handleEmptyDate(inputstring:string) {
  //   if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "") {
  //     return NA;
  //   }
  //   if (inputstring.includes("*")) {
  //     return inputstring;
  //   }
  //   var date = new Date(inputstring);
  //   var dateoptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   //return formatDate(inputstring, this.locale.data.datetimeformat, this.locale.data.timezone);
  //   return date.toLocaleString(this.locale.data.timezone, dateoptions);
  //   //return formatDate(inputstring, this.locale.data.dateformat, this.locale.data.timezone);
  //   //return new Date(inputstring).toLocaleDateString("en", this.locale.data.dateformat)
  // }
  // handleEmptyDateWithComma(inputstring:string) {
  //   if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "" || inputstring == NA) {
  //     return NA;
  //   }
  //   if (inputstring.includes("*")) {
  //     return inputstring;
  //   }
  //   let dt = inputstring.split(",");
  //   if (dt.length > 0) {
  //     var date = new Date(dt[0]);
  //     var dateoptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  //     return (date.toLocaleString(this.locale.data.timezone, dateoptions) + (dt.length == 2 ? " ," + dt[1] : ""));
  //   }
  //   return NA;
  // }
  handleDate(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return null;
    }
    return new Date(inputstring);
  }

  handleDateTime(inputstring:any) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return NA;
    }
    return  moment(inputstring).format("MM/DD/YYYY HH:mm")
  }
  // handleEmptyDateTime(inputstring:any) {
  //   if (
  //     inputstring === null ||
  //     typeof inputstring === "undefined" ||
  //     inputstring.trim() === ""
  //   ) {
  //     return NA;
  //   }
  //   var date = new Date(inputstring);
  //   var dateoptions = { day: "2-digit", month: "2-digit", year: "numeric"};
  //   var timeoptions = {
  //     hour12: this.locale.data.hour12,
  //     hour: "2-digit",
  //     minute: "2-digit"
  //   };
  //   return (
  //     date.toLocaleString(this.locale.data.timezone, dateoptions) +
  //     " " +
  //     date.toLocaleString(this.locale.data.timezone, timeoptions)
  //   );
  // }
  isEmptyObject(inputobject: any) {
    if (
      inputobject === null ||
      typeof inputobject === "undefined" ||
      inputobject.length === 0
    ) {
      return false;
    }
    return true;
  }
  isYesOrNo(inputobject: any) {
    if (inputobject === "Y") {
      return this.locale.data.yes;
    } else if (inputobject === "N") {
      return this.locale.data.no;
    }
    return NA;
  }
  restrictSpace(e:any) {
    if (e.which === 32) e.preventDefault();
  }
  restrictSemiColon(e:any) {
    var startPos = e.currentTarget.selectionStart;
    if (e.which === 59 && startPos == 0) e.preventDefault();
  }
  trimSpace(val:any) {
    return val.replace(/\s/g, "");
  }
  // validateAlphaNumericString(event:any, selectedOperator: number) {
  //   var regex = new RegExp("^[0-9a-zA-Z;]+$");
  //   var regex1 = new RegExp("^[0-9a-zA-Z]+$");
  //   var startPos = event.currentTarget.selectionStart;
  //   var key = String.fromCharCode(
  //     event.charCode ? event.which : event.charCode
  //   );
  //   if (selectedOperator == 10 || selectedOperator == 11) {
  //     if (startPos == 0 && !regex1.test(key)) {
  //       event.preventDefault();
  //       return false;
  //     } else {
  //       if (!regex.test(key)) {
  //         event.preventDefault();
  //         return false;
  //       }
  //     }
  //   } else {
  //     if (!regex1.test(key)) {
  //       event.preventDefault();
  //       return false;
  //     }
  //   }
  // }
 
}
