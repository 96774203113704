import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../common/registration/MustMatch';
import swal from 'sweetalert2'; 
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { UpdatePasswordRequest} from '../../shared/interfaces/login.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  UserNm:any;
  loggedIn=  false;
  UpdatePasswordForm: FormGroup;
  passwordUpdateSuccessful=false;
  Messages: ResponseMessage[]=[];
  UpdatePasswordRequest:UpdatePasswordRequest;
  constructor(private loginService: LoginService,
              private authService:AuthService,
              private cookieService: CookieService,
              private formBuilder: FormBuilder,
              private router: Router,
              public dialogRef: MatDialogRef<UpdatePasswordComponent>,

              ) {
   
    if (!!this.cookieService.get("loggedIn")) {
      this.loggedIn = this.cookieService.get("loggedIn") == "1" ? true :false;
      this.UserNm =  localStorage.getItem("userName") == null ? '': localStorage.getItem("userName");//'Hi,'+ +'!';
    }
   }
  
  ngOnInit(): void {
 
    this.UpdatePasswordForm = this.formBuilder.group({
      CurrentPassword: ['', [Validators.required]],
      NewPassword:['',[Validators.required,Validators.pattern(/^\w*(?=\w*\d)(?=\w*[A-Za-z])\w*$/)]],
      ConfirmPassword:['',Validators.required]
    },{
      validator: MustMatch('NewPassword', 'ConfirmPassword')
    } )
  }
  get f() { return this.UpdatePasswordForm.controls; }

  ShowHelpPrompt(){
    swal.fire({ 
      text: 'Your Password should contain at least 6 alphanumeric characters, including 1 number!', 
      title: 'Password',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
  })
  }

  onSubmit(data:UpdatePasswordRequest)  {
    this.loginService.PostOnlineUserPasswordUpdate(data).subscribe(
      (result) => {
        if (result)
        this.Messages=result.generalMessages
        if(this.Messages.length == 0)
          this.passwordUpdateSuccessful = true;
          else
          this.passwordUpdateSuccessful = false;
      })
  }

  openPopup() {
    this.Messages=[]  
    this.passwordUpdateSuccessful=false
  }

  closePopup() {
    this.Messages=[]
    this.passwordUpdateSuccessful=false;
    this.dialogRef.close('cancel');      
  }

}
