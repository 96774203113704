import { Component, OnInit,Input } from '@angular/core';
import { PaymentConfirmation, ShoppingCart } from 'src/app/core/models/ShoppingCart';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Countries, States } from 'src/app/shared/interfaces/login.interface';
@Component({
  selector: 'app-credit-cart-payment',
  templateUrl: './credit-cart-payment.component.html',
  styleUrls: ['./credit-cart-payment.component.css']
})
export class CreditCartPaymentComponent implements OnInit {
  @Input() ShoppingCart:ShoppingCart;
  @Input() SCartPayment:PaymentConfirmation;
  @Input() shoppingCartPayment: FormGroup;
  ShowLoader = false;
  CardTypes:any;
  Countries :Countries[]= [];
  States :States[]= [];
  constructor(private formBuilder: FormBuilder,
              private importchargesService : ImportchargesService,) { }

  ngOnInit(): void {

    this.Countries = [];
    this.ShowLoader = true;
    this.importchargesService.GetAllCountriesforPayment().subscribe((result) => {      
      this.ShowLoader = false;
      this.Countries = result.Countries;
    });    

    // this.shoppingCartPayment = this.formBuilder.group(
    //   {
    //     firstName: ['', Validators.required],
    //     lastName: ['', Validators.required ],
    //     address1: ['', Validators.required ],
    //     cityLocation: ['', Validators.required ],
    //     zip: ['', Validators.required ],
    //     countries: ['', Validators.required ],
    //     states: ['', Validators.required ],
    //     cardType: ['', Validators.required ],
    //     cNumber: ['', Validators.required ],
    //     cardExpMonth: ['', Validators.required ],
    //     cardExpYear: ['', Validators.required ],
    //     cvv: ['', Validators.required ],
    //   });

      this.CardTypes = [{
        CardTypeId: 'VIS', CardTypeName: 'Visa'
    }, { CardTypeId: 'MST', CardTypeName: 'Master Card' }, { CardTypeId: 'DSC', CardTypeName: 'Discover' }, {
        CardTypeId: 'AMX', CardTypeName: 'American Express'
    }];
  }
  
  GetCoutryStates(){
    
    var valID = '';
    this.SCartPayment.BillingInfo.State = '';    
    this.Countries.forEach((item:any) => {
      if (this.SCartPayment.BillingInfo.Country != '' && this.SCartPayment.BillingInfo.Country != undefined && this.SCartPayment.BillingInfo.Country != null) {
        if (item.CountryCode == this.SCartPayment.BillingInfo.Country) {
            valID = item.CountryName.toString();
        }
      }
    });    
    if(valID !=''){
    this.ShowLoader = true;
    this.importchargesService.GetCoutryStates(valID).subscribe((result) => {    
      this.ShowLoader = false;
      this.States = result.States;
      //$scope.State = { "StateId": '', "StateName": '' };
    });
  }

  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.shoppingCartPayment.controls;
  }

}
