import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { TrackFilter , EIRDetails } from 'src/app/core/models/trackandtrace.model';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { EIRPrintDetailsComponent } from './eirprint-details/eirprint-details.component';
@Component({
  selector: 'app-gate-transaction-results',
  templateUrl: './gate-transaction-results.component.html',
  styleUrls: ['./gate-transaction-results.component.css']
})
export class GateTransactionResultsComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  @Input()  Gatetransactionlist :any[]=[];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  PaidQTY:any;
  EIRdetails:EIRDetails;
  EIRInstruction:any;
  bookingdetails :{TerminalId:any,BookingNumber:any};
  TerminalId:any;
  BookingNumber:any;
  displayStyle = "none";
  ShowLoader = false;
  TerminalNameSelected:any;
  dataSource: MatTableDataSource<any>;
  @Input() TrackFilter:TrackFilter;  
  displayedColumns: string[] = ['NBR','STATUS','MISSIONDETAIL','TRUCKENTERED','TRUCKEXITED','SHIPPINGLINE','TRKC_ID',
  'CONTAINER','CTR_EQSZ','CHASSIS','EQO_NBR','VESSEL','VOYAGE'];
  
  constructor(private trackandtraceService: TrackandtraceService,
              public dialog: MatDialog,) {
                this.EIRdetails = new EIRDetails();
                this.bookingdetails = {TerminalId:'',BookingNumber:''};
    // this.TrackFilter = new TrackFilter();
   }

  ngOnInit(): void {
    if(this.Gatetransactionlist != null){
      this.dataSource= new MatTableDataSource(this.Gatetransactionlist);
      this.dataSource.sort = this.sort;
    }    
  }

  exportToExcel(Gatetable:any){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'Import Availability By BOL.xlsx');
  }

  EIRDetails(obj:any){
    this.ShowLoader= true;
    this.TrackFilter.Gkey = obj.GKEY;
    this.TrackFilter.GateContainer = obj.CONTAINER;
    this.TrackFilter.GateTruckEntered = obj.TRUCKENTERED;
    this.trackandtraceService.GetEIRReprintDetail(this.TrackFilter).subscribe((result) => {
        let EIRReprintDetailCollection = result.EIRReprintDetail;
        let EIRReprintDetailInstructionCollection = result.EIRReprintInstructions;
        this.TrackFilter.BookingNum = EIRReprintDetailCollection[0].EQO_NBR ? EIRReprintDetailCollection[0].EQO_NBR : EIRReprintDetailCollection[0].eqo_nbr;
        this.TrackFilter.GateContainerFromEIR = EIRReprintDetailCollection[0].CTR_NBR ? EIRReprintDetailCollection[0].CTR_NBR : EIRReprintDetailCollection[0].ctr_nbr;
        this.trackandtraceService.GetBookingInformation(this.TrackFilter).subscribe( (result) => {
            let PaidResult = result;
            let PaidResultCollection = PaidResult.BookingInformation;
            let PaidQTY;
            PaidResultCollection.forEach((item:any) => { if (item.PaidQty != null || item.PaidQty != undefined) {  PaidQTY = obj.PaidQty;   }  });

            if (PaidQTY == undefined || PaidQTY == null || PaidQTY == "")
              PaidQTY = 0;

              // sessionStorage.FromEIR = false;
              // this.PaidQTY = PaidQTY;
              // this.EIRdetails = EIRReprintDetailCollection[0];
              // this.EIRInstruction = EIRReprintDetailInstructionCollection;
          
              // this.bookingdetails.TerminalId = EIRReprintDetailCollection.TerminalId;
              // if (this.EIRdetails.EQO_NBR != undefined)
              //   this.bookingdetails.BookingNumber = this.EIRdetails.EQO_NBR;
              // else
              //   this.bookingdetails.BookingNumber = this.EIRdetails.eqo_nbr;

              //   this.displayStyle = "block";

              const dialogRef = this.dialog.open(EIRPrintDetailsComponent, {
                data: { EIRdetailsdata: EIRReprintDetailCollection, EIRInstructiondata: EIRReprintDetailInstructionCollection,TerminalId:this.TrackFilter.TerminalId,PaidQTYdata:PaidQTY },
                autoFocus: false,
                restoreFocus: false,
                panelClass: 'deletepanelclass'
              });
              dialogRef.afterClosed().subscribe(result => {
                // if (result != undefined && result !== 'cancel' && result !== true && this.dataSource.length > 0) {
                //   // if(chassisNumbers != '')
                //   //   this.CaptureChassisNumber(lchassisNumber);
                //   // this.updateChassisPoolData(this.expandedElementRef);
                // }
                // this.ShowLoader = false;
              });

              // window.open(window.location.origin + '/EIRPrintDetails', '_blank'); 

              this.ShowLoader= false;
      },
      (error)=> {   this.ShowLoader= false;});


    });

  }

  close(){
    this.displayStyle = "none";
  }

  gobacktoTT(){
    sessionStorage.TerminalId = this.bookingdetails.TerminalId;
    sessionStorage.BookingNumber = this.bookingdetails.BookingNumber == null ? '': this.bookingdetails.BookingNumber;
    sessionStorage.FromEIR = true;
    window.location.reload();  
  }

  printPage(){
    window.print();
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'NBR': return compare(a.NBR, b.NBR, isAsc);
        case 'STATUS': return compare(a.STATUS, b.STATUS, isAsc);
        case 'MISSIONDETAIL': return compare(a.MISSIONDETAIL, b.MISSIONDETAIL, isAsc);
        case 'TRUCKENTERED': return compare(a.TRUCKENTERED, b.TRUCKENTERED, isAsc);
        case 'TRUCKEXITED': return compare(a.TRUCKEXITED, b.TRUCKEXITED, isAsc);
        case 'SHIPPINGLINE': return compare(a.SHIPPINGLINE, b.SHIPPINGLINE, isAsc);
        case 'TRKC_ID': return compare(a.TRKC_ID , b.TRKC_ID, isAsc);       
        case 'CONTAINER': return compare(a.CONTAINER, b.CONTAINER, isAsc);    
        case 'CTR_EQSZ': return compare(a.CTR_EQSZ , b.CTR_EQSZ  , isAsc);
        case 'CHASSIS': return compare(a.CHASSIS , b.CHASSIS, isAsc);
        case 'EQO_NBR': return compare(a.EQO_NBR , b.EQO_NBR , isAsc); 
        case 'VESSEL': return compare(a.VESSEL , b.VESSEL , isAsc); 
        case 'VOYAGE': return compare(a.VOYAGE , b.VOYAGE , isAsc); 
        default: return 0;
      }
    });
   }

}


function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
