<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Export Account History</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1 noPrint"></div>
    <div *ngIf="Messages.length > 0">
        <div class="row"  *ngFor="let Msg of ExportInvoiceItem.generalMessages">
            <br />
            <div class="col-md-8 col-md-offset-2">
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>   
    <div class="row">
        <br /><br />
        <div class="col-md-2"></div>
        <div class="col-md-8">
                     
                <div class="row ">
                    <div class="form-group col-md-16 ">
                        <div class="col-sm-3 col-sm-offset-1">
                            <label class="btn  btn-default  btn-responsive col-md-12  text-left minPadding-top labelbtn">
                             
                                <div ng-init="ExportFilters.InvoiceStrip=1"></div>
                                <input type="radio"  name="StripOption" id="strip" autocomplete="off" [(ngModel)]="ExportFilters.InvoiceStrip" (ngModelChange)="InvoiceStripChange('strip')" value="1">  OOG Strip & Stuff History
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <label class="btn  btn-default  btn-responsive col-md-12  text-left minPadding-top labelbtn">
                                <input type="radio"  name="FlipOption" id="flip" autocomplete="off" [(ngModel)]="ExportFilters.InvoiceStrip" (ngModelChange)="InvoiceStripChange('flip')" value="2">  OOG Chassis Flip History
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <label class="btn  btn-default  btn-responsive col-md-12  text-left minPadding-top labelbtn">
                                <input type="radio"  name="VGMOption" id="vgm" autocomplete="off" [(ngModel)]="ExportFilters.InvoiceStrip"  (ngModelChange)="InvoiceStripChange('vgm')" value="3">  Weight Service History
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="row divTop-border-silver col-md-offset-3"></div>
                </div>
                <br /><br />
                <form name="ExportHistory" [formGroup]="ExportHistory" style="margin-left: -70px;" novalidate>     
                <div class="row">
                    <div class="form-group col-md-12">
                        <div class="col-md-2 text-right minPadding-top col-md-offset-2"><span style="color:red">*</span><label for="Inv">From :</label></div>
                       
                        <div class="col-md-3" [ngClass]="{ 'has-error' : submitted && (ExportFilters.FromDate === null || ExportFilters.FromDate===undefined) && ExportFilters.ContainerNum ==='ALL' && ExportFilters.InvoiceNum ==='ALL' && ExportFilters.StatementNum==='ALL'}">
                          
                                <input style="width: 130px;"  [matDatepicker]="fromdatepicker" [(ngModel)]="ExportFilters.FromDate"  formControlName="pickUpDate" class="form-control  col-md-8" required >
                                <mat-datepicker-toggle matSuffix class="dp" [for]="fromdatepicker"> 
                                    <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </span>
                                </mat-datepicker-toggle>
                                <mat-datepicker  [dateClass]="dateClass" #fromdatepicker></mat-datepicker>                             
                         
                            <span class="help-block" *ngIf="submitted && (ExportFilters.FromDate === null || ExportFilters.FromDate===undefined)">Dates are required when Invoice or Booking Number is not specified.</span>
                        </div>
                        <div class="col-md-2 text-right minPadding-top"><span style="color:red">*</span><label for="Inv">To :</label></div>
                        <div class="col-md-3" [ngClass]="{ 'has-error' : submitted && (ExportFilters.ToDate === null || ExportFilters.ToDate===undefined) }">
                                              
                                <input  style="width: 130px;"  [matDatepicker]="todatepicker" [(ngModel)]="ExportFilters.ToDate" formControlName="pickToDate" id="pickToDate" class="form-control  col-md-8" required />
                                <mat-datepicker-toggle  matSuffix class="dp" [for]="todatepicker"> 
                                    <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </span>
                                </mat-datepicker-toggle>
                                <mat-datepicker [dateClass]="dateClass" #todatepicker></mat-datepicker>  
                          
                            <span class="help-block" *ngIf="submitted && (ExportFilters.ToDate === null || ExportFilters.ToDate===undefined)">Please enter End Date.</span>
                            <span  *ngIf="f.pickUpDate.errors?.endBeforeStart">End date must be on or after start date.</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <div class="col-md-2 text-right minPadding-top col-md-offset-2"><label for="Book">Booking Number :</label></div>
                        <div class="col-md-3">
                            <input  class="form-control" formControlName="Book" type="text" name="Book" placeholder="All" default="ALL" [(ngModel)]="ExportFilters.BookingNum" style="text-transform:uppercase;width:170px;" (click)="ExportFilters.BookingNum=''">  
                            <span class="help-block" *ngIf="submitted && f.Book.errors?.required">Please enter Booking Number or ALL.</span>
                        </div>
                        <div class="col-md-2 text-right minpadding-top"><label for="Inv">Invoice Number :</label></div>
                        <div class="col-md-3"> 
                            <input  class="form-control" formControlName="Inv" type="text" name="Inv" placeholder="All" default="ALL" [(ngModel)]="ExportFilters.InvoiceNum" style="text-transform:uppercase;width:170px;" (click)="ExportFilters.InvoiceNum=''">  
                            <span class="help-block" *ngIf="submitted && f.Inv.errors?.required">Please enter Invoice Number or ALL.</span>
                        </div>
                    </div>
                </div>              
                <br />
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 col-sm-8 text-right" style="margin-left:27px">
                          
                            <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']">
                                <span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Back
                            </button>
                           
                            <button (click)="SearchExportInvoiceInfo();" class="btn btn-primary btn-site btn-margin">
                                <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Search
                            </button>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
                </form>
            
         
        </div>
    </div>
    <div style="padding-bottom: 15px;padding: 10px 100px 10px 100px;">
        <div class="mat-elevation-z8"  [hidden]="!searchactive">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">   
                    <!-- select Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="_select">
                            <mat-checkbox (change)="$event ? masterToggle($event) : null" (click)="$event.stopPropagation()"   [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"  >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="_select"> 
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="OnChange($event,row)"   [checked]="selection.isSelected(row)"   >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                
                    <!-- InvoiceNumber Column -->
                    <ng-container matColumnDef="InvoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Invoice Number </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"><a style="cursor: pointer;" (click)="ExportBookingReport(element)"> {{element.InvoiceNumber | uppercase}}</a> </td>
                    </ng-container>
                
                    <!-- StatementNumber Column -->
                    <ng-container matColumnDef="StatementNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">Statement # </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.StatementNumber}} </td>
                    </ng-container>
                
                    <!-- PoNumber Column -->
                    <ng-container matColumnDef="PoNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">PO Number </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.PoNumber}} </td>
                    </ng-container>
    
                    <!-- InvoiceDate Column -->
                    <ng-container matColumnDef="InvoiceDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Invoice Date </th>
                        <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.InvoiceDate | date:'MM-dd-yyyy'}} </td>
                    </ng-container>
    
                    <!-- Terminal Column -->
                    <ng-container matColumnDef="Terminal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Terminal </th>
                        <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.Terminal}} </td>
                    </ng-container>
    
                        <!-- TotalAmount Column -->
                    <ng-container matColumnDef="TotalAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Billing Amount </th>
                        <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TotalAmount}} </td>
                    </ng-container>

                     <!-- OutstandingBalance Column -->
                     <ng-container matColumnDef="OutstandingBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Outstanding Balance </th>
                        <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.OutstandingBalance}} </td>
                    </ng-container>

                     <!-- StatusName Column -->
                     <ng-container matColumnDef="StatusName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Status </th>
                        <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.StatusName}} </td>
                    </ng-container>                   
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div [ngClass]="{ hiddenElement: (dataSource.data.length) === 0 }">
                <mat-paginator [hidden]="!(displayInvCollection.length==0)"
                [ngStyle]="{display: displayInvCollection.length >0 ? 'block' : 'none'}"
                [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="10"
                    showFirstLastButtons
                    aria-label="Select page of periodic elements">
                </mat-paginator> 
            </div>
            <div *ngIf="this.displayInvCollection.length == 0" class="no-records"> No Results found.</div>  
            <!-- <mat-paginator #paginator  [ngStyle]="{display: displayInvCollection.length >0 ? 'block' : 'none'}" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons style=" border: 1px solid #e0e0e0;" aria-label="Select page">
            </mat-paginator>                   -->
        </div>            
        <div class="row">
        </div>
        <br /><br />
        <div class="row" *ngIf="displayInvCollection.length >0 && searchactive===true">
            <div class=" form-group col-md-10">
                <div class="col-md-4"></div>
                <div class="col-md-8 col-sm-8 text-right">
                    <!-- Print BUTTON -->
                    <button (click)="InvExportPrintPreview();" class="btn btn-primary btn-site btn-margin">
                        <span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print
                    </button>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
      </div>
    <div class="col-md-2"></div>
</div>
