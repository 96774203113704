<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="row">
    <div class="col-md-12">
      <div class="col-md-12 col-sm-12 no-padding">
        You will be sent to our payment partner CyberSource to complete your transaction.
        <br />
        <br />
        <label style="font-weight:bold" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">Contact info for fee mitigation:</label>&nbsp;
        <label style="white-space: pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{SCartPayment.OSRAmitigationcontact}}</label><br />
        <label  *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)"><span style="font-weight:bold">Digital Inquiries: </span> {{SCartPayment.OSRAfacilitymitigationcontact}}</label>
        <!--<label style="white-space: pre-wrap" *ngIf="ShoppingCart.LineItems[0].Product.ProductType == 1">{{SCartPayment.OSRAfacilitymitigationcontact}}</label>-->
        <br /><br />
        <input type="checkbox" name="checkbox" value="check" id="agree" [(ngModel)]="SCartPayment.AcceptTerms" (ngModelChange)="GetCyberSourceWebMobileParameters($event)" />   By submitting payment, I confirm that I am
        <!--By checking the box , I hereby represent and warrant that
  (i) I am fully aware of the nature and amount of the charges owed
  (ii) I acknowledge the validity of the charges
  (iii) I am fully authorized to make this payment on behalf of the parties that have right, title and interest in and to the cargo being
  released upon payment of the charges owed.<br /> <br />-->
        <label style="white-space: pre-wrap">{{SCartPayment.OSRAMessage}}</label><br /><br />
      </div>
        <form ngNoForm [action]="CyberSourceWebMobileURL" method="POST"  >  <!--target="_blank"-->
			<input type="submit" id="cyberSourceSubmit" class="btn btn-primary btn-site btn-margin" name="submit" value="$ Pay Now" [disabled]="!SCartPayment.AcceptTerms || Messages.length > 0" (click)="SentUserToCyberSource()"  /> <!---->
            <input type="hidden" id="access_key" name="access_key" [value]="CyberSourceData.access_key"/>
            <input type="hidden" id="profile_id" name="profile_id" [value]="CyberSourceData.profile_id" />
            <input type="hidden" id="transaction_uuid" name="transaction_uuid" [value]="CyberSourceData.transaction_uuid" />
            <input type="hidden" id="signed_field_names" name="signed_field_names" [value]="CyberSourceData.signed_field_names" />
            <input type="hidden" id="unsigned_field_names" name="unsigned_field_names" [value]="CyberSourceData.unsigned_field_names"/>
            <input type="hidden" id="signed_date_time" name="signed_date_time" [value]="CyberSourceData.signed_date_time"/>
            <input type="hidden" id="locale" name="locale" [value]="CyberSourceData.locale" />
            <input type="hidden" id="transaction_type" name="transaction_type" [value]="CyberSourceData.transaction_type" />
            <input type="hidden" id="reference_number" name="reference_number" [value]="CyberSourceData.reference_number" />
            <input type="hidden" id="amount" name="amount" [value]="CyberSourceData.amount" />
            <input type="hidden" id="currency" name="currency" [value]="CyberSourceData.currency" />
            <input type="hidden" id="echeck_sec_code" name="echeck_sec_code" [value]="CyberSourceData.echeck_sec_code" />
            <input type="hidden" id="signature" name="signature" [value]="CyberSourceData.signature" />
        </form>
    </div>
</div>
