import { Component, OnInit } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/shared/services/login.service';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  constructor(private loginService: LoginService,
              private shoppingCartService:ShoppingCartService,
              private AuthService: AuthService, 
              public dialog: MatDialog) { }

  ngOnInit(): void {
    let User_ID = Number(this.AuthService.User_Id());
    this.ShowLoader = true;
    this.loginService.GetCompanyRegistration(User_ID.toString()).subscribe((result) => {
      this.ShowLoader = false;
      this.Messages = result.generalMessages;
      let ClientsCollection = result.AssociatedClients;
      if (this.Messages.length == 0 && ClientsCollection[0].ClientTypeId === 0) {
        const dialogRef = this.dialog.open(AdditionalInfoComponent, {
          data: { UserClients: ClientsCollection },
          autoFocus: false,
          position:{'top' :'30px'},
          restoreFocus: false,
          panelClass: 'deletepanelclass',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {           
        });
    }
  });

  sessionStorage.removeItem("ImportChargeSearchRequest");
	sessionStorage.removeItem("S_CartInfo");
	this.shoppingCartService.DeleteCookie();

  }

}
