import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import { TrackFilter } from 'src/app/core/models/trackandtrace.model';
import { TerminalInfo } from 'src/app/core/models/Notification.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';
@Component({
  selector: 'app-import-availability-results',
  templateUrl: './import-availability-results.component.html',
  styleUrls: ['./import-availability-results.component.css']
})
export class ImportAvailabilityResultsComponent implements OnInit {
  @Input()  importAvailabilitylist :any[]=[];
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  ImportAvailabilityCollection:any[]=[];
  TerminalNameSelected:any;
  TerminalData: TerminalInfo;
  BillOfLading :string;
  dataSource: MatTableDataSource<any>;
  ShowLoader = false;  
  displayedColumns: string[] = ['NBR','em','BL_NBR', 'READYFORDELIVERY', 'HOLDS','FREIGHT','CUSTOMS','GOODTHRU','DEMURRAGE','CNTR_SIZE','TYPE','WEIGHT','SSL','SSL_SCAC','VESSEL_NAME','DESTINATION_CY','DESTINATION_DELIVERY'
  ,'VESSEL_ETA','VESSEL_ACT_ARR','VSL_DISCHARGE_COMPLETE','YARDLOCATION','LOADEDTORAIL','RAILACTUALDEPARTURE','TRUCK_RELEASE',
  'TRUCKERAPPOINTMENT','TRUCKERGATEIN','TRUCKERGATEOUT'];
  @Input() TrackFilter:TrackFilter;  
  constructor(private AuthService : AuthService,
              private _liveAnnouncer: LiveAnnouncer,
              private router : Router) { 
    
    // this.TrackFilter = new TrackFilter();
  }

  ngOnInit(): void {
    this.BillOfLading = this.TrackFilter.billoflanding_nbr;
    if(this.importAvailabilitylist.length > 0){     
      this.TrackFilter.terminals.forEach(item => { if(item.TerminalId == this.TrackFilter.TerminalId) { this.TerminalNameSelected = item.TerminalName }});
      this.dataSource= new MatTableDataSource(this.importAvailabilitylist);
      this.dataSource.sort = this.sort;
    }
  }

  importchargesearch(rowdata:any){
    this.TerminalData = new TerminalInfo();
    this.TerminalData.User_Id = Number(this.AuthService.User_Id());
    this.TerminalData.TerminalId = this.TrackFilter.TerminalId.toString();
    this.TerminalData.ContainerNumbers = rowdata.NBR;
    sessionStorage.setItem("ImportChargeSearchRequest", JSON.stringify( this.TerminalData));
    this.router.navigateByUrl('/importchargeresults');
  }
  
  exportToExcel(Gatetable:any){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'Import Availability By BOL.xlsx');
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'NBR': return compare(a.NBR, b.NBR, isAsc);
        case 'BL_NBR': return compare(a.BL_NBR, b.BL_NBR, isAsc);
        case 'READYFORDELIVERY': return compare(a.READYFORDELIVERY, b.READYFORDELIVERY, isAsc);
        case 'HOLDS': return compare(a.HOLDS, b.HOLDS, isAsc);
        case 'FREIGHT': return compare(a.FREIGHT, b.FREIGHT, isAsc);
        case 'CUSTOMS': return compare(a.CUSTOMS, b.CUSTOMS, isAsc);
        case 'GOODTHRU': return compare(a.GOODTHRU , b.GOODTHRU, isAsc);       
        case 'DEMURRAGE': return compare(a.DEMURRAGE, b.DEMURRAGE, isAsc);    
        case 'CNTR_SIZE': return compare(a.CNTR_SIZE , b.CNTR_SIZE  , isAsc);
        case 'TYPE': return compare(a.TYPE , b.TYPE, isAsc);
        case 'WEIGHT': return compare(a.WEIGHT , b.WEIGHT , isAsc); 
        case 'SSL': return compare(a.SSL , b.SSL , isAsc); 
        case 'SSL_SCAC': return compare(a.SSL_SCAC , b.SSL_SCAC , isAsc); 
        case 'VESSEL_NAME': return compare(a.VESSEL_NAME , b.VESSEL_NAME , isAsc); 
        case 'DESTINATION_CY': return compare(a.DESTINATION_CY , b.DESTINATION_CY , isAsc); 
        case 'DESTINATION_DELIVERY': return compare(a.DESTINATION_DELIVERY , b.DESTINATION_DELIVERY , isAsc); 
        case 'VESSEL_ETA': return compare(a.VESSEL_ETA , b.VESSEL_ETA , isAsc); 
        case 'VESSEL_ACT_ARR': return compare(a.VESSEL_ACT_ARR , b.VESSEL_ACT_ARR , isAsc); 
        case 'VSL_DISCHARGE_COMPLETE': return compare(a.VSL_DISCHARGE_COMPLETE , b.VSL_DISCHARGE_COMPLETE , isAsc); 
        case 'YARDLOCATION': return compare(a.YARDLOCATION , b.YARDLOCATION , isAsc); 
        case 'LOADEDTORAIL': return compare(a.LOADEDTORAIL , b.LOADEDTORAIL , isAsc); 
        case 'RAILACTUALDEPARTURE': return compare(a.RAILACTUALDEPARTURE , b.RAILACTUALDEPARTURE , isAsc); 
        case 'TRUCK_RELEASE': return compare(a.TRUCK_RELEASE , b.TRUCK_RELEASE , isAsc); 
        case 'TRUCKERAPPOINTMENT': return compare(a.TRUCKERAPPOINTMENT , b.TRUCKERAPPOINTMENT , isAsc); 
        case 'TRUCKERGATEIN': return compare(a.TRUCKERGATEIN , b.TRUCKERGATEIN , isAsc); 
        case 'TRUCKERGATEOUT': return compare(a.TRUCKERGATEOUT , b.TRUCKERGATEOUT , isAsc); 

        default: return 0;
      }
    });
   }

}


function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}