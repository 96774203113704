<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<form class="form-horizontal" style ="width:600px;" [formGroup]="ForgotuserForm" name="ForgotuserForm" novalidate>
    <!-- <div class="modal" style="background-color: rgba(0, 0, 0, 0.637) !important;" role="dialog" [ngStyle]="{'display':displayStyle}">
        <div class="modal-dialog" role="document"> -->
            <!-- <div class="modal-content"> -->
                <div class="modal-header custom-modal-header">
                    <button type="button" class="close" (click)="closePopup()">&times;</button>
                    <h4 *ngIf="!ForgetPasswordFlag && ForgotuserFlag" class="modal-title text-center">Forgot User Name?</h4>
                    <h4 *ngIf="ForgetPasswordFlag" class="modal-title text-center">Forgot Your Password?</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="row" *ngIf="this.ForgetUserMessages.length > 0">
                            <div *ngFor="let Msg of this.ForgetUserMessages">
                                <div [ngClass]="Msg.severity===1?'alert alert-info text-center':Msg.severity===3? 'alert alert-danger text-center':Msg.severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                                    <span  class="glyphicon glyphicon-exclamation-sign"  aria-hidden="true"></span>&nbsp;
                                        {{Msg.messagedetail}}                                        
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="this.LoginMessages[0]!=null">
                                <div [ngClass]="LoginMessages[0].severity===1?'alert alert-info text-center':LoginMessages[0].severity===3? 'alert alert-danger text-center':LoginMessages[0].severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                                        <span *ngIf="LoginMessages[0].messagedetail !='The Login Name/Password combination is not valid.'">
                                        {{LoginMessages[0].messagedetail}}
                                        </span>
                                    <span *ngIf="LoginMessages[0].messagedetail =='The Login Name/Password combination is not valid.'">
                                        Invalid temporary password. Please try again
                                    </span>
                                </div>
                        </div>                        
                    </div>
                    <br>
                    <div class="row" *ngIf="!ForgetPasswordFlag && ForgotuserFlag">
                        <div class="col-md-2 text-right" style="padding-top:5px">
                            <label>Email :</label>
                        </div>
                        <div class="col-md-10 text-left no-padding">
                            <input type="Email" formControlName="Email" (input)="ValidateUserNameform()" class="form-control"  required />
                            <span class="text-danger" *ngIf="(f.Email.touched ) && f.Email.errors?.required">
                                Email is required
                            </span>
                            <span class="text-danger" *ngIf="f.Email.touched && f.Email.errors?.Email">
                                Enter a valid email address
                            </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="ForgetPasswordFlag && !ForgetPasswordMsgFlag">
                        <div class="col-md-4 col-sm-4 text-right padding-top"><label>Login Name :</label></div>
                        <div class="col-md-8 col-sm-8 input-group text-left padding-top text-primary">
                            <span> {{this.userName}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="ForgetPasswordFlag && !ForgetPasswordMsgFlag">
                        <div class="col-md-4 col-sm-4 text-right padding-top">
                            <label>Temporary password :</label>
                        </div>
                        <div class="col-md-8 col-sm-8 text-left">
                            <input type="text" formControlName="temppass" class="form-control"
                                required [(ngModel)]="temppass" (input)="ValidatePasswordform()"/>
                            <span class="text-danger" *ngIf="(f.temppass.touched ) && f.temppass.errors?.required">
                                Temporary Password is required
                            </span>                           
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn-group btn-group-lg" *ngIf="!ForgetPasswordFlag">
                        <button  type="submit" class="btn btn-primary btn-site"
                            [disabled]="!ForgotuserForm.valid"
                            (click)="submitForgotUserName(ForgotuserForm.value)">Submit</button>
                        <!--type="button"-->                    
                    </div>
                    <div class="btn-group btn-group-lg" *ngIf="ForgetPasswordFlag  && !ForgetPasswordMsgFlag">
                        <button  type="submit" class="btn btn-primary btn-site"
                        [disabled]="!ForgotuserForm.valid"
                        (click)="submitForgetPassword()">Submit</button>
                        <!--type="button"-->                    
                    </div>
                    <div class="btn-group btn-group-lg">
                        <button class="btn btn-primary btn-site btn-margin"  (click)="closePopup()">
                            <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
                        </button>
                    </div> <!--type="button"-->
                </div>
            <!-- </div> -->
        <!-- </div>
    </div> -->
</form>