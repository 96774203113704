import { Component, OnInit,Input } from '@angular/core';
// import{Product,LineItem} from 'src/app/core/models/LineItem';
import{ShoppingCart,LineItem} from 'src/app/core/models/ShoppingCart';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
@Component({
  selector: 'app-shopping-cart-details',
  templateUrl: './shopping-cart-details.component.html',
  styleUrls: ['./shopping-cart-details.component.css']
})
export class ShoppingCartDetailsComponent implements OnInit {
  @Input() ShoppingCart:ShoppingCart;
  LineItemsCollection:LineItem[]=[];
  constructor(private importchargesService: ImportchargesService,
              private router: Router ) { 
    
  }

  ngOnInit(): void {
   
    if(this.ShoppingCart.LineItems != null){
      this.LineItemsCollection=this.ShoppingCart.LineItems ;
    }
    // this.ShoppingCart.HoldType=this.ShoppingCart.LineItems[0].HoldType;
    //     this.ShoppingCart.TotalPrice=this.ShoppingCart.LineItems[0].TotalPrice;
    // sessionStorage.setItem("S_ShoppingCartTotalPrice", this.ShoppingCart.TotalPrice);
  }

  
  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

}
