<div class="container-fluid">
<div class="row" style="padding-bottom:10px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center"><h3 style="color:#FF6218"><strong>APM Terminals Payment Options</strong></h3></div>
    <div class="col-md-4"></div>
</div>
<div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
<br />
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-8">
        <p>
            <strong>Credit Cards</strong><br />
            <ul>
                <li>Visa</li>
                <li>Master Card</li>
                <li>Discover</li>
                <li>American Express</li>
            </ul>
            <strong>Electronic Check</strong><br /><br />
            <strong>ACH / Wire Transfer Instructions</strong><br /><br />
            <ul>
                <li>
                    <a (click)="scroll(APMTELZ)" style="cursor: pointer;" class="terminal-details">APM Terminals Elizabeth, LLC – Elizabeth</a>
                </li>
                <li>
                    <a (click)="scroll(SFCT)" style="cursor: pointer;" class="terminal-details">South Florida Container Terminal – Miami</a>
                </li>
                <li>
                    <a (click)="scroll(MCT)" style="cursor: pointer;"  class="terminal-details">Mobile Container Terminal – Mobile</a>
                </li>
                <li>
                    <a (click)="scroll(APMTP)" style="cursor: pointer;"  class="terminal-details"> APM Terminals Pacific LLC – Los Angeles</a>
                </li>               
            </ul>
            <strong>Paper Checks are no longer accepted in Charlotte or any other terminal location</strong><br /><br />
	    <strong>Please note: *** PayCargo payments made through TOPS will result in immediate payment release***<br />
                                ***Any payment made directly to PayCargo (via PayCargo’s website) will be processed overnight; APMT does not release with a payment receipt nor confirmation.***</strong><br/>
        <br />
    </div>
    <div class="col-md-1"></div>
</div>
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-7">
        <div class="row divTop-border" id="SFCT" #SFCT>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11"><h3><strong>South Florida Container Terminal</strong></h3></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <address>
                        9300 Arrowpoint Blvd<br />
                        1st Floor, South<br />
                        Charlotte, NC 28273-8136<br /><br />
                        Bank of America<br />
                        901 Main Street<br />
                        Dallas, TX  75202<br /><br />
                        <strong>For ACH Transfers:</strong><br />
                        ABA#/Routing# 111000012<br />
                        Account# 4426604439<br /><br />
                        <strong>For Wire Transfers:</strong><br />
                        ABA#/Routing# 026009593<br />
                        Account# 4426604439<br /><br />
                        <strong>Swift code:</strong>  BOFAUS3N<br /><br />
                        <strong>Tax Id#</strong> 26-3160095<br />
                    </address>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <p>
                        <strong>
                            Please have container numbers (or Bill of Lading) in description of wire and send e-mail notification to
                            <a href="mailto:AMRDemurrage@apmterminals.com">AMRDemurrage@apmterminals.com</a> of pending wire transfer
                            to ensure correct application of payment.
                        </strong>
                    </p>
                </div>
            </div>
            <br />
        </div><br />
        <div class="row divTop-border" id="MCT" #MCT>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11"><h3><strong>Mobile Container Terminal</strong></h3></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <address>
                        9300 Arrowpoint Blvd<br />
                        1st Floor, South<br />
                        Charlotte, NC 28273-8136<br /><br />
                        Bank of America<br />
                        901 Main Street<br />
                        Dallas, TX  75202<br /><br />
                        <strong>For ACH Transfers:</strong><br />
                        ABA#/Routing# 111000012<br />
                        Account# 3756297151<br /><br />
                        <strong>For Wire Transfers:</strong><br />
                        ABA#/Routing# 026009593<br />
                        Account# 3756297151<br /><br />
                        <strong>Swift code:</strong>  BOFAUS3N<br /><br />
                        <strong>Tax Id#</strong> 20-3963463<br />
                    </address>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <p>
                        <strong>
                            Please have container numbers (or Bill of Lading) in description of wire and send e-mail notification to
                            <a href="mailto:AMRDemurrage@apmterminals.com">AMRDemurrage@apmterminals.com</a> of pending wire transfer
                            to ensure correct application of payment.
                        </strong>
                    </p>
                </div>
            </div>
            <br />
        </div><br />
        <div class="row divTop-border" id="APMTP" #APMTP>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11"><h3><strong>APM Terminals Pacific LLC<small style="color:#222">(formerly Maersk Pacific Limited)</small></strong></h3></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <address>
                        9300 Arrowpoint Blvd<br />
                        1st Floor, South Building<br />
                        Charlotte, NC 28273-8136<br />
                        <br />
                        Bank of America<br />
                        901 Main Street<br />
                        Dallas, TX  75202<br />
                        <br />
                        <strong>For ACH Transfers:</strong><br />
                        ABA#/Routing# 111000012<br />
                        Account# 3756672912<br />
                        <br />
                        <strong>For Wire Transfers:</strong><br />
                        ABA#/Routing# 026009593<br />
                        Account# 3756672912<br />
                        <br />
                        <strong>Swift code:</strong>  BOFAUS3N<br />
                        <br />
                        <strong>Tax Id#</strong> 13-3020006<br />
                    </address>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <p>
                        <strong>
                            Please have container numbers (or Bill of Lading) in description of wire and send e-mail notification to
                            <a href="mailto:Pier400Demurrage@apmterminals.com">Pier400Demurrage@apmterminals.com</a> of pending wire transfer
                            to ensure correct application of payment. <br /> For assistance call 310-221-4100.
                        </strong>
                    </p>
                </div>
            </div>
            <br />
        </div><br />
        <!-- @*<div class="row divTop-border" id="APMTT">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11"><h3><strong>APM Terminals Tacoma LLC</strong></h3></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <address>
                        9300 Arrowpoint Blvd<br />
                        1st Floor, South Building<br />
                        Charlotte, NC 28273-8136<br />
                        <br />
                        Bank of America<br />
                        901 Main Street<br />
                        Dallas, TX  75202<br />
                        <br />
                        <strong>For ACH Transfers:</strong><br />
                        ABA#/Routing# 111000012<br />
                        Account# 4451019073<br />
                        <br />
                        <strong>For Wire Transfers:</strong><br />
                        ABA#/Routing# 026009593<br />
                        Account# 4451019073<br />
                        <br />
                        <strong>Swift code:</strong>  BOFAUS3N<br />
                        <br />
                        <strong>Tax Id#</strong> 22-3471729<br />
                    </address>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <p>
                        <strong>
                            Please have invoice numbers in description of wire and/or send, fax 704-571-5430, e-mail notification to
                            <a href="mailto:AMRAccountsreceivable@apmterminals.com">AMRAccountsreceivable@apmterminals.com</a> to ensure correct application of payment.
                            Terminal Location (West Coast), Tacoma, WA
                        </strong>
                    </p>
                </div>
                <br />
            </div><br />
        </div><br />*@ -->
        <div class="row divTop-border" id="APMTELZ" #APMTELZ>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11"><h3><strong>APM Terminals Elizabeth, LLC</strong></h3></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <address>
                        9300 Arrowpoint Blvd<br />
                        1st Floor, South<br />
                        Charlotte, NC 28273-8136<br />
                        <br />
                        Bank of America<br />
                        901 Main Street<br />
                        Dallas, TX  75202<br />
                        <br />
                        <strong>For ACH Transfers:</strong><br />
                        ABA#/Routing# 111000012<br />
                        Account# 4427824179<br />
                        <br />
                        <strong>For Wire Transfers:</strong><br />
                        ABA#/Routing# 026009593<br />
                        Account# 4427824179<br />
                        <br />
                        <strong>Swift code:</strong>  BOFAUS3N<br />
                        <br />
                        <strong>Tax Id#</strong> 80-0956003<br />
                    </address>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-11">
                    <p>
                        <strong>
                            Please have container numbers (or Bill of Lading) in description of wire and send e-mail notification to
                            <a href="mailto:AMRDemurrage@apmterminals.com">AMRDemurrage@apmterminals.com</a> of pending wire transfer
                            to ensure correct application of payment.<br /><br />
                        </strong>
                    </p>
                </div>
                <br />
            </div>
        </div>
    </div>
    <div class="col-md-2"></div>
</div>
</div>