import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../MustMatch'
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/shared/services/login.service'
import { States, ClientType, Countries, UserContactInfo, Clients } from 'src/app/shared/interfaces/login.interface';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  Messages: any;
  registrationForm: FormGroup;
  submitted = false;
  countries: Countries[];
  states: States[];
  stateValidate = false;
  clientTypedd: ClientType[];
  coutryId: string;
  stateId: string;
  UserContactInfo: UserContactInfo
  NewCompanyflag: boolean = false;
  ddCompanyName: any;
  taxID: any;
  companynametxt: any;
  newRegistrationMessage: true;
  NewRegSuccess: boolean;
  topsUserKey: any;
  User_id: string;
  CNameVisibility: boolean = false;
  selected: boolean;
  isDisabled: boolean = false;
  Canada_FL: boolean = false;
  ShowLoader = false;
  hidePwdAndConfirmpwd: boolean = true;
  disableflag: boolean = false;
  selectedCountry: Countries;
  selectedState: States;
  selectedClientTypeId: ClientType;
  UpdateContactInfoOnly: boolean = false
  initalValues: any;
  UpdateAllInfo: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.UserContactInfo = new UserContactInfo();
    this.selectedCountry = new Countries();
    this.selectedCountry.CountryId = 0;
    this.selectedState = new States();
    this.selectedState.StateId = '';
    this.selectedClientTypeId = new ClientType();
  }

  ngOnInit() {
    this.topsUserKey = this.cookieService.get('TOPSUserKey')
    this.NewCompanyflag = (this.topsUserKey == "") ? true : false;
    if (!this.NewCompanyflag) {
      this.User_id = this.topsUserKey.match(new RegExp("&User_Id=" + "(.*)" + "&PublicKey"));
      this.CNameVisibility = true;
      this.loginService.GetAllClientAndUserInformation("-1", this.User_id[1]).subscribe((result) => {
        this.UserContactInfo = result;
        this.countries = result.Countries;
        this.states = result.States;
        this.clientTypedd = result.ClientTypes;
        this.ddCompanyName = result.Clients;
        this.selectedCountry.CountryId = result.CountryId;
        // this.selectedState.StateId=result.StateId;
        this.selectedClientTypeId = result.ClientTypeId;
      });
    }
    else {
      this.loginService.GetNewUserInformation().subscribe((result) => {
        this.UserContactInfo = result;
        this.countries = result.Countries;
        this.states = result.States;
        this.clientTypedd = result.ClientTypes;
        this.UserContactInfo.Contact_ID = result.Contact_ID;
        this.ddCompanyName = result.Clients;
        this.selectedCountry.CountryId = result.CountryId;
        // this.selectedState.StateId=result.StateId;
        this.selectedClientTypeId = result.ClientTypeId;
        if (this.UserContactInfo.Canada_FL == false) {
          this.registrationForm.get('TaxIdNumber')?.setErrors(null);
          this.registrationForm.get('ReTaxIdNumber')?.setErrors(null);
        }
      });
    }

    this.registrationForm = this.formBuilder.group({
      TaxIdNumber: ['', {
        validators: [Validators.required, Validators.pattern(/^(\d{2}-\d{7})$/), Validators.max(99)],
        updateOn: 'blur'
      }],
      ReTaxIdNumber: ['', {
        validators: [Validators.required, Validators.pattern(/(\d{2}-\d{7})$/), Validators.max(99)],
        updateOn: 'blur'
      }],
      companyName: ['', [Validators.required, Validators.maxLength(255)]],
      CompanyType: ['', Validators.required],
      address1: ['', Validators.required],
      countries: ['', [Validators.required]],
      states: ['', Validators.required],
      cityLocation: ['', Validators.required],
      zip: ['', Validators.required],
      phone: ['', {
        validators: [Validators.required, Validators.pattern(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/)],
        updateOn: 'blur'
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      loginName: ['', {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_]*$'), Validators.minLength(6)],
        updateOn: 'blur'
      }],
      SecurityEmail: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      address2: [],
      chkIntPhone: [],
      ext: [],
      fax: ['', {
        validators: [Validators.pattern(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/)],
        updateOn: 'blur'
      }],
      Title: [],
      ddCompanyName: [],
      Canada_FL: []
    }, {
      validator: [MustMatch('password', 'confirmPassword'), MustMatch('TaxIdNumber', 'ReTaxIdNumber')]
    }
    );
  }

  newCompanyRegistration() {
    this.ShowLoader = true;
    if (this.UserContactInfo.Canada_FL == false) {
      this.registrationForm.get('TaxIdNumber')?.setErrors(null);
      this.registrationForm.get('ReTaxIdNumber')?.setErrors(null);
    }

    // excluding these form controls from validation and enabling submit/update button
    this.registrationForm.get('password')?.setErrors(null);
    this.registrationForm.get('confirmPassword')?.setErrors(null);

    this.NewCompanyflag = true;
    this.UpdateAllInfo = false;
    this.topsUserKey = this.cookieService.get('TOPSUserKey');
    this.User_id = this.topsUserKey.match(new RegExp("&User_Id=" + "(.*)" + "&PublicKey"));
    this.CNameVisibility = true;
    this.loginService.GetAllClientAndUserInformation("-1", this.User_id[1]).subscribe((result) => {
      this.ddCompanyName = result.Clients;

      // this.clientTypedd = result.ClientTypes;
      this.UserContactInfo = result;

      // this.countries = result.Countries;
      this.states = result.States
      this.selectedCountry.CountryId = result.CountryId;
      this.selectedState.StateId = result.StateId;
      this.selectedClientTypeId = result.ClientTypeId;
      this.ShowLoader = false;
    });
  }

  changeCompanyType(e: any) {
    this.CompanyType?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  GetClientByTaxIDNumberChange(e: any) {
    this.taxID = e.target.value;
    if ((this.UserContactInfo.TaxID_AN != '' || this.UserContactInfo.ReTypeTaxID_AN != '') && (this.UserContactInfo.TaxID_AN == this.UserContactInfo.ReTypeTaxID_AN)) {
      this.loginService.GetClientByTaxIDNumber(this.taxID).subscribe((result) => {
        if (result.ClientId != 0) {
          this.companynametxt = result.ClientName;
          this.UserContactInfo.ClientName = this.companynametxt;
          this.UserContactInfo.ClientId = result.ClientId;
          if (result.ClientTypeId != 0) {
            this.selectedClientTypeId = result.ClientTypeId;
          }
          else
            this.selectedClientTypeId.ClientTypeId = "0";
          this.disableflag = true;
        }
        else {
          this.disableflag = false;
          this.UserContactInfo.ClientName = '';
          // this.CompanyType?.reset();
          this.selectedClientTypeId = new ClientType();
          this.selectedClientTypeId.ClientTypeId = "0";
        }
      });
    }
    else {
      this.UserContactInfo.ClientId = -1;
    }
  }

  // Access formcontrols getter
  get CompanyType() {
    return this.registrationForm.get('CompanyType');
  }

  ShowHelpPrompt() {
    swal.fire({
      text: 'Your Password should contain at least 6 alphanumeric characters, including 1 number!',
      title: 'Password',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })//fire
  }

  ShowTaxIDHelpPrompt() {
    swal.fire({
      text: 'Please use the following format when entering your company TAX ID: XX-XXXXXXX.',
      title: 'Tax ID Number',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })//fire
  }

  ShowPhoneNumHelpPrompt() {
    swal.fire({
      text: 'Please use the following format when entering your Phone number: area code-phone number or XXX-XXX-XXXX.',
      title: 'Phone',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })//fire
  }

  ShowLoginNameHelpPrompt() {
    swal.fire({
      text: 'Your Login Name should contain at least 6 alphanumeric characters',
      title: 'Login Name',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })//fire
  }

  UpdateContactInfo(contctinfoChangeFlag: boolean) {
    this.UpdateContactInfoOnly = contctinfoChangeFlag
  }

  changeCountries(e: any) {
    this.coutryId = e.target.value
    this.UserContactInfo.CountryName = e.target.selectedOptions[0].innerText;
    this.selectedCountry.CountryName = e.target.selectedOptions[0].innerText
    this.loginService.GetStatesByCountry(this.coutryId).subscribe((result) => {
      this.states = result.States;
    })
  }

  // Access formcontrols getter
  // get Country() {
  //   return this.registrationForm.get('countries');
  // }

  changeStates(e: any) {
    this.stateId = e.target.value;
    this.UserContactInfo.StateName = e.target.selectedOptions[0].innerText;
    this.selectedState.StateName = e.target.selectedOptions[0].innerText;
  }

  OutsideUS(isCustoutsideUS: boolean) {
    if (isCustoutsideUS) {
      this.Canada_FL = isCustoutsideUS;
      this.registrationForm.get('TaxIdNumber')?.setErrors(null);
      this.registrationForm.get('ReTaxIdNumber')?.setErrors(null);
    }
    else {
      this.Canada_FL = isCustoutsideUS;
    }
  }

  customerChanged(e: any) {
    this.ShowLoader = true;
    if (this.UserContactInfo.Canada_FL == false) {
      this.registrationForm.get('TaxIdNumber')?.setErrors(null);
      this.registrationForm.get('ReTaxIdNumber')?.setErrors(null);
    }

    // this.UserContactInfo.ClientName = e.target.selectedOptions[0].innerText;

    if (e.target.selectedOptions[0].innerText == "--- Please select ---")
      this.NewCompanyflag = false;
    else
      this.NewCompanyflag = true;

    this.disableflag = false;
    this.isDisabled = true;
    this.hidePwdAndConfirmpwd = false;
    // excluding these form controls from validation and enabling submit/update button
    this.registrationForm.get('password')?.setErrors(null);
    this.registrationForm.get('confirmPassword')?.setErrors(null);
    let cCompanyId = (e.target.value === "null") ? -1 : e.target.value;

    this.loginService.GetAllClientAndUserInformation(cCompanyId, this.User_id[1]).subscribe((result) => {
      this.UserContactInfo = result;
      if (result.CountryId != '224' && this.states.length != result.States.length)
        this.states = result.States;

      if (result.CountryId == '224' && this.states.length != result.States.length)
        this.states = result.States;

      this.selectedCountry.CountryId = result.CountryId;
      this.selectedState.StateId = result.StateId;
      this.selectedClientTypeId = result.ClientTypeId;
      //this.f.CompanyType.setValue(this.selectedClientTypeId)
      this.ShowLoader = false;
    });

    this.initalValues = this.registrationForm.value;
  }

  // Access formcontrols getter
  // get state() {
  //   return this.registrationForm.get('states');
  // }

  get f() { return this.registrationForm.controls; }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.registrationForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSubmit(user: UserContactInfo, buttontype: string) {
    let invalids = [];
    invalids = this.findInvalidControls();
    if (this.registrationForm.controls["states"].value == "0") { this.stateValidate = true; return; } else this.stateValidate = false;
    var ClientIdvalue = this.CompanyType?.value;
    this.UserContactInfo.CountryId = this.selectedCountry.CountryId;
    this.UserContactInfo.StateId = Number(this.selectedState.StateId);
    this.UserContactInfo.ClientTypeId = ClientIdvalue;
    this.UserContactInfo.CountryName = this.selectedCountry.CountryName;
    this.UserContactInfo.StateName = this.selectedState.StateName;
    if (invalids.length <= 0) {
      if (buttontype == 'submit') {
        this.submitted = true;
        this.ShowLoader = true;
        this.loginService.PostRegistration(this.UserContactInfo).subscribe((result) => {
          this.ShowLoader = false;
          if (result)
            this.Messages = result.Message;
          if (this.Messages == null)
            this.NewRegSuccess = true;
          else
            this.NewRegSuccess = false;
        },
          error => {
            this.ShowLoader = false;
            this.Messages = error;
          });
      }
      else if (buttontype == 'update') {
        this.submitted = true;
        this.ShowLoader = true;
        if (this.UpdateContactInfoOnly && this.UpdateAllInfo && this.NewCompanyflag) {
          this.loginService.PostOnlineUserContactInfoUpdate(this.UserContactInfo).subscribe((result) => {
            this.ShowLoader = false;
            // need to see here
            this.router.navigateByUrl('/landingpage');
          },
            error => {
              this.ShowLoader = false;
              this.Messages = error;
            });
        }
        else {

          this.ShowLoader = true;
          this.loginService.PostNewOnlineUserUpdate(this.UserContactInfo).subscribe((result) => {
            this.ShowLoader = false;
            // need to see here
            this.router.navigateByUrl('/landingpage');
          },
            error => {
              this.Messages = error;
            });
        }
      }
    }

  }

  onReset() {
    // if user is loged in route to landing page else go to home page
    if (this.cookieService.get('TOPSUserKey') != undefined && this.cookieService.get('TOPSUserKey') != '')
      this.router.navigateByUrl('/landingpage');
    else
      this.router.navigateByUrl('/home');
  }
}
