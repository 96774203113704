import { Component, OnInit, ViewEncapsulation, ViewChild,ElementRef, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import{InvoiceData,InvoiceFilters,Invoices} from 'src/app/core/models/InvoiceFilters'
import { CookieService } from 'ngx-cookie-service';
import swal from 'sweetalert2';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SelectionModel } from "@angular/cdk/collections";
import * as XLSX from 'xlsx';
import { LoginService } from 'src/app/shared/services/login.service'
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { TerminalInfo } from 'src/app/core/models/Notification.model';
import { AncillaryStatementInvoice, DemurageStatementInvoice, StatementFilters } from 'src/app/core/models/StatementFilters';
import * as moment from 'moment';
@Component({
  selector: 'app-importstatements',
  templateUrl: './importstatements.component.html',
  styleUrls: ['./importstatements.component.css']
})
export class ImportstatementsComponent implements OnInit {
@ViewChild('TABLE') table: ElementRef;
exportActive:boolean = false;
  Messages: ResponseMessage[]=[];
  StatementInvoices: FormGroup;
  StatementInvoicesobj:any
  ShowLoader = false;
  FromDate: Date;
  ToDate: Date;
  Dateformat: any;
  InvoiceAncil: boolean = true;
  today =  new Date(moment(new Date).format("M/D/YYYY"));
  TerminalData: TerminalInfo;
  StatementFilters:StatementFilters;
  minDate: Date;
  searchactive: boolean;
  submitted = false;
  displayInvCollectionA:AncillaryStatementInvoice[]=[];
  displayInvCollectionD:DemurageStatementInvoice[]=[];
  selectedInvoiceCollection:InvoiceData[]=[];
  displayClientsCollectionA:number=0;
  displayClientsCollectionD:number=0;
  dataSourceAncillary: any;
  dataSourceDemurage: any;
  displayedColumnsA: string[] = ['InvoiceNumber','ContainerNumber','BillLadingNumber','PoNumber','ClientName','vesselvoyage','tops_holdname','InvoiceDate','BillingAmount','Disputed'];
  displayedColumnsD: string[] = [
    'dem-InvoiceNumber'	,'dem-ContainerNumber',	'dem-BillLadingNumber',	'ReferenceNumber',	'VesselCode',	'VoyageNumber',	'SzTp',	'DateTimeOfFirstAvailability',	'PortLastFreeDate',	'LineLastFreeDate',	'Paid_Thru_DT', 'PickupDate',	'Day1',	'myTotalCharge1',	'Day2',	'myTotalCharge2',	'Day3',	'myTotalCharge3',	'Day4',	'myTotalCharge4','dem-BillingAmount','dem-Disputed'];
  TerminalId:string;
  selectedTerminalName:string;
  selection = new SelectionModel<DemurageStatementInvoice>(true, []);
  DemurageTotalBilling:number = 0.00;
  AncillaryTotalBilling:number = 0.00;
  StatementNumber:string;
  constructor(
    private formBuilder: FormBuilder,
    private importchargesService: ImportchargesService,
    private cookieService: CookieService,
    private AuthService: AuthService, 
    private loginService: LoginService,
  ) {  
    this.ToDate = new Date()
    this.FromDate = new Date();
    this.TerminalData = new TerminalInfo();
  }

  ngOnInit(): void {
    this.TerminalData.Terminals = [];
    this.GetAllTerminals();
    this.StatementFilters = new StatementFilters();
    this.StatementFilters.FromDate = this.today;
    this.StatementFilters.ToDate = this.today;    
    this.StatementFilters.InvoiceAncil =true;
    this.StatementFilters.InvoiceID = '';
      
    this.StatementInvoices = this.formBuilder.group({
      FromDate: [this.FromDate, Validators.required],
      ToDate: [this.ToDate, Validators.required],
      TerminalId: ['', Validators.required],
      InvoiceAncil: ['', Validators.required],
      User_id:Number(this.AuthService.User_Id()),
      StatementNum:[this.StatementNumber],
      InvoiceID:[]
    },
    { validator: this.checkDates });
  }
  
  OnChange(event:any,row:any) {
    var found = this.selectedInvoiceCollection.indexOf(row);
    // this.selectedInvoiceCollection.forEach((val:any) => { if(val["InvoiceId"] == row["InvoiceId"]) found =0; });    
    if(found == -1) {
      this.selectedInvoiceCollection.push(row);  
    }
    else  { 
      this.selectedInvoiceCollection.splice(found, 1);
    }  
    event ? this.selection.toggle(row) : null;
    return true;
  }

  arrayRemove(arr:any, value:any) {
    return arr.filter(function(ele:any){
        return ele != value;
    });
  }

  CreateStatement()  {
    // var InvoiceID=this.selectedInvoiceCollection.toString();
    this.selectedInvoiceCollection.forEach( (statrow:any)=> {
          this.StatementFilters.InvoiceID += statrow.InvoiceId + ',';
    });      

    // this.StatementFilters.InvoiceID = InvoiceID;
    this.StatementFilters.TerminalID = Number(this.TerminalData.TerminalId);
    if (this.selectedInvoiceCollection.length > 0) {
      this.ShowLoader=true; 
      this.importchargesService.CreateStatementInvoices(this.StatementFilters).subscribe( (result) => {
        this.StatementFilters.StatementNum =result.StatementNumber;      
        localStorage.StatementTerminalID = this.StatementFilters.TerminalID;
        localStorage.StatementInvoiceAncil = this.StatementFilters.InvoiceAncil;       
        localStorage.StatementNum = this.StatementFilters.StatementNum;
        localStorage.StatementFromDate = this.StatementFilters.FromDate;
        localStorage.StatementToDate = this.StatementFilters.ToDate;   
        this.selectedInvoiceCollection=[];
        if (this.StatementFilters.InvoiceAncil) {
          this.importchargesService.GetAncillaryInvoicesForStatements(this.StatementFilters).subscribe( (result) => {
              this.searchactive = true;    
              this.ShowLoader=false;          
              this.Messages = result.generalMessages;             
              this.AncillaryTotalBilling=result.AncillaryTotalBilling;        
              this.dataSourceAncillary = result.InvoicesForStat;                  
              this.selectedInvoiceCollection = result.InvoicesForStat;       
              if(result.InvoicesForStat != null)
                this.displayClientsCollectionA= result.InvoicesForStat?.length;                     
          });
        }
        else {
        this.importchargesService.GetDemurageInvoicesForStatements(this.StatementFilters).subscribe( (result)  => {
              this.searchactive = true;    
              this.ShowLoader=false;           
              this.Messages = result.generalMessages;
              this.DemurageTotalBilling=result.DemurageTotalBilling;
              this.dataSourceDemurage = result.InvoicesForStat; 
              this.selectedInvoiceCollection = result.InvoicesForStat; 
              if(result.InvoicesForStat != null)
                this.displayClientsCollectionD=result.InvoicesForStat?.length;                                 
          });
        }
        this.ShowLoader=false; 
        setTimeout(() => { window.open(window.location.origin + '/StatementInvoiceList', '_blank');  }, 2000);        
      });
    }
    else {
      swal.fire({
        text: 'Please identify invoices for Statement Creation.',
        title: 'Error...',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: "#004165",
        customClass: 'swal-wide',
      })//fire
    }
  }
  
  exporttoExcel() {
    this.exportActive = true;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');    
    /* save to file */
    XLSX.writeFile(wb, 'importaccounthistory.xlsx');    
  }

  InvImportPrintPreview(rowData:any) {
    localStorage.PrintInvoiceFilters = [];     
    localStorage.InvoiceAncil = this.StatementFilters.InvoiceAncil;        
    let selectedInvoiceCollection = [];  
    selectedInvoiceCollection.push(rowData);          
    localStorage.setItem("PrintInvoiceFilters",JSON.stringify(selectedInvoiceCollection));
    if(this.StatementFilters.InvoiceAncil === true){   
      window.open(window.location.origin + '/InvoicePreview', '_blank'); 
    }
    else {      
      window.open(window.location.origin + '/DemurageInvoice', '_blank'); 
    }  
  };

  GetAllTerminals() {
    this.loginService.GetAllTerminals().subscribe((result) => {
        this.TerminalData = result;  
      });
  }

  changeTerminals(e: any) {
    this.TerminalId = e.target.value;
    this.selectedTerminalName=e.target.selectedOptions[0].innerText;
    this.cookieService.set('TerminalName',this.selectedTerminalName);
  }

  checkDates(group: FormGroup) {
    if (group.controls.ToDate.value < group.controls.FromDate.value) {
      return { notValid: true }
    }
    return null;
  }
 
  calculateDiff() {
    let days = Math.floor((this.GetToDate() - this.GetFromDate()) / 1000 / 60 / 60 / 24);
    return days;
  }
  
  GetFromDate() {
    return this.StatementInvoices.get('FromDate')?.value;
  }

  GetToDate() {
    return this.StatementInvoices.get('ToDate')?.value;
  }

  GetStatementNum() {
    return this.StatementInvoices.get('StatementNum')?.value;
  }

  RadioOptionSelect(InvoiceAncil: boolean) {
    this.InvoiceAncil = InvoiceAncil;
    this.searchactive=false;
    this.submitted=false;
    this.selectedInvoiceCollection=[];
  }

  get f() { return this.StatementInvoices.controls; }

  OpenDatePrompt() {
    swal.fire({
      text: 'Please limit your search dates to a span of 60 days or less',
      title: 'Error...',
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })//fire
  }

  ngOnChanges() {
    this.SearchInvoiceInfo(this.StatementInvoices);  
  } 

  SearchInvoiceInfo(StatementInvoices: FormGroup) {
    this.submitted=true;
    if (this.calculateDiff() > 60) {
      this.OpenDatePrompt()
    }
    if (this.StatementInvoices.valid) {      
      this.searchactive=true;
      this.ShowLoader=true;
      this.selectedInvoiceCollection=[];
      if(this.StatementFilters.InvoiceAncil == true){
          this.importchargesService.GetAncillaryInvoicesForStatements(StatementInvoices).subscribe( (result) =>{
            this.ShowLoader=false;            
            this.Messages=result.generalMessages;    
            this.AncillaryTotalBilling=result.AncillaryTotalBilling;        
            this.dataSourceAncillary = result.InvoicesForStat;       
            this.selectedInvoiceCollection = result.InvoicesForStat;     
            if(result.InvoicesForStat != null)
              this.displayClientsCollectionA=result.InvoicesForStat?.length;         
          });
        }
        else {
            this.importchargesService.GetDemurageInvoicesForStatements(StatementInvoices).subscribe((result) => {
              this.ShowLoader=false;          
              this.Messages=result.generalMessages;
              this.DemurageTotalBilling=result.DemurageTotalBilling;
              this.dataSourceDemurage = result.InvoicesForStat; 
              this.selectedInvoiceCollection = result.InvoicesForStat;   
              if(result.InvoicesForStat != null)  
                this.displayClientsCollectionD=result.InvoicesForStat?.length;             
            });
        }     
      }
  }
}
