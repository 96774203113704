<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12 grid-padding">
    <div data-st-table="ContainerHistoryCollection" >
    <div class="row col-md-12 col-sm-12 no-padding">
    <div class="col-md-7 col-sm-7">
        <h3 class="text-left lbltitle"><strong style="color:#FF6218">Equipment History for : {{TrackFilter.ContainerNumber}}</strong></h3>
    </div>
    </div>
  
    <div class="row col-md-12 col-sm-12">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort>  <!--*ngIf="this.Gatetransactionlist.length > 0"-->
            <!-- Operator Column -->
            <ng-container matColumnDef="OPERATOR">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Operator </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext">{{element.OPERATOR | uppercase}} </td>
             </ng-container>
 
             <!-- PERFORMED Column -->
             <ng-container matColumnDef="PERFORMED">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Performed </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.PERFORMED | date:'MM-dd-yyyy HH:mm:ss'}} </td>
             </ng-container>
 
             <!-- EVENT Column -->
             <ng-container matColumnDef="EVENT">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Event </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.EVENT | uppercase}} </td>
             </ng-container>    
 
             <!-- POSNOTES Column -->
             <ng-container matColumnDef="POSNOTES">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Position Notes </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.POSNOTES}} </td>
             </ng-container>
             <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="this.ContainerHistorylist.length == 0" class="no-records"> No Results found.</div>  
    </div>
   </div>
<!-- </div> -->
</div>
</div>
