import { Component, Inject, OnInit } from '@angular/core';
import { userInfo } from 'src/app/shared/interfaces/login.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/common/registration/MustMatch';
import swal from 'sweetalert2'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/shared/services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-pasword',
  templateUrl: './change-pasword.component.html',
  styleUrls: ['./change-pasword.component.css']
})
export class ChangePaswordComponent implements OnInit {
  LoginName:any;
  Password_DS:any;
  ConfirmPassword_DS:any;
  ShowLoader = false;
  loggedIn=  false;
  changePaswordForm: FormGroup;
  passwordUpdateSuccessful=false;
  Messages: ResponseMessage[]=[];
  userInfo:userInfo;
  constructor( private formBuilder: FormBuilder,
              private loginService:LoginService,     
              private AuthService: AuthService,          
              private cookieService: CookieService,
              @Inject(MAT_DIALOG_DATA) public UserData: any,
              private router: Router,
              public dialogRef: MatDialogRef<ChangePaswordComponent>,) { }

  ngOnInit(): void {

    this.LoginName = this.UserData.userName;

    this.changePaswordForm = this.formBuilder.group({
      // LoginName: ['', [Validators.required]],
      UPassWord:['',[Validators.required,Validators.pattern(/^\w*(?=\w*\d)(?=\w*[A-Za-z])\w*$/)]],
      confirmPassWord:['',Validators.required]
    },{
      validator: MustMatch('UPassWord', 'confirmPassWord')
    } )
  }

  get f() { return this.changePaswordForm.controls; }
  
  ShowHelpPrompt() {
    swal.fire({ 
      text: 'Your Password should contain at least 6 alphanumeric characters, including 1 number!', 
      title: 'Password',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    })
  }

  closePopup() {
    this.Messages=[]
    this.passwordUpdateSuccessful=false;
    this.dialogRef.close('cancel');      
  }

  submitChangePassword() {

    if (this.changePaswordForm.valid && this.LoginName != '') {
      this.ShowLoader = true;
      this.userInfo = new userInfo();
      this.userInfo.User_Id = Number(this.AuthService.User_Id());
      this.userInfo.Password = this.Password_DS;
      this.userInfo.generalMessages = [];
      this.loginService.GetOnlineUserChangePassword(this.userInfo).subscribe( (result) => {
          this.ShowLoader = false;
          this.Messages = result.generalMessages;
          if (this.IsFieldNotBlank(this.Messages) && this.Messages.length === 0) {
              this.loggedIn = true;
              this.dialogRef.close('cancel'); 
              this.router.navigateByUrl('/landingpage');
          }
      });
    }

  }

  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  } 

}
