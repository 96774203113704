import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './common/home/home.component';
import { LayoutComponent } from './common/layout/layout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { TranslateService } from "./shared/services/translateservice.service";
import { ExamratesComponent } from './common/home/examrates/examrates.component';
import { HttpConfigInterceptor } from "./common/interceptors/httpconfig.interceptor.service";
import { FaqComponent } from './common/home/faq/faq.component';
import { ContactComponent } from './common/home/contact/contact.component';
import { MaterialModule} from './shared/modules/material.module';
import { LoginService } from './shared/services/login.service';
import { PaymentoptionsComponent } from './common/home/paymentoptions/paymentoptions.component';
import { DataLoadingSpinnerComponent } from './shared/components/data-loading-spinner/data-loading-spinner.component';
import { RegistrationComponent } from './common/registration/registration/registration.component';
import { LandingpageComponent } from './core/components/landingpage/landingpage.component';
import { ViewRegistrationComponent } from './core/components/view-registration/view-registration.component';
import { NotificationlistComponent } from './core/components/notificationlist/notificationlist.component';
import { ImportchargesearchComponent } from './core/components/importcharges/importchargesearch/importchargesearch.component';
import { OogchassisflipsearchComponent } from './core/components/exportcharges/oogchassisflipsearch/oogchassisflipsearch.component';
import { TrackandtraceComponent } from './core/components/trackandtrace/trackandtrace.component';
import { BookingInquiryResultsComponent } from './core/components/trackandtrace/booking-inquiry-results/booking-inquiry-results.component';
import { VesselScheduleResultsComponent } from './core/components/trackandtrace/vessel-schedule-results/vessel-schedule-results.component';
import { EquipmentHistoryResultsComponent } from './core/components/trackandtrace/equipment-history-results/equipment-history-results.component';
import { ImportAvailabilityResultsComponent } from './core/components/trackandtrace/import-availability-results/import-availability-results.component';
import { GateTransactionResultsComponent } from './core/components/trackandtrace/gate-transaction-results/gate-transaction-results.component';
import { ImportChargeResultsComponent } from './core/components/importcharges/importchargeresults/importchargeresults.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { UpdatePasswordComponent } from './common/update-password/update-password.component';
import { ImportaccounthistoryComponent } from './core/components/importcharges/importaccounthistory/importaccounthistory.component';
import { EIRPrintDetailsComponent } from './core/components/trackandtrace/gate-transaction-results/eirprint-details/eirprint-details.component';
import { VGMServiceResultsComponent } from './core/components/exportcharges/vgmservicesearch/vgmservice-results/vgmservice-results.component';
import { NewCheckOutConfirmationComponent } from './core/components/importcharges/new-check-out-confirmation/new-check-out-confirmation.component';
import { CheckOutConfirmationComponent } from './core/components/importcharges/check-out-confirmation/check-out-confirmation.component';
import { ShoppingCartDetailsComponent } from './core/components/shoppingcart/shopping-cart-details/shopping-cart-details.component';
import { NewShoppingCartPaymentComponent } from './core/components/shoppingcart/new-shopping-cart-payment/new-shopping-cart-payment.component';
import { ShoppingCartPaymentComponent } from './core/components/shoppingcart/shopping-cart-payment/shopping-cart-payment.component';
import { VGMDetailsViewComponent } from './core/components/trackandtrace/booking-inquiry-results/vgmdetails-view/vgmdetails-view.component';
import { VgmservicesearchComponent } from './core/components/exportcharges/vgmservicesearch/vgmservicesearch.component';
import { OogstripstuffsearchComponent } from './core/components/exportcharges/oogstripstuffsearch/oogstripstuffsearch.component';
import { ExportaccounthistoryComponent } from './core/components/exportcharges/exportaccounthistory/exportaccounthistory.component';
import { OogchassisflipresultsComponent } from './core/components/exportcharges/oogchassisflipsearch/oogchassisflipresults/oogchassisflipresults.component';
import { OogstripstuffresultsComponent } from './core/components/exportcharges/oogstripstuffsearch/oogstripstuffresults/oogstripstuffresults.component';
import { AdditionalInfoComponent } from './core/components/landingpage/additional-info/additional-info.component';
import { ExportInvoicePreviewComponent } from './core/components/exportcharges/exportaccounthistory/export-invoice-preview/export-invoice-preview.component';
import { CyberSourcePaymentComponent } from './core/components/shoppingcart/cyber-source-payment/cyber-source-payment.component';
import { PayCargoPaymentComponent } from './core/components/shoppingcart/pay-cargo-payment/pay-cargo-payment.component';
import { ImportstatementsComponent } from './core/components/importcharges/importstatements/importstatements.component';
import { ElectronicCheckPaymentComponent } from './core/components/shoppingcart/electronic-check-payment/electronic-check-payment.component';
import { CreditCartPaymentComponent } from './core/components/shoppingcart/credit-cart-payment/credit-cart-payment.component';
import { DemuragePreviewComponent } from './core/components/importcharges/importaccounthistory/demurage-preview/demurage-preview.component';
import { StatementInvoiceListComponent } from './core/components/importcharges/importstatements/statement-invoice-list/statement-invoice-list.component';
import { DemurrageConfirmationComponent } from './core/components/importcharges/demurrage-confirmation/demurrage-confirmation.component';
import { CheckoutcompletionComponent } from './core/components/importcharges/checkoutcompletion/checkoutcompletion.component';
import { ShoppingCartReceiptComponent } from './core/components/shoppingcart/shopping-cart-receipt/shopping-cart-receipt.component';
import { ExportbookingholdsconfirmationComponent } from './core/components/exportcharges/exportbookingholdsconfirmation/exportbookingholdsconfirmation.component';
import { ShowContainerinfoComponent } from './core/components/importcharges/importchargeresults/show-containerinfo/show-containerinfo.component';
import { CyberSourceLandingComponent } from './core/components/shoppingcart/cyber-source-landing/cyber-source-landing.component';
import { CybersourceprocessingComponent } from './core/components/shoppingcart/cybersourceprocessing/cybersourceprocessing.component';
import { ImportInvoicePreviewComponent } from './core/components/importcharges/importaccounthistory/import-invoice-preview/import-invoice-preview.component';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrintElementModule } from 'ngx-print-element';
import { AddnotificationsComponent } from './core/components/notificationlist/addnotifications/addnotifications.component';
import { ForgotUserNameComponent } from './common/home/forgot-user-name/forgot-user-name.component';
import { ChangePaswordComponent } from './common/home/change-pasword/change-pasword.component';
export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use("en", "");
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    ExamratesComponent,
    FaqComponent,
    ContactComponent,
    PaymentoptionsComponent,
    DataLoadingSpinnerComponent,
    RegistrationComponent,
    LandingpageComponent,
    ViewRegistrationComponent,
    NotificationlistComponent,
    ImportchargesearchComponent,
    OogchassisflipsearchComponent,
    TrackandtraceComponent,
    ImportChargeResultsComponent,
    BookingInquiryResultsComponent,
    VesselScheduleResultsComponent,
    EquipmentHistoryResultsComponent,
    ImportAvailabilityResultsComponent,
    GateTransactionResultsComponent,
    UpdatePasswordComponent,
    ImportaccounthistoryComponent,
    EIRPrintDetailsComponent,
    VGMServiceResultsComponent,
    NewCheckOutConfirmationComponent,
    CheckOutConfirmationComponent,
    ShoppingCartDetailsComponent,
    NewShoppingCartPaymentComponent,
    ShoppingCartPaymentComponent,
    VGMDetailsViewComponent,
    VgmservicesearchComponent,
     OogstripstuffsearchComponent,
    ExportaccounthistoryComponent,
    ImportstatementsComponent,
    OogchassisflipresultsComponent,
    OogstripstuffresultsComponent,
    AdditionalInfoComponent,
    ExportInvoicePreviewComponent,
    CyberSourcePaymentComponent,
    PayCargoPaymentComponent,
    DemuragePreviewComponent,
    StatementInvoiceListComponent,
    ElectronicCheckPaymentComponent,
    CreditCartPaymentComponent,
    DemurrageConfirmationComponent,
    CheckoutcompletionComponent,
    ShoppingCartReceiptComponent,
    ExportbookingholdsconfirmationComponent,
    CyberSourceLandingComponent,
    CybersourceprocessingComponent,
    ImportInvoicePreviewComponent,
    ShowContainerinfoComponent,
    AddnotificationsComponent,
    ForgotUserNameComponent,
    ChangePaswordComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPrintElementModule,
    NgxPrintModule
  ],
  providers: [
    LoginService,
    TranslateService,
    CookieService,
    DatePipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
