<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" style="background-color: white;" >
	<div class="row" style="padding-bottom:10px;">
		<div class="col-md-4"></div>
		<div class="col-md-4 text-center"><h3><strong>Submit Payment</strong></h3></div>
		<div class="col-md-4"></div>
	</div>
	<div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div><br />
    <div *ngIf="Messages.length > 0">
	    <div class="row"  *ngFor="let Msg of Messages">
		    <div class="col-md-8 col-md-offset-2">
			    <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
				    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
			    </div>
		    </div>
	    </div>
		<br/>
    </div>	
	<div class="row col-md-12 col-sm-12">
		<div class="row">
			<app-shopping-cart-details [ShoppingCart]="this.ShoppingCart" ></app-shopping-cart-details>
		</div>
		<div class="row" *ngIf = "SCartPayment.PaymentTypes != null">
            <app-new-shopping-cart-payment [ShoppingCart]="this.ShoppingCart" [SCartPayment] = "SCartPayment" (outputToParent)="GetOutputVal($event)" ></app-new-shopping-cart-payment>
		</div>
		<br /><br />
		<div class="row col-md-12 col-sm-12">
			<div class="col-md-10 text-right">
				<!-- BACK BUTTON -->
				<button class="btn btn-primary btn-site" [routerLink]="['/oogchassisflipsearch']"  *ngIf="ShoppingCart.SubHoldType == 'O'">
					<span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
					Back
				</button>
				<!-- BACK BUTTON -->
				<button class="btn btn-primary btn-site" [routerLink]="['/oogstripstuffsearch']" *ngIf="ShoppingCart.SubHoldType == 'W'">
					<span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
					Back
				</button>
				<button class="btn btn-primary btn-site" [routerLink]="['/vgmservicesearch']" *ngIf="ShoppingCart.SubHoldType == 'V'">
					<span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
					Back
				</button>
				<button class="btn btn-primary btn-site"  *ngIf="ShoppingCart.HoldType == 'S'">  <!--[routerLink]="['/serviceordersearch']"-->
					<span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
					Back
				</button>
				<button class="btn btn-primary btn-site" [routerLink]="['/importchargeresults']" *ngIf="ShoppingCart.HoldType != 'E' && ShoppingCart.HoldType != 'S'">
					<span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
					Back
				</button>
			</div>
		</div>
	</div>
	<br /><br />
</div>
