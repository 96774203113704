import { Injectable } from '@angular/core';
import { Observable, throwError,BehaviorSubject ,Subject} from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiurls,httpOptions } from 'src/app/common/utils/globalconstants';
@Injectable({
  providedIn: 'root'
})
export class ExportchargesService {
  
  constructor(private httpClient: HttpClient) { }
  
  GetExportChargesSearchRefData(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetExportChargesSearchRefData,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetAllExportInvoicesForUserIdByDates(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetAllExportInvoicesForUserIdByDates,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetBookingInvoiceDataForPrint(requestdata:any):Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetBookingInvoiceDataForPrint + "/"+ requestdata).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }    
  
  private handleError(err: HttpErrorResponse) {
    return throwError(() => new Error('Error Occured')); //throwError('Error Occured');
 }


}
