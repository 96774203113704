import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(private cookieService: CookieService) { }

  SentToCyberSource(){
    var SentToCyberSource = 'false';
			if (this.IsFieldNotBlank(this.cookieService.get("TOPSShoppingCart")))
				SentToCyberSource = this.cookieService.get("TOPSShoppingCart").split("&")[2].replace("SentToCyberSource=", "");

			return (SentToCyberSource === 'true');
  }

  HoldType(){
    var HoldType = null;
    if (this.IsFieldNotBlank(this.cookieService.get("TOPSShoppingCart")))
      HoldType = this.cookieService.get("TOPSShoppingCart").split("&")[1].replace("HoldType=", "");
    return HoldType;    
  }

  TransactionReferenceId(){
    var TransactionReferenceId = null;
    if (this.IsFieldNotBlank((this.cookieService.get("TOPSShoppingCart"))))
      TransactionReferenceId = this.cookieService.get("TOPSShoppingCart").split("&")[0].replace("TransactionReferenceId=", "");
    return TransactionReferenceId;
  }

  DeleteCookie(){
    if (this.IsFieldNotBlank(this.cookieService.get("TOPSShoppingCart"))) {
      var now = new Date();
      var expiresValue = new Date(now);
      expiresValue.setDate(now.getDate() - 1);
      this.cookieService.delete("TOPSShoppingCart");
    }

  }

  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

  CreateCookie(transactionReferenceId:any, holdType:any, sentToCyberSource:any){
    this.DeleteCookie();
    var topsShoppingCart = "TransactionReferenceId=" + transactionReferenceId + "&HoldType=" + holdType + "&SentToCyberSource=" + sentToCyberSource;
    this.cookieService.set('TOPSShoppingCart', topsShoppingCart);
  }


}
