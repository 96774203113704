import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort'; 
import { MatTableDataSource} from '@angular/material/table';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ExportchargesService } from 'src/app/core/services/exportcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportFilters , ExportInvoiceItem,ExportInvoiceData } from 'src/app/core/models/export.model';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import {SelectionModel} from '@angular/cdk/collections';
import * as moment from 'moment';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2'; 
@Component({
  selector: 'app-exportaccounthistory',
  templateUrl: './exportaccounthistory.component.html',
  styleUrls: ['./exportaccounthistory.component.css']
})
export class ExportaccounthistoryComponent implements  AfterViewInit,OnInit {
  // @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  Messages: ResponseMessage[]=[];
  ExportInvoiceItem:ExportInvoiceItem;
  ShowLoader = false;
  ExportInvoiceListData:ExportInvoiceData[]=[];
  ExportHistory: FormGroup;
  displayInvCollection = [];
  NonReleasedInvoicesExist = [];
  selectedInvoiceCollection:string[]=[];
  selection = new SelectionModel<ExportInvoiceData>(true, []);
  dataSource: MatTableDataSource<ExportInvoiceData>;
  displayedColumns: string[] = ['select', 'InvoiceNumber', 'StatementNumber', 'PoNumber','InvoiceDate','Terminal','TotalAmount','OutstandingBalance','StatusName'];
  searchactive = false;
  today =  new Date(moment(new Date).format("M/D/YYYY"));
  submitted = false;
  ExportFilters :ExportFilters;
  ValidateFromDate:false;
  ValidateToDate:false;

  constructor(private exportchargesService: ExportchargesService,
              private formBuilder: FormBuilder,
              private AuthService: AuthService, 
              private _liveAnnouncer: LiveAnnouncer) {   
   }
   
  ngAfterViewInit() {   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;  
  } 

  validateExpFormInput() {
    if (!this.ExportFilters) return;
    var endeExpDate = new Date(this.ExportFilters.ToDate);
    var startExpDate = new Date(this.ExportFilters.FromDate);
    if (endeExpDate < startExpDate) {
      swal.fire({ text: "The To date cannot be before From Date. Please check date range for your request", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return false;
    }

    if ((this.ExportFilters.FromDate != null &&  this.ExportFilters.ToDate != null  && this.ExportFilters.BookingNum == '' && this.ExportFilters.InvoiceNum == '') || (this.ExportFilters.BookingNum != '' || this.ExportFilters.InvoiceNum != '')){

      this.ExportHistory.controls["Book"].clearValidators();
      this.ExportHistory.controls["Book"].updateValueAndValidity();
      
      this.ExportHistory.controls["Inv"].clearValidators();
      this.ExportHistory.controls["Inv"].updateValueAndValidity();

    }   

    //validate input 
    if (this.ExportHistory.valid == false) {
      swal.fire({ text: "Please enter required information.", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return false;
    }    
    return true;
};


  ngOnInit(): void {    

    this.dataSource = new MatTableDataSource();
    this.ExportFilters = new ExportFilters();
    this.ExportFilters.FromDate = this.today;
    this.ExportFilters.ToDate = this.today;
    this.ExportFilters.BookingNum='';
    this.ExportFilters.InvoiceNum='';
    this.ExportFilters.InvoiceStrip='1';
    this.ExportFilters.User_ID = Number(this.AuthService.User_Id());

    this.ExportHistory = this.formBuilder.group({
      Book: ['', Validators.required],
      Inv: ['', Validators.required],
      pickUpDate: [null, Validators.required],
      pickToDate: [null, Validators.required],
    });

  }  
  
  arrayRemove(arr:any, value:any) {
    return arr.filter(function(ele:any){
        return ele != value;
    });
  }
  
  OnChange(event:any,row:any) {
    if(event.checked === true)  
      this.selectedInvoiceCollection.push(row["UniqueInvoiceId"]);    
    else   
      this.selectedInvoiceCollection = this.arrayRemove(this.selectedInvoiceCollection, row["UniqueInvoiceId"]);   
    event ? this.selection.toggle(row) : null;
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(e:any) {
    this.isAllSelected() ?  this.selection.clear() : this.dataSource.data.forEach((row:any) => this.selection.select(row));
    if(e.checked === true)
    {
      var tempDataSource = this.ExportInvoiceListData;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedInvoiceCollection.push(tempDataSource[i].UniqueInvoiceId.toString());
      // this.outputToParent.emit(this.parentObject);
    }
    else
    {
      var tempDataSource = this.ExportInvoiceListData;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedInvoiceCollection = this.arrayRemove(this.selectedInvoiceCollection, tempDataSource[i].UniqueInvoiceId);
    }
  }
  
  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    return '';
  }

  InvoiceStripChange(type:string){
    this.searchactive = false;
    this.submitted = false;
    // this.dataSource = new MatTableDataSource();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;           
    this.selection.clear() ;
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.ExportHistory.controls;
  }

  ExportBookingReport (rowData:any) {
    var invnum = rowData.InvoiceNumber;
    if (invnum.length == 0) {
      swal.fire({ text: "Please enter Invoice number", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return false;
    }  
    else {     
       var reportName = 'BookingInvoices';
       var QueryString = '?reportID=' + reportName + '&invoiceNumber=' + invnum;
      //  localStorage.setItem("QueryString",QueryString);
      //  window.location.href = window.location.pathname + '/Reports' + QueryString;
      //  window.open(window.location.origin + '/Reports' , '_blank'); 
       window.open(window.location.origin + '/ReportForm.aspx' + QueryString , '_blank'); 
      //  setTimeout(function () {
      //      tabWindowId.location.href = window.location.pathname + 'Import/Reports' + QueryString;
      //  }, 1001);
      return true;
    };         
  }

    InvExportPrintPreview(){
      localStorage.PrinteExpInvoiceFilters = [];
      if (this.selectedInvoiceCollection.length == 0 ) {    
        swal.fire({ text: "Please select invoices that you want to Print", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
        return; 
      }
      if (this.selectedInvoiceCollection.length > 0)
      { localStorage.PrinteExpInvoiceFilters = this.selectedInvoiceCollection; }
      //$window.open(val + 'exportinvoicepreview', '_blank');

      window.open(window.location.origin + '/ExportInvoicePreview', '_blank'); 
    };

    SearchExportInvoiceInfo () {

      //check if input is valid
      if (this.validateExpFormInput() != true) return;       
    
      if (this.ExportFilters.BookingNum === '')
      { this.ExportFilters.BookingNum = 'ALL' }
      else   {
          if (this.ExportFilters.BookingNum.toUpperCase() !== '' || this.ExportFilters.BookingNum.toUpperCase() !== 'ALL')
          {
              if (this.ExportFilters.ToDate === null && this.ExportFilters.FromDate === null)
              {
                  this.ExportFilters.ToDate = this.today;
                  this.ExportFilters.FromDate = new Date('01/1/2016');
              }
          }
      }

      if (this.ExportFilters.InvoiceNum === '')
      { this.ExportFilters.InvoiceNum = 'ALL' }
      else  {
          if (this.ExportFilters.InvoiceNum.toUpperCase() !== '' || this.ExportFilters.InvoiceNum.toUpperCase() !== 'ALL')
          {
              if (this.ExportFilters.ToDate ===null && this.ExportFilters.FromDate === null)
              {   
                  this.ExportFilters.ToDate = this.today;
                  this.ExportFilters.FromDate = new Date('01/1/2003');
              }
          }
      }

      if (this.ExportFilters.ToDate !== null && this.ExportFilters.FromDate !== null && (this.ExportFilters.InvoiceNum.toUpperCase() === '' || this.ExportFilters.InvoiceNum.toUpperCase() === 'ALL') &&(this.ExportFilters.BookingNum.toUpperCase() === '' || this.ExportFilters.BookingNum.toUpperCase() === 'ALL'))  {
          var fromDate = moment(this.ExportFilters.FromDate, 'M/D/YYYY');
          var toDate = moment(this.ExportFilters.ToDate, 'M/D/YYYY');
          var diffDays = toDate.diff(fromDate, 'days');

          if (diffDays >60) {
            swal.fire({ text: "Please limit your search dates to a span of 60 days or less", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
            return;
          }
      }
      this.ShowLoader = true;
      this.exportchargesService.GetAllExportInvoicesForUserIdByDates(this.ExportFilters).subscribe((result) => {
          this.ShowLoader = false;
          this.Messages = result.generalMessages;
          this.searchactive = true;
          if(result.InvoiceData != undefined){           
            this.ExportInvoiceListData = result.InvoiceData;           
            this.dataSource = new MatTableDataSource(result.InvoiceData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;           
            this.displayInvCollection = result.InvoiceData;    
          }
          else{
            this.dataSource = new MatTableDataSource();       
            this.displayInvCollection = [];
          }                
      });
  };

}
