import { Injectable } from '@angular/core';
import { Observable, throwError,BehaviorSubject ,Subject} from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiurls,httpOptions } from 'src/app/common/utils/globalconstants';

import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class ImportchargesService { 

  constructor(private httpClient: HttpClient) { }

  GetAllCountriesforPayment():Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetAllCountriesforPayment).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetAncillaryInvoiceDataForPrint(InvoiceFilterList:any):Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetAncillaryInvoiceDataForPrint + "/"+ InvoiceFilterList).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }   
  
  GetCyberSourceShoppingCartData(TransactionReferenceId:any):Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetCyberSourceShoppingCartData + "/"+ TransactionReferenceId).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  } 
  
  GetShoppingCartData():Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetShoppingCartData).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }   

  GetReferenceDataForPaymentConfirmation():Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetReferenceDataForPaymentConfirmation).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetCoutryStates(requestdata:string):Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetCoutryStates + "/"+ requestdata).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  PostImportChargesPaymentProcess(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.PostImportChargesPaymentProcess,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        
        // console.log(data)
        return data;
        
      }),
      catchError(this.handleError)
    );
  }

  ProcessExportCyberSourceTransaction(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.ProcessExportCyberSourceTransaction,  json_requestdata, httpOptions).pipe(    
      tap(data => {              
        return data;        
      }),
      catchError(this.handleError)
    );
  }  

  ProcessServiceOrderCyberSourceTransaction(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.ProcessServiceOrderCyberSourceTransaction,  json_requestdata, httpOptions).pipe(    
      tap(data => {              
        return data;        
      }),
      catchError(this.handleError)
    );
  }  

  ProcessImportCyberSourceTransaction(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.ProcessImportCyberSourceTransaction,  json_requestdata, httpOptions).pipe(    
      tap(data => {              
        return data;        
      }),
      catchError(this.handleError)
    );
  }
  
  PostExportChargesPaymentProcess(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.PostExportChargesPaymentProcess,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        
        console.log(data)
        return data;
        
      }),
      catchError(this.handleError)
    );
  }
  
  PostServiceOrderPaymentProcess(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.PostServiceOrderPaymentProcess,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        
        console.log(data)
        return data;
        
      }),
      catchError(this.handleError)
    );
  }  

  GetDemurageChargeDataForPrint(InvoiceNumbers:string,ClientID:string,TerminalID:string):Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetDemurageChargeDataForPrint + "/"+ InvoiceNumbers+ "/"+ ClientID+ "/"+ TerminalID).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  CheckStatementExists(StatementNumbers: string, StatementType: boolean): Observable<any> {
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.CheckStatementExists + "/" + StatementNumbers + "/" + StatementType).pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  GetAllInvoicesForUserIdByDates(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetAllInvoicesForUserIdByDates,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
        
      }),
      catchError(this.handleError)
    );
  }

  GetAncillaryInvoicesForStatements(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetAncillaryInvoicesForStatements,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
        
      }),
      catchError(this.handleError)
    );
  }

  GetDemurageInvoicesForStatements(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetDemurageInvoicesForStatements,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetShoppingCartDetailsForPayment(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetShoppingCartDetailsForPayment,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  GetCyberSourceWebMobileParameters(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetCyberSourceWebMobileParameters,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  CreateStatementInvoices(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);    
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.CreateStatementInvoices,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  } 

  GetStatementInvoicesByNumberAncillary(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);    
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetStatementInvoicesByNumberAncillary,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  } 

  GetImportChargesByContainerNumber(requestdata:any):Observable<any>{    
    let apiurl=environment.TOPSWebApi + apiurls.GetImportChargesByContainerNumber;
    let json_TerminalInfo = JSON.stringify(requestdata);
      return this.httpClient.post<any>(apiurl ,json_TerminalInfo, httpOptions );
  }

  GetStatementInvoicesByNumberDemurage(requestdata:any):Observable<any>{
    let json_requestdata = JSON.stringify(requestdata);    
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetStatementInvoicesByNumberDemurage,  json_requestdata, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  } 

  private handleError(err: HttpErrorResponse) {
    return throwError(() => new Error('Error Occured')); //throwError('Error Occured');
 }

}
