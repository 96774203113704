<div class="container-fluid">
<div class="row" style="padding-bottom:10px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center"><h3><strong>Terminal Exam Rates</strong></h3></div>
    <div class="col-md-4"></div>
</div>
<div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
<div class="row">
    <br />
    <div class="col-md-3 col-sm-2"></div>
    <div class="col-md-6 col-sm-8">
        <div class="row divTop-border">
            <div class="row">
                <div class="col-md-3 col-sm-1"></div><div class="col-md-6 col-sm-11 text-center"><h4><strong>Elizabeth, NJ Rates</strong></h4><br /></div>
                <div class="col-md-3"></div>
            </div>
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-7 col-sm-8">
                <address>
                    <!-- @*AQI Fee<br />*@ -->
                    On Terminal Exam (Dry)<br />
                    On Terminal Exam (Reefers)<br />
                    Out of Gauge Handling Fee<br /> <br /><br />
                </address>
            </div>
            <div class="col-md-3 col-sm-2">
              <address>
                <!-- @*$301.88<br />*@ -->
                $689.75<br />
                $785.65<br />
                $648.81<br />
              </address>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
    </div>
    <div class="col-md-3 col-sm-2"></div>
</div>
<div class="row">
    <br />
    <div class="col-md-3 col-sm-2"></div>
    <div class="col-md-6 col-sm-8">
        <div class="row divTop-border">
            <div class="row">
                <div class="col-md-3 col-sm-1"></div><div class="col-md-6 col-sm-11 text-center"><h4><strong>Miami, FL Rates</strong></h4><br /></div>
                <div class="col-md-3"></div>
            </div>
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-7 col-sm-8">
                <address>
                    Landing<br />
                    Vacis Shipside Exam<br />
                    Vacis Staged Exam<br />
                    USDA Exam<br />
                    US Coast Guard Exam<br />
                    LQV (Loaded Quantity Verification) Exam<br />
                    Dray Charges<br />
                    OOG<br /><br /><br />
                </address>
            </div>
            <div class="col-md-3 col-sm-2">
              <address>
                $85.00<br />
                $149.00<br />
                $268.00<br />
                $213.00<br />
                $213.00<br />
                $213.00<br />
                $280.00<br />
                $735.00<br />
              </address>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
    </div>
    <div class="col-md-3 col-sm-2"></div>
</div>
<div class="row">
    <br />
    <div class="col-md-3 col-sm-2"></div>
    <div class="col-md-6 col-sm-8">
        <div class="row divTop-border">
            <div class="row">
                <div class="col-md-3 col-sm-1"></div><div class="col-md-6 col-sm-11 text-center"><h4><strong>Mobile, AL Rates</strong></h4><br /></div>
                <div class="col-md-3"></div>
            </div>
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-7 col-sm-8">
                <address>
                    AQI<br />
                    Out of Gauge Handling Fee<br />
                    Vacis Shipside Exam<br />
                    Vacis Staged Exam<br />
                    <br /><br />
                </address>
            </div>
            <div class="col-md-3 col-sm-2">
              <address>
                $212.45<br />
                $525.81<br />
                $116.81<br />
                $228.27<br />
              </address>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
    </div>
    <div class="col-md-3 col-sm-2"></div>
</div>
<div class="row">
    <br />
    <div class="col-md-3 col-sm-2"></div>
    <div class="col-md-6 col-sm-8">
        <div class="row divTop-border">
            <div class="row">
                <div class="col-md-3 col-sm-1"></div><div class="col-md-6 col-sm-11 text-center"><h4><strong>Los Angeles, CA Rates</strong></h4><br /></div>
                <div class="col-md-3"></div>
            </div>
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-7 col-sm-8">
                <address>
                    Shipside Exam<br />
                    IBET Exam  	<br />
                    X-Ray Exam  <br />
                    CTG Exam  	<br />
                    <br /><br />
                </address>
            </div>
            <div class="col-md-3 col-sm-2">
              <address>
                $560.22<br />
                $560.22<br />
                $560.22<br />
                $560.22<br />
              </address>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
    </div>
    <div class="col-md-3 col-sm-2"></div>
</div>
</div>
