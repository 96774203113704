<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid">
    <div class="row col-md-12 col-sm-12 col-xs-12 divTop-border-empty1 noPrint"></div>
    <div class="row col-md-12 col-sm-12 col-xs-12 grid-padding" *ngIf="Errors.length > 0 || Messages.length > 0">
        <div class="col-md-12 col-sm-12 col-xs-12 alert alert-danger">
            <ul *ngFor="let Msg of Errors">
                <li><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}</li>
            </ul>
        </div>
    </div>
    <br />
    <br />   
    <div class="row col-md-12 col-sm-12 col-xs-12 ">
        <div class="text-right noPrint">
            <button class="btn btn-primary btn-sm btn-site text-right" ng-print print-element-id="printSection" id="printButton" (click) ="print();"><span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print</button>
        </div>
    </div>
    <div id="Printable">
        <div *ngFor="let row of PrintInvoice"  style="page-break-after:always"> 
            <div class="row" id="printSection">
                <div class="col-md-12 col-sm-12">
                    <div class="col-md-1 col-sm-1"></div>
                    <div class="col-md-10 col-sm-10" >
                        <div class="text-center img-responsive" *ngIf="row.TerminalLogo != null">
                            <h1>
                                <div style="margin-top: -41px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                                <!-- <img ng-src="data:image/jpg;base64,{{row.TerminalLogo}}" /> -->
                            </h1>
                        </div>
                        <div class="text-center text-primary" id="TerminalLoc">
                            <h2>
                                {{row.TerminalLoc}}
                            </h2>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Invoice Number :</label></div>
                            <div class="col-md-9 col-sm-9 text-left text-danger disp-Inline"><label>{{row.InvoiceNumber}}</label></div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Invoice Date :</label></div>
                            <div class="col-md-9 col-sm-3 text-left disp-Inline">
                                <label >{{row.InvoiceDate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>PO Number :</label></div>
                            <div class="col-md-9 col-sm-9 text-left disp-Inline">
                                <label >{{row.PoNumber}}</label>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-left:0px">
                                <div class="panel panel-default" style="min-height:260px">
                                    <div class="panel-heading">
                                        <h4>Bill To: <label class="text-primary">{{row.Client}}</label></h4>
                                    </div>
                                    <div class="panel-body">
                                        <!-- <p> -->
                                            Address:<br />
                                            <address>
                                                {{row.ClientAddress}} <br />
                                                {{row.ClientCity}}
                                            </address>
                                            Attn:{{row.ClientContact}}
                                        <!-- </p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-right:0px">
                              <div class="panel panel-default" style="min-height:260px">
                                <div *ngIf="!row.HasOverNightAddress">
                                  <div class="panel-heading">
                                    <h4>Remit To : <label class="text-primary">{{row.TerminalLoc}}</label></h4>
                                  </div>
                                  <div class="panel-body">
                                    <!-- <p> -->
                                    Address:
                                    <br />
                                    <address>
                                      {{row.TerminalAttn}} <br />
                                      {{row.TerminalAddr}} <br />
                                      {{row.TerminalCity}} <br />
                                    </address>
                                    <div *ngIf="row.SapNumber != ''">SAP Reference:{{row.SapNumber}}</div>
                                    <!-- </p> -->
                                  </div>
                                </div>
                                <div *ngIf="row.HasOverNightAddress">
                                  <div class="panel-heading">
                                    <h4>Remit To : </h4>
                                  </div>
                                  <div class="panel-body-overnightaddress">
                                    <label class="text-primary">
                                      USPS Mailing Address
                                    </label>
                                    <address>
                                      {{row.TerminalLoc}} <br />
                                      {{row.TerminalAddr}} <br />
                                      {{row.TerminalCity}} <br />
                                    </address>
                                    <label class="text-primary">Overnight Mailing Address</label>
                                    <address>
                                      {{row.TerminalOverNightAddr1}} <br />
                                      {{row.TerminalOverNightAddr2}} <br />
                                      {{row.TerminalOverNightAddr3}} <br />
                                      {{row.TerminalOverNightCity}} <br />
                                    </address>
                                    <div *ngIf="row.SapNumber != ''">SAP Reference:{{row.SapNumber}}</div>
                                    <!-- </p> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 disp-Inline">
                                <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                    <label>Container:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.ContainerNumber}}</label>
                                </div>
                            </div>
                            <div class="col-md-8 col-sm-8 disp-Inline">
                                <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                    <label>Vessel:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.VesselName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 disp-Inline">
                                <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                    <label>Location:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.TerminalName}}</label>
                                </div>
                            </div>
                            <div class="col-md-8 col-sm-8 disp-Inline">
                                <div class="col-md-5 col-ms-4 text-right disp-Inline">
                                    <label>Line:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.ShippingLineName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 disp-Inline">
                                <div class="col-md-5 col-sm-4 text-right disp-Inline">
                                    <label>BOL:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.BillLadingNumber}}</label>
                                </div>
                            </div>
                            <div class="col-md-8 col-sm-8 disp-Inline">
                                <div class="col-md-5 col-ms-4 text-right disp-Inline">
                                    <label>Voyage:</label>
                                </div>
                                <div class="col-md-7 col-sm-8 text-left disp-Inline">
                                    <label >{{row.VoyageNumber}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row table-responsive">
                            <table class="table table-bordered table-striped" data-st-table="InvoiceItemsCollection" data-st-safe-src="row.InvoiceItems">
                                <thead>
                                    <tr>
                                        <th data-st-ratio="50">Hold Name</th>
                                        <th data-st-ratio="10">Hold Date</th>
                                        <th data-st-ratio="20">Released Date</th>
                                        <th data-st-ratio="20">Hold Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of row.InvoiceItems">
                                        <td data-st-ratio="50">{{row.tops_holdname}}</td>
                                        <td data-st-ration="10">{{row.hold_date  | date:'MM/dd/yyyy'}}</td>
                                        <td data-st-ration="20">{{row.released_date | date:'MM/dd/yyyy'}}</td>
                                        <td data-st-ratio="20">{{row.hold_amount | currency}}</td>
                                    </tr>
                                    <tr *ngIf="!row.InvoiceItems.length">
                                        <td colspan="4">
                                            No results Found.
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="4" class="text-right" style="padding-right:63px">Total Invoice : {{row.TotalAmt | currency}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right" style="padding-right:63px">Amount Paid : {{row.PaymentAmt | currency}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right" style="padding-right:63px">Amount Due : {{row.PaymentRemain | currency}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <br />
                        </div>
                        <div class="row">
                            <span *ngIf="row.PaymentRemain > 0"><strong>Balance Due - PAYABLE UPON RECEIPT</strong></span><br />
                            <p>For bill inquiries: <label >{{row.TerminalPhone}}</label></p><br />
                            <p *ngIf="row.PaymentRemain < 0"><label>Please attach invoice with your payment and indicate invoice number and container number on your check.</label></p><br />
                        </div>
                    </div>

                    <div class="col-md-1 col-sm-1"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="PrintAncillary===false">
        <div *ngFor="let row of PrintInvoice" >    
            
                <div class="row" id="printSection">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="col-md-1 col-sm-1 col-xs-1"></div>
                        <div class="col-md-10 col-sm-10 col-xs-10" *ngIf="InvoiceNumbers != ''">
                            <div class="text-center img-responsive" *ngIf="row.TerminalLogo != null">
                                <h1>
                                    <div style="margin-top: -41px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive.png"/></div>
                                   
                                </h1>
                            </div>
                            <div class="text-center text-primary" id="TerminalLoc">
                                <h2>
                                    {{row.TerminalLoc}}
                                </h2>
                            </div>

                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-3 text-right disp-Inline"><label>Invoice Number :</label></div>
                                <div class="col-md-9 col-sm-9 col-xs-9 text-left text-danger disp-Inline"><label >{{row.InvoiceNumber}}</label></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-3 text-right disp-Inline"><label>Invoice Date :</label></div>
                                <div class="col-md-9 col-sm-3 col-xs-3 text-left disp-Inline">
                                    <label >{{row.InvoiceDate | date:'MM-dd-yyyy'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-3 text-right disp-Inline"><label>Your reference number :</label></div>
                                <div class="col-md-9 col-sm-9 col-xs-9 text-left disp-Inline">
                                    <label >{{row.ReferenceNumber}}</label>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-left:0px">
                                    <div class="panel panel-default" style="min-height:260px">
                                        <div class="panel-heading">
                                            <h4>Bill To: <label class="text-primary">{{row.Client}}</label></h4>
                                        </div>
                                        <div class="panel-body">
                                           
                                                Address:<br />
                                                <address>
                                                    {{row.ClientAddress}} <br />
                                                    {{row.ClientCity}}<br />
                                                    {{row.ClientCountry}}
                                                </address>
                                                Attn:{{row.ClientContact}}
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-right:0px">
                                    <div class="panel panel-default" style="min-height:260px">
                                        <div class="panel-heading">
                                            <h4>Remit To : <label class="text-primary">{{row.TerminalLoc}}</label></h4>
                                        </div>
                                        <div class="panel-body">
                                          
                                                Address:
                                                <br />
                                                <address>
                                                    {{row.TerminalAttn}} <br />
                                                    {{row.TerminalAddr}} <br />
                                                    {{row.TerminalCity}} <br />
                                                </address>
                                                <div *ngIf="row.SapNumber != ''">SAP Reference:{{row.SapNumber}}</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <br />
                            <br />
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12  text-left disp-Inline" style="padding-left:0px">
                                    <div class="panel panel-default col-xs-12 " style="min-height:260px">
                                        <div class="panel-heading col-xs-12 ">
                                            <h4>Location: <label class="text-primary" style="max-height:30px">{{row.TerminalName}}</label></h4>
                                        </div>
                                        <div class="panel-body mypanel">
                                            <div class="row table-responsive">
                                                <table class="table table-bordered table-striped" data-st-table="InvoiceItemsCollection" data-st-safe-src="row.DemurageInvoice">
                                                    <thead>
                                                        <tr>
                                                            <th data-st-ratio="10">Container</th>
                                                            <th data-st-ratio="10">Bill of Lading</th>
                                                            <th data-st-ratio="15">Vessel</th>
                                                            <th data-st-ratio="15">Voyage</th>
                                                            <th data-st-ratio="10">S/S Line</th>
                                                            <th data-st-ratio="5">F/A date</th>
                                                            <th data-st-ratio="5">LFD Port</th>
                                                            <th data-st-ratio="5">LFD Line</th>
                                                            <th data-st-ratio="5">Pickup date</th>
                                                            <th data-st-ratio="10">Holidays</th>
                                                            <th data-st-ratio="5">Size</th>
                                                            <th data-st-ratio="5">Type</th>
                                                            <th data-st-ratio="20">Demurage</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let demrow of row.DemurageInvoice">
                                                            <td data-st-ratio="10">{{demrow.ContainerNumber}}</td>
                                                            <td data-st-ration="10">{{demrow.BillLadingNumber}}</td>
                                                            <td data-st-ration="15">{{demrow.VesselName}}</td>
                                                            <td data-st-ratio="15">{{demrow.VoyageNumber}}</td>
                                                            <td data-st-ratio="10">{{demrow.ShippingLineName}}</td>
                                                            <td data-st-ratio="5">{{demrow.DateTimeOfFirstAvailability | date:'MM/dd/yyyy'}}</td>
                                                            <td data-st-ratio="5">{{demrow.PortLastFreeDate | date:'MM/dd/yyyy'}}</td>
                                                            <td data-st-ratio="5">{{demrow.LineLastFreeDate | date:'MM/dd/yyyy'}}</td>
                                                            <td data-st-ratio="5">{{demrow.PickupDate| date:'MM/dd/yyyy'}}</td>
                                                            <td class="trim-info" tooltip="{{demrow.Holidays}}">{{demrow.Holidays}}</td>
                                                            <td data-st-ratio="5">{{demrow.ContainerTypeAttribute1}}</td>
                                                            <td data-st-ratio="5">{{demrow.ContainerTypeAttribute2}}</td>
                                                            <td data-st-ratio="20">
                                                                <div class="row table-responsive">
                                                                    <table class="table" data-st-table="InvoiceTariffCollection" data-st-safe-src="demrow.InvoiceTariff">
                                                                        <thead>
                                                                            <tr>
                                                                                <th data-st-ratio="10"> Day Range</th>
                                                                                <th data-st-ratio="5">$/Day</th>
                                                                                <th data-st-ratio="5"># of days</th>
                                                                                <th data-st-ratio="20">Charge</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let inv of row.DemurageInvoice">
                                                                                <td data-st-ratio="10">{{inv.DateRange}}</td>
                                                                                <td data-st-ratio="5">{{inv.AmountPerDay}}</td>
                                                                                <td data-st-ratio="5">{{inv.NumberofDays}}</td>
                                                                                <td data-st-ratio="20">{{inv.TotalCharge}}</td>
                                                                            </tr>

                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td colspan="16" class="text-left" *ngIf="demrow.AdjustmentsNotes.length>0">Notes : {{demrow.AdjustmentsNotes }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="16" class="text-right" *ngIf="demrow.PortAdjApplied">Terminal Adj: {{demrow.TerminalAdjustment}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="16" class="text-right" *ngIf="demrow.LineAdjApplied">Line Adj: {{demrow.LineAdjustment}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="16" class="text-right" style="padding-right:63px">Sub Total : {{demrow.SubTotal}}</td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                    <br />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="16" class="text-right" style="padding-right:63px" *ngIf="row.NetAncillaryCharges.length>0">Ancillary Charge : {{row.NetAncillaryCharges}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="16" class="text-right" style="padding-right:63px">Total Invoice :{{row.CurrencyCode}}{{row.TotalAmt}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="16" class="text-right" style="padding-right:63px">Amount Paid :{{row.CurrencyCode}}{{row.PaymentAmt}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="16" class="text-right" style="padding-right:63px">Amount Due :{{row.CurrencyCode}}{{row.PaymentRemain }}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6 col-sm-6 col-md-push-3 text-center">
                            <p *ngIf="row.PaymentRemain>0"><strong><br />Balance Due - PAYABLE UPON RECEIPT</strong></p><br />
                            <p>For bill inquiries: <label >{{row.BillEnquires}}</label></p><br />
                            <span *ngIf="row.PaymentRemain>0"><label>Please attach invoice with your payment and indicate invoice number and container number on your check.</label></span><br />
                        </div>    
                    </div>
                    <div class="col-md-1 col-sm-1"></div>
                </div>       
        </div>
    </div> -->
    <br /><br />
</div>
