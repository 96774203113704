import { HttpHeaders } from '@angular/common/http';
export const NA: string = "N/A"
export const apiurls =
{

ValidateOnlineUserLogin:'/Account/ValidateOnlineUserLogin',
SendTempPassword:'/Account/SendTempPassword',
GetOnlineUserByIDAndPasswordHint:'/Account/GetOnlineUserByIDAndPasswordHint',
GetSecretQuestions:'/Account/GetSecretQuestions',
GetStaticContent:'/Account/GetStaticContent',
GetUserNameByPasswordHint: '/Account/GetUserNameByPasswordHint',
GetAllUserInformation:'/Account/GetAllUserInformation',
GetNewUserInformation:'/Account/GetNewUserInformation',
GetAllClientAndUserInformation:'/Account/GetAllClientAndUserInformation',
GetClientByTaxIDNumber:'/Account/GetClientByTaxIDNumber',
GetStatesByCountry:'/Account/GetStatesByCountry',
GetAllTerminals:'/Account/GetAllTerminals',
GetCompanyRegistration:'/Account/GetCompanyRegistration',
GetAncillaryChargeDataForPrint:'/Account/FleetRegistryUpload',
GetUserNameByEmail:'/Account/GetUserNameByEmail',
GetOnlineUserChangePassword:'/Account/GetOnlineUserChangePassword',
GetDemurageChargeDataForPrint:'/Account/GetDemurageChargeDataForPrint',
GetClientType:'/Account/GetClientType',
GetTerminalChargeTerminals:'/Account/GetTerminalChargeTerminals',
GetAllCountriesforPayment:'/Account/GetAllCountriesforPayment',
PostRegistration:'/Account/PostRegistration',
PostOnlineUserContactInfoUpdate:'/Account/PostOnlineUserContactInfoUpdate',
PostNewOnlineUserUpdate:'/Account/PostNewOnlineUserUpdate',
GetAncillaryInvoiceDataForPrint:'/Account/GetAncillaryInvoiceDataForPrint',
UpdateClientType:'/Account/UpdateClientType',
PostOnlineUserPasswordUpdate:'/Account/PostOnlineUserPasswordUpdate',
CheckStatementExists:'/Account/CheckStatementExists',
GetBookingChargeDataForPrint:'/Account/GetBookingChargeDataForPrint',
GetBookingInvoiceDataForPrint:'/Account/GetBookingInvoiceDataForPrint',
GetCoutryStates:'/Account/GetCoutryStates',
GetServiceOrderDataForPrint:'/Account/GetServiceOrderDataForPrint',
GetServiceOrderInvoiceForPrint:'/Account/GetServiceOrderInvoiceForPrint',

GetImportChargesByContainerNumber:'/Import/GetImportChargesByContainerNumber',
GetShoppingCartDetailsForPayment:'/Import/GetShoppingCartDetailsForPayment',
GetReferenceDataForPaymentConfirmation:'/Import/GetReferenceDataForPaymentConfirmation',
GetCyberSourceWebMobileParameters:'/Import/GetCyberSourceWebMobileParameters',
GetShoppingCartData:'/Import/GetShoppingCartData',
GetCyberSourceShoppingCartData:'/Import/GetCyberSourceShoppingCartData',
PostImportChargesPaymentProcess:'/Import/PostImportChargesPaymentProcess',
ProcessImportCyberSourceTransaction:'/Import/ProcessImportCyberSourceTransaction',
GetAllInvoicesForUserIdByDates:'/Import/GetAllInvoicesForUserIdByDates',
GetAncillaryInvoicesForStatements:'/Import/GetAncillaryInvoicesForStatements',
GetDemurageInvoicesForStatements:'/Import/GetDemurageInvoicesForStatements',
CreateStatementInvoices:'/Import/CreateStatementInvoices',
GetStatementInvoicesByNumberAncillary:'/Import/GetStatementInvoicesByNumberAncillary',
GetStatementInvoicesByNumberDemurage:'/Import/GetStatementInvoicesByNumberDemurage',

GetExportChargesSearchRefData:'/Export/GetExportChargesSearchRefData',
GetBookingDetails:'/Export/GetBookingDetails',
GetShoppingCartDetailsForExportBookingHolds:'/Export/GetShoppingCartDetailsForExportBookingHolds',
PostExportChargesPaymentProcess:'/Export/PostExportChargesPaymentProcess',
ProcessExportCyberSourceTransaction:'/Export/ProcessExportCyberSourceTransaction',
GetAllExportInvoicesForUserIdByDates:'/Export/GetAllExportInvoicesForUserIdByDates',

GetVesselSchedule:'/TrackAndTrace/GetVesselSchedule',
GetBookingInformation:'/TrackAndTrace/GetBookingInformation',
GetContainerHistory:'/TrackAndTrace/GetContainerHistory',
GetContainerAvailabiltyByBLByTerm:'/TrackAndTrace/GetContainerAvailabiltyByBLByTerm',
GetContainerAvailabiltyByTerm:'/TrackAndTrace/GetContainerAvailabiltyByTerm',
GetVesselScheduleDetails:'/TrackAndTrace/GetVesselScheduleDetails',
GetGateTransaction:'/TrackAndTrace/GetGateTransaction',
GetEIRReprintDetail:'/TrackAndTrace/GetEIRReprintDetail',
GetSearchOptionsbyTerminalId : "/TrackAndTrace/GetSearchOptionsbyTerminalId",

ServiceOrder:'/ServiceOrder/GetServiceOrders',
ExtraGateRequest:'/ServiceOrder/ExtraGateRequest',
GetAllServiceOrderInvoicesByDateRangeServiceOrderAndUserID_View:'/ServiceOrder/GetAllServiceOrderInvoicesByDateRangeServiceOrderAndUserID_View',
GetContactInfo:'/ServiceOrder/GetContactInfo',
GetShoppingCartDetailsForServiceOrder:'/ServiceOrder/GetShoppingCartDetailsForServiceOrder',
PostServiceOrderPaymentProcess:'/ServiceOrder/PostServiceOrderPaymentProcess',
GetServiceOrderInvoicePrint:'/ServiceOrder/GetServiceOrderInvoicePrint',
ProcessServiceOrderCyberSourceTransaction:'/ServiceOrder/ProcessServiceOrderCyberSourceTransaction',

GetNotificationList:'/Notification/GetNotificationList',
AddToContainerWatchList:'/Notification/AddToContainerWatchList',
DeleteContainerWatchList:'/Notification/DeleteContainerWatchList',
GetEventsForNotification:'/Notification/GetEventsForNotification',
UpdateContainerWatchList:'/Notification/UpdateContainerWatchList'

};
export const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
export const dateFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric'
};