import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DemurageStatementInvoicesPrint } from 'src/app/core/models/DemurageStatementInvoicesPrint';
import { AncillaryStatementInvoice, DemurageStatementInvoice, StatementFilters } from 'src/app/core/models/StatementFilters';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-statement-invoice-list',
  templateUrl: './statement-invoice-list.component.html',
  styleUrls: ['./statement-invoice-list.component.css']
})
export class StatementInvoiceListComponent implements OnInit {
  Messages: ResponseMessage[] = [];
  StatementInvoice: DemurageStatementInvoicesPrint;
  StatementFilters: StatementFilters;
  ShowLoader: boolean = false;
  // DemurageTotalBilling: string;
  // displayClientsCollectionA = [];
  // displayClientsCollectionD = [];
  // dataSourceAncillary: any;
  // dataSourceDemurage: any;  
  // displayedColumnsA: string[] = ['InvoiceNumber', 'ContainerNumber', 'BillLadingNumber', 'PoNumber', 'ClientName', 'vesselvoyage', 'tops_holdname', 'InvoiceDate', 'BillingAmount', 'Disputed'];
  // displayedColumnsD: string[] = ['InvoiceNumber', 'ContainerNumber', 'BillLadingNumber', 'ReferenceNumber', 'VesselCode', 'VoyageNumber', 'SzTp', 'DateTimeOfFirstAvailability', 'PortLastFreeDate', 'LineLastFreeDate', 'Paid_Thru_DT', 'PickupDate', 'Day1', 'myTotalCharge1', 'Day2', 'myTotalCharge2', 'Day3', 'myTotalCharge3', 'Day4', 'myTotalCharge4', 'BillingAmount'];
  // TerminalId: string;  
  constructor(private importchargesService: ImportchargesService,
              private AuthService: AuthService, ) {
    this.StatementInvoice=new DemurageStatementInvoicesPrint();  
    
  }

  ngOnInit() {

    this.StatementFilters=new StatementFilters();
    this.StatementFilters.TerminalID= localStorage.StatementTerminalID;
    this.StatementFilters.InvoiceAncil= localStorage.StatementInvoiceAncil === "true" ? true :false;    
    this.StatementFilters.StatNum = localStorage.StatementNum;
    this.StatementFilters.FromDate= new Date(moment(localStorage.StatementFromDate).format("M/D/YYYY"));
    this.StatementFilters.ToDate=new Date(moment(localStorage.StatementToDate).format("M/D/YYYY"));
    this.StatementFilters.User_ID  = Number(this.AuthService.User_Id());

    if(this.IsFieldNotBlank(this.StatementFilters)){
      this.ShowLoader = true;
      if (this.StatementFilters.InvoiceAncil === true) {
         this.importchargesService.GetStatementInvoicesByNumberAncillary(this.StatementFilters).subscribe( (result) => {
          this.ShowLoader = false;
          this.StatementInvoice = result;
          this.Messages = result.generalMessages;
          this.StatementInvoice.StatementInvoiceList = result.AncillaryStatementPrint;
          // this.DemurageTotalBilling = result.AncillaryTotalBilling;     
          // if(result.AncillaryStatementPrint != null)   {
          // this.dataSourceAncillary = result.AncillaryStatementPrint;
          // this.displayClientsCollectionA = result.AncillaryStatementPrint;      
          // }    
        });
    }
    else {

      this.importchargesService.GetStatementInvoicesByNumberDemurage(this.StatementFilters).subscribe((result) => {
          this.ShowLoader = false;
          this.StatementInvoice=result;         
          this.Messages = result.generalMessages;
          this.StatementInvoice.StatementInvoiceList = result.DemurageStatementPrint;
          // this.DemurageTotalBilling = result.DemurageTotalBilling;
          // if(result.DemurageStatementPrint != null)   {
          // this.dataSourceDemurage = result.DemurageStatementPrint;
          // this.displayClientsCollectionD = result.DemurageStatementPrint;    
          // }     
        });
    }
  } 
}

IsFieldNotBlank (val:any) {
  if (val === undefined || val === '' || val === null) { return false; } else { return true; }
}

}
