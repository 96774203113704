export class ContainerHoldCharges {
     HoldCharges: HoldCharges[];//Ancillary Hold Charges
     DemurrageHoldCharges: DemurrageHoldCharges[]; //Demurrage Hold Charges
     LienDemurrageHoldCharges: LienDemurrageHoldCharges[] //Lien Demurrage Hold Charges 
     LienDemurrageCharges: LienDemurrageCharges[];
     Clients: Clients[];
     FailedContainersMessage: string;
     TerminalLocations: any;
     ShowPay: boolean = false;
     User_Id: number;
     TerminalUsed: string;
     IsAncillaryPayment: boolean;
     IsLienPayment: boolean;
     Client_Id: string;
     Client_Name: string;
     Terminal: number;
     Date: Date;
    generalMessages:any;
}

export class Clients {
    ClientId: number;
    ClientName: string;
    ClientTypeId: string;
}

export interface ILienChild {
    tops_holdname:string;
    hold_date:Date;
    released:string;
    released_date:Date;
    ClientName:string;
    hold_amount:number;
}


export class HoldCharges {
     hold_id: number
     container_number: string
     gkey: string
     hold_name: string
     hold_amount: number
     hold_date: Date
     released_date: Date
     released: boolean
     terminal_id: number
     date_created: Date
     status: boolean
     ClientId: number
     whoheld: string
     userId: number
     manual_fl: boolean
     ContactId: number
     tops_holdname: string
     TerminalName: string
     ClientName: string
     Selected: boolean = false
}

export class DemurrageHoldCharges {
     CTR_GKEY: string
     NBR: string
     BL_NBR: string
     LOCATION: string
     READYFORDELIVERY: string
     READYFORDELIVERYREASON: string
     FREIGHT: string
     CUSTOMS: string
     HOLDS: string
     PORT_PTD: string
     PORT_LFD: string
     LINE_LFD: string
     DISCHARGED: string
     LINE_ID: string
     OUT_LOC_ID: string
     OUT_VISIT_ID: string
     EQSZ_ID: number
     EQTP_ID: string
     EQHT_ID: number
     GROSS_WEIGHT: string
     CATEGORY: string
     STATUS: string
     L_OOG_INDICATOR: string
     TEMP_UNITS: string
     VENT_UNITS: string
     HAZ_CLASS: string
     POSITION: string
     PICKDUP: string
     GROSS_UNITS: string
     GROUP_ID: string
     UPDATER: string
     PREVIOUSLYGUARUNTEEDPTD: string
     ContainerId: number
     ContactId: number
     ClientId: number
     RowStatus: string
     DEMURRAGE: string
     trucker_scan_an: string
     cc_transaction_id: string
     terminalid: number
     shippinglineid: number
     OSRAMessage: string
     firstAvailableDt: string
     allowedFreeDays: string
  freeTimeStartDt: string
  freeTimeEndDt: string
     InvoiceDate: string
     InvoiceDueDate: string
  DatesDemurrageCharged: string
  detentionDemurrageRule: string
  OSRAmitigationcontact: string
  OSRAfacilitymitigationcontact: string
     Selected: boolean = false;
}

export class LienDemurrageCharges {
     ContainerId: number
     ContainerNumber: string
     ClientId: number
     Contact_Id: number
     VoyageId: number
     VoyageNumber: string
     VesselName: string
     TerminalId: number
     ShippingLineId: number
     ShippingLineName: string
     ContainerTypeISOCode: string
     DateTimeOfFirstAvailabitity: Date
     PortLastFreeDate: Date
     LineLastFreeDate: Date
     PickUpDate: Date
     BillLadingNumber: string
     IsOverride: boolean
     ContainerTypeAttribute1: string
     ContainerTypeAttribute2: string
     ContainerTypeAttribute3: string
     ContainerTypeAttribute4: string
     ContainerTypeAttribute5: string
     ContainerTypeAttribute6: string
     ContainerTypeAttribute7: string
     ContainerTypeAttribute8: string
     UserId: number
     First_Resp_DT: Date
     Paid_Thru_DT: Date
     Inspection_FL: boolean
     trucker_scac_an: string
     CC_transaction_id: string
     InvoiceNumber: string
     InvoiceId: number
  InvoiceDate: Date
  InvoiceDueDate: string
     gkey: string
  demurragetotal: number
  firstAvailableDt: string
  allowedFreeDays: string
  freeTimeStartDt: string
  freeTimeEndDt: string
  DatesDemurrageCharged: string
  detentionDemurrageRule: string
  OSRAmitigationcontact: string
  OSRAfacilitymitigationcontact: string
     Selected: boolean = false
}

export class LienDemurrageHoldCharges {
     LienDemurrageCharge: LienDemurrageCharges
     AncillaryHoldCharges: HoldCharges[]
     TotalLIEN: number
     Selected:boolean = false;
     preselected:boolean = false;
}
