import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import{ShoppingCart,PaymentConfirmation} from 'src/app/core/models/ShoppingCart';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-shopping-cart-payment',
  templateUrl: './shopping-cart-payment.component.html',
  styleUrls: ['./shopping-cart-payment.component.css']
})
export class ShoppingCartPaymentComponent implements OnInit {
  @Input() ShoppingCart:ShoppingCart;
  @Input() SCartPayment:PaymentConfirmation;
  @Input() shoppingCartPayment: FormGroup;
  ShowLoader = false;
  constructor(private importchargesService: ImportchargesService,
              private formBuilder: FormBuilder,
              ) { }

   
  get f(): { [key: string]: AbstractControl } {
    return this.shoppingCartPayment.controls;
  }

  ngOnInit(): void { 

  }

  // ModelChange(any:any){
  //   if(this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.ElectronicCheckCorpType || this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.ElectronicCheckPersonalType){
  //     this.shoppingCartPayment =  this.formBuilder.group(     
  //       {
  //         ddPaymentType: ['', Validators.required],       
    
  //         username: ['', Validators.required],
  //         password: ['', Validators.required],
    
  //         firstName: ['', [Validators.required,Validators.max(15)]],
  //         lastName: ['', [Validators.required,Validators.max(25)]],
  //         address1: ['', Validators.required ],
          
  //         cityLocation: ['', Validators.required ],
  //         zip: ['', Validators.required ],
  //         countries: ['', Validators.required ],
  //         states: ['', Validators.required ],
  //         cardType: ['', Validators.required ],
  //         cNumber: ['', Validators.required ],
  //         cardExpMonth: ['', Validators.required ],
  //         cardExpYear: ['', Validators.required ],
  //         cvv: ['', Validators.required ],
  //         phone: ['', Validators.required ],
  //         routingNumber: ['', Validators.required ],
  //         cAccountNum: ['', Validators.required ],
  //         fTaxId: ['', Validators.required ],
  //         dLicNum: ['', Validators.required ],
  //         lstate: ['', Validators.required ],
    
  //         address2: [],
  //         PO: [],
  //         checkbox:[],
  //       });
  //     }
  //     else if(this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.CreditCardType){
  //       this.shoppingCartPayment =  this.formBuilder.group(     
  //         {
  //           ddPaymentType: ['', Validators.required],       
      
  //           username: ['', Validators.required],
  //           password: ['', Validators.required],
      
  //           firstName: ['', [Validators.required,Validators.max(15)]],
  //           lastName: ['', [Validators.required,Validators.max(25)]],
  //           address1: ['', Validators.required ],
            
  //           cityLocation: ['', Validators.required ],
  //           zip: ['', Validators.required ],
  //           countries: ['', Validators.required ],
  //           states: ['', Validators.required ],
  //           cardType: ['', Validators.required ],
  //           cNumber: ['', Validators.required ],
  //           cardExpMonth: ['', Validators.required ],
  //           cardExpYear: ['', Validators.required ],
  //           cvv: ['', Validators.required ],
  //           phone: ['', Validators.required ],
  //           routingNumber: ['', Validators.required ],
  //           cAccountNum: ['', Validators.required ],
  //           fTaxId: ['', Validators.required ],
  //           dLicNum: ['', Validators.required ],
  //           lstate: ['', Validators.required ],
      
  //           address2: [],
  //           PO: [],
  //           checkbox:[],
  //         });
  //       }
  //       else if(this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.PayCargoType){
  //         this.shoppingCartPayment =  this.formBuilder.group(     
  //           {
  //             ddPaymentType: ['', Validators.required],       
        
  //             username: ['', Validators.required],
  //             password: ['', Validators.required]
  //           })
  //         }
  // }




}
