<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Import Charge Search</strong></h3></div>
        <div class="col-md-4"></div>
    </div>    
    <div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
    <div class="row">
        <br /><br />
        <div class="col-md-2"></div>
        <div class="col-md-10" style="margin-left:-200px;">
            <form name="ImportSearchForm" [formGroup]="ImportSearchForm" (ngSubmit)="onSubmit(ImportSearchForm.value)">
               <!-- ANCIPATED PICKUP DATE -->
               <div class="row">
                <div class="form-group col-md-10">
                    <div class="col-md-6 text-right minPadding-top"><label ><span style="color:red">*</span>Please Select Your Pick Up Date :</label></div>
                     <div class="col-md-6">       
                        <input matInput style="width: 130px;font-size: 14px;border: 1px solid #cccccc;padding-left: 6px;"  [matDatepicker]="picker" formControlName="Date" id="Date" class="form-control  col-md-8" [(ngModel)]="TerminalData.Date">
                        <mat-datepicker-toggle matSuffix class="dp" [for]="picker"> 
                             <span class="input-group-addon">
                            <span class="glyphicon glyphicon-calendar"></span>
                        </span></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>                             
                        <span class="help-block" style="color: #b94a48;" *ngIf="f.Date.errors && (f.Date.touched ||f.Date.dirty)">Anticipated Pick Up Date is required.</span>
                    </div>
                </div>
            </div>  
          <!-- TERMINALS -->
          <div class="row">
            <div class="form-group col-md-10">
                <div class="col-md-6 text-right minPadding-top"><span style="color:red">*</span><label>Terminal :</label></div>
                <div class="col-md-6" >
                    <select name="terminals" class="form-control show-tick" (change)="changeTerminals($event)"  [(ngModel)]="TerminalData.TerminalId" formControlName="TerminalId" required 
                    data-select-picker data-selectpicker-options="TerminalData.Terminals">
                        <option value="" [selected]="true">Choose Terminal ...</option>
                        <option *ngFor="let c of this.TerminalData.Terminals" value="{{c.TerminalId}}">{{c.CodeNameLocation}}</option>
                    </select>
                    <span class="help-block" style="color: #b94a48;"
                    *ngIf=" (submitted || f.TerminalId.touched ||f.TerminalId.dirty)&& f.TerminalId.errors?.required " >Terminal is Required.</span>
                </div>
            </div>
          </div>
       
            <!-- CONTAINER NUMBERS -->
            <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-6 text-right"><span style="color:red">*</span><label for="cnNumbers">Container Number(s) :</label></div>
                        <div class="col-md-6" >
                            <textarea class="form-control" rows="5"  style="text-transform:uppercase" formControlName="ContainerNumbers" name="cnNumbers" [(ngModel)]="TerminalData.ContainerNumbers" 
                            data-capitalize required></textarea>
                            <span class="help-block"   style="color: #b94a48;"
                            *ngIf="(submitted || f.ContainerNumbers.touched ||f.ContainerNumbers.dirty) && f.ContainerNumbers.errors?.required"
                            >Please enter Container Number(s).</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 col-sm-6 text-right">
                            <!-- BACK BUTTON -->
                            <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']">
                                <span class="glyphicon glyphicon-arrow-left" aria-hidden="true"  style="font-size:20px;padding-right:10px"></span>Back
                            </button>
                            <!-- SEARCH BUTTON -->
                            <button  class="btn btn-primary btn-site btn-margin">
                                <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Search
                            </button>
                        
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
                <br /><br />
        
            </form>
        </div>
    </div>

    </div>
