import { Component, OnInit } from '@angular/core';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCart } from 'src/app/core/models/ShoppingCart';
@Component({
  selector: 'app-demurrage-confirmation',
  templateUrl: './demurrage-confirmation.component.html',
  styleUrls: ['./demurrage-confirmation.component.css']
})
export class DemurrageConfirmationComponent implements OnInit {
  ShoppingCart:ShoppingCart;
  ShowLoader = false;
  Messages: ResponseMessage[]=[];
  constructor(private importchargesService: ImportchargesService,) { this.ShoppingCart  = new ShoppingCart(); }

  ngOnInit(): void {

    this.ShowLoader = true;
      //Make web call to get reference data to load form.
    setTimeout(() => {
    this.importchargesService.GetShoppingCartData().subscribe((result) => {
      this.ShoppingCart = result;    
      this.ShowLoader = false;   
    });
    }, 2000);   

  }

}
