import { Injectable } from '@angular/core';
import { Observable, throwError,BehaviorSubject ,Subject} from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiurls,httpOptions } from 'src/app/common/utils/globalconstants';
import { ILogin,UserContactInfo,EMailObj,States, Countries,Clients,UpdatePasswordRequest} from '../interfaces/login.interface';
import { AddContainerFilters, NotificationList,TerminalInfo,DeleteContainerFilters } from 'src/app/core/models/Notification.model';
import{ContainerHoldCharges} from 'src/app/core/models/ContainerHoldCharges'
@Injectable({
  providedIn: 'root'
})
export class LoginService {  
  // customChangeDetector = new Subject<boolean>();
  // customChangeDetector$ = this.customChangeDetector.asObservable();
  // customUserNameChangeDetector = new Subject<string>();
  // customUserNameChangeDetector$ = this.customUserNameChangeDetector.asObservable();
  // loggedIn = false;
  UserInfo:UserContactInfo;
  NotificationList:NotificationList;
  TerminalData:TerminalInfo;
  Countries:Countries;
  States:States;
  Clients:Clients;
  data :ContainerHoldCharges
  // selectedloggedIn : BehaviorSubject<boolean>; // this.loggedIn.asObservable();
  constructor(private httpClient: HttpClient) {
    // this.selectedloggedIn = new BehaviorSubject(this.loggedIn);
  }
  // errorMessage: any;

  
  GetOnlineUserChangePassword(requestdata:any):Observable<any>{
    let json_IUserCOntactInfo = JSON.stringify(requestdata);
    return this.httpClient.post<UserContactInfo>( environment.TOPSWebApi + apiurls.GetOnlineUserChangePassword , json_IUserCOntactInfo , httpOptions ).pipe(    
      tap(data => {      
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  GetAllUserInformation(UserId : string):Observable<any>{
    return this.httpClient.get<UserContactInfo>(
      environment.TOPSWebApi + apiurls.GetAllUserInformation + "/" + UserId ).pipe(    
      tap(data => {
        this.UserInfo= data;
        return data;
      }),
      catchError(this.handleError)
    );

  }
  GetNewUserInformation():Observable<any>{
    return this.httpClient.get<UserContactInfo>(
      environment.TOPSWebApi + apiurls.GetNewUserInformation ).pipe(    
      tap(data => {
        this.UserInfo= data;
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetClientType():Observable<any>{
    return this.httpClient.get<any>(
      environment.TOPSWebApi + apiurls.GetClientType ).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  

  GetStatesByCountry(countryId : string):Observable<any>{
    return this.httpClient.get<UserContactInfo>(
      environment.TOPSWebApi + apiurls.GetStatesByCountry + "/" + countryId ).pipe(    
      tap(data => {
        this.UserInfo= data;
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetClientByTaxIDNumber(TaxID : string):Observable<any>{
    return this.httpClient.get<Clients>(
      environment.TOPSWebApi + apiurls.GetClientByTaxIDNumber + "/" + TaxID ).pipe(    
      tap(data => {
        this.Clients= data;
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  PostRegistration(requestdata: UserContactInfo): Observable<any> {    
    let apiurl=environment.TOPSWebApi + apiurls.PostRegistration;
    let json_IUserCOntactInfo = JSON.stringify(requestdata);
      return this.httpClient.post<UserContactInfo>(
        apiurl ,json_IUserCOntactInfo, httpOptions )
  }

  UpdateClientType(requestdata: any): Observable<any> {   
    let apiurl=environment.TOPSWebApi + apiurls.UpdateClientType;
    let json_IUserCOntactInfo = JSON.stringify(requestdata);
      return this.httpClient.post<any>(
        apiurl ,json_IUserCOntactInfo, httpOptions )
  }

  PostOnlineUserContactInfoUpdate(requestdata: UserContactInfo): Observable<any> {   
    let apiurl=environment.TOPSWebApi + apiurls.PostOnlineUserContactInfoUpdate;
    let json_IUserCOntactInfo = JSON.stringify(requestdata);
      return this.httpClient.post<UserContactInfo>(
        apiurl ,json_IUserCOntactInfo, httpOptions )
  }
  
  PostNewOnlineUserUpdate(requestdata: UserContactInfo): Observable<any> {   
    let apiurl=environment.TOPSWebApi + apiurls.PostNewOnlineUserUpdate;
    let json_IUserCOntactInfo = JSON.stringify(requestdata);
      return this.httpClient.post<UserContactInfo>(
        apiurl ,json_IUserCOntactInfo, httpOptions )
  }
  PostOnlineUserPasswordUpdate(requestdata: UpdatePasswordRequest): Observable<any> {    
    let apiurl=environment.TOPSWebApi + apiurls.PostOnlineUserPasswordUpdate;
    let json_IUpdatePasswordRequest = JSON.stringify(requestdata);
      return this.httpClient.post<UpdatePasswordRequest>(
        apiurl ,json_IUpdatePasswordRequest, httpOptions )
  }

  GetAllClientAndUserInformation(ClientId : string,UserId : string):Observable<any>{
    return this.httpClient.get<UserContactInfo>(
      environment.TOPSWebApi + apiurls.GetAllClientAndUserInformation + "/" + ClientId+ "/" + UserId ).pipe(    
      tap(data => {
        this.UserInfo= data;
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetUserNameByEmail(EMailObj : EMailObj): Observable<any> {   
    let apiurl=environment.TOPSWebApi + apiurls.GetUserNameByEmail;
   // let json_IUserCOntactInfo = JSON.stringify(EMailObj);
      return this.httpClient.post<EMailObj>(
        apiurl ,EMailObj, httpOptions )
  }

  SendTempPassword(UserName : string):Observable<any>{
    return this.httpClient.get<string>(
      environment.TOPSWebApi + apiurls.SendTempPassword + "/" + UserName ).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetCompanyRegistration(UserId : string):Observable<any>{
    return this.httpClient.get<UserContactInfo>(
      environment.TOPSWebApi + apiurls.GetCompanyRegistration + "/" + UserId ).pipe(    
      tap(data => {
        this.UserInfo= data;
        return data;
      }),
      catchError(this.handleError)
    );
  }

  GetNotificationList(Contact_ID : number):Observable<any>{
    return this.httpClient.get<NotificationList>(
      environment.TOPSWebApi + apiurls.GetNotificationList + "/" + Contact_ID ).pipe(    
      tap(data => {
        this.NotificationList = data;
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  GetAllTerminals():Observable<any>{
    return this.httpClient.get<TerminalInfo>(
      environment.TOPSWebApi + apiurls.GetAllTerminals).pipe(    
      tap(data => {
        this.TerminalData = data;
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetStaticContent(StaticContentName : string):Observable<any>{
    return this.httpClient.get<NotificationList>(
      environment.TOPSWebApi + apiurls.GetStaticContent + "/" + StaticContentName ).pipe(    
      tap(data => {
        // this.NotificationList = data;
        return data;
      }),
      catchError(this.handleError)
    );
  } 

  AddToContainerWatchList(requestdata: AddContainerFilters): Observable<any> {    
  let apiurl=environment.TOPSWebApi + apiurls.AddToContainerWatchList;
  let json_AddContainerFilters = JSON.stringify(requestdata);
    return this.httpClient.post<AddContainerFilters>(
      apiurl ,json_AddContainerFilters, httpOptions )
  } 

  
  DeleteContainerWatchList(requestdata: DeleteContainerFilters): Observable<any> {    
    let apiurl=environment.TOPSWebApi + apiurls.DeleteContainerWatchList;
    let json_DeleteContainerFilters = JSON.stringify(requestdata);
      return this.httpClient.post<DeleteContainerFilters>(
        apiurl ,json_DeleteContainerFilters, httpOptions )
  } 
  
  

  private handleError(err: HttpErrorResponse) {
    return throwError(() => new Error('Error Occured')); //throwError('Error Occured');
 }

}


