import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { ShoppingCart,PaymentConfirmation } from 'src/app/core/models/ShoppingCart';
@Component({
  selector: 'app-new-check-out-confirmation',
  templateUrl: './new-check-out-confirmation.component.html',
  styleUrls: ['./new-check-out-confirmation.component.css']
})
export class NewCheckOutConfirmationComponent implements OnInit {
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  ShoppingCart:ShoppingCart;
  SCartPayment:PaymentConfirmation;
  SentToCyberSource:any;  
  constructor(private importchargesService: ImportchargesService,
              private ShoppingCartService : ShoppingCartService,
              private router: Router) { this.ShoppingCart = new ShoppingCart(); this.SCartPayment = new PaymentConfirmation();  }

  ngOnInit(): void {

    this.ShoppingCart = JSON.parse(sessionStorage.S_CartInfo);    
	  this.SentToCyberSource = this.ShoppingCartService.SentToCyberSource();

    // if (this.ShoppingCart !== undefined)
		//  this.LineItemsCollection = this.ShoppingCart.LineItems;

    // Ensure the User has Shopping Cart data and hasn't gone to CyberSource already
    if (this.ShoppingCart === undefined || this.SentToCyberSource)  {
      console.log('ShoppingCart: ' + this.ShoppingCart);
      console.log('SentToCyberSource: ' + this.SentToCyberSource);
      this.router.navigateByUrl('/landingpage'); 
    }
    else
      this.GetReferenceDataForPaymentConfirmation();

  }

  GetOutputVal(msgData:any){
    this.Messages= msgData;
  }  

  //Make web call to get reference data to load form.
	GetReferenceDataForPaymentConfirmation () {
    this.ShowLoader = true;
    setTimeout(() => {
		this.importchargesService.GetReferenceDataForPaymentConfirmation().subscribe((result) => {
      this.ShowLoader = false;
      if(result != null){
			this.SCartPayment = result;
      this.SCartPayment.AcceptTerms = false;
      }
		})
	}, 2000);
 }

}
