import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};

  constructor(private http: HttpClient, private adapter: DateAdapter<any>) { }

  use(lang: string, terminal: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${terminal}/${lang || "en"}.json`;
      this.http.get<{}>(langPath).subscribe({
        next : (translation) => {
          this.data = Object.assign({}, translation || {});
          this.adapter.setLocale(this.data.timezone);
          resolve(this.data);
        },
        error : (err: any)  => {
          this.data = {};
          resolve(this.data);
        }
      }
      );
    
    });
  }
}
