import { Component, OnInit ,Inject } from '@angular/core';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { EMailObj, ILogin } from 'src/app/shared/interfaces/login.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-user-name',
  templateUrl: './forgot-user-name.component.html',
  styleUrls: ['./forgot-user-name.component.css']
})
export class ForgotUserNameComponent implements OnInit {
  ForgotuserForm: FormGroup;
  ForgetPasswordFlag:boolean=false;
  ForgetPasswordMsgFlag:boolean=false;
  ForgetUserMessages: ResponseMessage[]=[];
  ForgetPasswordMessages: ResponseMessage[]=[];
  LoginMessages: ResponseMessage[]=[];
  temppass:string;
  ForgotuserFlag:boolean=false;
  ShowLoader = false;
  password ='';
  userName='';
  email='';
  loggedIn= false;
  constructor(private authService: AuthService,
              private loginService:LoginService,    
              private router: Router,
              private cookieService: CookieService,  
              @Inject(MAT_DIALOG_DATA) public UserData: any,
              public dialogRef: MatDialogRef<ForgotUserNameComponent>,
              public dialog: MatDialog,    
              private formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.ForgotuserFlag = this.UserData.ForgotuserFlag;
    this.ForgetPasswordFlag = this.UserData.ForgetPasswordFlag;
    this.userName = this.UserData.userName;

    if(this.ForgetPasswordFlag) {
      this.ShowLoader=true;
      this.loginService.SendTempPassword(this.userName).subscribe((result) => {    
        this.ShowLoader=false; 
          this.ForgetUserMessages= result.generalMessages;
              if(result.generalMessages[0].severity==3)            
                this.ForgetPasswordMsgFlag=true;
              else
                this.ForgetPasswordMsgFlag=false;
      });
    }

    this.ForgotuserForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
      temppass:['',Validators.required]
    })  

  }
  
  ValidateUserNameform()  { 
    if(!this.ForgetPasswordFlag)
      this.ForgotuserForm.get('temppass')?.setErrors(null);
  }

  ValidatePasswordform() {   
    if(this.ForgetPasswordFlag && !this.ForgetPasswordMsgFlag)
      this.ForgotuserForm.get('Email')?.setErrors(null);  
  }

  submitForgotUserName(Email:EMailObj)  {
    this.ShowLoader=true;  
    this.loginService.GetUserNameByEmail(Email).subscribe((result) => {
      this.ShowLoader = false;      
      this.ForgetUserMessages= result.generalMessages;      
    })   
  }   
  
  get f() { return this.ForgotuserForm.controls; }

  closePopup() {
    this.dialogRef.close('cancel');  
  }

  submitForgetPassword()  {
    this.ShowLoader=true;
    this.temppass=this.ForgotuserForm.get('temppass')?.value;
    let lLogin= {LoginName :this.userName,Password:this.temppass,LBNode:environment.LBnode};

    this.authService.ValidateOnlineUserLogin(lLogin).subscribe( (result)=> { 
      this.ForgetUserMessages=[];
      this.LoginMessages[0]=result.generalMessages[0];
      this.ShowLoader = false;    
      if ((result.Contact_ID) && result.Contact_ID > 0) {
        let now = new Date();
        let expiresValue = new Date(now);
        expiresValue.setDate(now.getDate() + 1);

        var topsUserKey = "Login_Id=" + this.userName + "&User_Id=" + result.Contact_ID + "&PublicKey=" + result.PublicKey + "&PrivateKey=" + result.PrivateKey;
        this.cookieService.set('TOPSUserKey', topsUserKey, expiresValue);
        // localStorage.setItem("Contact_ID",result.Contact_ID);
        this.cookieService.set('loggedIn', "1", expiresValue); 
        
        this.authService.customChangeDetector.next(this.loggedIn);
        localStorage.setItem('userName', this.userName);    
        this.authService.customUserNameChangeDetector.next(this.userName);            
        this.dialogRef.close('ChangePwd');  
        }
          
      });      
     
  }

}
