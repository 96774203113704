import { Component, OnInit } from '@angular/core';
import { InvoiceItem } from 'src/app/core/models/InvoiceItem';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';

@Component({
  selector: 'app-import-invoice-preview',
  templateUrl: './import-invoice-preview.component.html',
  styleUrls: ['./import-invoice-preview.component.css']
})
export class ImportInvoicePreviewComponent implements OnInit {    
  Messages: ResponseMessage[]=[];  
  Errors:ResponseMessage[]=[];  
  ShowLoader = false;
  PrintAncillary:boolean = localStorage.InvoiceAncil;       
  InvoicesToPrint:any;    
  PrintInvoice:InvoiceItem[] = [];
  InvoiceItemsCollection:[]=[];
  InvoiceTariffCollection:[]=[];
  submitted = false;
  constructor(private importchargesService: ImportchargesService,) { }

  ngOnInit(): void {    
    let InvoiceFilterList = JSON.parse(localStorage.PrintInvoiceFilters);
    let UniqueInvoiceId='';
    InvoiceFilterList.forEach((val:any) =>  {
      UniqueInvoiceId += val.UniqueInvoiceId + ',';
    });  
    //have to build a string of Invoice Unique id and pass to procedure
    if (typeof (UniqueInvoiceId) !== 'undefined') { 
        this.ShowLoader = true;
        this.importchargesService.GetAncillaryInvoiceDataForPrint(UniqueInvoiceId).subscribe( (result) => {   
          this.ShowLoader = false;       
          this.PrintInvoice = result.AncillaryInvoice;
        });
      }
      else { console.log("No Invoice ID's"); }
  }

  print(){    
    window.print();
  }

}
