import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiurls } from 'src/app/common/utils/globalconstants';
import { ILogin } from '../interfaces/login.interface';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: boolean = false;
  customChangeDetector = new Subject<boolean>();
  customChangeDetector$ = this.customChangeDetector.asObservable();
  customUserNameChangeDetector = new Subject<string>();
  customUserNameChangeDetector$ = this.customUserNameChangeDetector.asObservable();
  selectedloggedIn: BehaviorSubject<boolean>;
  constructor(private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.selectedloggedIn = new BehaviorSubject(this.loggedIn);
  }

  ValidateOnlineUserLogin(requestdata: ILogin): Observable<any> {
    let json_ILogin = JSON.parse(JSON.stringify(requestdata));
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.ValidateOnlineUserLogin, json_ILogin).pipe(
        tap(data => {
          if ((data.Contact_ID) && data.Contact_ID > 0) { this.loggedIn = true; }
          return data;
        }), catchError(this.handleError)
      );
  }

  logout(): void {
    this.loggedIn = false;
    this.cookieService.deleteAll();
    localStorage.clear();
  }

  currentUser() {
    if (this.IsFieldNotBlank(this.cookieService.get("TOPSUserKey")))
      return this.cookieService.get("TOPSUserKey");
    else
      return null;
  }

  Login_Id() {
    let Login_Id = '';
    if (this.IsFieldNotBlank(this.cookieService.get("TOPSUserKey")))
      Login_Id = this.cookieService.get("TOPSUserKey").split("&")[0].replace("Login_Id=", "");
    return Login_Id;
  }

  User_Id() {
    let User_Id = '';
    if (this.IsFieldNotBlank(this.cookieService.get("TOPSUserKey")))
      User_Id = this.cookieService.get("TOPSUserKey").split("&")[1].replace("User_Id=", "");
    return User_Id;
  }

  IsFieldNotBlank(val: any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

  private handleError(err: HttpErrorResponse) {
    return throwError(() => new Error('Error Occured'));
  }

}
