import { Component, OnInit } from '@angular/core';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { TrackandtraceService } from '../../services/trackandtrace.service'; 
import { TrackFilter } from '../../models/trackandtrace.model';
import { TerminalInfo } from '../../models/Notification.model';
import * as moment from 'moment';
import swal from 'sweetalert2'; 
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
// import Validation from './utils/validation';
@Component({
  selector: 'app-trackandtrace',
  templateUrl: './trackandtrace.component.html',
  styleUrls: ['./trackandtrace.component.css']
})
export class TrackandtraceComponent implements OnInit {
  TrackandtraceForm: FormGroup;
  searchactive = false;
  searchresult = false;
  clicked = false;
  OldTerminalId :number=0;
  minfromDate = new Date();
  maxfromDate = new Date();
  mintoDate = new Date();
  maxtoDate = new Date();
  // TrackandtraceForm: Form;
  TerminalData:TerminalInfo;
  TrackFilter:TrackFilter;
  GateDaysLimit:number;
  GateContainerLimit:number;
  ImportAvailabilityLimit:number;
  ShowLoader = false;
  vesselScheduleCollection:[] = [];
  BookingInformationCollection:[] = [];
  ImportAvailabilityCollection:[] =[];
  ContainerHistoryCollection:[] = [];
  vesselScheduleDetailsCollection:[] = [];
  gatetransactiondataCollection:[]=[];
  SearchOptionsData:any;
  submitted = false;

  today = moment(new Date());
  pastdate = moment(new Date()).subtract(29, 'days').format('MM/DD/YYYY');

  constructor(private trackandtraceService: TrackandtraceService,
              private formBuilder: FormBuilder) { 

    this.TerminalData = new TerminalInfo();
    this.TrackFilter = new TrackFilter();
    this.trackandtraceService.GetAllTerminals().subscribe((result) => {
      this.TerminalData = result;
      this.TrackFilter.GateContainerNumberfocus= false;
      this.TrackFilter.GateBookingNumberfocus= false;
      this.TrackFilter.GateBillNumberfocus= false;
      // this.TrackFilter.terminals = result.Terminals;
      // this.TrackFilter.GateFromDate = new Date();
      // this.TrackFilter.GateToDate = new Date();
      this.TrackFilter.searches= [];
      this.TrackFilter.BookingNum= '';
      this.TrackFilter.ContainerNumber= '';
      this.TrackFilter.billoflanding_nbr= '';
      this.TrackFilter.ImportcontainerNum= '';
      this.TrackFilter.Scheduletrip= '';
      this.TrackFilter.Ship= '';
      this.TrackFilter.inVoyageCall= '';
      this.TrackFilter.outVoyageCall= '';
      this.TrackFilter.GateBookingNumber= '';
      this.TrackFilter.GateBillNumber= '';
      this.TrackFilter.GateContainerNumber= '';
      this.TrackFilter.GateToDate= new Date();
      this.TrackFilter.GateFromDate= new Date();
      this.TrackFilter.Gkey= '';
      this.TrackFilter.GateContainer= '';
      this.TrackFilter.GateTruckEntered= '';
      this.TrackFilter.ShippingLineIdVGM= '';
      this.TrackFilter.ChargeType= 'V';
      this.TrackFilter.vShippingLineId= '';
      this.TrackFilter.viewvgm= false

    if (sessionStorage.FromEIR != null || sessionStorage.FromEIR == "") {
      if (sessionStorage.FromEIR === "true") {
          this.TrackFilter.Scheduletrip = '3';
          if (sessionStorage.BookingNumber != '' || sessionStorage.BookingNumber == undefined) {
              this.TrackFilter.TerminalId = sessionStorage.TerminalId;
              if (sessionStorage.BookingNumber != '') {
                  this.TrackFilter.BookingNum = sessionStorage.BookingNumber;
                  this.TrackandandTraceSearch();
              }
              sessionStorage.EIR = '';
          }
          sessionStorage.FromEIR = '';
      }
    }

    });    

  }

  // GetOutputVal(msgData:any){
  //   this.Messages= msgData;
  // }  

  enableAllFeilds(name:string){
    if(name == 'GateContainerNumberfocus') this.TrackFilter.GateContainerNumberfocus=false;
    if(name == 'GateBookingNumberfocus') this.TrackFilter.GateBookingNumberfocus=false;
    if(name == 'GateBillNumberfocus') this.TrackFilter.GateBillNumberfocus=false;
    this.TrackandtraceForm.get("GateContainerNumber")?.enable();
    this.TrackandtraceForm.get("GateBookingNumber")?.enable();
    this.TrackandtraceForm.get("GateBillNumber")?.enable();
  }
  

  emptyotherfield(typename:string){
    
    if(typename == 'GateContainerNumber')    {
      this.TrackFilter.GateBookingNumber = '';
      this.TrackFilter.GateBillNumber ='';
      this.TrackandtraceForm.get("GateBookingNumber")?.disable();
      this.TrackandtraceForm.get("GateBillNumber")?.disable();
    }
    else  if(typename == 'GateBillNumber')    {
      this.TrackFilter.GateBookingNumber = '';
      this.TrackFilter.GateContainerNumber ='';
      this.TrackandtraceForm.get("GateContainerNumber")?.disable();
      this.TrackandtraceForm.get("GateBookingNumber")?.disable();
    }
    else if(typename == 'GateBookingNumber')
    {
      this.TrackFilter.GateBillNumber = '';
      this.TrackFilter.GateContainerNumber ='';
      this.TrackandtraceForm.get("GateContainerNumber")?.disable();
      this.TrackandtraceForm.get("GateBillNumber")?.disable();
    }

  }

  ngOnInit(): void {

    this.TrackandtraceForm = this.formBuilder.group({
      terminals: ['', Validators.required],
      searches:  ['', Validators.required],
      Contai:  ['', Validators.required],
      billnum:  ['', Validators.required],
      equipnum:  ['', Validators.required],
      bkngnum:  ['', Validators.required],
      GateContainerNumber:  ['', Validators.required],
      GateBookingNumber:  ['', Validators.required],
      GateBillNumber:  ['', Validators.required],
      gatefromdate:  ['', Validators.required],
      GateToDate:  ['', Validators.required],
    });
  }
  

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {

    return '';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.TrackandtraceForm.controls;
  }

  TrackandandTraceSearch(){
    this.submitted = true;

    if(!(sessionStorage.FromEIR === "true" && sessionStorage.BookingNumber != '' &&  this.TrackFilter.Scheduletrip == '3')){

    if (this.TrackFilter.Scheduletrip == '1' || this.TrackFilter.Scheduletrip == '2' || this.TrackFilter.Scheduletrip == '3' || this.TrackFilter.Scheduletrip == '5') {
        
      if(this.TrackFilter.Scheduletrip == '2'){
        this.TrackandtraceForm.controls["Contai"].clearValidators();
        this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();

      // this.TrackandtraceForm.controls["billnum"].clearValidators();
      // this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["equipnum"].clearValidators();
      this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["bkngnum"].clearValidators();
      this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();

      }
      else if(this.TrackFilter.Scheduletrip == '1'){
      // this.TrackandtraceForm.controls["Contai"].clearValidators();
      // this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();
        
      this.TrackandtraceForm.controls["billnum"].clearValidators();
      this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["equipnum"].clearValidators();
      this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["bkngnum"].clearValidators();
      this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();

      }
      else if(this.TrackFilter.Scheduletrip == '5'){
        this.TrackandtraceForm.controls["Contai"].clearValidators();
        this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();
          
        this.TrackandtraceForm.controls["billnum"].clearValidators();
        this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();
  
        // this.TrackandtraceForm.controls["equipnum"].clearValidators();
        // this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();
  
        this.TrackandtraceForm.controls["bkngnum"].clearValidators();
        this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();
  
        }
        else if(this.TrackFilter.Scheduletrip == '3'){
          this.TrackandtraceForm.controls["Contai"].clearValidators();
          this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();
            
          this.TrackandtraceForm.controls["billnum"].clearValidators();
          this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();
    
          this.TrackandtraceForm.controls["equipnum"].clearValidators();
          this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();
    
          // this.TrackandtraceForm.controls["bkngnum"].clearValidators();
          // this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();
    
          }    

      this.TrackandtraceForm.controls["GateContainerNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateContainerNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["GateBookingNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateBookingNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["GateBillNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateBillNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["gatefromdate"].clearValidators();
      this.TrackandtraceForm.controls["gatefromdate"].updateValueAndValidity();
      
      this.TrackandtraceForm.controls["GateToDate"].clearValidators();
      this.TrackandtraceForm.controls["GateToDate"].updateValueAndValidity();
    }
    else if (this.TrackFilter.Scheduletrip == '4') {
      this.TrackandtraceForm.controls["Contai"].clearValidators();
      this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();

      this.TrackandtraceForm.controls["billnum"].clearValidators();
      this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["equipnum"].clearValidators();
      this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["bkngnum"].clearValidators();
      this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["GateContainerNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateContainerNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["GateBookingNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateBookingNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["GateBillNumber"].clearValidators();
      this.TrackandtraceForm.controls["GateBillNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["gatefromdate"].clearValidators();
      this.TrackandtraceForm.controls["gatefromdate"].updateValueAndValidity();
      
      this.TrackandtraceForm.controls["GateToDate"].clearValidators();
      this.TrackandtraceForm.controls["GateToDate"].updateValueAndValidity();
    }
    else if (this.TrackFilter.Scheduletrip == '6') {

      this.TrackandtraceForm.controls["GateContainerNumber"].setValidators([Validators.required]);
      this.TrackandtraceForm.controls["GateContainerNumber"].updateValueAndValidity();
      this.TrackandtraceForm.controls["GateBookingNumber"].setValidators([Validators.required]);
      this.TrackandtraceForm.controls["GateBookingNumber"].updateValueAndValidity();
      this.TrackandtraceForm.controls["GateBillNumber"].setValidators([Validators.required]);
      this.TrackandtraceForm.controls["GateBillNumber"].updateValueAndValidity();

      this.TrackandtraceForm.controls["Contai"].clearValidators();
      this.TrackandtraceForm.controls["Contai"].updateValueAndValidity();

      this.TrackandtraceForm.controls["billnum"].clearValidators();
      this.TrackandtraceForm.controls["billnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["equipnum"].clearValidators();
      this.TrackandtraceForm.controls["equipnum"].updateValueAndValidity();

      this.TrackandtraceForm.controls["bkngnum"].clearValidators();
      this.TrackandtraceForm.controls["bkngnum"].updateValueAndValidity();

      if(!((this.TrackFilter.GateContainerNumber == '' || this.TrackFilter.GateContainerNumber == undefined) && (this.TrackFilter.GateBookingNumber == '' || this.TrackFilter.GateBookingNumber == undefined) && (this.TrackFilter.GateBillNumber == '' || this.TrackFilter.GateBillNumber == undefined))){
        this.TrackandtraceForm.controls["GateContainerNumber"].clearValidators();
        this.TrackandtraceForm.controls["GateContainerNumber"].updateValueAndValidity();
  
        this.TrackandtraceForm.controls["GateBookingNumber"].clearValidators();
        this.TrackandtraceForm.controls["GateBookingNumber"].updateValueAndValidity();
  
        this.TrackandtraceForm.controls["GateBillNumber"].clearValidators();
        this.TrackandtraceForm.controls["GateBillNumber"].updateValueAndValidity();
      }     

      // if(!((this.TrackFilter.GateFromDate == '' ||this.TrackFilter.GateFromDate == undefined) && (this.TrackFilter.GateToDate == '' || this.TrackFilter.GateToDate == undefined))){
      // this.TrackandtraceForm.controls["gatefromdate"].clearValidators();
      // this.TrackandtraceForm.controls["gatefromdate"].updateValueAndValidity();
      
      // this.TrackandtraceForm.controls["GateToDate"].clearValidators();
      // this.TrackandtraceForm.controls["GateToDate"].updateValueAndValidity();
      // }
    }


    if (this.TrackandtraceForm.invalid ) {
      return;
    }

  }

    this.TrackFilter.terminals = this.TerminalData.Terminals;
    this.searchactive = false;
    this.searchresult = false;
    this.vesselScheduleCollection = [];
    this.BookingInformationCollection = [];
    this.ContainerHistoryCollection = [];
    this.vesselScheduleDetailsCollection = [];
    this.ShowLoader=true;
    if (this.TrackFilter.Scheduletrip === '') return;
    if (this.TrackFilter.TerminalId == undefined) return;

    if (this.TrackFilter.Scheduletrip == '3') {
      if (this.TrackFilter.BookingNum === '' || this.TrackFilter.BookingNum == undefined) return;

      this.trackandtraceService.GetBookingInformation(this.TrackFilter).subscribe((result) => {
          // this.BookingInformationData = result;
          sessionStorage.BookingNumber = '';
          this.BookingInformationCollection = result.BookingInformation;
          this.searchactive = true;   
          this.searchresult = true;       
          this.ShowLoader=false;
      });

    }
    else if (this.TrackFilter.Scheduletrip == '2') {
     
      if (this.TrackFilter.billoflanding_nbr === '' || this.TrackFilter.billoflanding_nbr == undefined) return;

      this.trackandtraceService.GetContainerAvailabiltyByBLByTerm(this.TrackFilter).subscribe((result) => {
        this.searchactive = true;
        this.searchresult = true;
        result.ContainerAvailabiltyByBLByTerm.forEach( (a:any )=> {
          if (a.BL_NBR != '' && a.BL_NBR != undefined && a.BL_NBR !== null) {
            if (a.BL_NBR.length > 10) {
                a.BL_NBR = a.BL_NBR + ",";
                //a.BL_NBR = a.BL_NBR.replace(/(?=.{3}$)/, ' ');
            }
            if (a.BL_NBR.indexOf(',') > -1) {
                a.BL_NBR = a.BL_NBR.replace(/,/g, ", ");
            }
          }
        })
        this.ImportAvailabilityCollection = result.ContainerAvailabiltyByBLByTerm;
        this.ShowLoader=false;
      });
    }
    else if (this.TrackFilter.Scheduletrip == '4') {
      if (this.TrackFilter.TerminalId === 0) return;

      this.trackandtraceService.GetVesselSchedule(this.TrackFilter).subscribe((result) => {
        this.searchactive = true;        
        this.searchresult = true;
        this.vesselScheduleCollection = result.VesselSchedule;
        this.ShowLoader=false;
      });
    }
    else if (this.TrackFilter.Scheduletrip == '5') {
      if (this.TrackFilter.ContainerNumber === '' || this.TrackFilter.ContainerNumber == undefined) return;

      this.trackandtraceService.GetContainerHistory(this.TrackFilter).subscribe((result) => {
        this.searchactive = true;   
        this.searchresult = true;           
        this.ContainerHistoryCollection = result.ContainerHistory;
        this.ShowLoader=false;
      });
    }
    else if (this.TrackFilter.Scheduletrip == '1') {
    
      if (this.TrackFilter.ImportcontainerNum === '' || this.TrackFilter.ImportcontainerNum == undefined) return;

      this.trackandtraceService.GetContainerAvailabiltyByTerm(this.TrackFilter).subscribe((result) => {
        this.TrackFilter.billoflanding_nbr = '';
        this.ImportAvailabilityCollection = result.ContainerAvailabiltyByTerm;
        this.searchactive = true;
        this.searchresult = true;
        this.ShowLoader=false;
      });
    }
     
    else if (this.TrackFilter.Scheduletrip == '6') {   
      if ((this.TrackFilter.GateContainerNumber === '' || this.TrackFilter.GateContainerNumber === undefined) && (this.TrackFilter.GateBookingNumber === '' || this.TrackFilter.GateBookingNumber === undefined) && (this.TrackFilter.GateBillNumber === '' || this.TrackFilter.GateBillNumber === undefined)) return;
      if ((this.TrackFilter.GateFromDate === null || this.TrackFilter.GateFromDate === undefined) || (this.TrackFilter.GateToDate === null || this.TrackFilter.GateToDate === undefined)) return;
      if (this.TrackFilter.GateFromDate !== null && this.TrackFilter.GateToDate !== null) {

          var endDate = new Date(this.TrackFilter.GateToDate);
          var startDate = new Date(this.TrackFilter.GateFromDate);
          if (endDate < startDate) {
            this.ShowLoader=false;
            swal.fire("Error...", "The To date cannot be before From Date. Please check date range for your request", "error");
              return;
          }

          let fromDate= moment(this.TrackFilter.GateFromDate, 'M/D/YYYY');
          let toDate = moment(this.TrackFilter.GateToDate, 'M/D/YYYY');
          var diffDays = toDate.diff(fromDate, 'days');
          if (diffDays > this.GateDaysLimit) {
            this.ShowLoader=false;
            swal.fire("Error...", "Please limit your search dates to a span of " + this.GateDaysLimit + " days or less", "error");
              return;
          }
          else {
            var fDate = moment(this.TrackFilter.GateFromDate, 'M/D/YYYY');
            var tDate = moment(this.TrackFilter.GateToDate, 'M/D/YYYY');
            var pasttodate = tDate.diff(this.pastdate, 'days');
            var pastfromdate = fDate.diff(this.pastdate, 'days');
            var difffromtoDays = toDate.diff(fromDate, 'days');
            var difffromtodayDays = this.today.diff(toDate, 'days');
            if (pastfromdate >= 0 && difffromtodayDays >= 0 && pasttodate >= 0 && difffromtoDays >= 0) {
              this.trackandtraceService.GetGateTransaction(this.TrackFilter).subscribe((result)=> {
                    this.searchactive = true;
                    this.searchresult = true;                    
                    this.gatetransactiondataCollection = result.GateTransaction;
                    this.ShowLoader=false;
                });
            }
            else {
                this.ShowLoader=false;
                swal.fire("Error...", "Please limit your search dates to a span of less than " + this.GateDaysLimit, "error");
                return;
            }
          }
      }      
    }
  }

  onChange() {

    if(this.TrackFilter.TerminalId == this.OldTerminalId) return ;

    this.searchactive = false;
    this.submitted = false;
    this.BookingInformationCollection = [];
    this.vesselScheduleCollection = [];
    this.ImportAvailabilityCollection = [];
    this.ContainerHistoryCollection = [];
    this.TrackFilter.BookingNum='';
    this.TrackFilter.ContainerNumber='';
    this.TrackFilter.billoflanding_nbr='';
    this.TrackFilter.ImportcontainerNum='';
    this.TrackFilter.GateBillNumber='';
    this.TrackFilter.GateContainer='';
    this.TrackFilter.GateFromDate= new Date();
    this.TrackFilter.GateToDate= new Date();
  }

  GetOutputVal(val:any){
    
  }

  LoadTerminalOptions(tid :any){
    
     if(tid == this.OldTerminalId) return ;
     this.OldTerminalId = this.TrackFilter.TerminalId;
    if (tid != undefined &&  !(sessionStorage.BookingNumber != '' &&  this.TrackFilter.Scheduletrip == '3' )) {
      
      this.onChange();
      this.searchresult= false;
      this.TrackFilter.BookingNum='';
      this.TrackFilter.ContainerNumber='';
      this.TrackFilter.billoflanding_nbr='';
      this.TrackFilter.ImportcontainerNum='';
      this.TrackFilter.GateBillNumber='';
      this.TrackFilter.GateContainer='';
      this.TrackFilter.GateFromDate= new Date();
      this.TrackFilter.GateToDate= new Date();
      this.TrackFilter.Scheduletrip = '';
      if (tid != '' && tid != null && tid != undefined) {
          this.ShowLoader = true;
          this.trackandtraceService.GetSearchOptionsbyTerminalId(tid).subscribe((result) => {
                this.SearchOptionsData = result;
                this.TrackFilter.searches = [].concat( this.SearchOptionsData.SearchOptions);
                this.ImportAvailabilityLimit =  this.SearchOptionsData.ImportContainerLimit;
                this.GateContainerLimit =  this.SearchOptionsData.GateTransactionContainerLimit;
                this.GateDaysLimit =  this.SearchOptionsData.GateTransactionDayRange;
                this.ShowLoader = false;
                this.pastdate = moment(new Date()).subtract( this.GateDaysLimit - 1, 'days').format('MM/DD/YYYY');
                this.minfromDate = new Date(this.pastdate);
                this.mintoDate = new Date(this.pastdate);
          });
      }
      else {
            this.TrackFilter.searches = [];
            this.TrackFilter.Scheduletrip = '';
      }
    }
    else {
      this.TrackFilter.searches = [];
      this.TrackFilter.Scheduletrip = '';
    }
  };
  

}
