import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotUserNameComponent } from './forgot-user-name/forgot-user-name.component';
import { ChangePaswordComponent } from './change-pasword/change-pasword.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  password = '';
  userName = '';
  email = '';
  IsUserBlank = false;
  IsPasswordBlank = false;
  ShowLoader = false;
  Messages: ResponseMessage[] = [];
  loggedIn = false;
  StaticContent: any;
  constructor(private authService: AuthService,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.cookieService.get('TOPSUserKey') != undefined && this.cookieService.get('TOPSUserKey') != '')
      this.router.navigateByUrl('/landingpage');

    this.authService.selectedloggedIn.subscribe(c => {
      this.loggedIn = c;
    });

    this.loginService.GetStaticContent('ContentUpdateDate').subscribe((result) => {
      this.StaticContent = result.StaticContentValue;
    });
  }

  ForgotUserName() {
    const dialogRef = this.dialog.open(ForgotUserNameComponent, {
      data: { ForgotuserFlag: true, ForgetPasswordFlag: false, userName: this.userName },
      position: { 'top': '30px' },
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'deletepanelclass'
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  ForgetPassword() {
    this.ShowLoader = true;
    if (this.IsFieldNotBlank(this.userName)) {
      const dialogRef = this.dialog.open(ForgotUserNameComponent, {
        data: { ForgotuserFlag: false, ForgetPasswordFlag: true, userName: this.userName },
        position: { 'top': '30px' },
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'deletepanelclass'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != 'cancel') {

          const dialogRef = this.dialog.open(ChangePaswordComponent, {
            data: { userName: this.userName },
            position: { 'top': '30px' },
            autoFocus: false,
            restoreFocus: false,
            panelClass: 'deletepanelclass',
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(() => {
          });

        }
      });
    }
    else {
      this.IsUserBlank = true;
    }
    this.ShowLoader = false;
  }

  IsFieldNotBlank(val: any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

  submit() {
    if (this.userName && (this.password)) {
      let lLogin = { LoginName: this.userName, Password: this.password, LBNode: environment.LBnode };
      this.ShowLoader = true;
      this.authService.ValidateOnlineUserLogin(lLogin).subscribe(
        (result) => {
          this.Messages = result.generalMessages;
          if ((result.Contact_ID) && result.Contact_ID > 0) {
            let topsUserKey = "Login_Id=" + this.userName + "&User_Id=" + result.Contact_ID + "&PublicKey=" + result.PublicKey + "&PrivateKey=" + result.PrivateKey;
            let now = new Date();
            let expiresValue = new Date(now);
            expiresValue.setDate(now.getDate() + 1);
            this.cookieService.set('TOPSUserKey', topsUserKey, expiresValue);
            this.cookieService.set('loggedIn', "1");
            this.loggedIn = true;

            this.authService.customChangeDetector.next(this.loggedIn);

            this.loginService.GetAllUserInformation(result.Contact_ID).subscribe((result) => {
              this.Messages = result.generalMessages;
              localStorage.setItem('userName', result.First_Name_DS);
              this.authService.customUserNameChangeDetector.next(result.First_Name_DS);
              sessionStorage.UserFirstName = result.First_Name_DS;
              sessionStorage.SecurityEmail = result.Security_Email_DS;

              this.ShowLoader = false;

              this.router.navigateByUrl('/landingpage');
            });
          }
          else {
            this.loggedIn = false;
            this.ShowLoader = false;
          }
        });
    }
    else {
      if (this.userName == '') this.IsUserBlank = true; else this.IsUserBlank = false;
      if (this.password == '') this.IsPasswordBlank = true; else this.IsPasswordBlank = false;
    }
  }
}
