import { Component, OnInit,Input , Output , EventEmitter } from '@angular/core';
import { CyberSource, PaymentConfirmation, ShoppingCart } from 'src/app/core/models/ShoppingCart';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-cyber-source-payment',
  templateUrl: './cyber-source-payment.component.html',
  styleUrls: ['./cyber-source-payment.component.css']
})
export class CyberSourcePaymentComponent implements OnInit {
  @Input() ShoppingCart:ShoppingCart;
  @Output() outputToParent = new EventEmitter<any>();
  @Input() SCartPayment:PaymentConfirmation;
  @Input() form: FormGroup;
  CyberSourceData:CyberSource;
  ShowLoader = false;
  CyberSourceWebMobileURL:SafeUrl;
  Messages: ResponseMessage[]=[];
  constructor(private shoppingCartService : ShoppingCartService,
              private importchargesService : ImportchargesService,
              private domSanitizer: DomSanitizer) { this.CyberSourceData = new CyberSource(); }

  ngOnInit(): void {
    this.SCartPayment.AcceptTerms = false;
  }

  SentUserToCyberSource() {  
    //Note that I added 'e' and calling the event target's .submit()      
    this.shoppingCartService.CreateCookie(this.shoppingCartService.TransactionReferenceId(), this.shoppingCartService.HoldType(), true);
  
    this.ShowLoader = true;
  }

  GetCyberSourceWebMobileParameters(event:any){
    if(event === true){      
      this.form.get("PO")?.disable();
      }
      else
      this.form.get("PO")?.enable();    
    if (this.SCartPayment.AcceptTerms === true) {       
        this.ShowLoader = true;
        this.importchargesService.GetCyberSourceWebMobileParameters(this.SCartPayment).subscribe((result) => {
            if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
              this.outputToParent.emit(result.generalMessages);
              // this.parent.$parent.Messages = result.generalMessages;
            }
            else {
                this.CyberSourceData = result;
                this.CyberSourceWebMobileURL = this.domSanitizer.bypassSecurityTrustUrl(result.cyberSourceWebMobileURL);

            // Default the Hold Type to "I" if one doesn't exist
            var holdType = "I";
            if (this.IsFieldNotBlank(this.ShoppingCart.HoldType))
              holdType = this.ShoppingCart.HoldType;

            // Create the Shopping Cart cookie
            this.shoppingCartService.CreateCookie(result.reference_number, holdType, false);

          }
          this.ShowLoader = false;
      })
    }
};

IsFieldNotBlank (val:any) {
  if (val === undefined || val === '' || val === null) { return false; } else { return true; }
}

}
