import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  expiredDate: Date;
  constructor(private router:Router,
              private authService: AuthService,
              // private oidcSecurityService: OidcSecurityService,
              private cookieService: CookieService,) { }
  
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let aCookie = this.cookieService.get("TOPSUserKey");
    let cc = this.cookieService.get("loggedIn");
    if (cc=='1' && aCookie) {
      //  console.log('guardservice 1:' + aCookie);
        return true;
    }
    else{
      this.router.navigate(["/home"]);
      // return this.oidcSecurityService.getIsAuthorized().pipe(
      //   map((isAuthorized: boolean) => {
          // if (isAuthorized) {
          //   //this.getSessionData();
          //   this.expiredDate = new Date();
          //   this.expiredDate.setDate(
          //     this.expiredDate.getDate() + environment.expiredays
          //   );
          //   this.cookieService.set(
          //     "AuthCookie",
          //     JSON.stringify({
          //       bearer: this.oidcSecurityService.getToken(),
          //       client_id: 'eModal'
          //     }),
          //     this.expiredDate,
          //     "/",
          //     environment.domainname,
          //     false,
          //     "Lax"
          //   );
          //   this.cookieService.set(
          //     "cc",
          //     "cc",
          //     this.expiredDate,
          //     "/",
          //     environment.domainname,
          //     false,
          //     "Lax"
          //   );

          //   let eModal4Cookie = this.cookieService.get("LegacyCookie");
          //   this.cookieService.set(
          //     ".eModal4",
          //     eModal4Cookie,
          //     this.expiredDate,
          //     "/",
          //     environment.domainname,
          //     false
          //   );

          //   return true;
          // }

          //console.log("auth-guard.service.ts");
          //alert("auth-guard.service.ts");
          // window.location.href = environment.emodalLoginUrl;
          // this.router.navigate([environment.emodalLoginUrl]);
          // this.router.navigate(["/login"]);
          return false;
      //   })
      // );
    }

  }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): boolean | UrlTree {
  //      let url: string = state.url;
 
  //          return this.checkLogin(url);
  //      }
 
  //      checkLogin(url: string): true | UrlTree {
  //         console.log("Url: " + url)
  //         let val: string = localStorage.getItem('loggedIn');
 
  //         if(val != null && val == "true"){
  //            if(url == "/login")
  //               this.router.parseUrl('/expenses');
  //            else 
  //               return true;
  //         } else {
  //            return this.router.parseUrl('/login');
  //         }
  //      }
}
