<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong></strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1 noPrint"></div>
    <div *ngIf="Messages.length > 0">
    <div class="row" *ngFor="let Msg of StatementInvoice.generalMessages">
        <br />
        <div class="col-md-8 col-md-offset-2">
            <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
            </div>
        </div>
    </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-10 col-sm-10">
                <div class="text-center img-responsive" *ngIf="StatementInvoice.TerminalLogo != null">
                    <h1>
                        <div style="margin-top: -41px; text-align: center;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                        <!-- <img src="data:image/png;base64,assets/Images/Primary_Logo_Positive.png" /> -->
                    </h1>
                </div>
                <div class="text-center text-primary" id="TerminalLoc">
                    <h2>
                        {{StatementInvoice.TerminalName}}
                    </h2>
                </div>
               
                <div class="row">
                    <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Statement Number :</label></div>
                    <div class="col-md-9 col-sm-9 text-left text-danger disp-Inline"><label class="text-primary">{{StatementFilters.StatNum}}</label></div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-sm-3 text-right disp-Inline"><label>Statement Dates :</label></div>
                    <div class="col-md-9 col-sm-3 text-left disp-Inline">
                        <label class="text-primary">{{StatementFilters.FromDate| date: 'MM-dd-yyyy'}} - {{StatementFilters.ToDate| date: 'MM-dd-yyyy'}}</label>
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-left:0px">
                        <div class="panel panel-default" style="min-height:260px">
                            <div class="panel-heading">
                                <h4>
                                    Bill To: <label class="text-primary">
                                        {{StatementInvoice.Client}}
                                    </label>
                                </h4>
                            </div>
                            <div class="panel-body mypanel">
                                <div>
                                    Address:<br />
                                    <div>
                                        {{StatementInvoice.Client}} <br />
                                        {{StatementInvoice.ClientAddress1}} <br />
                                        {{StatementInvoice.ClientAddress2}} <br />
                                        {{StatementInvoice.ClientPostalInfo}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 text-left disp-Inline" style="padding-right:0px">
                      <div class="panel panel-default" style="min-height:260px">
                        <div *ngIf="!StatementInvoice.HasOverNightAddress">
                          <div class="panel-heading mypanel">
                            <h4>Remit To : <label class="text-primary">{{StatementInvoice.TerminalName}}</label></h4>
                          </div>
                          <div class="panel-body">
                            <div>
                              Address:
                              <br />
                              <div>
                                {{StatementInvoice.TerminalRemitName}} <br />
                                {{StatementInvoice.TerminalAddr}} <br />
                                {{StatementInvoice.TerminalCity}} <br />
                              </div>

                            </div>
                          </div>
                        </div>
                        <div *ngIf="StatementInvoice.HasOverNightAddress">
                          <div class="panel-heading mypanel">
                            <h4>Remit To : </h4>
                          </div>
                          <div class="panel-body-overnightaddress">
                            <label class="text-primary">
                              USPS Mailing Address
                            </label>
                            <div>
                              {{StatementInvoice.TerminalName}} <br />
                              {{StatementInvoice.TerminalAddr}} <br />
                              {{StatementInvoice.TerminalCity}} <br />
                            </div>
                            <br /><label class="text-primary">Overnight Mailing Address</label>
                            <div>
                              {{StatementInvoice.TerminalOverNightAddr1}} <br />
                              {{StatementInvoice.TerminalOverNightAddr2}} <br />
                              {{StatementInvoice.TerminalOverNightAddr3}} <br />
                              {{StatementInvoice.TerminalOverNightCity}} <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div class="row table-responsive myrow">
                    <table class="table table-bordered table-striped" data-st-table="StatementInvoice" data-st-safe-src="StatementInvoiceList" *ngIf="!StatementFilters.InvoiceAncil">
                        <thead>
                            <tr>
                                <th data-st-ratio="20">Invoice Number</th>
                                <th data-st-ratio="10">Container</th>
                                <th data-st-ratio="10">BOL</th>
                                <th data-st-ratio="20">Reference Number</th>
                                <th data-st-ratio="20">Vessel</th>
                                <th data-st-ratio="20">Voyage</th>
                                <th data-st-ratio="20">sz/tp</th>
                                <th data-st-ratio="20">FA Date</th>
                                <th data-st-ratio="20">Port LFD</th>
                                <th data-st-ratio="20">Line LFD</th>
                                <th data-st-ratio="20">PTD</th>
                                <th data-st-ratio="20">Gate Out</th>
                                <th data-st-ratio="20">t1 Days</th>
                                <th data-st-ratio="20">Tier1 Rate</th>
                                <th data-st-ratio="20">t2 Days</th>
                                <th data-st-ratio="20">Tier2 Rate</th>
                                <th data-st-ratio="20">t3 Days</th>
                                <th data-st-ratio="20">Tier3 Rate</th>
                                <th data-st-ratio="20">t4 Days</th>
                                <th data-st-ratio="20">Tier4 Rate</th>
                                <th data-st-ratio="20">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of StatementInvoice.StatementInvoiceList">
                                <td data-st-ratio="20">{{row.InvoiceNumber | uppercase}}</td>                                   
                                <td data-st-ratio="10">{{row.ContainerNumber}}</td>
                                <td class="trim-info" >{{row.BillLadingNumber}}</td>  <!--tooltip="{{row.BillLadingNumber}}"-->
                                <td data-st-ratio="10">{{row.ReferenceNumber}}</td>
                                <td data-st-ratio="10">{{row.VesselCode}}</td>
                                <td data-st-ratio="10">{{row.VoyageNumber}}</td>
                                <td data-st-ratio="10">{{row.SzTp}}</td>
                                <td data-st-ratio="10">{{row.DateTimeOfFirstAvailability | date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="10">{{row.PortLastFreeDate | date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="10">{{row.LineLastFreeDate| date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="10">{{row.Paid_Thru_DT| date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="10">{{row.PickupDate| date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="10">{{row.Day1}}</td>
                                <td data-st-ratio="10">{{row.myTotalCharge1| currency}}</td>
                                <td data-st-ratio="10">{{row.Day2}}</td>
                                <td data-st-ratio="10">{{row.myTotalCharge2| currency}}</td>
                                <td data-st-ratio="10">{{row.Day3}}</td>
                                <td data-st-ratio="10">{{row.myTotalCharge3| currency}}</td>
                                <td data-st-ratio="10">{{row.Day4}}</td>
                                <td data-st-ratio="10">{{row.myTotalCharge4| currency}}</td>
                                <td data-st-ratio="5">{{row.BillingAmount| currency}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.UniqueInvoiceId}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.ClientId}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.TerminalId}}</td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <div class="row table-responsive myrow">
                    <table id="tableHeader" class="table table-bordered table-striped" *ngIf="StatementFilters.InvoiceAncil">
                        <thead>
                            <tr>
                                <th data-st-ratio="10">Invoice Number</th>
                                <th data-st-ratio="10">Container</th>
                                <th data-st-ratio="10">BOL</th>
                                <th data-st-ratio="10">PO Number</th>
                                <th data-st-ratio="10">Customer</th>
                                <th data-st-ratio="10">Vessel/Voyage</th>
                                <th data-st-ratio="10">Hold Type</th>
                                <th data-st-ratio="10">Invoice Date</th>
                                <th data-st-ratio="5">Billing Amount</th>
                                <th data-st-ratio="10" *ngIf="false">Invoice ID</th>
                                <th data-st-ratio="10" *ngIf="false">Client ID</th>
                                <th data-st-ratio="10" *ngIf="false">Terminal ID</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of StatementInvoice.StatementInvoiceList">
                                <td data-st-ratio="14">{{row.InvoiceNumber | uppercase}}</td>
                                <td data-st-ratio="10">{{row.ContainerNumber}}</td>
                                <td class="trim-info" >{{row.BillLadingNumber}}</td>  <!--tooltip="{{row.BillLadingNumber}}"-->
                                <td data-st-ratio="10">{{row.PoNumber}}</td>
                                <td data-st-ratio="10">{{row.ClientName}}</td>
                                <td data-st-ratio="10">{{row.vesselvoyage}}</td>
                                <td data-st-ratio="10">{{row.tops_holdname}}</td>
                                <td data-st-ratio="10">{{row.InvoiceDate | date:'MM-dd-yyyy'}}</td>
                                <td data-st-ratio="5">{{row.BillingAmount| currency}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.UniqueInvoiceId}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.ClientId}}</td>
                                <td data-st-ratio="15" *ngIf="false">{{row.TerminalId}}</td>
                            </tr>
                        </tbody>

                    </table>

                    <br />
                </div>
                <!-- <div   #TABLE class="row col-md-12 col-sm-12" >

                 <div class="table table-bordered table-striped" style="overflow:auto;"  *ngIf="StatementFilters.InvoiceAncil ">                      

                    <div >
                           
                        <div>
                            <table  mat-table [dataSource]="dataSourceAncillary"  class="table table-striped"  >
                                
                                <ng-container matColumnDef="InvoiceNumber">
                                <th mat-header-cell *matHeaderCellDef 
                                    class="tableheadertextNoborder"> Invoice Number </th>
                                <td mat-cell *matCellDef="let element"
                                    class="tablebodytextNoborder">  {{element.InvoiceNumber | uppercase}} </td>
                                </ng-container>        
                                                        
                                <ng-container matColumnDef="ContainerNumber">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">Container</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.ContainerNumber}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="BillLadingNumber">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">BOL </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.BillLadingNumber}}
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="PoNumber">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">PO Number</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.PoNumber }}
                                    </td>
                                </ng-container>
                                
                                    <ng-container matColumnDef="ClientName">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">Customer</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.ClientName }}
                                    </td>
                                </ng-container>
                                    
                                <ng-container matColumnDef="vesselvoyage" >
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">Vessel/Voyage </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.vesselvoyage }}
                                    </td>
                                </ng-container>
                                                        
                                <ng-container matColumnDef="tops_holdname">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext">Hold Type</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.tops_holdname}}
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="InvoiceDate">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext"> Invoice Date</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.InvoiceDate| date:'MM-dd-yyyy'}} </td>
                                </ng-container>                                    
                                                           
                                <ng-container matColumnDef="BillingAmount">
                                    <th mat-header-cell *matHeaderCellDef 
                                        class="tableheadertext"> Billing Amount</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="tablebodytext"> {{element.BillingAmount| currency}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="Disputed">
                                    <th mat-header-cell *matHeaderCellDef >
                                        <mat-checkbox  class="tableheadertext">
                                        </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row" >
                                        <mat-checkbox  class="tablebodytext">
                                        </mat-checkbox>
                                        </td>
                                </ng-container>                                                           
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsA"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumnsA;"></tr>
                             </table>
                                
                                <div *ngIf="displayClientsCollectionA.length==0">
                                    <div class="no-records"> No Results found.</div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="table table-bordered table-striped"  style="overflow:auto;"  *ngIf="!StatementFilters.InvoiceAncil ">
                       
                        <div >
                         
                            <div>
                                <table   mat-table [dataSource]="dataSourceDemurage" class="table table-striped"                                     >
                                    
                                    <ng-container matColumnDef="InvoiceNumber">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertextNoborder"> Invoice Number </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytextNoborder">  {{element.InvoiceNumber | uppercase}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="ContainerNumber">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Container</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.ContainerNumber}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="BillLadingNumber">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">BOL </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.BillLadingNumber}}
                                        </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="ReferenceNumber">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Reference Number</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.ReferenceNumber }}
                                        </td>
                                    </ng-container>
                                        
                                    <ng-container matColumnDef="VesselCode" >
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Vessel </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.VesselCode }}
                                        </td>
                                    </ng-container>
                                                           
                                    <ng-container matColumnDef="VoyageNumber">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Voyage</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.VoyageNumber}}
                                        </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="SzTp">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> sz/tp</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.SzTp}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="DateTimeOfFirstAvailability">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> FA Date</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.DateTimeOfFirstAvailability| date: 'MM-dd-yyyy'}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="PortLastFreeDate">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> Port LFD</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.PortLastFreeDate| date: 'MM-dd-yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LineLastFreeDate">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Line LFD</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.LineLastFreeDate| date: 'MM-dd-yyyy' }}
                                        </td>
                                    </ng-container>
                                        
                                    <ng-container matColumnDef="Paid_Thru_DT" >
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">PTD </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.Paid_Thru_DT | date: 'MM-dd-yyyy'}}
                                        </td>
                                    </ng-container>
                                                           
                                    <ng-container matColumnDef="PickupDate">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext">Gate Out</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.PickupDate| date: 'MM-dd-yyyy'}}
                                        </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="Day1">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> t1 Days</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.Day1}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="myTotalCharge1">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> Tier1 Rate</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.myTotalCharge1}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="Day2">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> t2 Days</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.Day2}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="myTotalCharge2">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> Tier2 Rate</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.myTotalCharge2}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="Day3">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> t3 Days</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.Day3}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="myTotalCharge3">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> Tier3 Rate</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.myTotalCharge3}} </td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="Day4">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext"> t4 Days</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext"> {{element.Day4}} </td>
                                    </ng-container>                                    
                                   
                                    <ng-container matColumnDef="myTotalCharge4">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext" > Tier4 Rate</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext" > {{element.myTotalCharge4}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="BillingAmount">
                                        <th mat-header-cell *matHeaderCellDef 
                                            class="tableheadertext" > Total</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="tablebodytext" > {{element.BillingAmount}} </td>
                                    </ng-container>                                    
                                
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsD"></tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: displayedColumnsD;"></tr>
                                        </table>
                                      
                                          
                                 </div>

                                </div>
                </div>
                <div class="row">
                </div>
            </div> -->
            <div class="col-md-1 col-sm-1"></div>
        </div>
    </div>
    <br /><br />
</div>
