    <div class="modal" style="background-color: rgba(0, 0, 0, 0.637) !important;display: block;" role="dialog" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header custom-modal-header">
                    <button type="button" class="close" (click)="closeModal()">&times;</button>
                    <h4 class="modal-title text-center">Container Details {{Container.NBR}} </h4>
                </div>
                <div class="modal-body">
                    <div class="row" *ngIf="Container.OUT_LOC_ID != null && Container.OUT_LOC_ID != ''">
                        <div class="col-md-4 text-right"><label>Vessel : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.OUT_LOC_ID}}</div>
                    </div>
                    <div class="row" *ngIf="Container.OUT_VISIT_ID != null && Container.OUT_VISIT_ID != ''">
                        <div class="col-md-4 text-right"><label>Voyage : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.OUT_VISIT_ID}}</div>
                    </div>
                    <div class="row" *ngIf="Container.DISCHARGED != null && Container.DISCHARGED != ''">
                        <div class="col-md-4 text-right"><label>FA Date : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.DISCHARGED}}</div>
                    </div>
                    <div class="row" *ngIf="Container.EQSZ_ID != null">
                        <div class="col-md-4 text-right"><label>Size : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.EQSZ_ID}}</div>
                    </div>
                    <div class="row" *ngIf="Container.EQTP_ID != null && Container.EQTP_ID != ''">
                        <div class="col-md-4 text-right"><label>Type : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.EQTP_ID}}</div>
                    </div>
                    <div class="row" *ngIf="Container.EQHT_ID != null">
                        <div class="col-md-4 text-right"><label>Height : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.EQHT_ID}}</div>
                    </div>
                    <div class="row" *ngIf="Container.GROSS_WEIGHT != null && Container.GROSS_WEIGHT != ''">
                        <div class="col-md-4 text-right"><label>Gross Weight : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.GROSS_WEIGHT}}</div>
                    </div>
                    <div class="row" *ngIf="Container.CATEGORY != null && Container.CATEGORY != ''">
                        <div class="col-md-4 text-right"><label>Category : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.CATEGORY}}</div>
                    </div>
                    <div class="row" *ngIf="Container.STATUS != null && Container.STATUS != ''">
                        <div class="col-md-4 text-right"><label>Status : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.STATUS}}</div>
                    </div>
                    <div class="row" *ngIf="Container.L_OOG_INDICATOR != null && Container.L_OOG_INDICATOR != ''">
                        <div class="col-md-4 text-right"><label>Over Dim : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.L_OOG_INDICATOR}}</div>
                    </div>
                    <div class="row" *ngIf="Container.TEMP_UNITS != null && Container.TEMP_UNITS != ''">
                        <div class="col-md-4 text-right"><label>Temp : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.TEMP_UNITS}}</div>
                    </div>
                    <div class="row" *ngIf="Container.VENT_UNITS != null && Container.VENT_UNITS != ''">
                        <div class="col-md-4 text-right"><label>Vent : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.VENT_UNITS}}</div>
                    </div>
                    <div class="row" *ngIf="Container.HAZ_CLASS != null && Container.HAZ_CLASS != ''">
                        <div class="col-md-4 text-right"><label>Hazmat : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.HAZ_CLASS}}</div>
                    </div>
                    <div class="row" *ngIf="Container.POSITION != null && Container.POSITION != ''">
                        <div class="col-md-4 text-right"><label>Position : </label></div>
                        <div class="col-md-8 text-left text-primary">{{Container.POSITION}}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn-group btn-group-lg">
                        <button class="btn btn-primary btn-site btn-margin" (click)="closeModal()">
                            <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Close
                        </button>
                    </div> 
                </div>
            </div>
        </div>
    </div>