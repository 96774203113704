<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div >
    <!-- <div class="row"></div> -->
    <div class="row" *ngIf="this.Messages.length > 0">
        <div *ngFor="let Msg of this.Messages">
            <div
                [ngClass]="Msg.severity===1?'alert alert-info text-center':Msg.severity===3? 'alert alert-danger text-center':Msg.severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
            </div>
        </div>
    </div>
    <h1 style="padding-top: 5px;height: 20px;">Welcome to TOPS</h1>
    <div class="row">
        <div class="col-md-12 no-padding">
            <div class="col-md-8 col-sm-12 no-padding home-wall divIndexBackground">
                <h2 class="col-md-offset-4 col-sm-offset-4">
                    <img src="assets/Images/TOPS/TOPS Symbol - Orange.png">&nbsp;&nbsp;<strong style="color:#004165">How
                        can TOPS help you?</strong>
                </h2>
                <div class="col-md-6 bottom-img icon-content">
                    <i style="color:#ff6218" class="glyphicon glyphicon-tags"></i>
                    <label class="lbl">Order Services</label>
                    <p>Request terminal services to
                        seamlessly manage the flow of your
                        cargo through APM Terminals.</p>
                </div>
                <div class="col-md-6 bottom-img icon-content icon-contentright ">
                    <i style="color:#ff6218" class="glyphicon glyphicon-shopping-cart"></i>
                    <label class="lbl">Pay Online</label>
                    <p>Use our re-designed Terminal
                        On-Line Payment System (TOPS) to
                        efficiently pay for terminal services.</p>
                </div>
                <div class="col-md-6 bottom-img icon-content">
                    <i style="color:#ff6218" class="glyphicon glyphicon-calendar"></i>
                    <label class="lbl">Vessel Schedule</label>
                    <p>Monitor vessel arrival and
                        departure dates to keep current on
                        important supply chain events.</p>
                </div>
                <div class="col-md-6 bottom-img icon-content icon-contentright ">
                    <i style="color:#ff6218" class="glyphicon glyphicon-map-marker"></i>
                    <label class="lbl">Track and Trace</label>
                    <p>Easily track and manage your cargo
                        located at APM Terminals with
                        searches by container, bill of lading, or booking number.</p>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-12 no-padding row no-gutter">
                <div class="home-wall2">
                    <div class="form-group login-head">
                        <h3 class="text-center login-title log-text">Log in</h3>
                    </div>
                    <form class="form-signin" name="logInForm" novalidate autocomplete="off">
                        <div class="form-group" [ngClass]="{ 'has-error' : IsUserBlank }">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="glyphicon glyphicon-user"></i></div>
                                <input type="text" class="form-control" name="userName" [(ngModel)]="userName"
                                    placeholder="Username">
                            </div>
                            <span *ngIf="IsUserBlank" class="help-block">Please enter Username.</span>
                            <!--*ngif="IsUserBlank"-->
                            <a href="javascript:;" (click)="ForgotUserName()" class="forgotLinks">Forgot Username? </a>
                            
                        </div>
                        <div class="form-group" [ngClass]="{ 'has-error' : IsPasswordBlank }">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></div>
                                <input type="password" class="form-control" name="password" [(ngModel)]="password"
                                    placeholder="Password" required>
                            </div>
                            <span *ngIf="IsPasswordBlank" class="help-block">Please enter Password.</span>
                            <!--*ngif="IsPasswordBlank"-->
                            <a id="aforgotpwd" class="forgotLinks" (click)="ForgetPassword()" href="javascript:;">Forgot
                                Password? </a>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-site log-btn" id="login_btn" (click)="submit()"
                                value="Log in">Log in</button>
                        </div>
                        <div class="form-group">
                            <span style="font-size:20px"><span>No account yet? </span><a routerLink="/registration"
                                    style="text-decoration: underline">Register</a></span>
                        </div>
                    </form>
                    <img src="assets/Images/TOPS/TOPS - Custome Wave Image.png">
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top:-20px !important;" class="row">
        <!-- <div class="col-md-3 col-sm-3"></div> -->
        <div class="ImportantMessage" style="text-align: center;">
            <span style="text-align: center;">
                <h2 style="color: #ff6218;"><!--text-align: left; margin-left: 100px; margin-top:-10px;-->
                    Important Notices
                </h2>
            </span>
            <a href="http://www.apmterminals.com/en/operations/north-america/americas/important-notices"><strong>Click
                    here for Important Notices (last updated {{ this.StaticContent }})</strong></a><br />
            <!--<strong>Online Payment Options: Visa, Mastercard, AMEX, Discover & E-Check (up to $20,000)</strong><br/>-->
            <strong>Online Payment Options: Visa, Mastercard, AMEX, Discover & E-Check (up to $20,000) & Pay
                Cargo</strong><br />
            <strong>Please note: *** PayCargo payments made through TOPS will result in immediate payment
                release***<br />
                ***Any payment made directly to PayCargo (via PayCargo’s website) will be processed overnight; APMT does
                not release with a payment receipt nor confirmation.***</strong><br />
            <p>**Paper checks and payments over the phone are not accepted**</p>
        </div>
        <div class="col-md-3 col-sm-3"></div>
    </div>
</div>
<!-- <app-update-password *ngIf="ShowUpdate" ></app-update-password> -->
