<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid">
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Customer Statement Invoices</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1 noPrint"></div>
    <div class="row" *ngIf="Messages.length > 0" >
        <div *ngFor="let Msg of Messages">   
            <br />
            <div class="col-md-8 col-md-offset-2">
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>
    <!-- @*<div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>*@ -->
    <div class="row">
        <br /><br />
        <div class="col-md-2"></div>
        <div class="col-md-8">
                <form name="StatementInvoices" [formGroup]="StatementInvoices" (ngSubmit)="SearchInvoiceInfo(StatementInvoices.value)">            
                <!-- @*Type of Invoices*@ -->
                <div class="row ">
                    <div class="form-group col-md-10 ">
                        <div class="col-sm-4 col-sm-offset-3 col-xs-4 ">
                            <label class="btn btn-default btn-responsive col-md-12 text-left minPadding-top labelbtn">
                                <!-- @*active*@ -->
                                <input type="radio" name="InvoiceAncil" id="InvoiceAncil"
                              [(ngModel)]="StatementFilters.InvoiceAncil" formControlName="InvoiceAncil" (click)="RadioOptionSelect(true)" [value]="true">
                                 Ancillary Statement Invoices
                            </label>
                        </div>
                        <div class="col-sm-4 col-xs-4">
                            <label class="btn btn-default btn-responsive col-md-12 text-left minPadding-top labelbtn">
                                <input type="radio" name="InvoiceAncil" id="InvoiceAncil" 
                                [(ngModel)]="StatementFilters.InvoiceAncil" formControlName="InvoiceAncil" (click)="RadioOptionSelect(false)" [value]="false">
                                Demurrage Statement Invoices
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8 col-md-offset-2">
                    <div class="row divTop-border-silver col-md-offset-3"></div>
                </div>
                <br /><br />

                <!-- @*Statement Interval*@ -->
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-2 text-right minPadding-top col-md-offset-2"><span style="color:red">*</span><label for="cnStatement">From :</label></div>
                        <div class="col-md-3" >
                            <input  style="width: 120px;" [matDatepicker]="picker" formControlName="FromDate" id="FromDate" class="form-control   col-md-8" [(ngModel)]="StatementFilters.FromDate">
                            <mat-datepicker-toggle  class="dp" matSuffix [for]="picker">                                 
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                                 
                            <span class="help-block"  *ngIf="f.FromDate.errors && (f.FromDate.touched ||f.FromDate.dirty)">Please enter Start Date.</span>
                        </div>

                        <div class="col-md-2 text-right minPadding-top"><span style="color:red">*</span><label for="cnStatement">To :</label></div>
                        <div class="col-md-3" >
                            <input  style="width: 120px;"  [matDatepicker]="picker1" formControlName="ToDate" id="ToDate" class="form-control  col-md-8" [(ngModel)]="StatementFilters.ToDate">
                              <mat-datepicker-toggle  class="dp" matSuffix [for]="picker1"> 
                                   <span class="input-group-addon">
                                  <span class="glyphicon glyphicon-calendar"></span></span>
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>                              

                              <span class="help-block"  *ngIf="f.ToDate.errors && (f.ToDate.touched ||f.ToDate.dirty)">Please enter End Date.</span>
                              <span class="help-block"  *ngIf="StatementInvoices.hasError('notValid') && (f.ToDate.touched ||f.ToDate.dirty)">End date must be on or after start date.</span>
                        </div>
                    </div>
                </div>

               <!-- @*TERMINALS*@ -->
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-4 text-right minPadding-top"><span style="color:red">*</span><label>Terminal :</label></div>
                        <div class="col-md-8">
                          <select name="terminals" style="width: 98%;" class="form-control show-tick" [(ngModel)]="TerminalData.TerminalId" (change)="changeTerminals($event)" formControlName="TerminalId" required
                                  data-select-picker data-selectpicker-options="TerminalData.Terminals">
                            <option value="null" [selected]="true">Choose Terminal ...</option>
                            <option value="22" [selected]="true">Los Angeles</option>
                            <option *ngFor="let c of TerminalData.Terminals" value="{{c.TerminalId}}">{{c.CodeNameLocation}}</option>
                          </select>
                    <span class="help-block" style="color: #b94a48;"    *ngIf=" (submitted || f.TerminalId.touched ||f.TerminalId.dirty) && f.TerminalId.errors?.required "            
                    >Terminal is Required.</span>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-4"></div>
                        <div class="col-md-8 col-sm-8 text-right">
                            <!-- BACK BUTTON -->
                            <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']">
                                <span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Back
                            </button>
                            <!-- SEARCH BUTTON -->
                            <button  class="btn btn-primary btn-site btn-margin">
                                <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Search
                            </button>
                            <br />

                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>                
            </form>
        </div>

    </div>
    
    <!-- <div  #TABLE  > -->

        <div  style="padding: 10px 100px 10px 100px;"  *ngIf="InvoiceAncil && searchactive"  >    
            <div class="mat-elevation-z8" >                                 
        <table   mat-table [dataSource]="dataSourceAncillary"     >
           
                    <ng-container matColumnDef="InvoiceNumber">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext"> Invoice Number </th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> <a style="cursor: pointer;" (click)="InvImportPrintPreview(element)"> {{element.InvoiceNumber | uppercase}}</a> </td>
                    </ng-container>
                                        
                    <ng-container matColumnDef="ContainerNumber">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">Container</th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.ContainerNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="BillLadingNumber">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">BOL </th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.BillLadingNumber}}
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="PoNumber">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">PO Number</th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.PoNumber }}
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="ClientName">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">Customer</th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.ClientName }}
                    </td>
                    </ng-container>


                    <ng-container matColumnDef="vesselvoyage" >
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">Vessel/Voyage </th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.vesselvoyage }}
                    </td>
                    </ng-container>
                                        
                    <ng-container matColumnDef="tops_holdname">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext">Hold Type</th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.tops_holdname}}
                    </td>
                    </ng-container>
                    <ng-container matColumnDef="InvoiceDate">
                    <th mat-header-cell *matHeaderCellDef 
                        class="tableheadertext"> Invoice Date</th>
                    <td mat-cell *matCellDef="let element"
                        class="tablebodytext"> {{element.InvoiceDate| date:'MM-dd-yyyy'}} </td>
                    </ng-container>
                                        
                    <ng-container matColumnDef="BillingAmount">
                        <th mat-header-cell *matHeaderCellDef 
                            class="tableheadertext"> Billing Amount</th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.BillingAmount| currency}} </td>
                    </ng-container>                    
                    <ng-container matColumnDef="Disputed">
                        <th mat-header-cell *matHeaderCellDef class="tableheadertext">
                            Disputed
                            </th>
                            <td mat-cell *matCellDef="let row" class="_select" style="text-align:center;" >
                                <mat-checkbox  (click)="$event.stopPropagation()"  
                                                (change)="OnChange($event,row)"
                                                [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                    </ng-container>                   
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsA"></tr>
                    <tr mat-row  *matRowDef="let row; columns: displayedColumnsA;"></tr>
                    
                </table>
                <div *ngIf="displayClientsCollectionA == 0">
                    <div class="no-records"> No Results found.</div> 
                </div>
                <div  >
                    <div class="totalAmount"  > Total : {{ AncillaryTotalBilling | currency }}</div> 
                </div>                  
        </div>        
        </div>

        <div style="padding: 10px 50px 10px 50px;" >                  
          <div class="mat-elevation-z8" style="max-height: 500px;overflow: scroll;" #TABLE  *ngIf="!InvoiceAncil && searchactive">         
            <table mat-table id="tableResults" #table [dataSource]="dataSourceDemurage"  >                        
                        <ng-container matColumnDef="dem-InvoiceNumber">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Invoice Number</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> <a style="cursor: pointer;" (click)="InvImportPrintPreview(element)"> {{element.InvoiceNumber | uppercase}}</a> </td>
                        </ng-container>

                        <ng-container matColumnDef="dem-ContainerNumber">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Container</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.ContainerNumber}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="dem-BillLadingNumber">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">BOL </th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.BillLadingNumber}}
                            </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="ReferenceNumber">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Reference Number</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.ReferenceNumber }}
                            </td>
                        </ng-container>
                            
                        <ng-container matColumnDef="VesselCode" >
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Vessel</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.VesselCode }}
                            </td>
                        </ng-container>
                                               
                        <ng-container matColumnDef="VoyageNumber">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Voyage</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.VoyageNumber}}
                            </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="SzTp">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">sz/tp</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.SzTp}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="DateTimeOfFirstAvailability">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">FA Date</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.DateTimeOfFirstAvailability| date: 'MM-dd-yyyy'}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="PortLastFreeDate">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Port LFD</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.PortLastFreeDate| date: 'MM-dd-yyyy'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="LineLastFreeDate">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Line LFD</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.LineLastFreeDate| date: 'MM-dd-yyyy' }}
                            </td>
                        </ng-container>
                            
                        <ng-container matColumnDef="Paid_Thru_DT" >
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">PTD</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Paid_Thru_DT | date: 'MM-dd-yyyy'}}
                            </td>
                        </ng-container>
                                               
                        <ng-container matColumnDef="PickupDate">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Gate Out</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.PickupDate| date: 'MM-dd-yyyy'}}
                            </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="Day1">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">t1 Days</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Day1}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="myTotalCharge1">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Tier1 Rate</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.myTotalCharge1| currency}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="Day2">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">t2 Days</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Day2}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="myTotalCharge2">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Tier2 Rate</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.myTotalCharge2| currency}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="Day3">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">t3 Days</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Day3}} </td>
                        </ng-container>
                       
                        <ng-container matColumnDef="myTotalCharge3">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">Tier3 Rate</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.myTotalCharge3| currency}} </td>
                        </ng-container>                                   
                        <ng-container matColumnDef="Day4">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext">t4 Days</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Day4}} </td>
                        </ng-container>                        
                       
                        <ng-container matColumnDef="myTotalCharge4">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext" >Tier4 Rate</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext" > {{element.myTotalCharge4| currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dem-BillingAmount">
                            <th mat-header-cell *matHeaderCellDef 
                                class="tableheadertext" >Total</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext" > {{element.BillingAmount| currency}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="dem-Disputed">
                            <th mat-header-cell *matHeaderCellDef 
                            class="tableheadertext" >Disputed
                            </th>
                            <td mat-cell *matCellDef="let row" style="text-align:center;">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="OnChange($event,row) ? selection.toggle(row) : null" 
                                            [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>                     
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsD;sticky: true"></tr>
                        <tr mat-row  *matRowDef="let row; columns: displayedColumnsD;"></tr>   
                        <!-- <tr mat-footer-row class="sticky-footer" > Total :{{ DemurageTotalBilling | currency }}</tr>                    -->
                </table>
                <div *ngIf="displayClientsCollectionD==0">
                    <div class="no-records"> No Results found.</div> 
                </div>                
                <div class="dem-totalAmount" > Total : {{ DemurageTotalBilling | currency }}</div>                               
             </div>
                  
            </div>
    <!-- </div> -->
  
    <!-- CreateStatement BUTTON -->
    <div class="col-md-12 col-sm-8 text-center ng-star-inserted" *ngIf="(displayClientsCollectionD != 0 ||displayClientsCollectionA != 0) && searchactive===true">
        <br /> <br />
        <button class="btn btn-primary btn-site btn-margin" (click)="CreateStatement()" name="CreateStatement" id="StatImport">
            <span class="glyphicon glyphicon-edit" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Generate Statement
        </button>
    </div>
    <div class="row">
    </div>
    <br /><br />
    <div class="col-md-2"></div>
</div>
