import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TrackFilter,VesselDetails } from 'src/app/core/models/trackandtrace.model';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ValidationMethods } from 'src/app/common/utils/validationmethods';
@Component({
  selector: 'app-vessel-schedule-results',
  templateUrl: './vessel-schedule-results.component.html',
  styleUrls: ['./vessel-schedule-results.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class VesselScheduleResultsComponent implements OnInit { 
  dataSource: MatTableDataSource<VesselDetails>;
  ChilddataSource: MatTableDataSource<VesselDetails>;
  ShowLoader = false;
  @Input() VesselSchedulelist :VesselDetails[]=[];
  @Input() TrackFilter:TrackFilter;  
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  TerminalNameSelected:any;
  vesselScheduleCollection:[]=[];
  EIRdetails:[]=[];
  EIRReprintDetailCollection:[]=[];
  EIRReprintDetailInstructionCollection:[]=[];  
  // vesselScheduleDetailsCollection:VesselDetails[]=[];
  displayedColumns: string[] = ['parent-SHIP','parent-IN_VOYAGE_CALL','parent-OUT_VOYAGE_CALL','parent-ARRIVE','parent-DEPART','parent-FADATE','parent-CARGO_CUTOFF',
  'parent-REEFER_CUTOFF','parent-HAZARDOUS_CUTOFF','parent-START_REC_DATE','collapsepanelarrow'];
  displayedChildColumns:string[]=['SHIPPINGLINE','IN_VOYAGE_CALL','OUT_VOYAGE_CALL','FADATE','CARGO_CUTOFF',
  'REEFER_CUTOFF','HAZARDOUS_CUTOFF','START_REC_DATE'];
  constructor(private trackandtraceService: TrackandtraceService,
              private _liveAnnouncer: LiveAnnouncer,
              public validate: ValidationMethods) {    
    // this.TrackFilter = new TrackFilter();
   }

  ngOnInit(): void {
    if(this.VesselSchedulelist.length > 0){
      this.TrackFilter.terminals.forEach(item => { if(item.TerminalId == this.TrackFilter.TerminalId) { this.TerminalNameSelected = item.TerminalName }})
      this.dataSource = new MatTableDataSource(this.VesselSchedulelist);
      this.dataSource.sort = this.sort;
    }    
  }

  // sortData(sortState: Sort) {
  //   if (sortState.direction) {
  //     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  //   } else {
  //     this._liveAnnouncer.announce('Sorting cleared');
  //   }
  // }

  GetVesselScheduleDetail(obj:any){
    this.dataSource.data.forEach((row: any) => {
      if(!(obj.SHIP_ID == row.SHIP_ID && obj.IN_VOYAGE_CALL == row.IN_VOYAGE_CALL && obj.OUT_VOYAGE_CALL == row.OUT_VOYAGE_CALL ))
        row.expandedDetail = 0;
    });
    if(obj.expandedDetail == 1){
    // this.ChilddataSource= new MatTableDataSource();
    this.TrackFilter.Ship = obj.SHIP;
    this.TrackFilter.inVoyageCall = obj.IN_VOYAGE_CALL;
    this.TrackFilter.outVoyageCall = obj.OUT_VOYAGE_CALL;
    this.ShowLoader = true;
    this.trackandtraceService.GetVesselScheduleDetails(this.TrackFilter).subscribe((result)=> {
      if(result.VesselScheduleDetails != null) {
        this.ChilddataSource = new MatTableDataSource(result.VesselScheduleDetails);
        this.ChilddataSource.sort = this.sort;
        this.ShowLoader = false;
      }
      else {
        this.ChilddataSource = new MatTableDataSource();
      }
    });
   }
  }

  
  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'parent-SHIP': return compare(a.SHIP, b.SHIP, isAsc);
        case 'parent-IN_VOYAGE_CALL': return compare(a.IN_VOYAGE_CALL, b.IN_VOYAGE_CALL, isAsc);
        case 'parent-ARRIVE': return compare(a.arrive, b.arrive, isAsc);
        case 'parent-OUT_VOYAGE_CALL': return compare(a.OUT_VOYAGE_CALL, b.OUT_VOYAGE_CALL, isAsc);
        case 'parent-DEPART': return compare(a.depart || a.DEPART, b.depart || b.DEPART, isAsc);
        case 'parent-FADATE': return compare(a.fadate || a.FADATE, b.fadate || b.FADATE, isAsc);       
        case 'parent-CARGO_CUTOFF': return compare(a.cargo_cutoff || a.CARGO_CUTOFF, b.cargo_cutoff || b.CARGO_CUTOFF, isAsc);    
        case 'parent-REEFER_CUTOFF': return compare(a.reefer_cutoff || a.REEFER_CUTOFF, b.reefer_cutoff || b.REEFER_CUTOFF , isAsc);
        case 'parent-HAZARDOUS_CUTOFF': return compare(a.hazardous_cutoff || a.HAZARDOUS_CUTOFF, b.hazardous_cutoff || b.HAZARDOUS_CUTOFF, isAsc);
        case 'parent-START_REC_DATE': return compare(a.start_rec_date || a.START_REC_DATE, b.start_rec_date || b.START_REC_DATE, isAsc); 
        default: return 0;
      }
    });
   }

   ChildsortData(sort: Sort) {
    const data = this.ChilddataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.ChilddataSource.data = data;
      return;
    }
    this.ChilddataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'SHIPPINGLINE': return compare(a.SHIPPINGLINE, b.SHIPPINGLINE, isAsc);
        case 'IN_VOYAGE_CALL': return compare(a.IN_VOYAGE_CALL, b.IN_VOYAGE_CALL, isAsc);
        case 'OUT_VOYAGE_CALL': return compare(a.OUT_VOYAGE_CALL, b.OUT_VOYAGE_CALL, isAsc);
        case 'FADATE': return compare(a.fadate || a.FADATE, b.fadate || b.FADATE, isAsc);       
        case 'CARGO_CUTOFF': return compare(a.cargo_cutoff || a.CARGO_CUTOFF, b.cargo_cutoff || b.CARGO_CUTOFF, isAsc);    
        case 'REEFER_CUTOFF': return compare(a.reefer_cutoff || a.REEFER_CUTOFF, b.reefer_cutoff || b.REEFER_CUTOFF , isAsc);
        case 'HAZARDOUS_CUTOFF': return compare(a.hazardous_cutoff || a.HAZARDOUS_CUTOFF, b.hazardous_cutoff || b.HAZARDOUS_CUTOFF, isAsc);
        case 'START_REC_DATE': return compare(a.start_rec_date || a.START_REC_DATE, b.start_rec_date || b.START_REC_DATE, isAsc); 
        default: return 0;
      }
    });
   }

  
  expandchild(row: any): any { }
  
  expanded(element:any){}

  close(){}

  gobacktoTT(){}

  EIRDetails(obj:any){
    // this.EIRReprintDetailInstructionCollection=[];
    this.TrackFilter.Gkey = obj.GKEY;
    this.TrackFilter.GateContainer = obj.CONTAINER;
    this.TrackFilter.GateTruckEntered = obj.TRUCKENTERED;
    this.trackandtraceService.GetEIRReprintDetail(this.TrackFilter).subscribe((result) => {
      this.EIRReprintDetailCollection = result.GateTransactionData.EIRReprintDetail;
      
      // this.EIRReprintDetailInstructionCollection = result.GateTransactionData.EIRReprintInstructions;
      // this.TrackFilter.BookingNum = this.EIRReprintDetailCollection[0].EQO_NBR ? this.EIRReprintDetailCollection[0].EQO_NBR : this.EIRReprintDetailCollection[0].eqo_nbr;
      // this.TrackFilter.GateContainerFromEIR = this.EIRReprintDetailCollection[0].CTR_NBR ? this.EIRReprintDetailCollection[0].CTR_NBR : this.EIRReprintDetailCollection[0].ctr_nbr;
      this.trackandtraceService.GetBookingInformation(this.TrackFilter).subscribe((result) => {

        let PaidResultCollection = result.PaidResult.BookingInformation;
      });
     

  });
}

}

function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}