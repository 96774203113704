<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid">
    <div class="row" *ngIf="this.Messages.length > 0">
        <div *ngFor="let Msg in this.Messages">
            <div [ngClass]="Msg.severity===1?'alert alert-info text-center':Msg.severity===3? 'alert alert-danger text-center':Msg.severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
            </div>
        </div>
    </div>
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Active Registrations</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div><br />   
     <div class="row col-md-12 col-sm-12" style="padding: 10px 100px 0px 100px;">
        <!-- <div class="col-md-8 col-md-offset-2">           -->
    <!-- <div style="text-align:center"> -->
        <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->      
                <!-- Position Column -->
                <ng-container matColumnDef="ClientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Customer Name </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.ClientName}} </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="FullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Customer AKA </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.FullName}} </td>
                </ng-container>
            
                <!-- FirstName Column -->
                <ng-container matColumnDef="First_Name_DS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">Contact First Name </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.First_Name_DS}} </td>
                </ng-container>
            
                <!-- LastName Column -->
                <ng-container matColumnDef="Last_Name_DS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">Contact Last Name </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.Last_Name_DS}} </td>
                </ng-container>

                <!-- Title Column -->
                <ng-container matColumnDef="Title_DS">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Contact Title </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.Title_DS}} </td>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="Alt_State_NM">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> State </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.Alt_State_NM}} </td>
                </ng-container>

                    <!-- Country Column -->
                <ng-container matColumnDef="CountryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Country </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.CountryName}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [hidden]="!(displayClientsCollection.length==0)"
            [ngStyle]="{display: displayClientsCollection.length > 0 ? 'block' : 'none'}" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons style=" border: 1px solid #e0e0e0;" aria-label="Select page">
            </mat-paginator>
            <div *ngIf="this.displayClientsCollection.length == 0" class="no-records"> No Results found.</div>  
      
</div>
</div>
    
    <br />
    <div class="row col-md-12 col-sm-12">
        <br />
        <div class="col-md-5"></div>
        <div class="col-md-5 col-sm-6">
            <!-- BACK BUTTON -->
            <div class="col-md-4 no-padding" style="margin-bottom:10px;">
                <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']" ><span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Back</button>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>