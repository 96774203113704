<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12 grid-padding">
    <div data-st-table="VesselSchedulelist" data-st-safe-src="vesselscheduledata.VesselSchedule">
    <div class="row col-md-12 col-sm-12 no-padding">
    <div class="col-md-7 col-sm-7"><h3 class="text-left lbltitle"><strong style="color:#FF6218">Vessel Schedule for  : {{TerminalNameSelected}}</strong></h3></div>
    <div class="row col-md-12 col-sm-12" style="padding-bottom: 20px;">
      <!-- <div class="mat-elevation-z8"> -->
        <table mat-table multiTemplateDataRows style="width: 1480px;" (matSortChange)="sortData($event)" [dataSource]="dataSource"  matSort>
            <!-- SHIP Column -->
            <ng-container matColumnDef="parent-SHIP">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Vessel Name </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.SHIP | uppercase }} </td>
             </ng-container>
 
             <!-- IN_VOYAGE_CALL Column -->
             <ng-container matColumnDef="parent-IN_VOYAGE_CALL">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Inbound Voyage </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.IN_VOYAGE_CALL}} </td>
             </ng-container>
 
             <!-- OUT_VOYAGE_CALL Column -->
             <ng-container matColumnDef="parent-OUT_VOYAGE_CALL">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Outbound Voyage </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.OUT_VOYAGE_CALL}} </td>
             </ng-container>    
 
             <!-- >Arrival Date Column -->
             <ng-container matColumnDef="parent-ARRIVE">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Arrival Date </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.ARRIVE || element.arrive }} </td>
             </ng-container>
 
             <!-- DEPART Column -->
             <ng-container matColumnDef="parent-DEPART">
             <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Departure Date </th>
             <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.DEPART || element.depart }} </td>
             </ng-container>

             <!-- FADATE Column -->
             <ng-container matColumnDef="parent-FADATE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> First Available Date </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{(element.FADATE || element.fadate || element.time_first_availabiltiy) ==" " ? 'N/A' : (element.FADATE || element.fadate || element.time_first_availabiltiy) | date:'MM-dd-yyyy'}} </td>
                </ng-container>
                <!-- CARGO_CUTOFF Column -->
             <ng-container matColumnDef="parent-CARGO_CUTOFF">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Cargo Cutoff </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{(element.CARGO_CUTOFF || element.cargo_cutoff) ==" " ? 'N/A' : (element.CARGO_CUTOFF || element.cargo_cutoff) }} </td>
                </ng-container>
                <!-- REEFER_CUTOFF Column -->
             <ng-container matColumnDef="parent-REEFER_CUTOFF">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Reefer Cutoff </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.REEFER_CUTOFF || element.reefer_cutoff) == " " ? 'N/A' : (element.REEFER_CUTOFF || element.reefer_cutoff) }} </td>
                </ng-container>
                <!-- HAZARDOUS_CUTOFF Column -->
             <ng-container matColumnDef="parent-HAZARDOUS_CUTOFF">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Hazardous Cutoff </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.HAZARDOUS_CUTOFF || element.hazardous_cutoff) == " " ? 'N/A' : (element.HAZARDOUS_CUTOFF || element.hazardous_cutoff) }} </td>
                </ng-container>
                <!-- START_REC_DATE Column -->
             <ng-container matColumnDef="parent-START_REC_DATE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Start Receive </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.START_REC_DATE || element.start_rec_date) == " " ? 'N/A' : (element.START_REC_DATE || element.start_rec_date) }} </td>
                </ng-container>             

            <ng-container matColumnDef="collapsepanelarrow" sticky>
                <th mat-header-cell *matHeaderCellDef class="tableheadertext" mat-sort-header>Line Cutoff Times</th>
                <td mat-cell *matCellDef="let element" style="text-align:center" class="tablebodytext"> 
                    <span style="color:#428bca;cursor: pointer;margin-top:2px;">
                   <a   [class.example-expanded-row]="element.expandedDetail == 1" (click)="element.expandedDetail = element.expandedDetail === 1 ? 0 : 1; expanded(element);">    {{ element.expandedDetail == 1 ? 'Hide' : 'Show' }} Details </a> </span>
                    <mat-icon class="cell-icon" [class.example-expanded-row]="element.expandedDetail == 1" #expandedIcon style="color:#428bca;cursor: pointer;"
                        (click)="element.expandedDetail = element.expandedDetail === 1 ? 0 : 1; expanded(element);">
                    <span>  {{element.expandedDetail == 1 ?'keyboard_arrow_down':'keyboard_arrow_up'}} </span> </mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="14" class="padding0">
                    <div class="details-view" *ngIf="element.expandedDetail == 1" style="padding: 10px 0px 10px 0px;"
                        [@detailExpand]="element.expandedDetail == 1 ? 'expanded' : 'collapsed'">
                      
                        <!-- <div data-st-table="vesselScheduleDetailsCollection" data-st-safe-src="this.vesselscheduleDetailsdata.VesselScheduleDetails"> -->
                            <div class="mat-elevation-z8">
                            <table mat-table multiTemplateDataRows [dataSource]="ChilddataSource" (matSortChange)="ChildsortData($event)"   matSort>
                                <!-- SHIP Column -->
                                <ng-container matColumnDef="SHIPPINGLINE">
                                 <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Line </th>
                                 <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.SHIPPINGLINE }} </td>
                                 </ng-container>
                     
                                 <!-- IN_VOYAGE_CALL Column -->
                                 <ng-container matColumnDef="IN_VOYAGE_CALL">
                                 <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Inbound Voyage </th>
                                 <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.IN_VOYAGE_CALL}} </td>
                                 </ng-container>
                     
                                 <!-- OUT_VOYAGE_CALL Column -->
                                 <ng-container matColumnDef="OUT_VOYAGE_CALL">
                                 <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Outbound Voyage </th>
                                 <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.OUT_VOYAGE_CALL}} </td>
                                 </ng-container>    
                     
                                 <!-- >FADATE Column -->
                                 <ng-container matColumnDef="FADATE">
                                 <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> First Available Date </th>
                                 <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.FADATE ==" " ? 'N/A' : element.FADATE | date:'MM-dd-yyyy'}} </td>
                                 </ng-container> 

                                    <!-- CARGO_CUTOFF Column -->
                                 <ng-container matColumnDef="CARGO_CUTOFF">
                                    <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Cargo Cutoff </th>
                                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{(element.CARGO_CUTOFF ) ==" " ? 'N/A' : element.CARGO_CUTOFF }} </td>
                                    </ng-container>
                                    <!-- REEFER_CUTOFF Column -->
                                 <ng-container matColumnDef="REEFER_CUTOFF">
                                    <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Reefer Cutoff </th>
                                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.REEFER_CUTOFF) == " " ? 'N/A' : (element.REEFER_CUTOFF) }} </td>
                                    </ng-container>
                                    <!-- HAZARDOUS_CUTOFF Column -->
                                 <ng-container matColumnDef="HAZARDOUS_CUTOFF">
                                    <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Hazardous Cutoff </th>
                                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.HAZARDOUS_CUTOFF) == " " ? 'N/A' : (element.HAZARDOUS_CUTOFF) }} </td>
                                    </ng-container>
                                    <!-- START_REC_DATE Column -->
                                 <ng-container matColumnDef="START_REC_DATE">
                                    <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Start/Receive Date </th>
                                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ (element.START_REC_DATE) == " " ? 'N/A' : (element.START_REC_DATE) }} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedChildColumns;sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedChildColumns;"></tr>
                            </table>   
                     </div>                    
                    
                   </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let even = even;" (click)="GetVesselScheduleDetail(row)"
               ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="details-row"></tr>

             <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        </table>
        <div *ngIf="this.VesselSchedulelist.length == 0" class="no-records"> No Results found.</div>
      <!-- </div>  -->
    </div>
</div>
</div>
</div>
