<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="row divBorder" [formGroup]="shoppingCartPayment">
    <!-- <form name="shoppingCartPayment" [formGroup]="shoppingCartPayment" novalidate> -->
    <div class="col-md-12" >
        <br />
        <div class="row">
            <div class="form-group col-md-6 text-primary">
                <label><strong>Billing Address On Card</strong></label>
            </div>
        </div>
        <div class="row">
            <!-- First Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.firstName.errors && (f.firstName.touched || f.firstName.dirty) }">
                <span class="required-field">*</span><label>First Name</label>
                <input type="text" formControlName="firstName"  name="firstName" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.FirstName" maxlength="15" required>
                <span class="required-field" *ngIf="f.firstName.invalid && !f.firstName.pristine">
                    <span *ngIf="f.firstName.errors?.required" class="help-block">Please enter the first name on the card.</span>
                </span>
            </div>
            <!-- Last Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.lastName.invalid && (f.lastName.touched || f.lastName.dirty) }">
                <span class="required-field">*</span><label>Last Name</label>
                <input type="text" formControlName="lastName" name="lastName" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.LastName" maxlength="25" required>
                <span class="required-field" *ngIf="f.lastName.invalid && !f.lastName.pristine">
                    <span *ngIf="f.lastName.errors?.required" class="help-block">Please enter the last name on the card.</span>
                </span>
            </div>
        </div>
        <div class="row">
            <!-- Address1 -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.address1.invalid && (f.address1.touched || f.address1.dirty)}">
                <span class="required-field">*</span><label>Address 1</label>
                <input type="text" formControlName="address1" name="address1" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.Address1" maxlength="100" required>
                <span class="required-field" *ngIf="f.address1.invalid && !f.address1.pristine">
                    <span *ngIf="f.address1.errors?.required" class="help-block">Please enter an address for the card.</span>
                </span>
            </div>
            <!-- Address2 -->
            <div class="form-group col-md-6">
                <label>Address 2</label>
                <input type="text" name="address2" formControlName="address2" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.Address2" maxlength="100">                
            </div>
        </div>
        <div class="row">
            <!-- City/Location -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.cityLocation.invalid && (f.cityLocation.touched ||f.cityLocation.dirty)}">
                <span class="required-field">*</span><label>City/Location</label>
                <input type="text" formControlName="cityLocation" name="cityLocation" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.City" maxlength="100" required>
                <span class="required-field" *ngIf="f.cityLocation.invalid && !f.cityLocation.pristine">
                    <span *ngIf="f.cityLocation.errors?.required" class="help-block">Please enter a city for the card.</span>
                </span>
            </div>
            <!-- Zip -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.zip.invalid && (f.zip.touched || f.zip.dirty)}">
                <span class="required-field">*</span><label>Zip</label>
                <input type="text" formControlName="zip" name="zip" class="form-control" [(ngModel)]="SCartPayment.BillingInfo.PostalCode" maxlength="9" required>
                <span class="required-field" *ngIf="f.zip.invalid && !f.zip.pristine">
                    <span *ngIf="f.zip.errors?.required" class="help-block">Please enter your zip code.</span>
                </span>
            </div>
        </div>
        <div class="row">           
            <!-- Countries -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.countries.errors && (f.countries.touched || f.countries.dirty) }">
                <span class="required-field">*</span><label>Country</label>
                <select name="countries" formControlName="countries" class="form-control show-tick" data-select-picker data-selectpicker-options="Countries" [(ngModel)]="SCartPayment.BillingInfo.Country" (ngModelChange)="GetCoutryStates()" required>
                    <option value="">Choose Country...</option>
                    <option *ngFor="let option of Countries" value="{{option.CountryCode}}">{{option.CountryName}}</option>
                </select>
                <span class="required-field" *ngIf="f.countries.invalid && !f.countries.pristine">
                    <span *ngIf="f.countries.errors?.required" class="help-block">Please enter a country for the card.</span>
                </span>
            </div>
            <!-- States -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.states.errors && (f.states.touched || f.states.dirty) }">
                <span class="required-field">*</span><label>State</label>
                <select name="states" formControlName="states" class="form-control show-tick" data-select-picker data-selectpicker-options="States" [(ngModel)]="SCartPayment.BillingInfo.State" required>
                    <option value="">Choose State...</option>
                    <option *ngFor="let option of States" value="{{option.StateShort}}">{{option.StateName}}</option>
                </select>
                <span class="required-field" *ngIf="f.states.invalid && !f.states.pristine">
                    <span *ngIf="f.states.errors?.required" class="help-block">Please enter a state for the card.</span>
                </span>
            </div>
        </div>
    </div>
   
    <div class="col-md-12" style="padding-left:30px; padding-right:30px">
        <hr />
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="form-group col-md-6 text-primary">
                <label><strong>Card Information</strong></label>
            </div>
        </div>
        <div class="row">
            <!-- Card Type -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.cardType.errors && (f.cardType.touched || f.cardType.dirty) }">
                <span class="required-field">*</span><label>Card Type</label>
                <select name="cardType" formControlName="cardType" class="form-control show-tick" data-select-picker data-selectpicker-options="CardTypes" [(ngModel)]="SCartPayment.CreditCardInfo.CardType" required>
                    <option value="">Choose Card Type...</option>
                    <option *ngFor="let option of CardTypes" value="{{option.CardTypeId}}">{{option.CardTypeName}}</option>
                </select>
                <span class="required-field" *ngIf="f.cardType.invalid && !f.cardType.pristine">
                    <span *ngIf="f.cardType.errors?.required" class="help-block">Please select card type.</span>
                </span>
            </div>
            <!-- Card Number -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.cNumber.errors && (f.cNumber.touched || f.cNumber.dirty) }">
                <span class="required-field">*</span><label>Card Number</label>
                <input type="text" formControlName="cNumber" name="cNumber" class="form-control" [(ngModel)]="SCartPayment.CreditCardInfo.AccountNumber"  maxlength="16" restrict-input="^[0-9-]*$" pattern="^(\d{15}|\d{16})$" required>
                <span class="required-field" *ngIf="f.cNumber.invalid && !f.cNumber.pristine">
                    <span *ngIf="f.cNumber.errors?.required" class="help-block">Card Number is Required.</span>
                    <span *ngIf="f.cNumber.invalid && !f.cNumber.pristine && !f.cNumber.errors?.required" class="help-block">Please enter a valid card number.</span>
                </span>
            </div>
        </div>
        <div>
            <!-- Card Expiry Date -->
            <div class="form-group col-md-4" [ngClass]="{ 'has-error' : f.cardExpMonth.errors && (f.cardExpMonth.touched || f.cardExpMonth.dirty) }">
                <span class="required-field">*</span><label>Card Expiry Date</label>
                <select name="cardExpMonth" formControlName="cardExpMonth" class="form-control show-tick" data-select-picker data-selectpicker-options="SCartPayment.Months" [(ngModel)]="SCartPayment.CreditCardInfo.ExpirationMonth" required>
                    <option value="">Choose Month...</option>
                    <option *ngFor="let option of SCartPayment.Months" value="{{option.MonthValue}}">{{option.MonthText}}</option>
                </select>
                <span class="required-field" *ngIf="f.cardExpMonth.invalid && !f.cardExpMonth.pristine">
                    <span *ngIf="f.cardExpMonth.errors?.required" class="help-block">Month is required.</span>
                </span>
            </div>
            <div class="form-group col-md-3" [ngClass]="{ 'has-error' : f.cardExpYear.errors && (f.cardExpYear.touched || f.cardExpYear.dirty) }" style="padding-top:27px;padding-left:0px">
                <select name="cardExpYear" formControlName="cardExpYear" class="form-control show-tick" data-select-picker data-selectpicker-options="SCartPayment.Years" [(ngModel)]="SCartPayment.CreditCardInfo.ExpirationYear" required>
                    <option value="">Choose Year...</option>
                    <option *ngFor="let option of SCartPayment.Years" value="{{option.YearValue}}">{{option.YearText}}</option>
                </select>
                <span class="required-field" *ngIf="f.cardExpYear.invalid && !f.cardExpYear.pristine">
                    <span *ngIf="f.cardExpYear.errors?.required" class="help-block">Year is required.</span>
                </span>
            </div>
            <div class="form-group col-md-2" [ngClass]="{ 'has-error' : f.cvv.errors && (f.cvv.touched || f.cvv.dirty) }">
                <span class="required-field">*</span><label>CVV</label>
                <input type="text" name="cvv" formControlName="cvv" class="form-control" [(ngModel)]="SCartPayment.CreditCardInfo.VerificationNumber"  maxlength="5" restrict-input="^[0-9-]*$" required>
                <span class="required-field" *ngIf="f.cvv.invalid && !f.cvv.pristine">
                    <span *ngIf="f.cvv.errors?.required" class="help-block">CVV is Required.</span>
                </span>
            </div>
            <div class="form-group col-md-3" style="padding-top:35px;padding-left:0px">
                <a data-popover-template="'/src/CVVHelp.html'" data-popover-trigger="mouseenter" data-popover-title="CVV Help!">What's this?</a>
            </div>
        </div>
    </div>
<!-- </form> -->
    <div class="col-md-4 img-responsive" style="padding-top:40px;padding-left:0px">
        <img src="/assets/Images/accepted_creditcards.png" />
        <br /><br />
    </div>
</div>



