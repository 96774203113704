import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TerminalInfo } from 'src/app/core/models/Notification.model';
import { ILienChild,ContainerHoldCharges, DemurrageHoldCharges, HoldCharges, LienDemurrageCharges } from '../../../models/ContainerHoldCharges';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { States, Countries, UserContactInfo, Clients } from 'src/app/shared/interfaces/login.interface';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";
import swal from 'sweetalert2';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog } from '@angular/material/dialog';
import { ShowContainerinfoComponent } from './show-containerinfo/show-containerinfo.component';
@Component({
  selector: 'app-importchargeresults',
  templateUrl: './importchargeresults.component.html',
  styleUrls: ['./importchargeresults.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ImportChargeResultsComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatSort, {static: false}) Ancsort: MatSort;
  expandedElement: ILienChild | null;
  Messages: ResponseMessage[] = [];
  Errors: any[];
  ImportChargeSearchData: TerminalInfo;
  ContainerHoldCharges: ContainerHoldCharges;
  panelOpenState = false;
  ShowLoader = false;
  selectedDmgCollection:DemurrageHoldCharges[]=[];
  selectedAncCollection:HoldCharges[]=[];
  DemurrageHoldCharges: DemurrageHoldCharges;
  DemurrageHoldChargesList: DemurrageHoldCharges[] = [];
  Container: DemurrageHoldCharges;
  HoldChargesList: HoldCharges[] = [];
  LienDemurrageHoldCharges: LienDemurrageCharges;
  LienDemurrageHoldChargesList: LienDemurrageCharges[] = [];
  selectedLien:LienDemurrageCharges;
  dataSourceAncillary: MatTableDataSource<HoldCharges>;
  dataSourceDemurrage: MatTableDataSource<DemurrageHoldCharges>;
  dataSourceLien: any;
  selection = new SelectionModel<HoldCharges>(true, []);
  selectionD = new SelectionModel<DemurrageHoldCharges>(true, []);
  displayedDmgColumns: string[] = ['NBR', 'BL_NBR', 'FREIGHT', 'CUSTOMS', 'HOLDS', 'firstAvailableDt', 'freeTimeStartDt', 'PORT_LFD', 'LINE_LFD', 'PREVIOUSLYGUARUNTEEDPTD', 'DatesDemurrageCharged', 'DEMURRAGE', 'LINE_ID', 'allowedFreeDays','detentionDemurrageRule', 'InvoiceDate','InvoiceDueDate'];
  displayedColumns1_1: string[] = ['select', 'NBR', 'BL_NBR', 'FREIGHT', 'CUSTOMS', 'HOLDS', 'firstAvailableDt', 'freeTimeStartDt', 'PORT_LFD', 'LINE_LFD', 'PREVIOUSLYGUARUNTEEDPTD', 'DatesDemurrageCharged', 'DEMURRAGE', 'LINE_ID', 'allowedFreeDays','detentionDemurrageRule', 'InvoiceDate', 'InvoiceDueDate'];

  displayedAncColumns: string[] = ['container_number', 'tops_holdname', 'hold_amount', 'hold_date', 'released', 'released_date', 'ClientName'];
  displayedColumns2_1: string[] = ['select', 'container_number', 'tops_holdname', 'hold_amount', 'hold_date', 'released', 'released_date', 'ClientName'];

  displayedLienColumns: string[] = ['ContainerNumber', 'BillLadingNumber', 'InvoiceNumber', 'InvoiceDate', 'InvoiceDueDate', 'firstAvailableDt', 'freeTimeStartDt', 'PortLastFreeDate', 'LineLastFreeDate', 'PickUpDate', 'DatesDemurrageCharged', 'demurragetotal', 'ShippingLineName', 'allowedFreeDays', 'detentionDemurrageRule'];
  displayedColumns3_1: string[] = ['selectRadio', 'ContainerNumber', 'BillLadingNumber', 'InvoiceNumber', 'InvoiceDate', 'InvoiceDueDate', 'firstAvailableDt', 'freeTimeStartDt', 'PortLastFreeDate', 'LineLastFreeDate', 'PickUpDate', 'DatesDemurrageCharged', 'demurragetotal', 'ShippingLineName', 'allowedFreeDays', 'detentionDemurrageRule'];

  displayLienChildColumns:string[]=['child_tops_holdname','child_hold_date','child_released','child_released_date','child_ClientName','child_hold_amount'];

  NonReleasedContainersExist:HoldCharges[] = [];
  TerminalName: string;
  Clients: Clients[];
  TotalAncillary: number = 0.00;
  TotalDemurrage: number = 0.00;
  TotalLIEN : number = 0.00;
  Client_IdL: string = '';
  Client_IdD: string = '';
  Client_IdA: string = '';
  constructor(  private importChangeService: ImportchargesService,
                private cookieService: CookieService,
                private AuthService: AuthService, 
                private router: Router,
                private _liveAnnouncer: LiveAnnouncer,
                public dialog: MatDialog 
              ) {
                  this.ContainerHoldCharges = new ContainerHoldCharges();
                  this.ImportChargeSearchData = new TerminalInfo();
                  this.Container = new DemurrageHoldCharges()
                  this.LienDemurrageHoldCharges = new LienDemurrageCharges();
                }

  ngOnInit(): void {
    this.LoadInitialData();
  }

  LoadInitialData()  {
    this.ShowLoader= true;
    this.ImportChargeSearchData = JSON.parse(sessionStorage.ImportChargeSearchRequest);
    this.TerminalName = this.cookieService.get('TerminalName');
    this.importChangeService.GetImportChargesByContainerNumber(this.ImportChargeSearchData).subscribe((result) => {
      this.ContainerHoldCharges = result;
      this.Messages = result.generalMessages;
      this.Clients = result.Clients;

      this.DemurrageHoldCharges = result.DemurrageHoldCharges;
      this.DemurrageHoldChargesList = result.DemurrageHoldCharges;

      if (this.DemurrageHoldCharges != null) {
        this.displayedDmgColumns = this.displayedColumns1_1;
        this.dataSourceDemurrage = new MatTableDataSource(result.DemurrageHoldCharges);
        this.dataSourceDemurrage.sort = this.sort;
      }

      this.HoldChargesList = result.HoldCharges;
      if (this.HoldChargesList != null) {
        this.NonReleasedContainersExist = this.HoldChargesList.filter( (row) => row.released == false );
        this.displayedAncColumns = this.displayedColumns2_1;
        this.dataSourceAncillary = new MatTableDataSource(result.HoldCharges);
        this.dataSourceAncillary.sort = this.Ancsort;
      }

      this.LienDemurrageHoldCharges = result.LienDemurrageHoldCharges;
      this.LienDemurrageHoldChargesList = result.LienDemurrageHoldCharges;
      if (this.LienDemurrageHoldCharges != null) {
        this.displayedLienColumns = this.displayedColumns3_1;
        this.dataSourceLien = result.LienDemurrageHoldCharges;
      }           

      this.ShowLoader= false;      
    })

  }
  
  expandchild(row: any): any { }
  
  expanded(element:any){}

  ClientsChangedL(e: any) {
    this.Client_IdL = e.target.value;
  }

  ClientsChangedD(e: any) {
    this.Client_IdD = e.target.value;
  }

  ClientsChangedA(e: any) {
    this.Client_IdA = e.target.value;
  }

  updateCheckedList(e: any, row: any): boolean {
    if (e.checked === true) {
      if (row.released == false) {
        this.TotalAncillary = this.TotalAncillary + (+row.hold_amount)
        this.selectedAncCollection.push(row);
      }
    } else {
      if (row.released == false) {
        this.TotalAncillary = this.TotalAncillary - row.hold_amount
        this.selectedAncCollection=this.arrayRemove(this.selectedAncCollection, row);     
      }
    }
    return true;
  }

  updateCheckedListD(e: any, row: any): boolean {
    if(!isNaN(+row.DEMURRAGE)){
      if (e.checked === true) {
        this.TotalDemurrage = this.TotalDemurrage + (+ row.DEMURRAGE);
        this.selectedDmgCollection.push(row);
      }
      else {
        this.TotalDemurrage = this.TotalDemurrage - row.DEMURRAGE;
        this.selectedDmgCollection=this.arrayRemove(this.selectedDmgCollection, row);
      }
    }
    return true;
  }

  arrayRemove(arr:any, value:any) {
    return arr.filter(function(ele:any){
        return ele != value;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceAncillary.data.filter(row => !row.released).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(e: any) {
    this.isAllSelected() ? this.selection.clear() : this.dataSourceAncillary.data.forEach(row => {
        if (!row.released) {
          this.selection.select(row);
        }
    });
    var items = this.dataSourceAncillary.data.filter((val) => val.released === false);
    if (this.IsFieldNotBlank(items)) {
      if (this.selectedAncCollection.length === 0) {
          items.forEach( (val) => {
              if (!val.released) {
                  this.selectedAncCollection.push(val);
              }
          });
      } else if (this.selectedAncCollection.length > 0 && this.selectedAncCollection.length != items.length) {
          items.forEach((val) => {
              var found = this.selectedAncCollection.indexOf(val);
              if (found == -1 && !val.released) this.selectedAncCollection.push(val);
          });
      } else {
          this.selectedAncCollection = [];
      }
      // this.CalculateAncillaryTotal(this.selectedAncCollection);

    this.TotalAncillary = 0;
    if (e.checked === true) {
      var tempDataSource = this.HoldChargesList;
      for (let i = 0; i < tempDataSource.length; i++) { // do not add if released is true      
          if (tempDataSource[i].released == false) {
            this.TotalAncillary = this.TotalAncillary + (+(tempDataSource[i].hold_amount))
          }
        }
      }
    }
  } 

  selectLienContainer(row: any) {
    if (this.IsFieldNotBlank(row)) {      
      this.selectedLien = row;
      this.ContainerHoldCharges.LienDemurrageHoldCharges.forEach( (a) => {                
        if (a.LienDemurrageCharge.ContainerNumber == row.LienDemurrageCharge.ContainerNumber && a.LienDemurrageCharge.InvoiceNumber == row.LienDemurrageCharge.InvoiceNumber
            && a.LienDemurrageCharge.BillLadingNumber == row.LienDemurrageCharge.BillLadingNumber) {
            a.preselected = true;
        }
        else
            a.preselected = false;
      });
    }
    this.CalculateLienTotal(this.selectedLien);
  }

  CalculateLienTotal(selectedLien:any){    
    var hold_amount = 0.00;
    var demurrageAmount = selectedLien.LienDemurrageCharge.demurragetotal;
    var totalAmount = 0.00;
    if (selectedLien.AncillaryHoldCharges != null && selectedLien.AncillaryHoldCharges.length > 0) {
        selectedLien.AncillaryHoldCharges.forEach( (val:any) => {
            if (this.IsFieldNotBlank(val.hold_amount)) {
                hold_amount = hold_amount + Number(val.hold_amount);
            }
        });
        totalAmount = hold_amount + demurrageAmount;
        this.TotalLIEN =  totalAmount;
    }
    else
        this.TotalLIEN =  demurrageAmount;
  }

  ShowCointeinerDetails(row: any, evt: any) {    
    const dialogRef = this.dialog.open(ShowContainerinfoComponent, {
      data: { Container: row },
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'deletepanelclass'
    });
    dialogRef.afterClosed().subscribe(result => {           
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedD() {
    const numSelected = this.selectionD.selected.length;
    const numRows = this.dataSourceDemurrage.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleD(e: any) {
    this.isAllSelectedD() ?  this.selectionD.clear() : this.dataSourceDemurrage.data.forEach(row => this.selectionD.select(row));
    
    var items = this.dataSourceDemurrage.data.filter((val) => val.RowStatus === 'U');
    if (this.IsFieldNotBlank(items)) {
      if (this.selectedDmgCollection.length === 0) {
        items.forEach((val) => {
              if (val.RowStatus === 'U') {
                  this.selectedDmgCollection.push(val);
              }
          });
      } else if (this.selectedDmgCollection.length > 0 && this.selectedDmgCollection.length != items.length) {
        items.forEach((val) => {
              var found = this.selectedDmgCollection.indexOf(val);
              if (found == -1 && val.RowStatus === 'U') { this.selectedDmgCollection.push(val) };
          });
      } else {
          this.selectedDmgCollection = [];
      }
       this.CalculateDemurrageTotal();
     }
  }

  CalculateDemurrageTotal () {
    this.TotalDemurrage = 0;
    let hold_amount = 0.00;
    this.selectedDmgCollection.forEach( (val) => {
        if (this.IsFieldNotBlank(val.DEMURRAGE) && (val.RowStatus === 'U' || val.RowStatus === 'I')) {
            hold_amount = hold_amount + Number(val.DEMURRAGE);
        }
    });
    this.TotalDemurrage = hold_amount;
  }

  ShowHelpPromptCustmer() {
    swal.fire({
      text: 'Please choose customer to pay.',
      title: 'Error...',
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    });
   }

  ShowHelpPromptContainer() {
    swal.fire({
      text: 'Please choose at least one container to pay..',
      title: 'Error...',
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: "#004165",
      customClass: 'swal-wide',
    });
  }

  PayDemurrage() {
     if ((this.selectedDmgCollection.length == 0 || this.isAllSelectedD()) && this.TotalDemurrage === 0) {
        this.ShowHelpPromptContainer();
      }
      else if ((this.Client_IdD == '') || !this.IsFieldNotBlank(this.Client_IdD))  { 
        this.ShowHelpPromptCustmer();
      }   
      else {     
        this.ShowLoader= true;  
        this.ContainerHoldCharges.IsAncillaryPayment=false;
        this.ContainerHoldCharges.User_Id = Number(this.AuthService.User_Id());
        this.ContainerHoldCharges.Client_Id = this.Client_IdD;
        this.ContainerHoldCharges.Terminal = Number(this.ImportChargeSearchData.TerminalId);
        this.ContainerHoldCharges.Date = this.ImportChargeSearchData.Date;
        this.ContainerHoldCharges.Client_Name = this.ContainerHoldCharges.Clients[0].ClientName; 
        this.selectedDmgCollection.forEach((val) => {
          var found = this.ContainerHoldCharges.DemurrageHoldCharges.indexOf(val);
          if (found !== -1) this.ContainerHoldCharges.DemurrageHoldCharges[found].Selected = true;
        });
        this.importChangeService.GetShoppingCartDetailsForPayment(this.ContainerHoldCharges).subscribe((result) => {  
          if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
            this.Messages=result.generalMessages;
          }
          else if(result.MerchantData.CyberSourceWebMobile_FLG == true) {
            sessionStorage.setItem("S_CartInfo", JSON.stringify(result));
            this.router.navigateByUrl('/newcheckoutconfirmation');
          }
          else {
            sessionStorage.setItem("S_CartInfo", JSON.stringify(result));
            this.router.navigateByUrl('/checkoutconfirmation');
          }
          this.ShowLoader= false;                   
        });       
    }
  }

  IsFieldNotBlank(val:any) { if (val === undefined || val === '' || val === null) { return false; } else { return true; } }

  PayAncillary() {
    if ((this.selectedAncCollection.length == 0 || this.isAllSelected()) && this.TotalAncillary == 0) { 
      this.ShowHelpPromptContainer(); 
    }
    else if(this.Client_IdA == '' || !this.IsFieldNotBlank(this.Client_IdA) ) { 
      this.ShowHelpPromptCustmer();
    }
    else {       
       this.ShowLoader= true;    
       this.ContainerHoldCharges.IsAncillaryPayment=true;
       this.ContainerHoldCharges.User_Id = Number(this.AuthService.User_Id());
       this.ContainerHoldCharges.Client_Id = this.Client_IdA;
       this.ContainerHoldCharges.Terminal = Number(this.ImportChargeSearchData.TerminalId);
       this.ContainerHoldCharges.Date =  this.ImportChargeSearchData.Date;
       this.ContainerHoldCharges.Client_Name = this.ContainerHoldCharges.Clients[0].ClientName; 
       this.selectedAncCollection.forEach((val) => {
          var found = this.ContainerHoldCharges.HoldCharges.indexOf(val);
          if (found !== -1) this.ContainerHoldCharges.HoldCharges[found].Selected = true;
        });
      //  this.ContainerHoldCharges.HoldCharges=this.HoldChargesList;
       this.importChangeService.GetShoppingCartDetailsForPayment(this.ContainerHoldCharges).subscribe((result) => {           
          if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
            this.Messages=result.generalMessages
         }
         else if(result.MerchantData.CyberSourceWebMobile_FLG == true)    {
            sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
            this.router.navigateByUrl('/newcheckoutconfirmation');
         }
         else {
            sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
            this.router.navigateByUrl('/checkoutconfirmation');
        } 
        this.ShowLoader= false;              
      });
    }
  }

  PayLIEN() {
      if (!this.selectedLien || this.TotalLIEN == 0) { 
        this.ShowHelpPromptContainer(); 
      }
      else if(this.Client_IdL == ''  || !this.IsFieldNotBlank(this.Client_IdL)) { 
        this.ShowHelpPromptCustmer(); 
      }
      else {
        this.ShowLoader= true; 
        this.Messages = [];
        this.ContainerHoldCharges.IsAncillaryPayment = false;
        this.ContainerHoldCharges.IsLienPayment = true;
        this.ContainerHoldCharges.User_Id = Number(this.AuthService.User_Id());
        this.ContainerHoldCharges.Client_Id = this.Client_IdL;
        this.ContainerHoldCharges.Terminal = Number(this.ImportChargeSearchData.TerminalId);
        this.ContainerHoldCharges.Date = this.ImportChargeSearchData.Date;
        this.ContainerHoldCharges.Client_Name = this.ContainerHoldCharges.Clients[0].ClientName;      
        this.ContainerHoldCharges.LienDemurrageHoldCharges.forEach( (val:any) => {
          if (val === this.selectedLien) {
                val.Selected = true;
                val.LienDemurrageCharge.Selected = true;
                val.TotalLIEN = this.TotalLIEN;
                if (val.AncillaryHoldCharges != null && val.AncillaryHoldCharges.length > 0) {
                    val.AncillaryHoldCharges.forEach( (valA:any) => {
                        valA.Selected = true;
                    });
                }
          }
          else {
                val.Selected = false;
                val.LienDemurrageCharge.Selected = false;
                val.TotalLIEN = 0.00;
                if (val.AncillaryHoldCharges != null && val.AncillaryHoldCharges.length > 0) {
                    val.AncillaryHoldCharges.forEach(  (valA:any) => {
                        valA.Selected = false;
                    });
                }
            }
        });                   
        this.importChangeService.GetShoppingCartDetailsForPayment(this.ContainerHoldCharges).subscribe( (result) => {        
            // Ensure that there are no messages, and then determine what payment page to send the User
            if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
               this.Messages=result.generalMessages;
            }
            else if(result.MerchantData.CyberSourceWebMobile_FLG == true) {
                sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
                this.router.navigateByUrl('/newcheckoutconfirmation');
            }
            else {
                sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
                this.router.navigateByUrl('/checkoutconfirmation');
            }
            this.ShowLoader= false;    
        });
    }
  }

  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  AncsortData(sort: Sort) {
    const data = this.dataSourceAncillary.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceAncillary.data = data;
      return;
    }
    this.dataSourceAncillary.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'container_number': return compare(a.container_number, b.container_number, isAsc);
        case 'tops_holdname': return compare(a.tops_holdname, b.tops_holdname, isAsc);
         case 'hold_date': return compare(a.hold_date, b.hold_date, isAsc);
        case 'hold_amount': return compare(a.hold_amount, b.hold_amount, isAsc);
        case 'released': return compare(a.released, b.released, isAsc);
        case 'ClientName': return compare(a.ClientName, b.ClientName, isAsc);       
        case 'released_date': return compare(a.released_date, b.released_date, isAsc);       
        default: return 0;
      }
    });
   }
  
}

function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


