import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-loading-spinner',
  templateUrl: './data-loading-spinner.component.html',
  styleUrls: ['./data-loading-spinner.component.css']
})
export class DataLoadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
