<div class="col-md-12 col-sm-12">
    <div class="col-md-2 col-sm-2"></div>
    <div class="col-md-8 col-sm-8" [formGroup]="shoppingCartPayment">        
        <!-- Payment Options -->
        <div class="row">
            <div class="form-group col-md-6 col-sm-6" [ngClass]="{ 'has-error' : f.ddPaymentType.errors }" style="padding-left:0px">
                <span style="color:red">*</span><label>Payment Type</label>
                <select name="ddPaymentType" formControlName="ddPaymentType" class="form-control show-tick" data-select-picker data-selectpicker-options="SCartPayment.PaymentOptions" [(ngModel)]="SCartPayment.PaymentType"  required>
                    <option value="">Choose a Payment Type ...</option>
                    <option *ngFor="let option of SCartPayment.PaymentOptions" value="{{option.PaymentOptionValue}}">{{option.PaymentOptionText}}</option>
                    
                </select>
                <span style="color:red" *ngIf="f.ddPaymentType.invalid && !f.ddPaymentType.pristine">
                    <span style="color:red" *ngIf="f.ddPaymentType.errors?.required" class="help-block">Payment Type is Required.</span>
                </span>
            </div> 
            <div class="form-group col-md-6 col-sm-6" style="padding-right:0px">
                <label>PO Number</label>
                <input type="text" name="PO" formControlName="PO" class="form-control" [(ngModel)]="SCartPayment.PurchaseOrderNumber">
            </div>
        </div>       
        <div class="row">
            <div *ngIf="(SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType) && ShoppingCart.TotalPrice > SCartPayment.CheckLimit">
                <div class="alert alert-danger">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;Please choose another payment type if total is greater than $ {{SCartPayment.CheckLimit}}.
                </div>
            </div>
        </div> 
         <div class="row" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditCardType">
            <app-credit-cart-payment [shoppingCartPayment]="shoppingCartPayment" [ShoppingCart]="this.ShoppingCart" [SCartPayment]="this.SCartPayment"></app-credit-cart-payment>           
        </div>
        <div class="row" *ngIf="(SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType) && ShoppingCart.TotalPrice < SCartPayment.CheckLimit">
            <app-electronic-check-payment [shoppingCartPayment]="shoppingCartPayment" [ShoppingCart]="this.ShoppingCart" [SCartPayment]="this.SCartPayment"></app-electronic-check-payment>           
        </div> 
         <div class="row" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.PayCargoType">
            <app-pay-cargo-payment [shoppingCartPayment]="shoppingCartPayment" [SCartPayment]="this.SCartPayment"></app-pay-cargo-payment>          
        </div>        
         <div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditReserveType">
            <br />
            <div class="col-md-12 col-sm-12 text-primary">
                <label>Credit Reserve</label>
            </div>
            <div class="col-md-12 col-sm-12">
                <div style="background: rgba(197, 195, 195, 0.14);padding-left:30px">
                    <br />
                    <label>Available Credit Reserve :</label>
                    $&nbsp;<label >{{SCartPayment.CreditReserveBalance}}</label>
                    <br /><br />
                </div>
            </div>
            <br />
        </div>
        <div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CheckType">
            <br />
            <div class="col-md-12 col-sm-12 text-primary">
                <label>Pay by Check</label>
            </div>
            <div class="col-md-12 col-sm-12">
                <div style="background: rgba(197, 195, 195, 0.14);padding-left:10px">
                    <br />Please use certified checks for amounts over $1000. Checks require
                    a minimum of two days processing time and this may affect your pickup date. So
                    mail your check ASAP.<br /><br> 
                </div>
            </div>
            <br />
        </div>
        <div class="row" *ngIf="SCartPayment.PaymentType !== '' && SCartPayment.PaymentType !== undefined">
            <br />
            <div class="col-md-12 col-sm-12 no-padding">
              <input type="checkbox" name="checkbox" formControlName="checkbox" value="check" id="agree" [(ngModel)]="SCartPayment.AcceptTerms" /> By checking the box , I hereby represent and warrant that
    (i) I am fully aware of the nature and amount of the charges owed
    (ii) I acknowledge the validity of the charges
    (iii) I am fully authorized to make this payment on behalf of the parties that have right, title and interest in and to the cargo being
    released upon payment of the charges owed.<br />
            </div>
        </div> 
    </div>
    <div class="col-md-2 col-sm-2"></div>
</div>


