import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { TrackFilter , EQHistory } from 'src/app/core/models/trackandtrace.model';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-equipment-history-results',
  templateUrl: './equipment-history-results.component.html',
  styleUrls: ['./equipment-history-results.component.css']
})
export class EquipmentHistoryResultsComponent implements OnInit {
  @Input() TrackFilter:TrackFilter;  
  @Input() ContainerHistorylist: EQHistory[]=[];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  ShowLoader = false;
  displayedColumns:string[]= ['OPERATOR','PERFORMED','EVENT','POSNOTES'];
  // dataSource:MatTableDataSource<EQHistory>;
  dataSource: MatTableDataSource<EQHistory>;
  constructor() { }

  ngOnInit(): void {    
    if(this.ContainerHistorylist != null){         
      this.dataSource = new MatTableDataSource(this.ContainerHistorylist);
      this.dataSource.sort = this.sort;
    }  
  }
  
  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'OPERATOR': return compare(a.OPERATOR, b.OPERATOR, isAsc);
        case 'PERFORMED': return compare(a.PERFORMED, b.PERFORMED, isAsc);
        case 'EVENT': return compare(a.EVENT, b.EVENT, isAsc);
        case 'POSNOTES': return compare(a.POSNOTES, b.POSNOTES, isAsc);
        default: return 0;
      }
    });
   }
}

function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
