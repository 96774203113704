
<form name="EIRPrintDetailsForm"  novalidate ng-cloak style="display:block !important;">
    <div  class="print-style" id="printable">        
        <div  class="k-dialog" >
            <div class="modal-header custom-modal-header">
                <button type="button" class="close noPrint" (click)="close()">&times;</button>
                <h4 class="modal-title text-center"><strong>EIR Inquiry</strong></h4>
            </div>
            <div class="modal-body" >
                <div class="row" style="border-bottom: 8px solid black;">
                    <div class="col-md-4 disp-Inline" style="width:370px;padding-right:0px;">
                        <div class="row minPadding-top" style="font-size: 24px">
                            <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].TERMINALLONGNAME || EIRdetails[0].terminalLongName}}</strong></div>
                        </div>
                        <div class="row minPadding-top" style="font-size: 24px">
                            <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline" style="padding-right:0px"><strong>{{EIRdetails[0].LINE_ID || EIRdetails[0].line_id}}: {{EIRdetails[0].NAME || EIRdetails[0].name}}</strong></div>
                        </div>
                        <div class="row minPadding-top">
                            <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline">{{EIRdetails[0].MISSION_TYPE}}</div>
                        </div>
                    </div>
                    <div class="col-md-4 disp-Inline" style="width:320px;padding-left:0px;padding-right:0px;">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline"></div>
                        </div>
                        <div class="row minPadding-top">
                            <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline" style="padding-right:0px;">{{EIRdetails[0].TRKC_ID || EIRdetails[0].trkc_id}} {{EIRdetails[0].TRKC_NAME}}</div>
                        </div>
                        <div class="row minPadding-top">
                            <div class="col-md-3 col-sm-3 text-left disp-Inline"><label>Driver: </label></div>
                            <div class="col-md-9 col-sm-9 text-left text-primary disp-Inline">{{EIRdetails[0].DRIVER_NAME || EIRdetails[0].driver_name}}</div>
                        </div>
                    </div>
                    <div class="col-md-4 disp-Inline" style="width:315px">
                        <div class="row minPadding-top" style="font-size: 24px">
                            <div class="col-md-6 col-sm-6 text-right disp-Inline"><label><strong>TICKET : </strong></label></div>
                            <div class="col-md-6 col-sm-6 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].NBR || EIRdetails[0].nbr}}</strong></div>
                        </div>
                        <div class="row minPadding-top">
                            <div class="col-md-5 col-sm-5 text-left disp-Inline" style="padding-right:0px"><label><strong>Arrive : </strong></label></div>
                            <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline" style="padding-left:0px"><strong>{{EIRdetails[0].EIR_DATE | date:'MM/dd/yyyy HH:mm'}}</strong></div>
                        </div>
                        <div class="row minPadding-top">
                            <div class="col-md-4 col-sm-4 text-right disp-Inline"><label><strong> </strong></label></div>
                            <div class="col-md-8 col-sm-8 text-left text-primary disp-Inline"><strong> </strong></div>
                        </div>
                    </div>
                </div>        
                <div class="row" style="border-bottom:8px solid black">
                    <div class="col-md-4 disp-Inline" style="width:340px">
                        <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Container : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].CTR_NBR || EIRdetails[0].ctr_nbr}}</strong></div>
                </div>  
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Chassis : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].CHS_NBR || EIRdetails[0].chs_nbr}}</strong></div>
                </div>       
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Vessel : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].SHIP_ID || EIRdetails[0].ship_id}} {{EIRdetails[0].VOY_NBR || EIRdetails[0].voy_nbr}} {{EIRdetails[0].CALL_NBR || EIRdetails[0].call_nbr}}</strong></div>
                </div>
            </div>
            <div class="col-md-4 disp-Inline" style="width:300px">
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                </div>  
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                </div>       
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Port : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].DISCHARGE_POINT_ID1 || EIRdetails[0].discharge_point_id1}}</strong></div>
                </div>
            </div>
            <!-- </div> -->
                    <div class="col-md-4 disp-Inline" style="width:365px">
                        <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Container Size/Type : </label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].CTR_EQSZ_ID || EIRdetails[0].ctr_eqsz_id}} {{EIRdetails[0].CTR_EQTP_ID || EIRdetails[0].ctr_eqtp_id}} {{EIRdetails[0].CTR_EQHT_ID || EIRdetails[0].ctr_eqht_id}}</strong></div>
                </div>  
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Chassis Size/Type : </label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].CHS_EQSZ_ID || EIRdetails[0].chs_eqsz_id}} {{EIRdetails[0].CHS_EQTP_ID || EIRdetails[0].chs_eqtp_id}}</strong></div>
                </div>       
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Release : </label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].EQO_NBR || EIRdetails[0].eqo_nbr}}</strong></div>
                </div>
                    </div>
                </div> 
                <div class="row" style="border-bottom:8px solid black">
                    <div class="col-md-4 disp-Inline" style="width:340px">
                        <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Gen Set : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].GENSET || EIRdetails[0].genset}}</strong></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Driver ID : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].TRUCK_LICENSE_NBR || EIRdetails[0].truck_license_nbr}}</strong></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Temp : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].TEMP_UNITS || EIRdetails[0].temp_units}}</strong></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Seals : </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].SEAL_NBR1 || EIRdetails[0].seal_nbr1}}</strong></div>
                </div>
                    </div>
                    <div class="col-md-4 disp-Inline" style="width:300px">
                        <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-5 col-sm-5 text-right disp-Inline"><label></label></div>
                    <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"></div>
                </div>
                    </div>
                    <div class="col-md-4 disp-Inline" style="width:365px">
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Scale Wt : </label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].SCALEWTLBS || EIRdetails[0].scaleWtLbs}} LB</strong></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Total Shipping Wt : </label></div>
                    <div *ngIf="PaidQTY > 0" class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails[0].CTRGROSSWTLBS || EIRdetails[0].ctrGrossWtLbs}} LB</strong></div>
                    <div *ngIf="PaidQTY == 0" class="col-md-4 col-sm-4 text-left disp-Inline"><label>click <a style="cursor: pointer;" (click)="gobacktoTT();">here</a> to order</label></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label></label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong></strong></div>
                </div>
                <div class="row minPadding-top">
                    <div class="col-md-8 col-sm-8 text-right disp-Inline"><label></label></div>
                    <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong></strong></div>
                </div>
                    </div>
                </div>
                
                <div class="row" *ngFor="let row of EIRInstruction">{{row.TEXT}}</div>
                
            </div>
        </div>
    </div>
    <div class="modal-footer form-horizontal noPrint">
        <div class="row">
            <button class="btn btn-primary btn-sm btn-site" (click)="close()">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
            </button>
            <button class="btn btn-primary btn-sm btn-site text-right" (click)="printPage();" print-element-id="EIRprintSection" id="printButton"><span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print</button>
        </div>
    </div>
</form>
