<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" style="background-color: white;">
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Payment Receipt</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div>
    <div *ngIf="Messages.length > 0">
        <div class="row"  *ngFor="let Msg of Messages">
            <br />
            <div class="col-md-8 col-md-offset-2">
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>
    <form name="shoppingCartReceipt" novalidate>
        <br />
        <div class="row" *ngIf="Messages.length == 0 && ShoppingCart != null && ShoppingCart.PaymentData != null">
            <app-shopping-cart-receipt [ShoppingCart]="ShoppingCart"></app-shopping-cart-receipt>
        </div>
        <div class="row" *ngIf="Messages.length == 0 && ShoppingCart != null && ShoppingCart.LineItems != null">
           <app-shopping-cart-details [ShoppingCart]="ShoppingCart"></app-shopping-cart-details>
        </div>
        <div class="row" *ngIf="HoldReleaseErrors != null && HoldReleaseErrors != ''">  
            <br />
            <div class="col-md-8 col-md-offset-2">
                <div class="alert alert-danger">
                    <div class="text-left" style="white-space: pre;">{{HoldReleaseErrors}}</div>
                </div>
            </div>
        </div>
        <div class="row" style="padding-left:10px" *ngIf="Messages.length == 0 && ShoppingCart != null">
            <div class="col-md-8 col-md-offset-2"><h4>Please print this receipt out and keep for your records.</h4></div>
        </div>
        <br /><br />
        <div class="row col-md-12 col-sm-12 text-right">
            <div class="col-md-10">
                <!-- IMPORT SEARCH RESULTS BUTTON -->
                <button class="btn btn-primary btn-site" [routerLink]="['/importchargeresults']" *ngIf="ShoppingCart != null && ShoppingCart.HoldType != 'E' && ShoppingCart.HoldType != 'S'">
                    <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Back To Import Search Results
                </button>
                <!-- Service Order RESULTS BUTTON -->
                <button class="btn btn-primary btn-site" [routerLink]="['/serviceordersearch']"  *ngIf="ShoppingCart != null && ShoppingCart.HoldType == 'S'">
                    <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Back To Service Order Search
                </button>
                <!-- HOME BUTTON -->
                <button class="btn btn-primary btn-site btn-margin" [routerLink]="['/landingpage']" >
                    <span class="glyphicon glyphicon-home btnStyle" aria-hidden="true" style="font-size:17px"></span>
                    Return Home
                </button>
            </div>
        </div>
    </form>
    <br /><br />
</div>