import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ShoppingCart,PaymentConfirmation } from 'src/app/core/models/ShoppingCart';
import swal from 'sweetalert2'; 
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-check-out-confirmation',
  templateUrl: './check-out-confirmation.component.html',
  styleUrls: ['./check-out-confirmation.component.css']
})
export class CheckOutConfirmationComponent implements OnInit {
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  ShoppingCart:ShoppingCart;
  SCartPayment:PaymentConfirmation;
  shoppingCartPayment: FormGroup;
  constructor(private importchargesService: ImportchargesService,
              private router: Router,
              private formBuilder: FormBuilder,) { this.ShoppingCart = new ShoppingCart(); this.SCartPayment = new PaymentConfirmation();  }

  ngOnInit(): void {
    

      this.ShoppingCart = JSON.parse(sessionStorage.S_CartInfo);    
      this.ShowLoader = true;
      //Make web call to get reference data to load form.
      setTimeout(() => {
      this.importchargesService.GetReferenceDataForPaymentConfirmation().subscribe((result) => {     
        if(result != null)
        this.SCartPayment = result;
        this.ShowLoader = false;
      });    
    }, 2000);    
    
    this.shoppingCartPayment =  this.formBuilder.group(     
      {
        ddPaymentType: ['', Validators.required],       
  
        username: ['', Validators.required],
        password: ['', Validators.required],
  
        firstName: ['', [Validators.required,Validators.max(15)]],
        lastName: ['', [Validators.required,Validators.max(25)]],
        address1: ['', Validators.required ],
        
        cityLocation: ['', Validators.required ],
        zip: ['', Validators.required ],
        countries: ['', Validators.required ],
        states: ['', Validators.required ],
        cardType: ['', Validators.required ],
        cNumber: ['', Validators.required ],
        cardExpMonth: ['', Validators.required ],
        cardExpYear: ['', Validators.required ],
        cvv: ['', Validators.required ],
        phone: ['', Validators.required ],
        routingNumber: ['', Validators.required ],
        cAccountNum: ['', Validators.required ],
        fTaxId: ['', Validators.required ],
        dLicNum: ['', Validators.required ],
        lstate: ['', Validators.required ],
  
        address2: [],
        PO: [],
        checkbox:[],
      });

    

    
  
  }
  
  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

  PayNow(){
    this.Messages = [];
    if (this.shoppingCartPayment.valid) {
        if (this.SCartPayment.AcceptTerms == true) {
          swal.fire({ 
            text: 'Do you want to proceed with payment?', 
            title: 'Are You Sure?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,     
            confirmButtonText: "Ok, Proceed!",
            confirmButtonColor: "#004165",
            cancelButtonColor:"#004165",
            focusCancel:true,
            customClass: 'swal-wide',
            reverseButtons: true
         }).then((result) => {
          if (result.isConfirmed) {
                if (this.ShoppingCart.HoldType === 'E') {
                    this.importchargesService.PostExportChargesPaymentProcess(this.SCartPayment).subscribe((result) =>  {
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                            this.Messages = result.generalMessages;
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
                else if (this.ShoppingCart.HoldType === 'S') {
                    this.importchargesService.PostServiceOrderPaymentProcess(this.SCartPayment).subscribe((result) => {
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                            this.Messages = result.generalMessages;
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
                else {
                    this.importchargesService.PostImportChargesPaymentProcess(this.SCartPayment).subscribe((result) =>  {
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                            this.Messages = result.generalMessages;
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
            }
          });
        } else {
            swal.fire("Error...", "Please check the box to accept terms.", "error");
        }
    }
  }


    // ShowHelpPrompt(Control:string) {
    //     var PromptTitle, PromptText;
    //     var PromptType = 'info';

    //     switch (Control) {
    //         case 'CheckNumber':
    //             PromptTitle = 'Check Number';
    //             PromptText = 'Please type-in only the last 8 digits of the Check Number';
    //         default:
    //             break;
    //     }

    //     swal.fire(PromptTitle, PromptText, 'info');
    //   }

}
