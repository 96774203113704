<!-- <div class="site-footer footer" style="z-index:1;">
        <div class="row" style="padding-top:12px">
            <p style="text-align:center;">&copy;APM Terminals NAM - All rights reserved</p>
            <p style="text-align:center;"><strong>A.P. Moller Maersk Group</strong></p>
            </div>
        </div> -->
        <div>
            <footer id="siteFooter" class="site-footer footer" style="z-index:1;">
                <div class="row" style="padding-top:12px">
                    <div class="col-md-5 col-sm-5 text-right"><p>&copy; APM Terminals NAM - All rights reserved</p></div>
                    <div class="col-md-3 col-sm-3"><p style="text-align:center;"><strong>A.P. Moller Maersk Group</strong></p></div>
                    <!-- @{
                        string LBnode = System.Configuration.ConfigurationManager.AppSettings["LBnode"];
                    } -->
                    <!-- <div class="col-md-4 col-sm-4 text-right"><p style="color:silver;">@LBnode</p></div> -->
                    <!--<div class="col-md-4 col-sm-4">
                        <div class="col-md-2 col-sm-3">
                            <a href="#" title="FaceBook"><img src="~/Images/apmt_facebooklogo.jpg" /></a>
                        </div>
                        <div class="col-md-2 col-sm-3">
                            <a href="#" title="Twitter">
                                <img src="~/Images/apmt_twitterlogo.jpg" />
                            </a>
                        </div>
                        <div class="col-md-2 col-sm-3">
                            <a href="#" title="Google+">
                                <img src="~/Images/apmt_Gpluslogo.jpg" />
                            </a>
                        </div>
                        <div class="col-md-2 col-sm-3">
                            <a href="#" title="LinkedIn">
                                <img src="~/Images/apmt_Inlogo.jpg" />
                            </a>
                        </div>-->
                    <!-- </div> -->
                </div>
            </footer>
        </div>