<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<form class="form-horizontal"  name="VGMServiceResultsForm"  [formGroup]="VGMServiceResultsForm" style="display:block !important;" novalidate>
    <div style="min-width:600px;text-align: center;display: block;"  (clickedOutside)="close();$event.stopPropagation()" >
        <div class="modal-content">
            <div class="modal-header custom-modal-header">
                <button type="button" class="close" (click)="close()">&times;</button>
                <h4 class="modal-title text-center">Order Weight Service Results</h4>
            </div>
       
        <div class="modal-body" >
         <div *ngIf="Messages.length > 0">
          <div class="row" *ngFor="let Msg of Messages">
            <div [ngClass]="Msg.severity==1?'alert alert-info':Msg.severity==3? 'alert alert-danger':Msg.severity==2? 'alert alert-warning' : 'alert alert-success'">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="ShowPay === false">
            <div class="alert alert-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;The quantity you have chosen to pay for exceeds the remaining quantity on the booking.  Please verify and retry.
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 col-sm-5 text-right"><label>Terminal : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.terminalName}}</div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-5 col-sm-5 text-right"><label>Booking Number : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.ExportBookingHoldCharges[0].booking_nbr}}</div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-5 col-sm-5 text-right"><label>Shipping Line : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.shippingLine}}</div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-5 col-sm-5 text-right"><label>Weight Service Rate : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.ExportBookingHoldCharges[0].chassisflip_rate | currency}}</div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-5 col-sm-5 text-right"><label>Total Quantity : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.ExportBookingHoldCharges[0].booking_qty}}</div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-5 col-sm-5 text-right"><label>Paid Quantity : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.ExportBookingHoldCharges[0].paid_qty}}</div>
        </div>
        <div class="row minPadding-top" style="padding-bottom:10px">
            <div class="col-md-5 col-sm-5 text-right"><label>Total Amount : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">{{BookingInfo.ExportBookingHoldCharges[0].totalAmount | currency}}</div>
        </div>
        <div class="row">
            <div class="col-md-5 col-sm-5 text-right minPadding-top"><label>Customer : </label></div>
            <div class="col-md-7 col-sm-7 text-left text-primary" [ngClass]="{ 'has-error' : f.ddCustomers.errors  }">
                <select formControlName="ddCustomers" name="ddCustomers" class="form-control show-tick" required data-select-picker data-selectpicker-options="BookingInfo.Clients" [(ngModel)]="BookingInfo.Client_Id" [disabled]="!BookingInfo.ShowPay">
                    <option value="">Choose Customer ...</option>
                    <option *ngFor="let option of BookingInfo.Clients" value="{{option.ClientId}}">{{option.ClientName}}</option>
                </select>
                <span *ngIf="submitted && f.ddCustomers.errors?.required" class="help-block">Customer is Required.</span>
            </div>
        </div>
        </div>
        <div class="modal-footer form-horizontal">
            <button class="btn btn-primary btn-lg" [disabled]="VGMServiceResultsForm.invalid || !BookingInfo.ShowPay || BookingInfo.ExportBookingHoldCharges[0].totalAmount <= 0" (click)="payVGMServiceCharges()">
                <span class="glyphicon glyphicon-usd btnStyle" aria-hidden="true"></span>&nbsp;Pay
            </button>
            <button class="btn btn-primary btn-lg" (click)="close()">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
            </button>
        </div>
    </div>
    </div>
</form>
