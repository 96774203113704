import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-eirprint-details',
  templateUrl: './eirprint-details.component.html',
  styleUrls: ['./eirprint-details.component.css']
})
export class EIRPrintDetailsComponent implements OnInit {
  PaidQTY:any;
  EIRdetails:any;
  EIRInstruction:any;
  bookingdetails :{TerminalId:any,BookingNumber:any};
  TerminalId:any;
  BookingNumber:any;
  constructor(
    public dialogRef: MatDialogRef<EIRPrintDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public EIRdetailsdata: any,
    private router: Router   
    ) { 
      this.bookingdetails = {TerminalId:'',BookingNumber:''};
    }

  ngOnInit(): void {  

    sessionStorage.FromEIR = false;
    this.PaidQTY = this.EIRdetailsdata.PaidQTYdata;
    this.EIRdetails = this.EIRdetailsdata.EIRdetailsdata;
    this.EIRInstruction = this.EIRdetailsdata.EIRInstructiondata;

    this.bookingdetails.TerminalId = this.EIRdetailsdata.TerminalId;
    if (this.EIRdetails[0].EQO_NBR != undefined)
      this.bookingdetails.BookingNumber = this.EIRdetails[0].EQO_NBR;
    else
      this.bookingdetails.BookingNumber = this.EIRdetails[0].eqo_nbr;
  }

  close() {
    this.dialogRef.close('cancel');
  }

  gobacktoTT(){
    sessionStorage.TerminalId = this.bookingdetails.TerminalId;
    sessionStorage.BookingNumber = this.bookingdetails.BookingNumber == null ? '': this.bookingdetails.BookingNumber;
    sessionStorage.FromEIR = true;
    window.location.reload();    
  }

  printPage() {
    
      let mywindow : any = window.open('', '_blank', 'width=600,height=650,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      mywindow.document.write('<html><head><title>Print</title><link rel="stylesheet" src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.2/css/bootstrap.min.css">'); 
      mywindow.document.write('<link rel="stylesheet" type="text/css" src="src/styles.css" />');
      // mywindow.document.write(`<style>${styles}</style>`)
      mywindow.document.write('<meta charset="utf-8"><title>TOPS Portal!</title><base href="/"><meta name="viewport" content="width=device-width, initial-scale=1"> <link rel="icon" type="image/x-icon" href="assets/Images/apmt_logo2.jpg">  <link rel="preconnect" href="https://fonts.gstatic.com">  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet">  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">')
      mywindow.document.write('</head><body >' + document.getElementById('printable')?.innerHTML + '</body></html>'); 
     
      mywindow.document.close(); // necessary for IE >= 10    onload="window.print();window.close()
      mywindow.focus(); // necessary for IE >= 10*/
      // setTimeout(function(){mywindow.print();},1000);
      mywindow.print();
      mywindow.close();

      // return true;

     
 
  }
}
