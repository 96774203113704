export interface ILogin {
    LoginName:string;
    Password:string;
    LBNode:string;
}
export class EMailObj
{
    Email    			:string;
}

export class UserContactInfo
{
    Contact_ID				:number;	
    Login_DS    			:string;
    Last_Name_DS    		:string;
    First_Name_DS    		:string;
    Password_DS    			:string;
    ConfirmPassword_DS    	:string;
    Secret_Question_CD    	:number;
    Secret_Answer_DS    	:string;
    Security_Email_DS    	:string;
    ClientId    			:number	;
    ClientCode    			:string;
    ClientName    			:string;
    FullName    			:string;
    StatusId    			:number	;
    Status          		:boolean;
    Canada_FL    			:boolean;
    Credit_Card_FL    		:boolean;
    E_Check_FL    			:boolean;
    TaxID_AN    			:string;
    ReTypeTaxID_AN    		:string;
    SCAC_AN    				:string;
    E_Mail_DS    			:string;
    Address_Line1_DS    	:string;
    Address_Line2_DS    	:string;
    City_DS    				:string;
    StateId    				:number;
    StateName    			:string;
    CountryId    			:number;
    CountryName    			:string;
    Postal_Code_AN          :string;
    Phone_Number_AN    		:string;
    Phone_Extension_AN    	:string;
    Fax_Number_AN    		:string;
    Title_DS    			:string;
    ClientTypeId    		:number;
    Message    				:string;
    SecretQuestions: SecretQuestions[];    
    ClientTypes:ClientType[];    
    Countries:Countries[];   
    States: States[];
    Clients:Clients[];   
}

export class SecretQuestions
{
    Secret_Question_CD: number;
    Secret_Question_DS: string;
}
export class ClientType{
    ClientTypeId:string;
    ClientTypeDescription:string;
}

export class Clients{
    ClientId:number;
    ClientName:string;
    ClientTypeId:string;
}

export class States{
    StateId:string;
    StateName:string;
    StateShort:string;
}

export class Countries{
    CountryId:number;
    CountryName:string;
    CountryCode:string;
    DropdownDisplay_FL:string;
}


export interface MenuNode {
    name: string;
    children?: MenuNode[];
    url:string;
    is_href: boolean;
    class:string;
  }

  export class UpdatePasswordRequest
  {
    CurrentPassword :string;
    NewPassword :string;
  }

  export class userInfo
  {
    User_Id :number;
    Password :string;
    generalMessages:any;
  }
  

  
  /** Flat node with expandable and level information */
  export interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    url:string;
    is_href: boolean;
    class:string;
  };
