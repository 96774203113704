import { Terminals } from "./Notification.model";

export class TrackFilter{
    BookingNum: string;
    ContainerNumber: string;
    billoflanding_nbr: string;
    ImportcontainerNum: string;
    Scheduletrip: string;
    Ship: string;
    inVoyageCall: string;
    outVoyageCall: string;
    GateBookingNumber: string;
    GateBillNumber: string;
    GateContainerNumber: string;
    TerminalId:number;
    TerminalName  :string;
    terminalCode  :string
    terminals  :Terminals[]
    GateFromDate :Date
    GateToDate :Date
    GateTruckEntered :string
    Gkey  :string
    GateContainer  :string
    ShippingLineIdVGM  :string
    ChargeType  :string
    viewvgm  :boolean;
    vShippingLineId  :string;
    searches:SearchOptions[];
    GateContainerLimit:number;
    GateContainerFromEIR:string;
    
    GateContainerNumberfocus:boolean;
    GateBillNumberfocus :boolean;
    GateBookingNumberfocus :boolean;
}

export class SearchOptions
{
    SearchId :number;
    SearchName : string;
}

export class TTSearchOptions
{
    TerminalId : number;
    SearchOptions : SearchOptions[];
    ImportContainerLimit : number;
    GateTransactionContainerLimit : number;
    GateTransactionDayRange : number;
}


export class ExportChargesSearchModel
{       
    ContainerNumbers :string;
    BookingNumber :string;
    User_Id : number;
    TerminalId :number;
    ShippingLineId :number;
    Terminals :Terminals[];
    TChargeTerminals :TerminalChargeTerminals;
    ShippingLines : ShippingLine[];
    HoldType_Cd :string;
    Date :Date;
}

export class TerminalChargeTerminals
{
    Terminals:TerminalCharge[] ;
}

export class TerminalCharge
{
    TerminalChargeId :number;
    TerminalId :number;
    TerminalName :string;
    TerminalChargeCd :string;
    TerminalChargeType :string;
    Amount :number;
    Email :string;
    UserId : number;
    Active_FL :number;
    updatedbyuser :string;
    UpdateDate :Date;
}

export class ShippingLine
{
    ShippingLineId :number;
    ShippingLineName :string;
    CompanySCACCode :string;
    CompanyBICCode :string;
    UserId :number;
    Status :boolean;
    CarrierNumber :string;
}

export class BookingHoldCharges
{
    ExportBookingHoldCharges : ExportBookingHoldCharges[];
    Clients :Clients[]; 
    ShowPay: boolean;
    Client_Id: string;
    FailedContainersMessage :string;
    User_Id :number;
    TerminalUsed :string;
    IsAncillaryPayment :boolean;
    Client_Name :string;
    Terminal :number;
    shippingLine :string;
    shippingLineCode :string;
    terminalCode :string;
    terminalName : string;
    HoldType_Cd :string;
    }

export class Clients
{
    ClientId:number;
    ClientName :string;
    ClientTypeId :number;
}

export class ExportChargesSearchRefData
{
     TerminalChargeCode:string;
}

export class ExportBookingHoldCharges
{
  booking_id : number;
  booking_gkey :string;
  booking_nbr :string;
  line_id :string;
  LINE_ID: string;
  ship_id :string;
  voy_nbr :string;
  oog_flg :string;
  booking_qty :number;
  paid_qty :number;
  email :string;
  holdtype :string;
  bookingcharge_id : number;
  quantity_paid :number;
  charge_qty :number;
  terminal_id :number;
  totalAmount : number;
  chassisflip_rate:number;
}

export class VesselDetails {
    SHIP:string;
    SHIPPINGLINE:string;
    SHIP_ID:string;
    IN_VOYAGE_CALL:string;
    OUT_VOYAGE_CALL:string;
    ARRIVE:string;
    arrive:string;
    DEPART:string;
    depart:string;
    FADATE:string;
    fadate:string;
    CARGO_CUTOFF:string;
    cargo_cutoff:string;
    REEFER_CUTOFF:string;
    reefer_cutoff:string;
    HAZARDOUS_CUTOFF:string;
    hazardous_cutoff:string;
    START_REC_DATE:string;
    start_rec_date:string;
}

export class EIRDetails {
Created				:  Date;
EIR_DATE			:  Date;
GENSET				: any;
genset              : any;
MISSION_TYPE		: string;
TEMP_REQUIRED		: string;
TEMP_UNITS			: string;
temp_units          : string;
TRAN_GKEY			: string;
TRKC_NAME			: string;
VGM					: string;
call_nbr			: string;
CALL_NBR            : string;
cargoWtLbs			: string;
cargo_weight		: string;
chs_eqsz_id			: string;
CHS_EQSZ_ID         : string;
chs_eqtp_id			: string;
CHS_EQTP_ID         : string;
chs_nbr				: string;
CHS_NBR             : string;
ctrGrossWtLbs		: string;
CTRGROSSWTLBS       : string;
ctrGrossWtLbs1		: string;
ctr_eqht_id			: string;
CTR_EQHT_ID         : string;
ctr_eqsz_id			: string;
CTR_EQSZ_ID         : string;
ctr_eqtp_id			: string;
CTR_EQTP_ID         : string;
ctr_nbr				: string;
CTR_NBR             : string;
discharge_point_id1	: string;
DISCHARGE_POINT_ID1 : string;
driver_name			: string;
DRIVER_NAME         : string;
eqo_nbr				: string;
EQO_NBR             : string;
gkey				: string;
line_id				: string;
LINE_ID             : string;
name				: string;
NAME                : string;
nbr					: string;
NBR                 : string;
pick_up_date		: Date;
scaleWtLbs			: string;
SCALEWTLBS          : string;
seal_nbr1			: string;
SEAL_NBR1           : string;
seal_nbr2			: string;
seal_nbr3			: string;
ship_id				: string;
SHIP_ID             : string;
sub_type			: string;
terminalLongName	: string;
TERMINALLONGNAME    : string;
trkc_id				: string;
TRKC_ID             : string;
truck_license_nbr	: string;
TRUCK_LICENSE_NBR   : string;
voy_nbr				: string;
VOY_NBR             : string;
}

export class EQHistory{
    OPERATOR:string;
    PERFORMED:string;
    EVENT:string;
    POSNOTES:string;
}






     
