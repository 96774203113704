<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12 grid-padding" style="padding-bottom: 5px;">
    <div data-st-table="ImportAvailabilityCollection">
    <div class="row col-md-12 col-sm-12 no-padding">
    <div class="row">
    <div class="col">
    <div class="col-md-7 col-sm-7 no-padding">
        <h3 class="text-left lbltitle"><strong style="color:#FF6218">Import Availability for: {{BillOfLading}} </strong></h3>
    </div>
    </div>
    <div *ngIf="importAvailabilitylist.length" style="padding-top:10px;padding-bottom:10px;" class="col text-right">
    <button (click)="exportToExcel('#tableResults')" class="btn btn-primary btn-site btn-margin">
    <span class="glyphicon glyphicon-arrow-up" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Export to Excel
    </button>
    </div>
    </div>
    <div class="mat-elevation-z8" style="overflow: scroll;max-height:500px;" #TABLE>      
        <table mat-table id="tableResults" style="width: 1489px;" #table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort>                
                <ng-container matColumnDef="NBR"  sticky>
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Container </th>
                <td mat-cell *matCellDef="let element" style="font-weight: bold;" class="tablebodytext"> {{element.NBR}} </td>
                </ng-container>
                <ng-container matColumnDef="em" >
                    <th mat-header-cell *matHeaderCellDef > </th>
                    <td mat-cell *matCellDef="let element" >  </td>
                    </ng-container>
                <ng-container matColumnDef="BL_NBR" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header> Bill of Lading </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.BL_NBR}} </td>
                </ng-container>
                
                <ng-container matColumnDef="READYFORDELIVERY" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Available </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                  <span  *ngIf="element.READYFORDELIVERY!='YES'" [innerHtml] ="element.READYFORDELIVERY"  > {{ element.READYFORDELIVERY.substring(element.READYFORDELIVERY.substring(0,element.READYFORDELIVERY.length-7).lastIndexOf('>')+1 , element.READYFORDELIVERY.length-7)  }} </span>
                  <span *ngIf="element.READYFORDELIVERY=='YES'"  [innerHtml] ="element.READYFORDELIVERY"  >   {{element.READYFORDELIVERY}} </span>
                </td>
                <!-- <td mat-cell *matCellDef="let element" style="font-size:16px; color:rgb(60,182,206); font-weight:bold;" *ngIf="element.READYFORDELIVERY!='YES'" class="tablebodytext"> {{element.READYFORDELIVERY}} </td>
                <td mat-cell *matCellDef="let element" *ngIf="element.READYFORDELIVERY=='YES'" class="tablebodytext"> {{element.READYFORDELIVERY}} </td> -->
                </ng-container>  
                  
                <ng-container matColumnDef="HOLDS" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Hold Type </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                    <span  *ngIf="element.HOLDS!=''" [innerHtml] ="element.HOLDS" >{{ element.HOLDS.substring(element.HOLDS.substring(0,element.HOLDS.length-7).lastIndexOf('>')+1 , element.HOLDS.length-7) }}</span>
                    <span *ngIf="element.HOLDS==''" [innerHtml] ="element.HOLDS" >{{element.HOLDS}} </span>
                </td>
                </ng-container>  
                <ng-container matColumnDef="FREIGHT" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Release Status </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                    <span *ngIf="element.FREIGHT=='RELEASED'"   [innerHtml] ="element.FREIGHT">{{element.FREIGHT}} </span>
                    <span   *ngIf="element.FREIGHT!='RELEASED'" [innerHtml] ="element.FREIGHT">{{ element.FREIGHT.substring(element.FREIGHT.substring(0,element.FREIGHT.length-7).lastIndexOf('>')+1 , element.FREIGHT.length-7)}} </span>
                </td>
                <!-- <td mat-cell *matCellDef="let element" *ngIf="element.FREIGHT=='RELEASED'" class="tablebodytext"> {{element.FREIGHT}} </td>
                <td mat-cell *matCellDef="let element"  style="font-size:16px;font-weight:bold;"  *ngIf="element.FREIGHT!='RELEASED'" class="tablebodytext"> {{element.FREIGHT}} </td> -->
                </ng-container>  
                <ng-container matColumnDef="CUSTOMS" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Customs Status </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                    <span *ngIf="element.CUSTOMS=='RELEASED'"  [innerHtml] ="element.CUSTOMS">{{element.CUSTOMS}} </span>
                    <span  *ngIf="element.CUSTOMS!='RELEASED'" [innerHtml] ="element.CUSTOMS">{{element.CUSTOMS.substring(element.CUSTOMS.substring(0,element.CUSTOMS.length-7).lastIndexOf('>')+1 , element.CUSTOMS.length-7)}} </span>
                 </td>
                <!-- <td mat-cell *matCellDef="let element" *ngIf="element.CUSTOMS=='RELEASED'" class="tablebodytext"> {{element.CUSTOMS}} </td>
                <td mat-cell *matCellDef="let element" style="font-size:16px;font-weight:bold;" *ngIf="element.CUSTOMS!='RELEASED'" class="tablebodytext"> {{element.CUSTOMS}} </td> -->
                </ng-container>  
                <ng-container matColumnDef="GOODTHRU" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Good Thru </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.GOODTHRU}} </td>
                </ng-container>  
                <ng-container matColumnDef="DEMURRAGE" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Demurrage </th>
                <td mat-cell *matCellDef="let element"  class="tablebodytext">
                    <span *ngIf="element.DEMURRAGE > 0"><a style="cursor: pointer;" (click) ="importchargesearch(element)"  >Pay $</a></span>
                    <span  *ngIf="!(element.DEMURRAGE | number) || !element.DEMURRAGE || (element.DEMURRAGE!='' && element.DEMURRAGE <=0)">N/A</span>
                </td>
                <!-- <td mat-cell *matCellDef="let element" *ngIf="element.DEMURRAGE > 0" class="tablebodytext"> Pay $ </td>
                <td mat-cell *matCellDef="let element" *ngIf="!(element.DEMURRAGE | number) || !element.DEMURRAGE || (element.DEMURRAGE!='' && element.DEMURRAGE <=0)"class="tablebodytext"> N/A </td> -->
                </ng-container>  
                <ng-container matColumnDef="CNTR_SIZE" >
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Size / Type / Height </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">  {{element['CNTR SIZE'] || element.CNTR_SIZE}} </td>
                </ng-container>  
                <ng-container matColumnDef="TYPE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Type </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TYPE}} </td>
                </ng-container>  
                <ng-container matColumnDef="WEIGHT">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Weight </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.WEIGHT }} </td>
                </ng-container>  
                <ng-container matColumnDef="SSL">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Container Operator SSL </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.SSL}} </td>
                </ng-container>  
                <ng-container matColumnDef="SSL_SCAC">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Container Operator SSL SCAC </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ element['SSL SCAC'] || element.SSL_SCAC}} </td>
                </ng-container>  
                <ng-container matColumnDef="VESSEL_NAME">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Vessel Name </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element['VESSEL NAME'] || element.VESSEL_NAME}} </td>
                </ng-container>  
                <ng-container matColumnDef="DESTINATION_CY">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Destination CY </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element['DESTINATION CY'] || element.DESTINATION_CY}} </td>
                </ng-container>  
                <ng-container matColumnDef="DESTINATION_DELIVERY">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Destination Delivery </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element['DESTINATION DELIVERY'] || element.DESTINATION_DELIVERY}} </td>
                </ng-container>  
                <ng-container matColumnDef="VESSEL_ETA">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Vessel ETA </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ element['VESSEL ETA'] || element.VESSEL_ETA | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="VESSEL_ACT_ARR">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Vessel Actual Arrival </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{ element['VESSEL ACT ARR'] || element.VESSEL_ACT_ARR | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="VSL_DISCHARGE_COMPLETE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Vsl Discharge Complete </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element['VSL DISCHARGE COMPLETE'] || element.VSL_DISCHARGE_COMPLETE | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  

                <ng-container matColumnDef="YARDLOCATION">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Yard Location </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.YardLocation || element.YARDLOCATION}} </td>
                </ng-container>  
                <ng-container matColumnDef="LOADEDTORAIL">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Moved to Rail </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.LoadedtoRail || element.LOADEDTORAIL | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="RAILACTUALDEPARTURE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Rail Actual Departure </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.RailActualDeparture || element.RAILACTUALDEPARTURE | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="TRUCK_RELEASE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Trucker ID </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TRUCK_RELEASE}} </td>
                </ng-container>  
                <ng-container matColumnDef="TRUCKERAPPOINTMENT">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Trucker Appointment </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TruckerAppointment || element.TRUCKERAPPOINTMENT | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="TRUCKERGATEIN">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Trucker Gate In </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TruckerGateIn || element.TRUCKERGATEIN | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>  
                <ng-container matColumnDef="TRUCKERGATEOUT">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext" mat-sort-header>Trucker Gate Out </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TruckerGateOut || element.TRUCKERGATEOUT | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>     
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="this.importAvailabilitylist.length == 0" class="no-records"> No Results found.</div>  
        <!-- </div> -->
    <!-- </div> -->
    <!-- </div>              -->
    <!-- </div> -->
    </div>

</div>
</div>
</div>