<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center"><h3><strong>Weight Service Search</strong></h3></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
    <div *ngIf="Messages.length > 0">
        <div class="row col-md-12 col-sm-12"  *ngFor="let Msg of Messages">
            <br />
            <div>
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <br /><br />
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <form name="VGMServiceSearchForm" [formGroup]="VGMServiceSearchForm"   novalidate>              
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-4 text-right minPadding-top"><span style="color:red">*</span><label>Terminal :</label></div>
                        <div class="col-md-8" [ngClass]="{ 'has-error' : submitted && f.terminals.errors }">
                            <select name="terminals" formControlName="terminals" class="form-control show-tick" required data-select-picker data-selectpicker-options="VGMServiceSearchRefData.TChargeTerminals.Terminals" [(ngModel)]="VGMServiceSearchRefData.TerminalId">
                                <option [ngValue]="null">Choose Terminal ...</option>                              
                                <option *ngFor="let option of Terminals" value="{{option.TerminalId}}">{{option.TerminalName}}</option>
                            </select>
                            <span *ngIf="submitted && f.terminals.errors?.required" class="help-block">Terminal is Required.</span>
                        </div>
                    </div>
                </div>             
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-4 text-right minPadding-top"><span style="color:red">*</span><label>Shipping Line :</label></div>
                        <div class="col-md-8" [ngClass]="{ 'has-error' : submitted && f.shippingLine.errors }">
                            <select name="shippingLine" formControlName="shippingLine"  class="form-control show-tick" required data-select-picker data-selectpicker-options="VGMServiceSearchRefData.ShippingLines" [(ngModel)]="VGMServiceSearchRefData.ShippingLineId">
                                <option [ngValue]="null">Choose Shipping Line ...</option>
                                <option *ngFor="let option of ShippingLines" value="{{option.ShippingLineId}}">{{option.ShippingLineName}}</option>
                            </select>
                            <span *ngIf="submitted && f.shippingLine.errors?.required" class="help-block">Shipping Line is Required.</span>
                        </div>
                    </div>
                </div>              
                <div class="row">
                    <div class="form-group col-md-10">
                        <div class="col-md-4 text-right minPadding-top"><span style="color:red">*</span><label for="bKNumbers">Booking Number :</label></div>
                        <div class="col-md-8" [ngClass]="{ 'has-error' : submitted && f.bKNumbers.errors }">
                            <input type="text" name="bKNumbers" formControlName="bKNumbers" class="form-control" [(ngModel)]="VGMServiceSearchRefData.BookingNumber" maxlength="20" data-capitalize required>
                            <span class="help-block" *ngIf="submitted && f.bKNumbers.errors?.required">Please enter Booking Number.</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <br />
                    <div class="form-group col-md-10">
                        <div class="col-md-4"></div>
                        <div class="col-md-8 col-sm-8 text-right">                        
                            <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']">
                                <span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Back
                            </button>                      
                            <button (click)="SearchVGMServiceCharges();" class="btn btn-primary btn-site btn-margin">
                                <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Search
                            </button>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
                <br /><br />
            </form>
        </div>
    </div>
    <div class="col-md-2"></div>
</div>