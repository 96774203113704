import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import{ShoppingCart,PaymentConfirmation} from 'src/app/core/models/ShoppingCart';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2'; 
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-new-shopping-cart-payment',
  templateUrl: './new-shopping-cart-payment.component.html',
  styleUrls: ['./new-shopping-cart-payment.component.css']
})
export class NewShoppingCartPaymentComponent implements OnInit {
  @Input() ShoppingCart:ShoppingCart;  
  @Output() outputToParent = new EventEmitter<any>();
  option:any;
  Messages:ResponseMessage[]=[];
  @Input() SCartPayment:PaymentConfirmation;
  form: FormGroup; 
  shoppingCartPayment: FormGroup;
  ShowLoader = false;
  constructor(private importchargesService: ImportchargesService,
    private ShoppingCartService:ShoppingCartService,
    private router: Router,
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer
    ) { //this.SCartPayment = new PaymentConfirmation();
      
     }

  ngOnInit(): void {
   
    console.log(this.SCartPayment);
    // this.SCartPayment=SampleJson
    // console.log(this.SCartPayment)

    // if(sessionStorage.getItem("S_ShoppingCartTotalPrice")!=null||sessionStorage.getItem("S_ShoppingCartTotalPrice")!=undefined)
    // this.ShoppingCart.TotalPrice = sessionStorage.getItem("S_ShoppingCartTotalPrice")?.toString()!;
      this.form = this.formBuilder.group(
      {
        ddPaymentType: ['', Validators.required],
        PO: [] //new FormControl({, disabled: true})        
      });

      // this.shoppingCartPayment = this.formBuilder.group(
      // {
      //   ddPaymentType: ['', Validators.required],
      //   PO: ['' ],
      // });

      this.shoppingCartPayment = this.formBuilder.group(
        {
          username: ['', Validators.required],
          password: ['', Validators.required],
          checkbox:[]
        });


  }

  addProp(event:any){
    if(event === true){      //&& this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.PayCargoType
      this.form.get("PO")?.disable();
      }
      else
      this.form.get("PO")?.enable();       
  }

  enablePO(){
    this.form.get("PO")?.enable(); 

    if(this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.LineOfCreditType || this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.CreditReserveType || this.SCartPayment.PaymentType === this.SCartPayment.PaymentTypes.CheckType){
      this.shoppingCartPayment.controls["username"].clearValidators();
      this.shoppingCartPayment.controls["username"].updateValueAndValidity();
      this.shoppingCartPayment.controls["password"].clearValidators();
      this.shoppingCartPayment.controls["password"].updateValueAndValidity();
    }
  }
  
  // LoadInitialData()  {
  //   this.GetReferenceDataForPaymentConfirmation()
  // }

  // GetReferenceDataForPaymentConfirmation () {
  //   this.importchargesService.GetReferenceDataForPaymentConfirmation().subscribe((result) => {
  //     this.SCartPayment = result;
  //     console.log(this.SCartPayment)
  //   })
  // };
    
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // ddPaymentTypeChanged(e:any){
  //   this.SCartPayment.PaymentTypes=e.target.selectedOptions[0].innerText;
   
  // }

  onSubmit(){

  }

  PayNow(){
    this.Messages = [];
    if (this.shoppingCartPayment.valid) {
        if (this.SCartPayment.AcceptTerms == true) {
          swal.fire({ 
            text: 'Do you want to proceed with payment?', 
            title: 'Are You Sure?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,     
            confirmButtonText: "Ok, Proceed!",
            confirmButtonColor: "#004165",
            cancelButtonColor:"#004165",
            focusCancel:true,
            customClass: 'swal-wide',
            reverseButtons: true
         }).then((result) => {
          if (result.isConfirmed) {
                if (this.ShoppingCart.HoldType === 'E') {
                  this.ShowLoader= true;
                  this.importchargesService.PostExportChargesPaymentProcess(this.SCartPayment).subscribe((result) => {
                    this.ShowLoader= false;
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                            this.outputToParent.emit(result.generalMessages);
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
                else if (this.ShoppingCart.HoldType === 'S') {
                  this.ShowLoader= true;
                  this.importchargesService.PostServiceOrderPaymentProcess(this.SCartPayment).subscribe((result) => {
                    this.ShowLoader= false;
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                          this.outputToParent.emit(result.generalMessages);
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
                else {
                  this.ShowLoader= true;
                  this.importchargesService.PostImportChargesPaymentProcess(this.SCartPayment).subscribe((result) => {
                    this.ShowLoader= false;
                        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0) {
                          this.outputToParent.emit(result.generalMessages);
                        }
                        else {
                            if (this.IsFieldNotBlank(result.ReturnURL)) {
                              this.router.navigateByUrl(result.ReturnURL);
                            }
                        }
                    });
                }
                // this.ShowLoader= false;
            }            
          });
        }
        else {
          swal.fire("Error...", "Please check the box to accept terms.", "error");
        }
      }      
  }

  GetOutputVal(msgData:any){
    this.outputToParent.emit(msgData);
  }

  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }

}
