import { Component, OnInit } from '@angular/core';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportchargesService } from 'src/app/core/services/exportcharges.service';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import { OogchassisflipresultsComponent } from './oogchassisflipresults/oogchassisflipresults.component';
@Component({
  selector: 'app-oogchassisflipsearch',
  templateUrl: './oogchassisflipsearch.component.html',
  styleUrls: ['./oogchassisflipsearch.component.css']
})
export class OogchassisflipsearchComponent implements OnInit {
  submitted=false;
  ShowLoader=false;
  Terminals: any;
  ShippingLines:any;  
  OOGChassisFlipSearchForm: FormGroup; 
  OOGChassisFlipSearchRefData:ExportChargesSearchModel;
  Messages: ResponseMessage[]=[];
  constructor(private exportchargesService: ExportchargesService,    
              private trackandtraceService: TrackandtraceService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) { this.OOGChassisFlipSearchRefData = new ExportChargesSearchModel(); }

  ngOnInit(): void {
    
    var tChargeCd = "O";
    let ExportChargesSearchRefData = { "TerminalChargeCode": tChargeCd };
    this.ShowLoader=true;
    this.exportchargesService.GetExportChargesSearchRefData(ExportChargesSearchRefData).subscribe((result) => {
        this.OOGChassisFlipSearchRefData = result;
        this.Terminals = result.TChargeTerminals.Terminals;
        this.ShippingLines = result.ShippingLines;
        this.Messages = result.generalMessages;
        this.ShowLoader=false;
    });    

    
    this.OOGChassisFlipSearchForm = this.formBuilder.group({
      terminals: ['', Validators.required],
      shippingLine:  ['', Validators.required],
      bKNumbers:  ['', Validators.required],
    });
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.OOGChassisFlipSearchForm.controls;
  }

  SearchOOGChassisFlipCharges(){
    this.submitted=true;
    this.Messages =[];
    if (!this.OOGChassisFlipSearchForm.invalid) {
      this.ShowLoader=true;
      this.OOGChassisFlipSearchRefData.HoldType_Cd = 'O';//'EXPOOGCHSFLIP';
      this.trackandtraceService.GetBookingDetails(this.OOGChassisFlipSearchRefData).subscribe((result)=> {
        this.ShowLoader=false;
          if (result.generalMessages.length > 0)
          { this.Messages = result.generalMessages; }
          else {
            
            const dialogRef = this.dialog.open(OogchassisflipresultsComponent, {
              data: { BookingInfo: result ,OOGChassisFlipSearchRefData : this.OOGChassisFlipSearchRefData },
              autoFocus: false,
              restoreFocus: false,
              panelClass: 'deletepanelclass'
            });
            dialogRef.afterClosed().subscribe(result => {           
            });

          }
        });
      }
  }

}
