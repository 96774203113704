import { Component,AfterViewInit, OnInit,Inject,ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort'; 
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { VGMServiceResultsComponent } from '../../../exportcharges/vgmservicesearch/vgmservice-results/vgmservice-results.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-vgmdetails-view',
  templateUrl: './vgmdetails-view.component.html',
  styleUrls: ['./vgmdetails-view.component.css']
})
export class VGMDetailsViewComponent implements AfterViewInit, OnInit {
  dataSource:any;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  displayedColumns: string[] = ['booking_nbr','containernumber','vgm'];
  constructor(public dialogRef: MatDialogRef<VGMServiceResultsComponent>,
              @Inject(MAT_DIALOG_DATA) public VgmData: any,
              private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit(): void {   
  }
  
  ngAfterViewInit() {
    this.dataSource = this.VgmData.VgmDetails;   
    this.dataSource.sort = this.sort;
  } 
  
//   sortData(sortState: Sort) {
//     if (sortState.direction) {
//       this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
//     } else {
//       this._liveAnnouncer.announce('Sorting cleared');
//     }
//  }

  
  close() {
    this.dialogRef.close('cancel');
  }

  
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'booking_nbr': return compare(a.booking_nbr, b.booking_nbr, isAsc);
        case 'containernumber': return compare(a.containernumber, b.containernumber, isAsc);
        case 'vgm': return compare(a.vgm, b.vgm, isAsc);  
        default: return 0;
      }
    });
   }
  
}

function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

