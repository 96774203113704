<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12">
	<div class="col-md-2 col-sm-2"></div>
	<div class="col-md-8 col-sm-8">
        <form name="form" [formGroup]="form">
		<!-- Payment Options -->
		<div class="row">
			<div class="form-group col-md-6 col-sm-6" style="padding-left:0px">
				<span style="color:red">*</span><label>Payment Type</label>
				<select name="ddPaymentType" class="form-control show-tick" [(ngModel)]="SCartPayment.PaymentType" (ngModelChange)="enablePO()" name="ddPaymentType" formControlName="ddPaymentType"  required>
					<option value="">Choose a Payment Type ...</option>
					<option *ngFor="let c of SCartPayment.PaymentOptions" [value]="c.PaymentOptionValue">
                        {{ c.PaymentOptionText }}
                    </option>
				</select>				
                <div *ngIf="f.ddPaymentType.errors && (f.ddPaymentType.touched ||f.ddPaymentType.dirty)" class="invalid-feedback">
                    <span style="color:red" *ngIf="f.ddPaymentType.errors.required" class="help-block">Payment Type is Required.</span>
                </div>
			</div>
			<div class="form-group col-md-6 col-sm-6" style="padding-right:0px">
				<label>PO Number</label>
				<input type="text" name="PO" class="form-control" formControlName="PO"  [disabled]="SCartPayment.AcceptTerms" autocomplete="off">
			</div>
		</div>
        
        </form>
		<!-- @*Error Message*@ -->	
		<div class="row"> 
			<div *ngIf="(SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType) && ShoppingCart.TotalPrice > SCartPayment.CheckLimit">
				<div class="alert alert-danger">
					<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;Please choose another payment type if total is greater than $ {{SCartPayment.CheckLimit}}.
				</div>
			</div>
		</div>
		<div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditCardType">
			<br />
			<div class="col-md-12 col-sm-12 text-primary">
				<label>Credit Card</label>
			</div>		
				<app-cyber-source-payment [form]="form" [ShoppingCart] = "ShoppingCart" [SCartPayment] = "SCartPayment" (outputToParent)="GetOutputVal($event)"  ></app-cyber-source-payment>
                <!-- @Html.Partial("~/Views/Partial/ShoppingCart/_CyberSourcePayment.cshtml") -->
			<br />
		</div>
		<div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType && ShoppingCart.TotalPrice < SCartPayment.CheckLimit">
			<br />
			<div class="col-md-12 col-sm-12 text-primary">
				<label>Electronic Check - Corporate</label>
			</div>
			<app-cyber-source-payment [form]="form" [ShoppingCart] = "ShoppingCart" [SCartPayment] = "SCartPayment" (outputToParent)="GetOutputVal($event)"  ></app-cyber-source-payment>
			<!-- @Html.Partial("~/Views/Partial/ShoppingCart/_CyberSourcePayment.cshtml") -->          
			<br />
		</div>
		<div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType && ShoppingCart.TotalPrice < SCartPayment.CheckLimit">
			<br />
			<div class="col-md-12 col-sm-12 text-primary">
				<label>Electronic Check - Personal</label>
			</div>
			<app-cyber-source-payment [form]="form" [ShoppingCart] = "ShoppingCart" [SCartPayment] = "SCartPayment" (outputToParent)="GetOutputVal($event)" ></app-cyber-source-payment>
			<!-- @Html.Partial("~/Views/Partial/ShoppingCart/_CyberSourcePayment.cshtml") -->
			<br />
		</div>
		<div class="row divBorder" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.LineOfCreditType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditReserveType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.CheckType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.PayCargoType">
			<div class="col-md-12 col-sm-12 text-primary" *ngIf="SCartPayment.PaymentType !== SCartPayment.PaymentTypes.PayCargoType">
				<br />
				<label *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.LineOfCreditType">Line Of Credit</label>
				<label *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditReserveType">Credit Reserve</label>
				<label *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CheckType">Pay by Check</label>
			</div>
			<div class="col-md-12 col-sm-12" >
				<div style="background: rgba(197, 195, 195, 0.14);padding-left:30px">
					<div *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CreditReserveType">
						<br />
						<label>Available Credit Reserve :</label>
						$&nbsp;<label >{{SCartPayment.CreditReserveBalance}}</label>
						<br /><br />
					</div>
					<div *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.CheckType">
						<br />Please use certified checks for amounts over $1000. Checks require
						a minimum of two days processing time and this may affect your pickup date. So
						mail your check ASAP.<br /><br>
					</div>
				</div>
				<br />
				<form name="shoppingCartPayment" [formGroup]="shoppingCartPayment" novalidate>
					<div class="col-md-12 col-sm-12 no-padding" *ngIf="SCartPayment.PaymentType === SCartPayment.PaymentTypes.PayCargoType">
						<app-pay-cargo-payment [shoppingCartPayment]="shoppingCartPayment" [SCartPayment] = "SCartPayment" ></app-pay-cargo-payment>
						<!-- @Html.Partial("~/Views/Partial/ShoppingCart/_PayCargoPayment.cshtml") -->
						<!--<br />-->
					</div>
					<div class="col-md-12 col-sm-12 no-padding">
						<br />
						<label style="font-weight:bold" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">Contact info for fee mitigation:</label>&nbsp;
						<label style="white-space: pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{SCartPayment.OSRAmitigationcontact}}</label><br />
						<label *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)"><span style="font-weight:bold">Digital Inquiries: </span> {{SCartPayment.OSRAfacilitymitigationcontact}}</label>
						<!--<label style="font-weight:bold" *ngIf="ShoppingCart.LineItems[0].Product.ProductType == 1">Digital Inquiries:</label>-->&nbsp;
						<!--<label style="white-space: pre-wrap" *ngIf="ShoppingCart.LineItems[0].Product.ProductType == 1">{{SCartPayment.OSRAfacilitymitigationcontact}}</label>-->
						<br />
						<input type="checkbox" name="checkbox" value="check" id="agree" formControlName="checkbox" [(ngModel)]="SCartPayment.AcceptTerms" (ngModelChange)="addProp($event)" />		By submitting payment, I confirm that I am
						<!--By checking the box , I hereby represent and warrant that
	(i) I am fully aware of the nature and amount of the charges owed
	(ii) I acknowledge the validity of the charges
	(iii) I am fully authorized to make this payment on behalf of the parties that have right, title and interest in and to the cargo being
	released upon payment of the charges owed.<br /><br />-->
						<label style="white-space: pre-wrap">{{SCartPayment.OSRAMessage}}</label>
						<br /><br />
					</div>
					<!-- PAY ANCILLARY BUTTON --> 
					<!-- {{shoppingCartPayment.invalid}} - {{!SCartPayment.AcceptTerms}} - {{Messages.length}}  - {{((SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType) && ShoppingCart.TotalPrice > SCartPayment.CheckLimit)}} -->
					<button class="btn btn-primary btn-site btn-margin" [disabled]="shoppingCartPayment.invalid || !SCartPayment.AcceptTerms || Messages.length > 0 || ((SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckCorpType || SCartPayment.PaymentType === SCartPayment.PaymentTypes.ElectronicCheckPersonalType) && ShoppingCart.TotalPrice > SCartPayment.CheckLimit)" (click)="PayNow()">
						<span class="glyphicon glyphicon-usd btnStyle" aria-hidden="true" style="font-size:17px"></span>
						Pay Now
					</button>
				</form>
			</div>
			<br />
		</div>
	</div>
	<div class="col-md-2 col-sm-2"></div>
</div>
