import { Component, OnInit } from '@angular/core';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { LoginService } from 'src/app/shared/services/login.service';
import { Notification,NotificationList,AddContainerFilters,TerminalInfo,AddNotificationResults,DeleteContainerFilters, SelectedEvents } from '../../models/Notification.model';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import swal from 'sweetalert2'; 
import { AuthService } from 'src/app/shared/services/auth.service';
import { AddnotificationsComponent } from './addnotifications/addnotifications.component';
@Component({
  selector: 'app-notificationlist',
  templateUrl: './notificationlist.component.html',
  styleUrls: ['./notificationlist.component.css']
})
export class NotificationlistComponent implements OnInit {
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  MaxNotificationCount:number=200;
  NotificationList :Notification[]=[];
  NotificationResults:ResponseMessage[]=[];
  NotificationEvents:SelectedEvents;
  NotificationCount:number=0;
  selectedContainerCollection:string[]=[];
  dataSource: MatTableDataSource<NotificationList>;
  selection = new SelectionModel<NotificationList>(true, []);
  displayedColumns: string[] = ['select','ContainerNumber', 'TerminalName', 'email']; 
  AddContainerFilters :AddContainerFilters;
  countvalidation = false;
  CurrentCount:number=0;
  ResultContainers :AddNotificationResults; 
  DeleteContainerFilters:DeleteContainerFilters;
  constructor( private loginService: LoginService,         
               private AuthService: AuthService,            
               public dialog: MatDialog        
              ) { }

  ngOnInit(): void {
    this.LoadIntialData();   
  }

  LoadIntialData(){
    this.ShowLoader = true;
    this.loginService.GetNotificationList(Number(this.AuthService.User_Id())).subscribe((result) => {
    this.Messages = result.generalMessages;
    if(result.NotificationsList != undefined){
      this.NotificationList = result.NotificationsList;
      result.NotificationsList.filter((item :any) => item.email = item.email.replace(/,/g, '\n'))
      this.dataSource = new MatTableDataSource(result.NotificationsList);
      this.NotificationEvents = result.NotificationEvents;
      this.NotificationCount = result.NotificationsList.length;
      this.CurrentCount = this.NotificationCount;
    }
    else {
      this.dataSource = new MatTableDataSource();
      this.selectedContainerCollection = [];
    }      
    this.ShowLoader = false;
    },
    // error => {
    //   this.ShowLoader=false;
    //   alert(error);
    // }
    );     
  }
  
  OnChange(event:any,row:any) {
    if(event.checked === true)  
      this.selectedContainerCollection.push(row["ContainerWatchListId"]);    
    else   
      this.selectedContainerCollection = this.arrayRemove(this.selectedContainerCollection, row["ContainerWatchListId"]);   
    event ? this.selection.toggle(row) : null;
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(e:any) {
    this.isAllSelected() ?  this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    if(e.checked === true) {
      var tempDataSource = this.NotificationList;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedContainerCollection.push(tempDataSource[i].ContainerWatchListId.toString());
      // this.outputToParent.emit(this.parentObject);
    }
    else  {
      var tempDataSource = this.NotificationList;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedContainerCollection = this.arrayRemove(this.selectedContainerCollection, tempDataSource[i].ContainerWatchListId);
    }
  }

  arrayRemove(arr:any, value:any) {
    return arr.filter(function(ele:any){
        return ele != value;
    });
  }

  filter(text :any) {    
      return text.replace(/,/g, '\n');
  };
  
  AddcontainerPopup(event:any) {    
    this.countvalidation = false;
    this.AddContainerFilters = new AddContainerFilters();
    this.AddContainerFilters.Contact_ID = Number(this.AuthService.User_Id());
    this.AddContainerFilters.Email = String(sessionStorage.SecurityEmail).replace(/^"(.*)"$/, '$1');   
    this.AddContainerFilters.EventType = this.EventString(this.NotificationEvents); 
    const dialogRef = this.dialog.open(AddnotificationsComponent, {
      data: { AddContainerFilters: this.AddContainerFilters, CurrentCount: this.CurrentCount},
      autoFocus: false,
      position:{'top' :'30px'},
      restoreFocus: false,
      panelClass: 'deletepanelclass'
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result != undefined && result !== 'cancel') {        
        this.NotificationResults = result;      
      this.LoadIntialData();
      this.ShowLoader = false;
      }
    });

  }    

  EventString(array:any) {
    var e;
    for (var i = 0; i <= array.length - 1; i++) {
        if (i == 0) e = array[i].ContainerEventTypeId.toString();
        else e += "," + array[i].ContainerEventTypeId.toString();
      }
      return e;
  }

  deleteContainers(){
    if(this.selectedContainerCollection.length == 0) {
      swal.fire("Error...", "Please select atleast one container to delete", "error");
      return;
    }
    swal.fire({ 
      text: 'Do you want to delete?', 
      title: 'Are You Sure?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,     
      confirmButtonText: "Ok, Proceed!",
      confirmButtonColor: "#004165",
      cancelButtonColor:"#004165",
      focusCancel:true,
      customClass: 'swal-wide',
      reverseButtons: true
   }).then((result) => {
    if (result.isConfirmed) {
        this.DeleteContainerFilters = new DeleteContainerFilters();
        for (var i = 0; i <= this.selectedContainerCollection.length - 1; i++) {
          if (i == 0) this.DeleteContainerFilters.ContainerWatchListId = this.selectedContainerCollection[i];
          else this.DeleteContainerFilters.ContainerWatchListId += "," + this.selectedContainerCollection[i];
        }
        this.ShowLoader=true;
        this.loginService.DeleteContainerWatchList(this.DeleteContainerFilters).subscribe((result) => {
          if((result.generalMessages).length !== 0) 
            this.Messages = result.generalMessages;
          else
          {
            this.selectedContainerCollection =[];
            this.LoadIntialData();
          }
        this.ShowLoader=false;
        },
        error => {
          this.ShowLoader=false;
          alert(error);
        });  
      }}
    ) 
  }
}
