<form name="VGMDetailsViewForm" novalidate>
    <div class="modal-header custom-modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h4 class="modal-title text-center">Ordered Weight Results</h4>
    </div>
    <div class="modal-body">
        <div class="row"  style="height:400px;overflow-y:auto;">
            <div class="mat-elevation-z8"  >
                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"  > 
                   <!-- booking_nbr Column -->
                   <ng-container matColumnDef="booking_nbr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Booking # </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext">  {{element.booking_nbr | uppercase}}  </td>
                    </ng-container>
        
                    <!-- containernumber Column -->
                    <ng-container matColumnDef="containernumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Container # </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.containernumber | uppercase}} </td>
                    </ng-container>
        
                    <!-- vgm Column -->
                    <ng-container matColumnDef="vgm">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  class="tableheadertext">Total Shipping Weight(Lbs) </th>
                    <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.vgm}} </td>
                    </ng-container>    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div *ngIf="VgmData.VgmDetails.length == 0" class="no-records"> No Results found.</div>  
                </div>
        </div>
    </div>
    <div class="modal-footer form-horizontal noPrint">
        <button class="btn btn-primary btn-lg" (click)="close()">
            <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
        </button>
    </div>
   
</form>

