import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2'; 

@Component({
  selector: 'app-oogchassisflipresults',
  templateUrl: './oogchassisflipresults.component.html',
  styleUrls: ['./oogchassisflipresults.component.css']
})
export class OogchassisflipresultsComponent implements OnInit {
  OOGChassisFlipSearchRefData:ExportChargesSearchModel;  
  submitted= false;
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  BookingInfo:BookingHoldCharges;
  ShowPay:boolean=true;
  OOGChassisFlipResultsForm: FormGroup;
  constructor(private trackandtraceService: TrackandtraceService,
    public dialogRef: MatDialogRef<OogchassisflipresultsComponent>,
    @Inject(MAT_DIALOG_DATA) public OOGChassisFlipdata: any,
    private AuthService: AuthService, 
    private formBuilder: FormBuilder,
    private router: Router) 
    { this.BookingInfo = new BookingHoldCharges(); }

  ngOnInit(): void {
 
    this.BookingInfo = this.OOGChassisFlipdata.BookingInfo;   
    this.OOGChassisFlipSearchRefData = this.OOGChassisFlipdata.OOGChassisFlipSearchRefData ;

    this.OOGChassisFlipResultsForm = this.formBuilder.group({
      ddCustomers: ['', Validators.required],
      PaidQty:['',Validators.required]
    });

  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.OOGChassisFlipResultsForm.controls;
  }  
  
  close() {
    this.dialogRef.close('cancel');
  }

  QtyPaidChange(){
    this.BookingInfo.ExportBookingHoldCharges[0].totalAmount = 0.00;
    if (this.IsFieldNotBlank(this.BookingInfo.ExportBookingHoldCharges[0].quantity_paid)) {
        var BookingQty = this.IsFieldNotBlank(this.BookingInfo.ExportBookingHoldCharges[0].booking_qty) ? this.BookingInfo.ExportBookingHoldCharges[0].booking_qty : 0
        var PaidQty = this.IsFieldNotBlank(this.BookingInfo.ExportBookingHoldCharges[0].paid_qty) ? this.BookingInfo.ExportBookingHoldCharges[0].paid_qty : 0
        if (this.BookingInfo.ExportBookingHoldCharges[0].quantity_paid > (BookingQty - PaidQty)) {
            this.ShowPay = false;
        } else {
            this.ShowPay = true;
            var total_amount = new Number(0.00);
            total_amount = this.BookingInfo.ExportBookingHoldCharges[0].quantity_paid * this.BookingInfo.ExportBookingHoldCharges[0].chassisflip_rate;
            this.BookingInfo.ExportBookingHoldCharges[0].totalAmount = Number(total_amount.toFixed(2));
        }
    }
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  IsFieldNotBlank(val:any) { if (val === undefined || val === '' || val === null) { return false; } else { return true; } }

  payOOGChassisFlipCharges(){
        this.Messages = [];
        this.submitted = true;
        if (this.BookingInfo.ExportBookingHoldCharges[0].totalAmount <= 0) {
            swal.fire({ text: "Please enter Quantity to pay.", title: 'Error...',
            icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
        }
        else if (!this.IsFieldNotBlank(this.BookingInfo.Client_Id))
        { swal.fire({title: "Error...",text: "Please choose customer to pay.", icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",}); }
        else {
            this.ShowLoader = true;
            this.BookingInfo.User_Id = Number(this.AuthService.User_Id());
            this.BookingInfo.Terminal = this.OOGChassisFlipSearchRefData.TerminalId;
            this.BookingInfo.HoldType_Cd = this.OOGChassisFlipSearchRefData.HoldType_Cd;
            this.BookingInfo.Client_Name = this.BookingInfo.Clients[0].ClientName;
            this.trackandtraceService.GetShoppingCartDetailsForExportBookingHolds(this.BookingInfo).subscribe( (result) => {
              this.ShowLoader = false;
              // Ensure that there are no messages, and then determine what payment page to send the User
                if (result.generalMessages.length > 0)
                { this.Messages = result.generalMessages; }
                else if (result.MerchantData.CyberSourceWebMobile_FLG === true) {
                  sessionStorage.setItem("S_CartInfo", JSON.stringify(result));
                  this.dialogRef.close('cancel');
                  this.router.navigateByUrl('/newcheckoutconfirmation');
                }
                else {
                  sessionStorage.setItem("S_CartInfo", JSON.stringify(result));
                  this.dialogRef.close('cancel');
                  this.router.navigateByUrl('/checkoutconfirmation');
                }
            });
        }
    }

}
