import { Component, OnInit,Input } from '@angular/core';
import * as moment from 'moment';
import { ShoppingCart } from 'src/app/core/models/ShoppingCart';
@Component({
  selector: 'app-shopping-cart-receipt',
  templateUrl: './shopping-cart-receipt.component.html',
  styleUrls: ['./shopping-cart-receipt.component.css']
})
export class ShoppingCartReceiptComponent implements OnInit {
  todayDate :Date = new Date();// string = moment(new Date()).format("MM/dd/yyyy");
  @Input() ShoppingCart:ShoppingCart;
  constructor() { }

  ngOnInit(): void {
    console.log("ShoppingCart : " + ShoppingCart)
  }

}
