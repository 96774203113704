<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<form [formGroup]="AddContainerForm"  style ="display: block;"  name="AddContainerForm" novalidate>
    <!-- <div class="modal" role="dialog" style ="display: block;" appOutside  (clickedOutside)="closePopup();$event.stopPropagation()" >
        <div class="modal-dialog" role="document"  style ="display: block;"> -->
            <div class="modal-content">
                <div class="modal-header custom-modal-header">
                    <button type="button" class="close" (click)="closePopup()">&times;</button>
                    <h4 class="modal-title text-center">Add Containers to Notification List</h4>
                </div>
        <div class="modal-body" style ="width: 600px;">
            <div class="row form-group">
            <div class="col-md-4 col-sm-4 text-right"><span style="color: red">*</span><label>Terminal : </label>
            </div>
            <div class="col-md-7 col-sm-7 text-left text-primary" [ngClass]="{ 'has-error' : submitted && f.terminals.errors }"  >            
                <select name="terminals" formControlName="terminals" class="form-control show-tick" required data-select-picker data-selectpicker-options="this.TerminalData.Terminals" [(ngModel)]="this.AddContainerFilters.TerminalId">
                    <option value="">Choose Terminal ...</option>
                    <option *ngFor="let option of this.TerminalData.Terminals" value="{{option.TerminalId}}">{{option.CodeNameLocation}}</option>
                </select>
                <span  *ngIf="submitted && f.terminals.errors?.required"  class="help-block">Terminal is Required.</span>
            </div>
        </div>
        <div class="form-group row minPadding-top">
            <div class="col-md-4 col-sm-4 text-right">
                <span style="color: red">*</span><label>Containers : </label>
                <label style="width: 100px;">(max {{MaxNotificationCount}})</label>
            </div>
            <div class="col-md-7 col-sm-7 text-left text-primary" [ngClass]="{ 'has-error' :  countvalidation || (submitted && f.containernumbers.errors?.required) }"> <!--[ngClass]="{ 'has-error' : countvalidation||(f.containernumbers.$error.required)}"-->
                <textarea name="containernumbers" formControlName="containernumbers" class="form-control" style="min-height: 150px; background-color: transparent;" [(ngModel)]="AddContainerFilters.ContainerNumbers" placeholder="Enter Container Numbers (Limit {{MaxNotificationCount}} Entries)" oninput="this.value = this.value.toUpperCase()" xml-invalidchar data-capitalize required></textarea>
                <span class="help-block" *ngIf="submitted && f.containernumbers.errors?.required">Container Numbers are required.</span>
                <span class="help-block" *ngIf="countvalidation">Maximum limit of Containers is exceeded</span>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-4 col-sm-4 text-right"></div>
            <div class="col-md-7 col-sm-7 text-left">Remaining {{MaxNotificationCount-CurrentCount}} of {{MaxNotificationCount}}</div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
        <div class="form-group row minPadding-top">
            <div class="col-md-4 col-sm-4 text-right"><span style="color: red">*</span><label>email : </label>
            </div>
            <!--[ngClass]="{ 'has-error' : AddEmail}"-->
            <div class="col-md-7 col-sm-7 text-left text-primary" [ngClass]="{ 'has-error' :
            ((( AddContainerFilters.Email === '' || AddContainerFilters.Email === undefined) && (email2 === ''|| email2 ===undefined)&& (email3 === '' ||email3 === undefined) )) || (f.emailId.errors?.pattern) ||
            (AddContainerFilters.Email != '' || email2 != '' || email3 != '') && (( this.AddContainerFilters.Email === (email2 || email3 )) || (email2 === (email3 || this.AddContainerFilters.Email)) || (email3 === (email2 || this.AddContainerFilters.Email)))  }"  >
                <input type="email" name="emailId" formControlName="emailId"  email class="form-control" restrict-comma [(ngModel)]="AddContainerFilters.Email"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" /><!--ng-pattern="/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/"-->
                 <span class="help-block"  *ngIf="(f.emailId.errors && f.emailId.errors?.pattern) || ((( AddContainerFilters.Email === '' || AddContainerFilters.Email === undefined) && (email2 === ''|| email2 ===undefined)&& (email3 === '' ||email3 === undefined)))">Atleast one valid email is required.</span>
                 <span class="help-block" *ngIf="(AddContainerFilters.Email != '' || email2 != '' || email3 != '') && (( this.AddContainerFilters.Email === (email2 || email3 )) || (email2 === (email3 || this.AddContainerFilters.Email)) || (email3 === (email2 || this.AddContainerFilters.Email)))">Please enter different Emails.</span>
            
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-4 col-sm-4 text-right"></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">
                <input type="text" name="email2" formControlName="email2"  class="form-control" restrict-comma [(ngModel)]="email2" />
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
        <div class="row minPadding-top">
            <div class="col-md-4 col-sm-4 text-right"></div>
            <div class="col-md-7 col-sm-7 text-left text-primary">
                <input type="text" name="email3" formControlName="email3"  class="form-control" restrict-comma [(ngModel)]="email3" />
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>       
    <div class="modal-footer form-horizontal">
        <div class="row">
            <button class="btn btn-primary btn-lg" (click)="closePopup()">
                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
            </button>&nbsp;
            <button class="btn btn-primary btn-lg" (click)="AddContainers();">               
                <span class="glyphicon glyphicon-plus btnStyle" aria-hidden="true"></span>&nbsp;Add
            </button>
        </div>
    </div>
</div>
<!-- </div></div> -->
    </div>
</form>