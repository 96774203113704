import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { LoginService } from 'src/app/shared/services/login.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort'; 
import { States, Countries, UserContactInfo } from 'src/app/shared/interfaces/login.interface';
import {MatTableDataSource} from '@angular/material/table';
import { TranslateService } from 'src/app/shared/services/translateservice.service';
import { IAssociatedClients } from '../../models/view-registration.model';
@Component({
  selector: 'app-view-registration',
  templateUrl: './view-registration.component.html',
  styleUrls: ['./view-registration.component.css']
})
export class ViewRegistrationComponent implements AfterViewInit, OnInit  {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  Messages: ResponseMessage[]=[];
  ShowLoader = false;
  UserContactInfo: UserContactInfo;
  dataSource: MatTableDataSource<IAssociatedClients>;
  Registrationdata:any;
  displayClientsCollection =[];
  displayedColumns: string[] = ['ClientName', 'FullName', 'First_Name_DS', 'Last_Name_DS','Title_DS','Alt_State_NM','CountryName'];
  constructor(private loginService: LoginService,
              private cookieService: CookieService,
              private AuthService: AuthService, 
              private _liveAnnouncer: LiveAnnouncer
              )  {  }

  ngOnInit(): void {
   
  }
  
  LoadIntialData(){
    this.ShowLoader =true;
    this.loginService.GetCompanyRegistration(this.AuthService.User_Id()).subscribe((result) => {
    this.Messages = result.generalMessages;
    if(result.AssociatedClients != undefined){
      this.dataSource = new MatTableDataSource(result.AssociatedClients);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.displayClientsCollection = result.AssociatedClients;
    }
    else {
      this.dataSource = new MatTableDataSource();
      this.displayClientsCollection = [];
    }
    this.ShowLoader =false;      
    });
  }

  ngAfterViewInit() {
    this.LoadIntialData();  
  } 
  
  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
 }

}
