    export class ShoppingCart {
        Messages: string;
        generalMessages: any;
        LineItems: LineItem[];
        ShippingAddress:AddressInfo;
        PaymentData:PaymentData;
        MerchantData:MerchantData;
        PaymentStatus :PaymentStatus;
        ShoppingCart_Id: number;
        TotalPrice:number;
        UserId: string;
        CustomerId: string;
        CustomerName: string;
        AdditionalData: [];
        IsDebugMode: boolean = false;
        IsbypassCybersource: boolean = false;
        HoldType: string;
        SubHoldType: string;
        TerminalLogo:any;
        PaymentLock: [];
        HoldReleaseErrors:string;
        Client:string;
        ClientAddress:string;
        ClientCity:string;
        ClientContact:string;
        TerminalLoc:string;
        TerminalCity:string;
        TerminalAttn:string;
        TerminalAddr:string;
        CyberSourceWebMobileTransactionID:string;
        CyberSourceWebMobileDecision:string;
        CyberSourceWebMobileReasonCode:string;
        CyberSourceWebMobileErrorMessage:string;

    }

    export class LineItem{
        HoldType:string;
        Quantity:number;
        Product:Product;
        TotalPrice:number;
    }

    export class CyberSource{
        access_key:string;
        amount:string;
        currency:string;
        cyberSourceWebMobileURL:string;
        echeck_sec_code:string;
        generalMessages:any;
        profile_id:string;
        reference_number:string;
        signature:string;
        signed_date_time:Date;
        signed_field_names:string;
        submit:string;
        locale:string;
        transaction_type:string;
        transaction_uuid:string;
        unsigned_field_names:string;
    }

    export class AddressInfo
    {
    FirstName:	string;
    LastName:		string;
    Address1:		string;
    Address2:		string;
    Country:		string;
    City:			string;
    State:		string;
    PostalCode:	string;
    Email:		string;
    Phone:		string;
    IpAddress:	string;
    }

    export class PaymentData{
        BillingInfo:AddressInfo;
        PurchaseOrder:string;
        PayCargoInfo:PayCargoInfo;
        TransactionReferenceId:string;
        PaymentTypeName:string;
        LastErrorMessage:string;
    }

    export class PayCargoInfo{
        Username:		string;
        Password:		string;	 
        ApiKey:			string;
        ApiSecret:		string;
        VendorId:		string;
        Type:			string;
        Direction:		string;
        HasArrived:		string;
        Number:			string;
        Total:			number;		
        PaymentDueDate:	string;
        MakePayment:		string;
        CustomerRefNumber:string;
    }

    export class MerchantData{
        
        MerchantId:					string; 
        TerminalId:					number; 
        RegionName:					string; 
        TerminalName:					string; 
        TerminalCode:					string; 
        CyberSourceID:					string; 
        WarrantyNum:					string; 
        VerificationNum:				string; 
        CyberSourceProfileID_CC:		string; 
        CyberSourceAccessKey_CC:		string; 
        CyberSourceSecretKey_CC:		string; 
        CyberSourceProfileID_ECC:		string; 
        CyberSourceAccessKey_ECC:		string; 
        CyberSourceSecretKey_ECC:		string; 
        CyberSourceProfileID_ECP:		string; 
        CyberSourceAccessKey_ECP:		string; 
        CyberSourceSecretKey_ECP:		string; 
        CyberSourceWebMobile_FLG:		boolean;	 
        PayCargoAPIKey:				string;  
        PayCargoAPISecret:				string;  
        PayCargoVendorId:				string;  
        LogoId:						number;	 
                    
    }

    export enum PaymentStatus
    {
        PaymentCollected,
        PaymentNotCollected,
        PaymentRejected,
        PaymentProcessing
    };

    export class Product
    {
        ProductType:ProductType;
        ProductName:string;
        ProductDescription:string;
        ProductId:string;
        ProductPrice:number;
        HoldTypeFriendlyName:string;
        ContainerNumber:string;
        PaidDate:Date;
        IsHoldReleased:boolean;
        PortLFD:Date;
        LineLFD:Date;
        Bols:string;
        FADate:Date;
        OSRAMessage:string;
        Gkey:any;
        HoldType:string;
        Terminal:string;
        HoldDate:Date;
      ContainerId: string;
      OSRATier: string;
      OSRArate: string
      OSRAquantity: string;
      OSRAextendedPrice: string;
      OSRAmitigationcontact: string;
      OSRAfacilitymitigationcontact: string
    }

    export enum ProductType
    {
        AncillaryHold,
        Demurrage,
        Invoice,
        OOGChassisFlipHold,
        OOGStripStuffHold,
        VGMService,
        ExtraGate,
        LienAncillaryHold,
        LienDemurrage
    };


    export class PaymentConfirmation
    {
        CreditCard:boolean = false;
        LineOfCredit:boolean = false;
        Check:boolean = false;
        ElectronicCheck:boolean = false;
        PayCargo:boolean = false; //Added payment type on 05/06/2019
        CreditReserve:boolean = false;
        AcceptTerms:boolean = false;
        ShoppingCart_Id:number = 0;
        LineOfCreditBalance:number = 0;
        CreditReserveBalance:number = 0;
        PaymentType:string;
        PurchaseOrderNumber:string;
        CheckLimit:number;
        HoldReleaseErrors:string;
      OSRAMessage: string;
      OSRAmitigationcontact: string;
      OSRAfacilitymitigationcontact: string
        ClientIP:string;
        PaymentTypes:PaymentTypes;
        PaymentOptions:PaymentOptions[];
        BillingInfo:AddressInformation;
        CreditCardInfo:CreditCardPaymentInfo;
        ElectronicCheckInfo:ElectronicCheckPaymentInfo;
        PayCargoInfo:PayCargoPaymentInfo;
        Years:any;
        Months:any;
        generalMessages:any;
    }

    export class PayCargoPaymentInfo{
        Username:string;
        Password:string;
        CardType:string;
    }

    export class ElectronicCheckPaymentInfo{
        CheckType:string;
        CheckNumber:string;
        RoutingNumber:string;
        AccountNumber:string;
        LicenseNumber:string;
        LicenseState:string;
        CorporateTaxID:string;
    }

    export class CreditCardPaymentInfo{
        ExpirationYear:string;
        ExpirationMonth:string;
        VerificationNumber:string;
        AccountNumber:string;
        CardType:string;
    }

    export class AddressInformation{
        FirstName:string;
        LastName:string;
        Address1:string;
        Address2:string;
        Country:string;
        City:string;
        State:string;
        PostalCode:string;
        Email:string;
        Phone:string;
        IpAddress:string;
    }

    export class PaymentTypes{
        CreditCardType:string = "8";
        ElectronicCheckType:string = "9";
        ElectronicCheckCorpType:string = "10";
        ElectronicCheckPersonalType:string = "11";
        CreditReserveType:string = "7";
        LineOfCreditType:string = "3";
        CheckType:string = "2";
        PayCargoType:string= "12"; //Added payment
    }

    export class PaymentOptions{
        PaymentOptionText:string;
        PaymentOptionValue:string;
    }
