import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2'; 
@Component({
  selector: 'app-oogstripstuffresults',
  templateUrl: './oogstripstuffresults.component.html',
  styleUrls: ['./oogstripstuffresults.component.css']
})
export class OogstripstuffresultsComponent implements OnInit {
  OOGStripStuffSearchRefData:ExportChargesSearchModel;  
  Messages: ResponseMessage[]=[];
  submitted=false;
  ShowLoader = false;
  BookingInfo:BookingHoldCharges;
  ShowPay:boolean=true;
  OOGStripStuffResultsForm: FormGroup;
  constructor(private trackandtraceService: TrackandtraceService,
    public dialogRef: MatDialogRef<OogstripstuffresultsComponent>,
    private AuthService: AuthService, 
    @Inject(MAT_DIALOG_DATA) public OOGStripStuffdata: any,
    private formBuilder: FormBuilder,
    private router: Router) 
    { this.BookingInfo = new BookingHoldCharges(); }

  ngOnInit(): void {
 
    this.BookingInfo = this.OOGStripStuffdata.BookingInfo;   
    this.OOGStripStuffSearchRefData = this.OOGStripStuffdata.OOGStripStuffSearchRefData ;

    this.OOGStripStuffResultsForm = this.formBuilder.group({
      ddCustomers: ['', Validators.required]
    });

  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.OOGStripStuffResultsForm.controls;
  }  
  
  close() {
    this.dialogRef.close('cancel');
  }
  
  IsFieldNotBlank(val:any) { if (val === undefined || val === '' || val === null) { return false; } else { return true; } }

  payOOGStripStuffCharges () {
    this.Messages = [];
    this.submitted=true;
    if (this.BookingInfo.ExportBookingHoldCharges[0].totalAmount <= 0) {
      swal.fire({title:"Error...", text: "Total amount must be greater than zero.", icon:  "error",customClass: 'swal-wide',confirmButtonColor:"#004165"});
    }
    else if (!this.IsFieldNotBlank(this.BookingInfo.Client_Id))
    { swal.fire({title:"Error...",text:  "Please choose customer to pay.", icon:  "error",customClass: 'swal-wide',confirmButtonColor:"#004165"}); }
    else {
        this.ShowLoader = true;
        this.BookingInfo.User_Id = Number(this.AuthService.User_Id());
        this.BookingInfo.Terminal = this.OOGStripStuffSearchRefData.TerminalId;
        this.BookingInfo.HoldType_Cd = this.OOGStripStuffSearchRefData.HoldType_Cd;
        this.BookingInfo.Client_Name = this.BookingInfo.Clients[0].ClientName;
        this.trackandtraceService.GetShoppingCartDetailsForExportBookingHolds(this.BookingInfo).subscribe( (result) => {
          this.ShowLoader = false;
          // Ensure that there are no messages, and then determine what payment page to send the User
            if (result.generalMessages.length > 0)
            { this.Messages = result.generalMessages; }
            else if (result.MerchantData.CyberSourceWebMobile_FLG === true) {
              sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
              this.dialogRef.close('cancel');
              this.router.navigateByUrl('/newcheckoutconfirmation');
            }
            else {
              sessionStorage.setItem("S_CartInfo",  JSON.stringify(result));
              this.dialogRef.close('cancel');
              this.router.navigateByUrl('/checkoutconfirmation');
            }
        });
    }
  }

}
