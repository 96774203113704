import { Component, OnInit, ViewEncapsulation, ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import { InvoiceData,InvoiceFilters,Invoices} from 'src/app/core/models/InvoiceFilters';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import * as XLSX from 'xlsx';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import * as moment from 'moment';
@Component({
  selector: 'app-importaccounthistory',
  templateUrl: './importaccounthistory.component.html',
  styleUrls: ['./importaccounthistory.component.css']
})
export class ImportaccounthistoryComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  exportActive:boolean = false;
  today =  new Date(moment(new Date).format("M/D/YYYY"));
  Messages: ResponseMessage[]=[];
  InvoiceHistory: FormGroup;
  ShowLoader = false;
  FromDate: Date
  ToDate: Date
  Dateformat: any;
  InvoiceAncil: boolean = true;
  ContainerNum: string = "";
  InvoiceNum: string = "";
  StatementNum: string = "";
  minDate: Date;
  searchactive: boolean;
  InvoiceData: InvoiceData[]=[];;
  InvoiceFilters: InvoiceFilters;
  InvoiceListData:InvoiceData[]=[];;
  displayClientsCollection =[];
  selectedInvoiceCollection:InvoiceData[]=[];
  dataSource: MatTableDataSource<InvoiceData>;
  displayedColumns2: string[] = ['select','InvoiceNumber', 'StatementNumber', 'PoNumber', 'InvoiceDate', 'Terminal', 'TotalAmount', 'OutstandingBalance', 'StatusName'];
  selection = new SelectionModel<InvoiceData>(true, []);
  displayedColumns1: string[] = ['select','InvoiceNumber', 'StatementNumber', 'ReferenceNumber', 'InvoiceDate', 'Customer','Terminal', 'TotalAmount', 'OutstandingBalance', 'StatusName'];
  submitted=false;
  constructor(
    private formBuilder: FormBuilder,
    private importchargesService: ImportchargesService,
    private AuthService: AuthService, 
    private _liveAnnouncer: LiveAnnouncer
  ) {
    
    this.ToDate = new Date()
    this.FromDate = new Date();
    this.dataSource = new MatTableDataSource();
    this.InvoiceFilters = new InvoiceFilters();
    this.InvoiceFilters.FromDate = this.today;
    this.InvoiceFilters.ToDate = this.today;
    this.InvoiceFilters.ContainerNum='';
    this.InvoiceFilters.StatementNum='';
    this.InvoiceFilters.InvoiceNum='';
    this.InvoiceFilters.InvoiceAncil =true;
    this.InvoiceFilters.User_ID = Number(this.AuthService.User_Id());
  }

  ngOnInit(): void {
          
    this.InvoiceHistory = this.formBuilder.group({
      FromDate: [this.FromDate, Validators.required],
      ToDate: [this.ToDate, Validators.required],
      ContainerNum: ['', Validators.required],
      InvoiceNum: ['', Validators.required],
      StatementNum: ['', Validators.required],
      InvoiceAncil: ['', Validators.required],
      User_id:[localStorage.Contact_ID]
    },
      { validator: this.checkDates });
  }

  get f() { return this.InvoiceHistory.controls; }

  printPage() {
    if(this.selection.isEmpty())
    {
      this.ShowPrintPrompt()
    }
   else   {
    this.selection.clear()
    window.print();
   }
  }

  exporttoExcel()  {
    this.exportActive = true;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'importaccounthistory.xlsx');    
  }

  checkDates(group: FormGroup) {
    if (group.controls.ToDate.value < group.controls.FromDate.value) {
      return { notValid: true }
    }
    return null;
  }    

  calculateDiff() {
    let days = Math.floor((this.GetToDate() - this.GetFromDate()) / 1000 / 60 / 60 / 24);
    return days;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  
  masterToggle(e:any) {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    if(e.checked === true) {
      var tempDataSource = this.InvoiceListData;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedInvoiceCollection.push(tempDataSource[i]);
    }
    else {
      var tempDataSource = this.InvoiceListData;
      for(let i=0;i< tempDataSource.length;i++)
        this.selectedInvoiceCollection = this.arrayRemove(this.selectedInvoiceCollection, tempDataSource[i]);
    }
  }  

  arrayRemove(arr:any, value:any) {
    return arr.filter(function(ele:any){
        return ele != value;
    });
  }

  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  isSomeSelected() {
    console.log(this.selection.selected);
    return this.selection.selected.length > 0;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    return '';
  }
  
  GetFromDate() {
    return this.InvoiceHistory.get('FromDate')?.value;
  }

  GetToDate() {
    return this.InvoiceHistory.get('ToDate')?.value;
  }
 
  OnChange(event:any,row:any) {
    if(event.checked === true)  
      this.selectedInvoiceCollection.push(row);    
    else   
      this.selectedInvoiceCollection = this.arrayRemove(this.selectedInvoiceCollection, row);   
    event ? this.selection.toggle(row) : null;
  }

  ExportInvoiceReport(rowData:any) {
    var invnum = rowData.InvoiceNumber;
    if (invnum.length == 0) {
      swal.fire({ text: "Please enter Invoice number", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
        return false;
    }
    else {        
        var reportName = 'DemurrageInvoices';
        if (this.InvoiceFilters.InvoiceAncil)  reportName = 'AncillaryInvoices';        
        var QueryString = '?reportID=' + reportName + '&invoiceNumber=' + invnum;
        window.open(window.location.origin + '/ReportForm.aspx' + QueryString , '_blank');        
        return true;
    };
  }

  ExportStatementReport() {
    if (this.InvoiceFilters.StatementNum.length == 0) {
      swal.fire({ title: "Error...", text: "Please enter statement number ", icon: 'error', customClass: 'swal-wide', confirmButtonColor: "#004165", });
      return;
    } else {

      this.importchargesService.CheckStatementExists(this.InvoiceFilters.StatementNum, this.InvoiceFilters.InvoiceAncil).subscribe((result) => {
        let tabWindowId = window.open('about:blank', '_blank');
        if (result.StatementExists) {
          var reportName = 'DemurrageStatement';
          if (this.InvoiceFilters.InvoiceAncil) reportName = 'AncillaryStatement';
          var QueryString = '?reportID=' + reportName + '&statementID=' + this.InvoiceFilters.StatementNum;
          this.InvoiceFilters.StatementNum = '';
          setTimeout(function () {  // window.open(window.location.origin + '/ReportForm.aspx' + QueryString , '_blank');
            tabWindowId!.location.href = window.location.origin + '/ReportForm.aspx' + QueryString;
          }, 1001);
          return true;
        }
        else {
          setTimeout(function () { tabWindowId!.close(); }, 1001);
          swal.fire({ title: "Error...", text: "Statement Number not found", icon: "error", customClass: 'swal-wide', confirmButtonColor: "#004165", });
          // angular.element('#ancillary').focus();
          return false;
        }
      });
    }
  };

  InvImportPrintPreview() {
    localStorage.PrintInvoiceFilters = [];
    if (this.selectedInvoiceCollection.length == 0 )  {   
      swal.fire({ text: "Please select invoices that you want to Print", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return;             
    }    
    localStorage.InvoiceAncil = this.InvoiceFilters.InvoiceAncil;       
    if(this.InvoiceFilters.InvoiceAncil === true){
      if (this.selectedInvoiceCollection.length > 0) {          
        localStorage.setItem("PrintInvoiceFilters",JSON.stringify(this.selectedInvoiceCollection));        
      }  
      window.open(window.location.origin + '/InvoicePreview', '_blank'); 
    }
    else {
      if (this.selectedInvoiceCollection.length > 0) {
        localStorage.setItem("PrintInvoiceFilters",JSON.stringify(this.selectedInvoiceCollection));        
      }  
      window.open(window.location.origin + '/DemurageInvoice', '_blank'); 
    }  
  };
  
  validateImpFormInput() {
    if (!this.InvoiceFilters) return;
    var endeExpDate = new Date(this.InvoiceFilters.ToDate);
    var startExpDate = new Date(this.InvoiceFilters.FromDate);
    if (endeExpDate < startExpDate) {
      swal.fire({ text: "The To date cannot be before From Date. Please check date range for your request", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return false;
    }

    if (this.calculateDiff() > 60) {
      swal.fire({
        text: 'Please limit your search dates to a span of 60 days or less',
        title: 'Error...',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: "#004165",
        customClass: 'swal-wide',
      })
    }

    if ((this.InvoiceFilters.FromDate != null &&  this.InvoiceFilters.ToDate != null  && this.InvoiceFilters.ContainerNum == '' && this.InvoiceFilters.InvoiceNum == '' && this.InvoiceFilters.StatementNum == '') || (this.InvoiceFilters.StatementNum != '' || this.InvoiceFilters.InvoiceNum != '' || this.InvoiceFilters.ContainerNum !='')){

      this.InvoiceHistory.controls["ContainerNum"].clearValidators();
      this.InvoiceHistory.controls["ContainerNum"].updateValueAndValidity();
      
      this.InvoiceHistory.controls["InvoiceNum"].clearValidators();
      this.InvoiceHistory.controls["InvoiceNum"].updateValueAndValidity();

      this.InvoiceHistory.controls["StatementNum"].clearValidators();
      this.InvoiceHistory.controls["StatementNum"].updateValueAndValidity();
    }   

    //validate input 
    if (this.InvoiceHistory.valid == false) {
      swal.fire({ text: "Please enter required information.", title: 'Error...', icon: 'error', customClass: 'swal-wide',confirmButtonColor:"#004165",});
      return false;
    }    
    return true;
  };
  
  SearchInvoiceInfo() {
    //check if input is valid
    if (this.validateImpFormInput() != true) return;
    this.searchactive=true;
    this.selection.clear() ;
    if (this.InvoiceHistory.valid) {

      if (this.InvoiceFilters.ContainerNum.toUpperCase() === '') { this.InvoiceFilters.ContainerNum = 'ALL' }
      else {
        if (this.InvoiceFilters.ContainerNum !== '' || this.InvoiceFilters.ContainerNum.toUpperCase() !== 'ALL') {
          if (this.InvoiceFilters.ToDate === null && this.InvoiceFilters.FromDate === null) {
            this.InvoiceFilters.ToDate = this.today;
            this.InvoiceFilters.FromDate = new Date(moment('01/1/2003').format("M/D/YYYY"));
          }
        }
      }
      if (this.InvoiceFilters.InvoiceNum.toUpperCase() === '') { this.InvoiceFilters.InvoiceNum = 'ALL' }
      else {
        if (this.InvoiceFilters.InvoiceNum !== '' || this.InvoiceFilters.InvoiceNum.toUpperCase() !== 'ALL') {
          if (this.InvoiceFilters.ToDate === null && this.InvoiceFilters.FromDate === null) {
            this.InvoiceFilters.ToDate = this.today;
            this.InvoiceFilters.FromDate = new Date(moment('01/1/2003').format("M/D/YYYY"));
          }
        }
      }
      if (this.InvoiceFilters.StatementNum.toUpperCase() === '') { this.InvoiceFilters.StatementNum = 'ALL' }
      else {
        if (this.InvoiceFilters.StatementNum !== '' || this.InvoiceFilters.StatementNum.toUpperCase() !== 'ALL') {
          if (this.InvoiceFilters.ToDate === null && this.InvoiceFilters.FromDate === null) {
            this.InvoiceFilters.ToDate = this.today;
            this.InvoiceFilters.FromDate = new Date(moment('01/1/2003').format("M/D/YYYY"));
          }
        }
      }

      this.ShowLoader = true;
      this.selectedInvoiceCollection =[];
      this.importchargesService.GetAllInvoicesForUserIdByDates(this.InvoiceFilters).subscribe(
        (result) => {
        this.ShowLoader = false;
        this.InvoiceData=result.InvoiceData;
        this.InvoiceListData=result.InvoiceData;
        this.Messages = result.generalMessages;
        if(this.InvoiceData!=null) {
          this.dataSource = new MatTableDataSource(result.InvoiceData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.displayClientsCollection=result.InvoiceData;

        }
        else {
          this.dataSource = new MatTableDataSource();
          this.displayClientsCollection = [];
        }
      }
      );
    }
  }

  RadioOptionSelect(InvoiceAncil: boolean) {
    this.InvoiceAncil = InvoiceAncil;
    this.searchactive=false;
    this.selection.clear() ;
    this.selectedInvoiceCollection =[];
  }

  ShowPrintPrompt()  {
    swal.fire({ 
       text: 'Please select invoices that you want to Print', 
       title: 'Error...',
       icon: 'error',
       showConfirmButton: true,
       confirmButtonColor: "#004165",
       customClass: 'swal-wide', 
  
  });
  }
}
