<app-header></app-header>
<div>
<mat-toolbar style="height: 45px;background-color:#004165;position: absolute;z-index: 100;color: white;">
<button mat-flat-button style="height: 45px;background-color:#004165;position: absolute;z-index: 100;color: white;" *ngIf="this.loggedIn==true" (click)="this.sidenav_opened = !this.sidenav_opened"   aria-label="Example icon-button with a menu" >
  <mat-icon>menu</mat-icon><span style ="font-size: 16px;"> Menu</span> 
</button>
</mat-toolbar>
</div>
<mat-sidenav-container>
  <mat-sidenav #snav [mode]="over" [(opened)]="this.sidenav_opened" class="mat-sidenav">
    <mat-nav-list>      
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">      
        <mat-tree-node  *matTreeNodeDef="let node" >          
          <a mat-list-item class="nav-list-item" [ngStyle]="{'background-color': ((node.level==0 && URLName == node.url)  ? 'white' : 'unset') }"  (click)="changeView(node.url,snav);" [routerLinkActiveOptions]="{exact: true}"  routerLink="{{ node.url }}" [routerLinkActive]="['link-active']">
            <span *ngIf="node.level==0" style="padding: 15px;" [ngClass]="node.class"></span>
            <span *ngIf="node.level==1" style="padding: 15px;"></span>
           <span [ngStyle]="{'font-weight': (node.level==1 ? 'lighter' : 'bold'),'margin-top':(node.level==1 ? '0px' : '7px') }">{{ node.name  }}</span> 
          </a>        
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node  *matTreeNodeDef="let node;when: hasChild" (click)="treeNodeexpand(node,treeControl.isExpanded(node));"  matTreeNodeToggle  class='menu-l1-title matdivider'  >
          <a mat-list-item class="nav-list-item"  routerLinkActive="linkactive"  >
             <span *ngIf="node.level==0" style="padding: 15px;"  [ngClass]="node.class"></span>
                 <span style="min-width:70%;margin-top: 7px;">
            {{ node.name  }}
          </span>  
          <mat-icon class="pointer">{{ treeControl.isExpanded(node) ? "expand_more" : "expand_less" }}</mat-icon>
        </a>          
        </mat-tree-node>
      </mat-tree>     
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="body-content"  style="margin-top:40px;min-height: 550px !important;">  
      <router-outlet #o="outlet"></router-outlet>
    </div>   
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>
