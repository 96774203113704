import { Component, OnInit,Input } from '@angular/core';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import{ShoppingCart,PaymentConfirmation} from 'src/app/core/models/ShoppingCart';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-pay-cargo-payment',
  templateUrl: './pay-cargo-payment.component.html',
  styleUrls: ['./pay-cargo-payment.component.css']
})
export class PayCargoPaymentComponent implements OnInit {
  Messages:ResponseMessage[]=[];
  // @Input() ShoppingCart:ShoppingCart;
  @Input() SCartPayment:PaymentConfirmation;
  @Input() shoppingCartPayment: FormGroup;
  constructor(private formBuilder: FormBuilder) { }
  
  get f(): { [key: string]: AbstractControl } {
    return this.shoppingCartPayment.controls;
  }

  ngOnInit(): void {

    

  }


  

}
