import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
// import { OidcSecurityStorage } from "angular-auth-oidc-client";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';
import * as forge from 'node-forge';

@Injectable({
  providedIn: "root"
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
  //   private oidcSecurityStorage: OidcSecurityStorage, 
    private cookieService: CookieService
    ) { }
  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // request = request.clone({
    //   withCredentials: true,
    // });
  //   // setTimeout(() => {this.loaderService.show(); }, 0);
  //   let token: any;
  //   let aCookie = this.cookieService.get("AuthCookie");
  //   let cc = this.cookieService.get("cc");
  //   if (!cc && aCookie) {
  //       let c = JSON.parse(aCookie);
  //       if(c){
  //         token = c.bearer;
  //         //console.log('interceptor:' + token);
  //       }else{
  //         token = this.oidcSecurityStorage.read("authorizationData");
  //       }
  //   }
  //   else {
  //     token = this.oidcSecurityStorage.read("authorizationData");
  //     //console.log(token);
  //   }
    // request = request.clone({
    //   headers: request.headers.set("Authorization", "Bearer " + token)
    // });

let cookie = this.cookieService.get("TOPSUserKey");
if (cookie !== undefined && cookie != "") {
    var publicKey = cookie.split("&")[2].replace("PublicKey=", ""),
    privateKeyString = cookie.split("&")[3].replace("PrivateKey=", ""),
    privateKey = [],
    signature,
    // params = "",
    timestamp = new Date().getTime(),
    // urlPath = config.url,
    queryString = "";//"?" + JSON.stringify(params)
    var hmac = forge.hmac.create(),
    // data = data ? angular.toJson(data) : "",
    finalBytes = [];

    for (var i = 0; i < privateKeyString.length; ++i) {
        privateKey.push(privateKeyString.charCodeAt(i));
    }
    if (queryString === "?") {
        queryString = "";
    }
    let url = request.url.substring(request.url.indexOf('/api'),request.url.length);
    hmac.start('sha256', privateKeyString);
    hmac.update(url.toLowerCase() + ":" + request.method + ":" + queryString.toLowerCase() + ":" + timestamp);
    var sig1 = hmac.digest().getBytes();

    for (var i = 0; i < sig1.length; ++i) {
        finalBytes.push(sig1.charCodeAt(i));
    }
    var str = String.fromCharCode.apply(null, finalBytes);

    signature = btoa(str);
    let token = "TOPS-AUTH apikey=\"" + publicKey + "\", signature=\"" + signature + "\", utctimestamp=\"" + timestamp + "\"";
    request = request.clone({
      headers: request.headers.set("Authorization",token)
    });
  }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });
    request = request.clone({
      headers: request.headers.set("Accept", "text/html; q=1.0, text/*; q=0.8, image/gif; q=0.6, image/jpeg; q=0.6, image/*; q=0.5, */*; q=0.1,gzip, deflate")
    });
    request = request.clone({
      headers: request.headers.set("Cache-Control", "no-cache, no-store, must-revalidate, post- check=0, pre-check=0")
    });
    request = request.clone({
      headers: request.headers.set("Pragma", "no-cache")
    });   
    request = request.clone({
      headers: request.headers.set("Expires", "0")
    });

     request = request.clone({
        headers: request.headers.set("Access-Control-Allow-Origin", "*")

    });


    return next.handle(request).pipe(
      //finalize(() => setTimeout(() => { this.loaderService.hide(); } , 200)),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error.reason ? error.error.reason : "",
          status: error.status
        };

        // if (
        //   data["status"] === 401 &&
        //   error["error"]["reason"] === "invalid_token"
        // ) {
        //   /* Call the refresh token service here and do paste below line inside success method*/
        //   // return next.handle(request);
        // }
        return throwError(error);
      })
    );
  }
}
