import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/shared/services/login.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ClientType,Clients } from 'src/app/shared/interfaces/landingpage.model';
@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.css']
})
export class AdditionalInfoComponent implements OnInit {
  Messages: ResponseMessage[]=[];
  AdditionalInfoForm: FormGroup;
  showmessage = false;
  ShowLoader = false;
  companytypevalidation = false;
  message = "";
  clients:Clients[];
  ClientTypeData:ClientType[];
  submitted:false;
  UpdateClient:Clients;
  constructor(private loginService: LoginService,
    public dialogRef: MatDialogRef<AdditionalInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public UserClientData: any,
    private formBuilder:FormBuilder,
  ) { 
    this.UpdateClient = new Clients();
    let cleints = UserClientData.UserClients;
    if(cleints[0].ClientTypeId == '0') cleints[0].ClientTypeId = '' ;
    this.clients= UserClientData.UserClients;
    
  }

  ngOnInit(): void {
    this.ShowLoader = true;
    this.loginService.GetClientType().subscribe((result)=> {
      this.ShowLoader = false;
      if(result.generalMessages != 0)
        this.dialogRef.close('cancel');
      
      this.ClientTypeData = result.ClientTypes;
    });

    this.UpdateClient.ClientID = this.clients[0].ClientID;
    if (this.clients[0].ClientTypeId !== '0')  { this.UpdateClient.ClientTypeId = this.clients[0].ClientTypeId.toString(); }

    this.AdditionalInfoForm = this.formBuilder.group({
      companytype: ['', [Validators.required]],
    });
  }

  get f() { return this.AdditionalInfoForm.controls; }

  
  close() {
    this.dialogRef.close('cancel');
  }
  

  Submit(){
    if (this.AdditionalInfoForm.controls['companytype'].valid == false) {
      this.companytypevalidation = true;
      return;
  }
  this.ShowLoader = true;
  this.loginService.UpdateClientType(this.UpdateClient).subscribe((result) => {  
    this.ShowLoader = false;
      if (result.Message != null)  {
          this.message = "An error occured while processing the request. Please try again";
          this.showmessage = true;
          return;
      }
      this.dialogRef.close('cancel');      
  });

};
  

}
