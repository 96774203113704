
<form class="form-horizontal" [formGroup]="UpdatePasswordForm" (ngSubmit)="onSubmit(UpdatePasswordForm.value)" name="UpdatePasswordForm" novalidate>
    <div style="display:block;width:600px;">    
        <div class="modal-header custom-modal-header">
            <h4  class="modal-title text-center">Update Password</h4>
        </div>
        <div class="modal-body">
        <div class="row">
            <div style="padding-right: 50px;">
                <div *ngIf="passwordUpdateSuccessful == true" class="modal-body">
                    <div class="alert alert-success">
                        <span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Password successfully updated!
                    </div>
                </div>    
                <div class="row" *ngIf="this.Messages.length > 0">
                <div *ngFor="let Msg of this.Messages">
                    <div [ngClass]="Msg.severity===1?'alert alert-info text-center':Msg.severity===3? 'alert alert-danger text-center':Msg.severity===2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                    </div>
                </div>
                </div>
                <br>
                <!-- Current Password -->
                <div class="form-group">
                    <div class="row" *ngIf="passwordUpdateSuccessful != true">
                        <div class="col-md-5 col-sm-5 text-right padding-top">
                            <label for="currentPassword">Current Password:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 input-group text-left">
                            <input type="password" formControlName="CurrentPassword" name="CurrentPassword" class="form-control" required>
                            <span *ngIf="f.CurrentPassword.errors  && (f.CurrentPassword.touched ||f.CurrentPassword.dirty)" >
                                <span style="color:#b94a48"  *ngIf="f.CurrentPassword.errors.required" class="help-block">Current Password is required.</span>
                            </span> 
                        </div>
                    </div>
                </div>
                <!-- New Password -->
                <div class="form-group">
                    <div class="row" *ngIf="passwordUpdateSuccessful != true">
                        <div class="col-md-5 col-sm-5 text-right padding-top">
                            <button type="button" class="btn btn-info btn-xs" style="margin-right: 10px;" (click)="ShowHelpPrompt()">?</button><label for="newPassword">New Password:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 input-group text-left">
                            <input type="password" formControlName="NewPassword" name="NewPassword" class="form-control" ng-pattern="" required>
                            <span *ngIf="f.NewPassword.errors  && (f.NewPassword.touched ||f.NewPassword.dirty)">
                                <span style="color:#b94a48"  *ngIf="f.NewPassword.errors.required"  class="help-block">New Password is required.</span>
                            </span>
                            <span style="color:#b94a48" *ngIf="f.NewPassword.errors?.pattern" class="help-block">Enter a valid Password.</span>
                        </div>
                    </div>
                </div>    
                <!-- Confirm Password -->
                <div class="form-group">
                    <div class=" row" *ngIf="passwordUpdateSuccessful != true">
                        <div class="col-md-5 col-sm-5 text-right padding-top">
                            <label for="confirmPassword">Confirm Password:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 input-group text-left">
                            <input type="password" formControlName="ConfirmPassword"  name="ConfirmPassword" class="form-control" required>
                            <span *ngIf="f.ConfirmPassword.errors  && (f.ConfirmPassword.touched ||f.ConfirmPassword.dirty)">
                                <span style="color:#b94a48" *ngIf="f.ConfirmPassword.errors.required" class="help-block">Confirm Password is required.</span>
                            </span>
                            <span style="color:#b94a48" class="help-block" *ngIf="f.ConfirmPassword.errors?.mustMatch">Passwords do not match.</span>
                        </div>
                    </div>
                </div>
           </div>
          </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group btn-group-lg">
                    <button type="button" class="btn btn-primary btn-site" style="padding-left: 25px;padding-right: 25px;" (click)="closePopup()">
                        <span *ngIf="passwordUpdateSuccessful == false">Cancel</span>
                        <span *ngIf="passwordUpdateSuccessful == true">Close</span>
                    </button>
            </div>&nbsp;
            <div class="btn-group btn-group-lg" >
                <button *ngIf="passwordUpdateSuccessful == false" style="padding-left: 25px;padding-right: 25px;"  type="submit" [disabled]="!UpdatePasswordForm.valid"class="btn btn-primary btn-site" >Submit</button>                     
            </div>                  
        </div>
    </div>
</form>