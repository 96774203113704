import { Component, OnInit,Input,Output,EventEmitter,ViewChild } from '@angular/core';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { TrackFilter } from 'src/app/core/models/trackandtrace.model';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import { VGMServiceResultsComponent } from '../../exportcharges/vgmservicesearch/vgmservice-results/vgmservice-results.component';
import { VGMDetailsViewComponent } from './vgmdetails-view/vgmdetails-view.component';
@Component({
  selector: 'app-booking-inquiry-results',
  templateUrl: './booking-inquiry-results.component.html',
  styleUrls: ['./booking-inquiry-results.component.css']
})
export class BookingInquiryResultsComponent implements OnInit {
  @Input()  BookingInformationlist :any[]=[];  
  @Output() outputToParent = new EventEmitter<any>();
  VGMServiceSearchRefData:ExportChargesSearchModel;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  VgmviewdetailsCollection:[]=[];
  ExportChargesSearchRefData:ExportChargesSearchRefData;
  ShowLoader = false;
  TerminalNameSelected:any;
  Messages: ResponseMessage[]=[];
  displayedColumns: string[] = ['BOOKING','EQUIPMENTSIZE','QTY','HAZ','LINE','PaidQty','VESSELNAME',
  'VOYAGE','BEGINRECEIVE','CARGOCUTOFF','RECEIVED','EMPTIESAVAIL','RESERVEDONLY','PREADVISED','TMFHOLD'];
  dataSource: MatTableDataSource<any>;
  @Input() TrackFilter:TrackFilter;  
  BookingNumber :string;
  constructor(private trackandtraceService: TrackandtraceService,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.BookingNumber = this.TrackFilter.BookingNum;
    if(this.BookingInformationlist.length > 0){
      this.TrackFilter.terminals.forEach(item => { if(item.TerminalId == this.TrackFilter.TerminalId) { this.TerminalNameSelected = item.TerminalName }});
      this.dataSource= new MatTableDataSource(this.BookingInformationlist);
      this.dataSource.sort = this.sort;
    }
  }
  
  Ordervgmdetails(element:any){        
    this.ShowLoader = true;
    this.ExportChargesSearchRefData = { "TerminalChargeCode": 'V' };
    this.trackandtraceService.GetExportChargesSearchRefData(this.ExportChargesSearchRefData).subscribe((result)=> {
        this.VGMServiceSearchRefData = result;
        let ShippingInfo = this.VGMServiceSearchRefData.ShippingLines.filter( item =>  ((item.CompanySCACCode == element.LINE) || (item.CompanyBICCode == element.LINE)) );
        this.VGMServiceSearchRefData.ShippingLineId = ShippingInfo[0].ShippingLineId;
        this.VGMServiceSearchRefData.HoldType_Cd = 'V';
        this.VGMServiceSearchRefData.TerminalId = this.TrackFilter.TerminalId;
        this.VGMServiceSearchRefData.BookingNumber = element.BOOKING;
        this.outputToParent.emit(result.generalMessages); 

        this.trackandtraceService.GetBookingDetails(this.VGMServiceSearchRefData).subscribe( (result) => {       
          if (result.generalMessages.length > 0) { 
            this.outputToParent.emit(result.generalMessages); 
          }
          else {
            const dialogRef = this.dialog.open(VGMServiceResultsComponent, {
              data: { BookingInfo: result ,VGMServiceSearchRefData : this.VGMServiceSearchRefData },
              autoFocus: false,
              restoreFocus: false,
              panelClass: 'deletepanelclass'
            });
            dialogRef.afterClosed().subscribe(result => {           
            });
            this.ShowLoader= false;
            this.Messages =[];
          }
          this.ShowLoader = false;
        }
        );
    });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
         let mem = '';
      switch (sort.active) {
        case 'BOOKING': return compare(a.BOOKING, b.BOOKING, isAsc);
        case 'EQUIPMENTSIZE': return compare(a.EQUIPMENTSIZE, b.EQUIPMENTSIZE, isAsc);
        case 'QTY': return compare(a.QTY, b.QTY, isAsc);
        case 'HAZ': return compare(a.HAZ, b.HAZ, isAsc);
        case 'LINE': return compare(a.LINE, b.LINE, isAsc);
        case 'PaidQty': return compare(a.PaidQty, b.PaidQty, isAsc);
        case 'VESSELNAME': return compare(a.VESSELNAME , b.VESSELNAME, isAsc);       
        case 'VOYAGE': return compare(a.VOYAGE, b.VOYAGE, isAsc);    
        case 'BEGINRECEIVE': return compare(a.BEGINRECEIVE , b.BEGINRECEIVE  , isAsc);
        case 'CARGOCUTOFF': return compare(a.CARGOCUTOFF , b.CARGOCUTOFF, isAsc);
        case 'RECEIVED': return compare(a.RECEIVED , b.RECEIVED , isAsc); 
        case 'EMPTIESAVAIL': return compare(a.EMPTIESAVAIL , b.EMPTIESAVAIL , isAsc); 
        case 'RESERVEDONLY': return compare(a.RESERVEDONLY , b.RESERVEDONLY , isAsc); 
        case 'PREADVISED': return compare(a.PREADVISED , b.PREADVISED , isAsc); 
        case 'TMFHOLD': return compare(a.TMFHOLD , b.TMFHOLD , isAsc);        

        default: return 0;
      }
    });
   }

  Viewvgmdetails(obj:any) {

    this.TrackFilter.viewvgm = true;
    this.TrackFilter.vShippingLineId = obj.LINE;
    this.TrackFilter.BookingNum = obj.BOOKING.toString();
    this.ShowLoader = true;
    this.trackandtraceService.GetBookingInformation(this.TrackFilter).subscribe( (result) => {       
      this.ShowLoader = false;
        this.VgmviewdetailsCollection = result.VgmViewDetails;
        const dialogRef = this.dialog.open(VGMDetailsViewComponent, {
          data: { VgmDetails: this.VgmviewdetailsCollection  },
          autoFocus: false,
          restoreFocus: false,
          panelClass: 'deletepanelclass'
        });
        dialogRef.afterClosed().subscribe(result => {           
        });
    });
  }

}


function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}