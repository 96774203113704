<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12 grid-padding" style="padding-bottom: 8px;" Id ="GateTransaction">
    <div data-st-table="gatetransactiondataCollection" data-st-safe-src="gatetransactiondata.GateTransaction">
    <div class="row col-md-12 col-sm-12 no-padding">
    <div class="row">
    <div class="col">
    <div class="col-md-7 col-sm-7 no-padding">
        <h3 class="text-left lbltitle"><strong style="color:#FF6218">&nbsp;Gate Transaction</strong></h3>
    </div>
    </div>
    <div *ngIf="Gatetransactionlist.length" style="padding-top:10px;padding-bottom:10px; " class="col text-right">
    <button (click)="exportToExcel('#Gatetable')" class="btn btn-primary btn-site btn-margin">
    <span class="glyphicon glyphicon-arrow-up" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Export to Excel
    </button>
    </div>
    </div>
    <div class="mat-elevation-z8"   #TABLE>
        <table mat-table [dataSource]="dataSource" #table  (matSortChange)="sortData($event)" matSort>  <!--*ngIf="this.Gatetransactionlist.length > 0"-->
           <!-- Interchange Column -->
           <ng-container matColumnDef="NBR">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Interchange </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext">
                <a style="cursor:pointer" (click)="EIRDetails(element)">{{element.NBR}}</a>  </td>
            </ng-container>

            <!-- STATUS Column -->
            <ng-container matColumnDef="STATUS">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.STATUS}} </td>
            </ng-container>

            <!-- MISSIONDETAIL Column -->
            <ng-container matColumnDef="MISSIONDETAIL">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Move </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.MISSIONDETAIL}} </td>
            </ng-container>    

            <!-- TRUCKENTERED Column -->
            <ng-container matColumnDef="TRUCKENTERED">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Entered </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TRUCKENTERED | date:'MM-dd-yyyy HH:mm'}} </td>
            </ng-container>

            <!-- TRUCKEXITED Column -->
            <ng-container matColumnDef="TRUCKEXITED">
            <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Exit </th>
            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TRUCKEXITED | date:'MM-dd-yyyy HH:mm'}} </td>
            </ng-container>
             <!-- SHIPPINGLINE Column -->
             <ng-container matColumnDef="SHIPPINGLINE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Line </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.SHIPPINGLINE}} </td>
                </ng-container>
                 <!-- TRKC_ID Column -->
            <ng-container matColumnDef="TRKC_ID">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Truck </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.TRKC_ID}} </td>
                </ng-container>
                 <!-- CONTAINER Column -->
            <ng-container matColumnDef="CONTAINER">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Container </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.CONTAINER}} </td>
                </ng-container>
                 <!-- CTR_EQSZ Column -->
            <ng-container matColumnDef="CTR_EQSZ">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> SzTpHt </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">{{element.CTR_EQSZ}}/{{element.CTR_EQTP}}/{{element.CTR_EQHT}} </td>
                </ng-container>
                 <!-- CHASSIS Column -->
            <ng-container matColumnDef="CHASSIS">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Chassis </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.CHASSIS}} </td>
                </ng-container>
                 <!-- EQO_NBR Column -->
            <ng-container matColumnDef="EQO_NBR">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Booking </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.EQO_NBR}} </td>
                </ng-container>
                 <!-- VESSEL Column -->
            <ng-container matColumnDef="VESSEL">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Vessel </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.VESSEL}} - {{element.VESSELNAME}} </td>
                </ng-container>
            <ng-container matColumnDef="VOYAGE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Voyage </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.VOYAGE}}</td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
            <div *ngIf="this.Gatetransactionlist.length == 0" class="no-records"> No Results found.</div>  
        </div>
    </div>
</div>
</div>

<form name="EIRPrintDetailsForm"  class="form-horizontal" novalidate style="display:block !important;">
    <div class="modal" role="dialog" [ngStyle]="{'display':displayStyle}">        
        <div class="modal-dialog" role="document" >
            <div class="modal-content" id="EIRprintSection">
                <div class="modal-header custom-modal-header">
                    <button type="button" class="print-none" (click)="close()">&times;</button>
                    <h4 class="modal-title text-center"><strong>EIR Inquiry</strong></h4>
                </div>    
                <div class="modal-body" >
                    <div class="row" style="border-bottom: 8px solid black;">
                        <div class="col-md-4 disp-Inline" style="width:370px;padding-right:0px;">
                            <div class="row minPadding-top" style="font-size: 24px">
                                <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline"><strong>{{EIRdetails.TERMINALLONGNAME || EIRdetails.terminalLongName}}</strong></div>
                            </div>
                            <div class="row minPadding-top" style="font-size: 24px">
                                <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline" style="padding-right:0px"><strong>{{EIRdetails.LINE_ID || EIRdetails.line_id}}: {{EIRdetails.NAME || EIRdetails.name}}</strong></div>
                            </div>
                            <div class="row minPadding-top">
                                <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline">{{EIRdetails.MISSION_TYPE}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 disp-Inline" style="width:320px;padding-left:0px;padding-right:0px;">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline"></div>
                            </div>
                            <div class="row minPadding-top">
                                <div class="col-md-12 col-sm-12 text-left text-primary disp-Inline" style="padding-right:0px;">{{EIRdetails.TRKC_ID || EIRdetails.trkc_id}} {{EIRdetails.TRKC_NAME}}</div>
                            </div>
                            <div class="row minPadding-top">
                                <div class="col-md-3 col-sm-3 text-left disp-Inline"><label>Driver: </label></div>
                                <div class="col-md-9 col-sm-9 text-left text-primary disp-Inline">{{EIRdetails.DRIVER_NAME || EIRdetails.driver_name}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 disp-Inline" style="width:315px">
                            <div class="row minPadding-top" style="font-size: 24px">
                                <div class="col-md-6 col-sm-6 text-right disp-Inline"><label><strong>TICKET : </strong></label></div>
                                <div class="col-md-6 col-sm-6 text-left text-primary disp-Inline"><strong>{{EIRdetails.NBR || EIRdetails.nbr}}</strong></div>
                            </div>
                            <div class="row minPadding-top">
                                <div class="col-md-5 col-sm-5 text-left disp-Inline" style="padding-right:0px"><label><strong>Arrive : </strong></label></div>
                                <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline" style="padding-left:0px"><strong>{{EIRdetails.EIR_DATE | date:'MM/dd/yyyy HH:mm'}}</strong></div>
                            </div>
                            <div class="row minPadding-top">
                                <div class="col-md-4 col-sm-4 text-right disp-Inline"><label><strong> </strong></label></div>
                                <div class="col-md-8 col-sm-8 text-left text-primary disp-Inline"><strong> </strong></div>
                            </div>
                        </div>
                    </div>        
                    <div class="row" style="border-bottom:8px solid black">
                        <div class="col-md-4 disp-Inline" style="width:340px">
                            <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Container : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.CTR_NBR || EIRdetails.ctr_nbr}}</strong></div>
                    </div>  
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Chassis : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.CHS_NBR || EIRdetails.chs_nbr}}</strong></div>
                    </div>       
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Vessel : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.SHIP_ID || EIRdetails.ship_id}} {{EIRdetails.VOY_NBR || EIRdetails.voy_nbr}} {{EIRdetails.CALL_NBR || EIRdetails.call_nbr}}</strong></div>
                    </div>
                </div>
                <div class="col-md-4 disp-Inline" style="width:300px">
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                    </div>  
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                    </div>       
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Port : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.DISCHARGE_POINT_ID1 || EIRdetails.discharge_point_id1}}</strong></div>
                    </div>
                </div>
            
                        <div class="col-md-4 disp-Inline" style="width:365px">
                            <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Container Size/Type : </label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails.CTR_EQSZ_ID || EIRdetails.ctr_eqsz_id}} {{EIRdetails.CTR_EQTP_ID || EIRdetails.ctr_eqtp_id}} {{EIRdetails.CTR_EQHT_ID || EIRdetails.ctr_eqht_id}}</strong></div>
                    </div>  
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Chassis Size/Type : </label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails.CHS_EQSZ_ID || EIRdetails.chs_eqsz_id}} {{EIRdetails.CHS_EQTP_ID || EIRdetails.chs_eqtp_id}}</strong></div>
                    </div>       
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Release : </label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails.EQO_NBR || EIRdetails.eqo_nbr}}</strong></div>
                    </div>
                        </div>
                    </div> 
                    <div class="row" style="border-bottom:8px solid black">
                        <div class="col-md-4 disp-Inline" style="width:340px">
                            <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Gen Set : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.GENSET || EIRdetails.genset}}</strong></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Driver ID : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.TRUCK_LICENSE_NBR || EIRdetails.truck_license_nbr}}</strong></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Temp : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.TEMP_UNITS || EIRdetails.temp_units}}</strong></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label>Seals : </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"><strong>{{EIRdetails.SEAL_NBR1 || EIRdetails.seal_nbr1}}</strong></div>
                    </div>
                        </div>
                        <div class="col-md-4 disp-Inline" style="width:300px">
                            <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label> </label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"> </div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-5 col-sm-5 text-right disp-Inline"><label></label></div>
                        <div class="col-md-7 col-sm-7 text-left text-primary disp-Inline"></div>
                    </div>
                        </div>
                        <div class="col-md-4 disp-Inline" style="width:365px">
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Scale Wt : </label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails.SCALEWTLBS || EIRdetails.scaleWtLbs}} LB</strong></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label>Total Shipping Wt : </label></div>
                        <div *ngIf="PaidQTY > 0" class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong>{{EIRdetails.CTRGROSSWTLBS || EIRdetails.ctrGrossWtLbs}} LB</strong></div>
                        <div *ngIf="PaidQTY == 0" class="col-md-4 col-sm-4 text-left disp-Inline"><label>click <a style="cursor: pointer;" (click)="gobacktoTT();">here</a> to order</label></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label></label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong></strong></div>
                    </div>
                    <div class="row minPadding-top">
                        <div class="col-md-8 col-sm-8 text-right disp-Inline"><label></label></div>
                        <div class="col-md-4 col-sm-4 text-left text-primary disp-Inline"><strong></strong></div>
                    </div>
                        </div>
                    </div>                
                    <div class="row" *ngFor="let row of EIRInstruction">{{row.TEXT}}</div>                
                </div>           
                <div class="modal-footer form-horizontal noPrint">
                    <div class="row">
                        <button class="btn btn-primary btn-sm btn-site" (click)="close()">
                            <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>&nbsp;Back
                        </button>
                        <button class="btn btn-primary btn-sm btn-site text-right" (click)="printPage();" print-element-id="EIRprintSection" id="printButton"><span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print</button>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</form>
