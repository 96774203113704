<div class="container-fluid">
<div class="row " style="padding-bottom: 10px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
        <h3><strong>Frequently Asked Questions</strong></h3>
    </div>
    <div class="col-md-4"></div>
</div>

<div class="row col-md-12 col-sm-12 divTop-border-empty1 vertical-center-row"></div>
<div class="container-fluid">

    <!-- FAQ  section -->
    <div class="row">
        <br />
        <div class="col-md-2"></div>
        <div class="col-md-8">

            <div>
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="panel-title">
                               What is TOPS?
                            </h4>    
                        </mat-panel-title>
                    </mat-expansion-panel-header>                   
                        <mat-panel-description>                          
                                <p>
                            TOPS is designed to optimize the flow of cargo through APM Terminals. From tracking cargo to paying for terminal services, 
                            TOPS gives you the tools and visibility you need to effectively manage your supply chain.
                        </p>                   
                        </mat-panel-description>                     
                    </mat-expansion-panel>
                    <div style="height:5px;"></div>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                What payment methods are accepted?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    Payment can be made online via Credit Card (Visa, Mastercard, AMEX, Discover), E-Check (up to $20,000) and PayCargo. 
                                    APM Terminals also accepts wire and ACH payments. Please log into TOPS for further details. Please note funds sent via 
                                    wire or ACH must be received into APMT's bank before your container can be released.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                What payment methods are not accepted?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    APM Terminals does not accept paper checks nor does it allow payments to be made over the phone. 
                                    Additionally email guarantees of payment are not accepted.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                What is an E-Check Payment?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    The "Pay by online check with TeleCheck®" option enables you to pay directly from your existing checking
                                    account in much the same way you would pay by charge, credit or debit cards. The Online Check Option simply
                                    requires that you enter the numbers imprinted on the bottom of your paper check (also known as bank routing
                                    and account numbers). TeleCheck converts this information into an electronic item and then processes it
                                    through the Automated Clearing House (ACH) network, the same system that handles ATM transactions and the
                                    direct deposit of payroll checks. Think of it as a check without the paper.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                When using E-Check payment, are the funds immediately debited from my account?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    No. The check is processed through the bank’s electronic systems in about the same amount of time as a paper check.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                What happens if there is an error?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    Customers must provide notice of the dispute or error to their bank no later than 60 days after their bank sends
                                    a statement on which the possible error is first reflected. The bank is then responsible for reviewing the dispute
                                    or error.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                What is the PayCargo® System?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    The PayCargo System is a neutral on-line freight payment system for the global shipping industry. 
                                    It allows Payers, Vendors, and Freight Brokers to settle accounts rapidly and reliably within a standardized, secure platform. 
                                    To sign up please go to www.paycargo.com and click on Register, and signup as a carrier or a Shipper, or call PayCargo at 1-888-250-7778.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>

                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                I already have a PayCargo account. How can I pay using PayCargo within TOPS?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                      <p>
                                When submitting a payment in TOPS, select 'PayCargo' from the Payment Type list. Enter your PayCargo user name and password, and click '$ Pay Now'. 
                                After your payment is validated, you will see a payment confirmation in TOPS and a payment receipt will be sent to you by email.<br />
                                <span style="font-size:large">Please note:</span> *** PayCargo payments made through TOPS will result in immediate payment release***<br />
                                ***Any payment made directly to PayCargo (via PayCargo's website) will be processed overnight; APMT does not release with a payment receipt nor confirmation.*** </p>
                                            
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                How can I get an APM Terminals lien removed from my container?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    If a lien has been filed against a container, it means that there are charges owed to the terminal. 
                                    Charges must be satisfied before the lien will be released. If paying via Credit Card, E-Check, 
                                    or PayCargo please log into TOPS and search for Import Charges using a Container Number to view and pay for the lien charges. 
                                    For questions, or if paying via Wire Transfer, please contact (310) 221-4498 or <a href="mailto:pier400demurrage@apmterminals.com"><u>pier400demurrage@apmterminals.com</u></a> for containers at Pier 400 Los Angeles. 
                                    For all other terminals please contact (866) 855-8552 or <a href="mailto:amrdemurrage@apmterminals.com"><u>amrdemurrage@apmterminals.com</u></a>.
                                                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                Where can I view import container availability?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    TOPS has been enhanced to now include import availability (searchable by container or Bill of Lading number). This information is also available in Container Notifications. 
                                    See "How do I receive Container Notifications" for additional information.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                Where can I view additional status information?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    The newly enhanced TOPS includes options to search Booking Numbers, 
                                    Gate Transactions, Container Histories, and Vessel Schedules by terminal. Status information is also available in Container Notifications. See "How do I receive Container Notifications" for additional information.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                How do I receive Container Notifications?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    Click on Container Notifications under Menu.  Add up to 200 import containers to your Container Notifications list using the "+ Add" button.  
                                Once containers are added to your Container Notifications list, they will be available to view within this Menu item.   Also enter up to 3 emails to receive notifications on the Container Notifications List Add.   A Container Notifications Update email with the attached list of container event updates will be sent automatically twice daily to the email addresses entered.   
                                To delete containers, select either individual containers or "All" by using the buttons to the left of the container list.  Next click "X Delete" to remove.  Lastly, click "Ok, Proceed!" to confirm your removal.  Containers will automatically drop off the Container Notification List after the final event (Gate/Rail Out) has occurred and updated in Notifications.
                               </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>
                      <div style="height:5px;"></div>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <h4 class="panel-title">
                                How can I order weight services?
                              </h4>    
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                          <mat-panel-description>                          
                                  <p>
                                    TOPS offers 2 options for ordering weight services.<br />
                                (Option 1) Using Gate Transaction in Track and Trace, click on an Interchange number in the search results.  On the EIR Inquiry popup that appears, click the link next to "Total Shipping Wt:" to order and view Total Shipping Weight.<br />
                                (Option 2) Using Booking Inquiry in Track and Trace, search by choosing a terminal and entering a booking number.  If Total Shipping Weight has already been ordered, the "Total Shipping Weight" column will display "View"; otherwise click "Order" to access the Order Weight Service popup. 
                                In the Order Weight Service popup, select from the "Customer" drop down and click "$ Pay" to order weight services.
                          </p>                   
                          </mat-panel-description>                     
                      </mat-expansion-panel>

                </mat-accordion>
            </div>

            <!-- <div class="panel-group" id="accordion">

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseOne">
                        <h4 class="panel-title">
                            <a href="">What is TOPS?</a>
                        </h4>

                    </div>
                    <div id="collapseOne" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                TOPS is designed to optimize the flow of cargo through APM Terminals. From tracking cargo to paying for terminal services, 
                                TOPS gives you the tools and visibility you need to effectively manage your supply chain.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTwo">
                        <h4 class="panel-title">
                            <a href="">What payment methods are accepted?</a>
                        </h4>

                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                Payment can be made online via Credit Card (Visa, Mastercard, AMEX, Discover), E-Check (up to $20,000) and PayCargo. 
				                APM Terminals also accepts wire and ACH payments. Please log into TOPS for further details. Please note funds sent via 
				                wire or ACH must be received into APMT's bank before your container can be released.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseThree">
                        <h4 class="panel-title">
                            <a href="">What payment methods are not accepted?</a>
                        </h4>

                    </div>
                    <div id="collapseThree" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                APM Terminals does not accept paper checks nor does it allow payments to be made over the phone. 
                               Additionally email guarantees of payment are not accepted.
                            </p>
                        </div>
                    </div>
                </div>


                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseFour">
                        <h4 class="panel-title">
                            <a href="">What is an E-Check Payment?</a>

                        </h4>

                    </div>
                    <div id="collapseFour" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                The "Pay by online check with TeleCheck®" option enables you to pay directly from your existing checking
                                account in much the same way you would pay by charge, credit or debit cards. The Online Check Option simply
                                requires that you enter the numbers imprinted on the bottom of your paper check (also known as bank routing
                                and account numbers). TeleCheck converts this information into an electronic item and then processes it
                                through the Automated Clearing House (ACH) network, the same system that handles ATM transactions and the
                                direct deposit of payroll checks. Think of it as a check without the paper.
                            </p>
                        </div>
                    </div>
                </div>


                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseFive">
                        <h4 class="panel-title">
                            <a href="">When using E-Check payment, are the funds immediately debited from my account?</a>
                        </h4>

                    </div>
                    <div id="collapseFive" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                No. The check is processed through the bank’s electronic systems in about the same amount of time as a paper check.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseSix">
                        <h4 class="panel-title">
                            <a href="">What happens if there is an error?</a>
                        </h4>

                    </div>
                    <div id="collapseSix" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                Customers must provide notice of the dispute or error to their bank no later than 60 days after their bank sends
                                a statement on which the possible error is first reflected. The bank is then responsible for reviewing the dispute
                                or error.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseSeven">
                        <h4 class="panel-title">
                            <a href="">What is the PayCargo® System?</a>
                        </h4>

                    </div>
                    <div id="collapseSeven" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                The PayCargo System is a neutral on-line freight payment system for the global shipping industry. 
                                It allows Payers, Vendors, and Freight Brokers to settle accounts rapidly and reliably within a standardized, secure platform. 
                                To sign up please go to www.paycargo.com and click on Register, and signup as a carrier or a Shipper, or call PayCargo at 1-888-250-7778.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseEight">
                        <h4 class="panel-title">
                            <a href="">I already have a PayCargo account. How can I pay using PayCargo within TOPS?</a>
                        </h4>

                    </div>
                    <div id="collapseEight" class="panel-collapse collapse">
                        <div class="panel-body"> -->
                         <!--    <p>
                                When submitting a payment in TOPS, select 'PayCargo' from the Payment Type list. Enter your PayCargo user name and password, and click '$ Pay Now'. 
                                After your payment is validated, you will see a payment confirmation in TOPS and a payment receipt will be sent to you by email.<br />
                                <b style="font-size:x-large">Please note: *** PayCargo payments made through TOPS will result in immediate payment release***<br />
                                ***Any payment made directly to PayCargo (via PayCargos website) will be processed overnight; APMT does not release with a payment receipt nor confirmation.***
                            </p> -->
                            <!-- </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseNine">
                        <h4 class="panel-title">
                            <a href="">How can I get an APM Terminals lien removed from my container?</a>
                        </h4>

                    </div>
                    <div id="collapseNine" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                If a lien has been filed against a container, it means that there are charges owed to the terminal. 
				                Charges must be satisfied before the lien will be released. If paying via Credit Card, E-Check, 
				                or PayCargo please log into TOPS and search for Import Charges using a Container Number to view and pay for the lien charges. 
				                For questions, or if paying via Wire Transfer, please contact (310) 221-4498 or <a href="mailto:pier400demurrage@apmterminals.com"><u>pier400demurrage@apmterminals.com</u></a> for containers at Pier 400 Los Angeles. 
				                For all other terminals please contact (866) 855-8552 or <a href="mailto:amrdemurrage@apmterminals.com"><u>amrdemurrage@apmterminals.com</u></a>.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTen">
                        <h4 class="panel-title">
                            <a href="">Where can I view import container availability?</a>
                        </h4>

                    </div>
                    <div id="collapseTen" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                TOPS has been enhanced to now include import availability (searchable by container or Bill of Lading number). This information is also available in Container Notifications. 
                                See "How do I receive Container Notifications" for additional information.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseEleven">
                        <h4 class="panel-title">
                            <a href="">Where can I view additional status information?</a>
                        </h4>

                    </div>
                    <div id="collapseEleven" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                The newly enhanced TOPS includes options to search Booking Numbers, 
                               Gate Transactions, Container Histories, and Vessel Schedules by terminal. Status information is also available in Container Notifications. See "How do I receive Container Notifications" for additional information.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTwelve">
                        <h4 class="panel-title">
                            <a href="">How do I receive Container Notifications?</a>
                        </h4>

                    </div>
                    <div id="collapseTwelve" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                Click on Container Notifications under Menu.  Add up to 200 import containers to your Container Notifications list using the "+ Add" button.  
                                Once containers are added to your Container Notifications list, they will be available to view within this Menu item.   Also enter up to 3 emails to receive notifications on the Container Notifications List Add.   A Container Notifications Update email with the attached list of container event updates will be sent automatically twice daily to the email addresses entered.   
                                To delete containers, select either individual containers or "All" by using the buttons to the left of the container list.  Next click "X Delete" to remove.  Lastly, click "Ok, Proceed!" to confirm your removal.  Containers will automatically drop off the Container Notification List after the final event (Gate/Rail Out) has occurred and updated in Notifications.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-info">
                    <div class="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseThirteen">
                        <h4 class="panel-title">
                            <a href="">How can I order weight services?</a>
                        </h4>

                    </div>
                    <div id="collapseThirteen" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p>
                                TOPS offers 2 options for ordering weight services.<br />
                                (Option 1) Using Gate Transaction in Track and Trace, click on an Interchange number in the search results.  On the EIR Inquiry popup that appears, click the link next to "Total Shipping Wt:" to order and view Total Shipping Weight.<br />
                                (Option 2) Using Booking Inquiry in Track and Trace, search by choosing a terminal and entering a booking number.  If Total Shipping Weight has already been ordered, the "Total Shipping Weight" column will display "View"; otherwise click "Order" to access the Order Weight Service popup. 
                                In the Order Weight Service popup, select from the "Customer" drop down and click "$ Pay" to order weight services.
                            </p>
                        </div>
                    </div>
                </div> -->

            <!-- </div> -->
            <br />
            <br />
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
