<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid">    
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
            <h3><strong>Import Charge Search Results</strong></h3>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div><br />
    <div class="row">
        <br />
        <div class="col-md-12">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="row">
                                <div class="col-md-11 no-padding">
                                     <h4 style="color:#3a87ad">
                                        <span class="glyphicon glyphicon-import" ></span>&nbsp;&nbsp;<span class="panel-title">DEMURRAGE
                                        CHARGES</span>
                                    </h4>
                                </div>
                                <div class="col-md-1" style="left:40px"></div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <br> <br> <br> <br>
                        <div class="row col-md-12 col-sm-12 grid-padding" style="padding-left: 35px;">
                            <br>
                            <div class="row col-md-12 col-sm-12 no-padding" *ngIf="this.Messages.length > 0">
                                <div class="col-md-12 col-sm-12 alert alert-danger">
                                    <div *ngFor="let Msg of this.Messages" style="padding:5px;">
                                       <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- <br> -->
                                <br>
                                <div data-st-table="displayDmgCollection" data-st-safe-src="data.DemurrageHoldCharges">
                                    <div class="divBorder">                                    
                                        <div class="row col-md-12 col-sm-12 no-padding">
                                            <div class="col-md-7 col-sm-7">
                                                <h3 class="text-left lbltitle"><strong>Demurrage Charges for
                                                        {{TerminalName}} :</strong></h3>
                                            </div>
                                        </div>
                                    <div class="containerA">
                                        <div class="col-md-12" style="padding-left: 0;padding-right: 0;">
                                            <!-- <div > -->
                                      <div class="divBorder1">
                                        <table mat-table [dataSource]="dataSourceDemurrage" class="table table-striped" matSort (matSortChange)="sortData($event)">
                                          <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                              <mat-checkbox style="height:10px; margin-bottom: 16px;border-right: solid 1px gainsboro;"
                                                            (change)="$event ? masterToggleD($event) : null"
                                                            [checked]="selectionD.hasValue() && isAllSelectedD()"
                                                            [indeterminate]="selectionD.hasValue() && !isAllSelectedD()">
                                              </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                              <mat-checkbox style="height:10px;margin-top: 3px; border-right: solid 1px gainsboro;"
                                                            (click)="$event.stopPropagation()"
                                                            (change)="updateCheckedListD($event,row) ? selectionD.toggle(row) : null"
                                                            [checked]="selectionD.isSelected(row)"
                                                            *ngIf="row.DEMURRAGE == +row.DEMURRAGE && row.DEMURRAGE!=0">
                                              </mat-checkbox>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="NBR">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Container Number
                                            </th>
                                            <td mat-cell *matCellDef="let element ; let i = index; let row"
                                                class="tablebodytext">
                                              <a (click)="ShowCointeinerDetails(row,$event)" style="text-decoration: underline;cursor: pointer;">{{element.NBR}} </a>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="BL_NBR">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              BOL Number
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.BL_NBR}} </td>
                                          </ng-container>
                                          <ng-container matColumnDef="FREIGHT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Freight
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="tablebodytext">
                                              {{element.FREIGHT}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="CUSTOMS">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Customs
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="tablebodytext">
                                              {{element.CUSTOMS}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="HOLDS">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Holds
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                [ngStyle]="{'color': element.HOLDS!='RELEASED' ? 'red' : 'blue'}"
                                                class="tablebodytext">
                                              {{element.HOLDS}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="firstAvailableDt">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              FA Date
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.firstAvailableDt}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="freeTimeStartDt">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Start of Free Time
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.freeTimeStartDt}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="PORT_LFD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Port LFD
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.PORT_LFD}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="LINE_LFD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Line LFD
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.LINE_LFD}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="PREVIOUSLYGUARUNTEEDPTD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Prev. Guaranteed PTD
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.PREVIOUSLYGUARUNTEEDPTD}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="DatesDemurrageCharged">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Dates Demurrage Charged
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.DatesDemurrageCharged}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="DEMURRAGE">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Demurrage
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              <!-- apply currency pipe only if demurrage is number else display message -->
                                              {{(element.DEMURRAGE == +element.DEMURRAGE) ? (element.DEMURRAGE| currency) : (element.DEMURRAGE)}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="LINE_ID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Line
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.LINE_ID}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="allowedFreeDays">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              FTD
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.allowedFreeDays}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="detentionDemurrageRule">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              D&D Rule 
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.detentionDemurrageRule}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="InvoiceDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Invoice Date
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.InvoiceDate}}
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="InvoiceDueDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                class="tableheadertext">
                                              Invoice Due Date
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                class="tablebodytext">
                                              {{element.InvoiceDueDate}}
                                            </td>
                                          </ng-container>
                                          <tr mat-header-row *matHeaderRowDef="displayedDmgColumns"></tr>
                                          <tr mat-row *matRowDef="let row; columns: displayedDmgColumns;"></tr>
                                        </table>
          <div *ngIf="this.DemurrageHoldCharges== null" class="no-records"> No Results found.</div>
      </div>

  </div>

  </div>
          <!-- <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div> -->
                                                  <!-- @*DEMURRAGE DATA GRID*@ -->

                                                  <br>
                                                  <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                                    <div class="col-md-3"></div>
                                                    <div class="col-md-9 col-sm-12">
                                                      <div class="col-md-3 col-sm-6 text-right"><label>Total Demurrage Charge :</label></div>
                                                      <div class="col-md-2 col-sm-6 text-left"><label>${{TotalDemurrage | number : '1.2-2'}}</label></div>
                                                    </div>
                                                  </div>
                                                  <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                                    <div class="col-md-3"></div>
                                                    <div class="col-md-9 col-sm-12">
                                                      <div class="col-md-3 col-sm-6 text-right">
                                                        <label>Pick Up Date :</label>
                                                      </div>
                                                      <div class="col-md-2 col-sm-6 text-left"><label>{{ImportChargeSearchData.Date | date: 'MM-dd-yyyy'}}</label></div>
                                                    </div>
                                                  </div>
                                                  <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                                    <div class="col-md-3"></div>
                                                    <div class="col-md-9 col-sm-12">
                                                      <div class="col-md-3 col-sm-6 text-right" style="padding-top:5px">
                                                        <label>Customer : </label>
                                                      </div>
                                                      <div class="col-md-5 col-sm-6 text-left">
                                                        <select name="customers1" class="form-control show-tick" required
                                                                (click)="ClientsChangedD($event)">
                                                          <option value="">Choose Customer ...</option>
                                                          <option *ngFor="let c of Clients" [value]="c.ClientId">
                                                            {{ c.ClientName }}
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <br />
</div>
                                </div>
                                <br /><br />
                            </div><br />

                            <div class="row text-right">
                                <!-- PAY DEMURRAGE BUTTON -->
                                <button class="btn btn-primary btn-site btn-margin"
                                    [disabled]="ContainerHoldCharges.ShowPay === false || ContainerHoldCharges.DemurrageHoldCharges== null || TotalDemurrage === 0" (click)="PayDemurrage()">
                                    <span class="glyphicon glyphicon-usd btnStyle" aria-hidden="true"></span>
                                    Pay Demurrage
                                </button>
                            </div>
                            <br /><br />
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel>

                <!-- EXAM / ANCILLARY CHARGES -->

                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="row">
                                <div class="col-md-11 no-padding">
                                    <h4  style="color:#3a87ad">
                                        <span class="glyphicon glyphicon-import"></span>&nbsp;&nbsp;<span class="panel-title">EXAM /
                                        ANCILLARY CHARGES</span>
                                    </h4>
                                </div>
                                <div class="col-md-1" style="left:40px"></div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <br> <br> <br> <br>
                        <div class="row col-md-12 col-sm-12 no-padding">
                            <div>
                                <!-- <br> -->
                                <br>
                                <div data-st-table="displayDmgCollection" data-st-safe-src="data.DemurrageHoldCharges">
                                    <div class="divBorder">
                                        <!-- @*DEMURRAGE PAGING HEADER SETUP*@ -->
                                        <div class="row col-md-12 col-sm-12 no-padding">
                                            <div class="col-md-7 col-sm-7">
                                                <h3 class="text-left lbltitle"><strong>Ancillary Charges for
                                                        {{TerminalName}} :</strong></h3>
                                            </div>
                                        </div>
                                        <div class="containerA">                                        
                                            <div>
                                                <div class="col-md-12" style="padding-left: 0;padding-right: 0;">
                                                    <!-- <div > -->
                                                    <div class="divBorder1">
                                                        <table mat-table [dataSource]="dataSourceAncillary" class="table table-striped" matSort  (matSortChange)="AncsortData($event)">                                                           
                                                            <ng-container matColumnDef="select">
                                                                <th mat-header-cell *matHeaderCellDef>
                                                                    <mat-checkbox style="height:10px; margin-bottom: 16px ;"
                                                                        (change)="$event ? masterToggle($event) : null "
                                                                        [checked]="selection.hasValue() && isAllSelected()"
                                                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                                    </mat-checkbox>
                                                                </th>
                                                                <td mat-cell *matCellDef="let row">
                                                                    <mat-checkbox style="height:10px;margin-top: 4px;" (click)="$event.stopPropagation()"
                                                                        (change)="updateCheckedList($event,row)? selection.toggle(row) : null"
                                                                        [checked]="selection.isSelected(row)"
                                                                        *ngIf="!row.released">
                                                                    </mat-checkbox>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="container_number">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Container Number
                                                                </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                    {{element.container_number | uppercase}} 
                                                                </td>
                                                            </ng-container>                                                           
                                                            <ng-container matColumnDef="tops_holdname">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Hold Name</th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.tops_holdname}}
                                                                </td>
                                                            </ng-container>                                                           
                                                            <ng-container matColumnDef="hold_amount">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">Hold Amount </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.hold_amount | currency}}
                                                                </td>
                                                            </ng-container>                                                            
                                                            <ng-container matColumnDef="hold_date">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext">Hold Date </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext"> 
                                                                    {{element.hold_date | date: 'MM-dd-yyyy'}}
                                                                </td>
                                                            </ng-container>                                                            
                                                            <ng-container matColumnDef="released">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Released </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                    {{element.released === true ? 'Yes' : 'No'}}                                                                    
                                                                </td>
                                                            </ng-container>                                                           
                                                            <ng-container matColumnDef="released_date">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Released Date </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                     {{element.released_date | date: 'MM-dd-yyyy'}}
                                                                </td>
                                                            </ng-container>                                                           
                                                            <ng-container matColumnDef="ClientName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tableheadertext"> Client</th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.ClientName}} </td>
                                                            </ng-container>
                                                            <tr mat-header-row *matHeaderRowDef="displayedAncColumns"> </tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedAncColumns;"></tr>
                                                        </table>                                                       
                                                        <div *ngIf="ContainerHoldCharges.HoldCharges == null || (ContainerHoldCharges.HoldCharges != null && ContainerHoldCharges.HoldCharges.length==0)" class="no-records"> No Results found.</div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>                                      
                                        <br>
                                        <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9 col-sm-12">
                                                <div class="col-md-3 col-sm-6 text-right"><label>Total Ancillary Charge :</label></div>
                                                <div class="col-md-2 col-sm-6 text-left"><label>${{TotalAncillary | number : '1.2-2'}}  </label></div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9 col-sm-12">
                                                <div class="col-md-3 col-sm-6 text-right" style="padding-top:5px">
                                                    <label>Customer : </label>
                                                </div>
                                                <div class="col-md-5 col-sm-6 text-left">
                                                    <select name="customers1" class="form-control show-tick"
                                                        (click)="ClientsChangedA($event)" required>
                                                        <option value="">Choose Customer ...</option>
                                                        <option *ngFor="let c of Clients" [value]="c.ClientId">
                                                            {{ c.ClientName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <br /><br />
                            </div><br />
                            <div class="row text-right">
                                <!-- PAY DEMURRAGE BUTTON -->  
                                <button class="btn btn-primary btn-site btn-margin" [disabled]="ContainerHoldCharges.ShowPay === false || NonReleasedContainersExist.length === 0"
                                    (click)="PayAncillary()">
                                    <span class="glyphicon glyphicon-usd btnStyle" aria-hidden="true"></span>
                                    Pay Ancillary
                                </button>
                            </div>
                            <br /><br />
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel>
                <!-- LIEN DEMURRAGE / ANCILLARY CHARGES -->
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="row">
                                <div class="col-md-11 no-padding">
                                    <h4  style="color:#3a87ad">
                                        <span class="glyphicon glyphicon-import"></span>&nbsp;&nbsp;<span class="panel-title">LIEN DEMURRAGE / ANCILLARY CHARGES</span>
                                    </h4>
                                </div>
                                <div class="col-md-1" style="left:40px"></div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <br> <br> <br> <br>
                        <div class="row col-md-12 col-sm-12 no-padding">
                            <div>
                                <!-- <br> -->
                                <br>
                                <div data-st-table="displayDmgCollection" data-st-safe-src="data.DemurrageHoldCharges">
                                    <div class="divBorder">
                                        <!-- @*DEMURRAGE PAGING HEADER SETUP*@ -->
                                        <div class="row col-md-12 col-sm-12 no-padding">
                                            <div class="col-md-7 col-sm-7">
                                                <h3 class="text-left lbltitle"><strong>LIEN Charges for
                                                        {{TerminalName}} :</strong></h3>
                                            </div>
                                        </div>
                                        <div class="containerA">
                                            <!-- <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div>    -->

                                            <div class="col-md-12" style="padding-left: 0;padding-right: 0;">
                                                <!-- <div > -->
                                                <div class="divBorder1" style="max-height: 500px;overflow-y: scroll;">
                                                  <table mat-table [dataSource]="dataSourceLien" multiTemplateDataRows class="table table-striped">
                                                    <ng-container matColumnDef="selectRadio">
                                                      <th mat-header-cell style="border-right: solid 1px gainsboro;" *matHeaderCellDef></th>
                                                      <td mat-cell style=" border-right: solid 1px gainsboro" *matCellDef="let row">
                                                        <mat-list role="list">
                                                          <mat-list-item style="height:10px;" role="listitem">
                                                            <mat-radio-button (change)="selectLienContainer(row)">
                                                            </mat-radio-button>
                                                          </mat-list-item>
                                                        </mat-list>
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ContainerNumber">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertextNoborder"> Container Number </th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytextNoborder">
                                                        {{element.LienDemurrageCharge.ContainerNumber}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="BillLadingNumber">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> BOL Number</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        <span [innerHtml]="element.LienDemurrageCharge.BillLadingNumber"></span>   <!--{{element.LienDemurrageCharge.BillLadingNumber}} -->
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="InvoiceNumber">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext">Invoice Number </th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.InvoiceNumber}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="InvoiceDate">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext">Invoice Date </th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.InvoiceDate| date: 'MM-dd-yyyy'}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="InvoiceDueDate">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Invoice Due Date</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.InvoiceDueDate}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="firstAvailableDt">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> FA Date </th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.firstAvailableDt}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="freeTimeStartDt">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Start of Free Time </th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.freeTimeStartDt}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PortLastFreeDate">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Port LFD</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.PortLastFreeDate| date: 'MM-dd-yyyy'}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LineLastFreeDate">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Line LFD </th>
                                                      <td mat-cell *matCellDef="let element"
                                                          class="tablebodytext">
                                                        {{element.LienDemurrageCharge.LineLastFreeDate| date: 'MM-dd-yyyy'}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PickUpDate">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Pick Up Date</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.PickUpDate| date: 'MM-dd-yyyy'}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DatesDemurrageCharged">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Dates Demurrage Charged</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.DatesDemurrageCharged}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="demurragetotal">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Demurrage</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext" style="color:rgb(60,182,206)">
                                                        <strong> {{element.LienDemurrageCharge.demurragetotal| currency}}</strong>
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ShippingLineName">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Line</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.ShippingLineName}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="allowedFreeDays">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> FTD</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.allowedFreeDays}}
                                                      </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="detentionDemurrageRule">
                                                      <th mat-header-cell *matHeaderCellDef class="tableheadertext"> D&D Rule</th>
                                                      <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                        {{element.LienDemurrageCharge.detentionDemurrageRule}}
                                                      </td>
                                                    </ng-container>
                                                    <!-- <ng-container matColumnDef="collapsepanelarrow"  sticky >
  <th mat-header-cell *matHeaderCellDef class="_collapse"></th>
  <td mat-cell *matCellDef="let element" style="text-align:center" class="_collapse">                                                                 -->
                                                    <!-- <mat-icon class="cell-icon" [class.example-expanded-row]="element.expandedDetail == 1" #expandedIcon
  (click)="element.expandedDetail = element.expandedDetail === 1 ? 0 : 1; expanded(element);">
  {{element.expandedDetail == 1 ?'keyboard_arrow_down':'keyboard_arrow_up'}}</mat-icon> -->
                                                    <!-- </td>
  </ng-container> -->
                                                    <ng-container matColumnDef="expandedDetail">
                                                      <th mat-header-cell *matHeaderCellDef> </th>
                                                      <td mat-cell *matCellDef="let row" [attr.colspan]="12" [ngStyle]="{display: row.AncillaryHoldCharges != null && row.AncillaryHoldCharges.length > 0 ? 'revert' : 'none'}">
                                                        <div class="details-view" style="padding: 2px 200px 2px 200px;" *ngIf="row.AncillaryHoldCharges != null && row.AncillaryHoldCharges.length > 0" [@detailExpand]="expanded">
                                                          <div class="mat-elevation-z8">
                                                            <table mat-table multiTemplateDataRows [dataSource]="row.AncillaryHoldCharges">
                                                              <ng-container matColumnDef="child_tops_holdname">
                                                                <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Hold Name </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                  {{element.tops_holdname}}
                                                                </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="child_hold_date">
                                                                <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Hold Date</th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                  {{element.hold_date | date:'MM-dd-yyyy'}}
                                                                </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="child_released">
                                                                <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Released </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                  {{element.released === true ? 'Yes' : 'No'}}
                                                                </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="child_released_date">
                                                                <th mat-header-cell *matHeaderCellDef
                                                                    class="tableheadertext">
                                                                  Released Date
                                                                </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                  {{element.released_date| date: 'MM-dd-yyyy'}}
                                                                </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="child_ClientName">
                                                                <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Client</th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext">
                                                                  {{element.ClientName}}
                                                                </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="child_hold_amount">
                                                                <th mat-header-cell *matHeaderCellDef class="tableheadertext"> Hold Amount </th>
                                                                <td mat-cell *matCellDef="let element" class="tablebodytext" style="color:rgb(60,182,206)">
                                                                  <strong>   {{element.hold_amount|currency}} </strong>
                                                                </td>
                                                              </ng-container>
                                                              <tr mat-header-row *matHeaderRowDef="displayLienChildColumns;"></tr>
                                                              <tr mat-row *matRowDef="let row; columns: displayLienChildColumns;">
                                                              </tr>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedLienColumns;sticky: true"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedLienColumns;let even = even;" (click)="expandchild(row)" [class.example-expanded-row]="expandedElement === row"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="details-row"></tr>
                                                  </table>
                                            <div *ngIf="this.LienDemurrageHoldCharges== null" class="no-records">
                                                No Results found.
                                            </div>
                                        </div>
                                               
                                       </div>

                                        </div>
                                        <!-- <div class="row col-md-12 col-sm-12 divTop-border-empty1" style="padding-bottom:10px"></div> -->
                                        <!-- @*DEMURRAGE DATA GRID*@ -->
                                        <br>
                                        <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9 col-sm-12">
                                                <div class="col-md-3 col-sm-6 text-right"><label>Total LIEN Charge :</label></div>
                                                <div class="col-md-2 col-sm-6 text-left"><label>${{TotalLIEN | number : '1.2-2'}} </label></div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="ContainerHoldCharges.ShowPay">
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9 col-sm-12">
                                                <div class="col-md-3 col-sm-6 text-right" style="padding-top:5px">
                                                    <label>Customer : </label>
                                                </div>
                                                <div class="col-md-5 col-sm-6 text-left">
                                                    <select name="customers1" class="form-control show-tick"
                                                        (click)="ClientsChangedL($event)" required>
                                                        <option value="">Choose Customer ...</option>
                                                        <option *ngFor="let c of Clients" [value]="c.ClientId">
                                                            {{ c.ClientName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <br /><br />
                            </div><br />
                            <div class="row text-right">
                                <!-- PAY DEMURRAGE BUTTON -->  
                                <button class="btn btn-primary btn-site btn-margin"
                                    [disabled]="ContainerHoldCharges.ShowPay === false || (ContainerHoldCharges.LienDemurrageHoldCharges != null && !ContainerHoldCharges.LienDemurrageHoldCharges.length) || !selectedLien" (click)="PayLIEN()">
                                    <span class="glyphicon glyphicon-usd btnStyle" aria-hidden="true"></span>
                                    Pay LIEN
                                </button>
                            </div>
                            <br /><br />
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="row text-right" style="padding: 10px 0px 10px 10px;">
                <!-- BACK BUTTON -->
                <button class="btn btn-primary btn-site" [routerLink]="['/importchargesearch']">
                    <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"></span>
                    Back
                </button>
            </div>
        </div>
    </div>
</div>
