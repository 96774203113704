<div class="container-fluid">
<div class="row" style="padding-bottom: 10px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
        <h3><strong>Important Contacts</strong></h3>
    </div>
    <div class="col-md-4"></div>
</div>
<div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
<br/>
<table align="center" style="width: 1030px; table-layout :fixed">
    <tr class="divTopborder1">
        <th class="text-center" colspan="3">
            <h4><strong>Customer Service</strong></h4>
        </th>
    </tr>
    <tbody>
        <tr>
            <td class="tdLmargin">Los Angeles
            </td>
            <td colspan="2" style ="padding-left:120px"  >
                <span>(310)</span> 221-4498 or <a href="mailto:pier400demurrage@apmterminals.com">pier400demurrage@apmterminals.com </a>
            </td>
        </tr>
        <tr>
            <td class="tdLmargin tdBpdng">All Other Terminals
            </td>
            <td colspan="2" class="tdBpdng" style ="padding-left:120px" >
                <span>(866)</span> 855-8552 or <a href="mailto:amrdemurrage@apmterminals.com">amrdemurrage@apmterminals.com </a>
            </td>
        </tr>
    </tbody>
    <tr class="divTopborder1">
        <th class="text-center"  colspan="3">
            <h4><strong>Terminal Main Numbers</strong></h4>
        </th>
    </tr>
   <tbody>
        <tr>
            <td class="tdLmargin">Elizabeth, NJ</td>
            <td  class="text-center"><span>(908)</span> 558-6000</td>
            <td></td>
        </tr>
        <tr>
            <td class="tdLmargin">Los Angeles, CA</td>
            <td  class="text-center"><span>(310)</span> 221-4000</td>
            <td></td>
        </tr>
        <tr>
            <td class="tdLmargin">Miami, FL</td>
            <td  class="text-center"><span>(305)</span> 347-3800</td>
            <td></td>
        </tr>
        <tr>
            <td class="tdLmargin tdBpdng">Mobile, AL</td>
            <td class="tdBpdng text-center"><span>(251)</span> 410-6100</td>
            <td class="tdBpdng"></td>
        </tr>


        <tr class="divTopborder1">
            <th class="text-center" colspan="3">
                <h4><strong>Steamship Line Contacts</strong></h4>
            </th>
        </tr>
        <tbody>
            <tr>
                <td class="tdLmargin">ACL</td>
                <td  class="text-center"><span>(888)</span> 802-0403</td>
                <td class="tdLmargin1"><a href="http://www.aclcargo.com" target="_blank" class="a-color"><u>www.aclcargo.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">ANL/US Lines</td>
               <td  class="text-center"><span>(757)</span> 961-2100</td>
                <td class="tdLmargin1"><a href="http://www.anl.com.au" target="_blank" class="a-color"><u>www.anl.com.au</u></a></td>
            </tr>
            <tr>
                <td class="tdLmargin">APL</td>
                <td  class="text-center"><span>(800)</span> 999-7733</td>
                <td class="tdLmargin1"><a href="http://www.apl.com" target="_blank" class="a-color"><u>www.apl.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">CGM-CMA</td>
                <td  class="text-center"><span>(877)</span> 556-6308</td>
                <td class="tdLmargin1"><a href="http://www.cma-cgm.com" target="_blank" class="a-color"><u>www.cma-cgm.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Cosco Lines</td>
                <td  class="text-center"><span>(800)</span> 242-7354</td>
                <td class="tdLmargin1"><a href="http://www.cosco-usa.com" target="_blank" class="a-color"><u>www.cosco-usa.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Evergreen America</td>
                <td  class="text-center"><span>(201)</span> 761-3000</td>
                <td class="tdLmargin1"><a href="https://www.evergreen-line.com/" target="_blank" class="a-color"><u>www.evergreen-line.com</u></a></td>
            </tr>
            
            <tr>
                <td class="tdLmargin">Hamburg Sud</td>
                <td  class="text-center"><span>(973)</span> 775-5300</td>
                <td class="tdLmargin1"><a href="http://www.hamburgsud-line.com" target="_blank" class="a-color"><u>www.hamburgsud-line.com</u></a></td>
            </tr>
            <tr>
                <td class="tdLmargin">Hapag-Lloyd</td>
                <td  class="text-center"><span>(732)</span> 562-1800</td>
                <td class="tdLmargin1"><a href="http://www.hapag-lloyd.com" target="_blank" class="a-color"><u>www.hapag-lloyd.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Hyundai Lines</td>
                <td  class="text-center"><span>(877)</span> 749-8632</td>
                <td class="tdLmargin1"><a href="http://www.hmm21.com" target="_blank" class="a-color"><u>www.hmm21.com</u></a></td>
            </tr>
            
            
            <tr>
                <td class="tdLmargin">Maersk Line</td>
                <td  class="text-center"><span>(800)</span> 321-8807</td>
                <td class="tdLmargin1"><a href="http://www.maerskline.com" target="_blank" class="a-color"><u>www.maerskline.com</u></a></td>
            </tr>
            
             <tr>
                <td class="tdLmargin">Marfret</td>
                <td  class="text-center"><span>(757)</span> 627-3738</td>
                <td class="tdLmargin1"><a href="http://www.marfret.fr/en/" target="_blank" class="a-color"><u>www.marfret.fr/en/</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Mediterranean Shipping</td>
               <td  class="text-center"><span>(212)</span> 764-4800</td>
                <td class="tdLmargin1"><a href="http://www.msc.com/usa" target="_blank" class="a-color"><u>www.msc.com/usa</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Nile Dutch </td>
                <td  class="text-center"><span>(201)</span> 293-4586</td>
                <td class="tdLmargin1"><a href="http://www.niledutch.com" target="_blank" class="a-color"><u>www.niledutch.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Ocean Network Express</td>
                <td  class="text-center"><span>(844)</span> 413-6029</td>
                <td class="tdLmargin1"><a href="http://www.one-line.com" target="_blank" class="a-color"><u>www.one-line.com</u></a></td>
            </tr>
            <tr>
                <td class="tdLmargin">OOCL</td>
                <td  class="text-center"><span>(888)</span> 388-6625</td>
                <td class="tdLmargin1"><a href="http://www.oocl.com/eng" target="_blank" class="a-color"><u>www.oocl.com/eng</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Safmarine</td>
                <td  class="text-center"><span>(866)</span> 866-4723</td>
                <td class="tdLmargin1"><a href="http://www.safmarine.com" target="_blank" class="a-color"><u>www.safmarine.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Turkon</td>
                <td  class="text-center"><span>(201)</span> 866-6966</td>
                <td class="tdLmargin1"><a href="http://www.turkon.com/en" target="_blank" class="a-color"><u>www.turkon.com/en</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin">Yang Ming Line</td>
                <td  class="text-center"><span>(201)</span> 420-5800</td>
                <td class="tdLmargin1"><a href="http://www.yangming.com" target="_blank" class="a-color"><u>www.yangming.com</u></a></td>
            </tr>

            <tr>
                <td class="tdLmargin tdBpdng ">ZIM </td>
                <td class="tdBpdng text-center" ><span>(866)</span> 744-7046</td>
                <td class="tdBpdng tdLmargin1"><a href="http://www.zim.com" target="_blank" class="a-color"><u>www.zim.com</u></a></td>
            </tr>
     


</table>
</div>
