import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { UpdatePasswordComponent } from 'src/app/common/update-password/update-password.component';
import { ShoppingCartService } from '../../services/shopping-cart.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  UserNm:any;
  menuOpen: boolean;
  loggedIn=  false;
  displayStyle = "none";
  passwordUpdateSuccessful=false;
  Messages: ResponseMessage[]=[];
  constructor(private ShoppingCartService: ShoppingCartService,
              private authService:AuthService,
              private cookieService: CookieService,
              private router:Router,
              public dialog: MatDialog,) {
    
    if (!!this.cookieService.get("loggedIn")) {
      this.loggedIn = this.cookieService.get("loggedIn") == "1" ? true :false;
      this.UserNm =  localStorage.getItem("userName") == null ? '': localStorage.getItem("userName");//'Hi,'+ +'!';
    }
   }
  
  ngOnInit(): void {
  
  }

  logOut(){

    this.authService.logout();

    // Delete the Shopping Cart cookie
    this.ShoppingCartService.DeleteCookie();

    this.router.navigateByUrl('/home');
    // this.cookieService.deleteAll();
    // this.loggedIn = false;
    // localStorage.clear();
    
    // var now = new Date();
    // var expiresValue = new Date(now);
    // expiresValue.setDate(now.getDate() - 1);
    // this.cookieService.set("TOPSUserKey", { path: "/" }, {
    //     expires: expiresValue
    // });
  //   StorageModule.clearLocalStorage();

  }

  openPopup() {
    const dialogRef = this.dialog.open(UpdatePasswordComponent, {
      position:{'top' :'30px'},
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'deletepanelclass'
    });
    dialogRef.afterClosed().subscribe(result => {           
    });
  }

  myaccount(){                  
    this.router.navigateByUrl('/registration');
  }

}
