import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { ImportchargesService } from 'src/app/core/services/importcharges.service';
import {  ShoppingCart } from 'src/app/core/models/ShoppingCart';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
@Component({
  selector: 'app-exportbookingholdsconfirmation',
  templateUrl: './exportbookingholdsconfirmation.component.html',
  styleUrls: ['./exportbookingholdsconfirmation.component.css']
})
export class ExportbookingholdsconfirmationComponent implements OnInit {
  TransactionReferenceId :any;
  ShoppingCart:ShoppingCart;
  ShowLoader = false;
  Messages: ResponseMessage[]=[];
  HoldReleaseErrors:any;
  Logo:any;
  constructor(private importchargesService: ImportchargesService,
              private ShoppingCartService : ShoppingCartService) { }

  ngOnInit(): void {

    this.TransactionReferenceId = this.ShoppingCartService.TransactionReferenceId();

    if (this.TransactionReferenceId === null) {
      this.ShowLoader = true;
      setTimeout(() => {
      this.importchargesService.GetShoppingCartData().subscribe((result) => {       
        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0)
        { this.Messages = result.generalMessages; } else {
          this.ShoppingCart = result;
          // this.LineItemsCollection = this.ShoppingCart.LineItems;
          this.Logo = this.ShoppingCart.TerminalLogo;
          if (this.IsFieldNotBlank(this.ShoppingCart.HoldReleaseErrors))
            this.HoldReleaseErrors = this.ShoppingCart.HoldReleaseErrors.replace(/<br ?\/?>/g, "\n");
        }
        this.ShowLoader = false;
      });
    }, 2000);    
    }
    else {
      this.ShowLoader = true;
      setTimeout(() => {
      this.importchargesService.GetCyberSourceShoppingCartData(this.TransactionReferenceId).subscribe((result) => {      
        if (this.IsFieldNotBlank(result.generalMessages) && result.generalMessages.length > 0)
        { this.Messages = result.generalMessages; } else {
          this.ShoppingCart = result;
          // this.LineItemsCollection = [].concat(this.ShoppingCart.LineItems);
          this.Logo = this.ShoppingCart.TerminalLogo;
          if (this.IsFieldNotBlank(this.ShoppingCart.HoldReleaseErrors))
            this.HoldReleaseErrors = this.ShoppingCart.HoldReleaseErrors.replace(/<br ?\/?>/g, "\n");
        }
        this.ShowLoader = false;
      });
    }, 2000);    
  
      // Delete the Shopping Cart cookie
      this.ShoppingCartService.DeleteCookie();
    }
  }
  
  IsFieldNotBlank (val:any) {
    if (val === undefined || val === '' || val === null) { return false; } else { return true; }
  }
}
