<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid">
    <div class="row" style="padding-bottom:10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
            <h3><strong>TOPS Online Registration</strong></h3>
        </div>
        <div class="col-md-4"></div>
        <br />
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
  
    <div>
        <div class="row" >

            <div class="col-md-2 col-sm-1"></div>
            <div class="col-md-8 col-sm-10" *ngIf="NewRegSuccess != true">

            <form name="registrationForm" [formGroup]="registrationForm" autocomplete="off">
                <br>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="divTop-border" style="padding-left:15px; padding-right:15px;padding-bottom: 20px;">
                                <br />
                                If you do not have a valid Federal Tax ID and are a United States based customer, please
                                contact the APMT Demurrage Desk to register at
                                <a href="mailto:amrdemurrage@apmterminals.com" target="_blank" class="aLink-style">amrdemurrage@apmterminals.com</a><br /><br />
                                Phone #: 866-855-8552 <br />                                
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 col-sm-12" *ngIf="Messages != null">
                            <p class="alert alert-danger" style="padding-left:15px; padding-right:15px">
                                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{this.Messages}}
                            </p>
                        </div>
                    </div>
                    <div class="row">

                        <div class="form-group col-md-6 col-sm-12" *ngIf="NewCompanyflag==false ||  this.topsUserKey!='' ||(CNameVisibility==true && isDisabled==true)">
                            <label>Company Name</label>
                            <select name="ddCompanyName" formControlName="ddCompanyName" (change)="customerChanged($event)" class="form-control show-tick">
                                <option value="null" >--- Please select ---</option>
                                <option *ngFor="let c of ddCompanyName" [value]="c.ClientId">
                                    {{ c.ClientName }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="NewCompanyflag==false||  this.topsUserKey!='' ||(CNameVisibility==true && isDisabled==true)" class="col-md-1" style="margin-top:35px">
                            <label><strong>OR</strong></label>
                        </div>

                        <div class="form-group col-md-5" style="padding-top:20px;padding-left:0px;">
                            <input type="button" *ngIf="NewCompanyflag==false||  this.topsUserKey!=''||(CNameVisibility==true && isDisabled==true)" name="newCompany"
                                class="btn btn-sm btn-primary btn-site" value="New Company" (click)="newCompanyRegistration()"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Customer outside the United States?  </label>&nbsp;
                            <label class=".checkbox-circle radio-inline">
                                <input type="radio" name="Canada_FL" [(ngModel)]="this.UserContactInfo.Canada_FL" formControlName="Canada_FL" [value]=true  (click)="OutsideUS(true)"/>
                              
                                Yes
                            </label>
                            <label class=".checkbox-circle radio-inline">
                                <input type="radio" name="Canada_FL" [(ngModel)]="this.UserContactInfo.Canada_FL" formControlName="Canada_FL" [value]=false  (click)="OutsideUS(false)"/>
                              
                                No
                            </label>
                        </div>
                    </div>
                    <div class="row" *ngIf="!this.UserContactInfo.Canada_FL">
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.TaxIdNumber.errors  && (f.TaxIdNumber.touched ||f.TaxIdNumber.dirty) }">
                            <span style="color:red">*</span><label>Tax ID Number</label>   <button type="button" class="btn btn-info btn-xs" (click)="ShowTaxIDHelpPrompt()" style="margin-left: 10px">?</button>
                            <input type="text" formControlName="TaxIdNumber" [readonly]="!NewCompanyflag " class="form-control show-tick" required
                                [(ngModel)]="this.UserContactInfo.TaxID_AN"
                                />
                            <div *ngIf="f.TaxIdNumber.errors && NewCompanyflag!=false && (f.TaxIdNumber.touched ||f.TaxIdNumber.dirty) " class="invalid-feedback">
                                <span  *ngIf="f.TaxIdNumber.errors.required" class="help-block">Tax ID
                                    Number is
                                    Required.</span>
                                <span *ngIf="f.TaxIdNumber.errors.max" class="help-block">Tax ID
                                    Number is too
                                    long</span>
                                    <span *ngIf="f.TaxIdNumber.errors.pattern" class="help-block">
                                         Tax ID Number is not in the correct US format.</span>
                            </div>
                        </div>
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.ReTaxIdNumber.errors  && (f.ReTaxIdNumber.touched ||f.ReTaxIdNumber.dirty) }">
                            <span style="color:red">*</span><label>Re-type Tax ID Number</label>   <button type="button" class="btn btn-info btn-xs" (click)="ShowTaxIDHelpPrompt()" style="margin-left: 10px">?</button>
                            <input type="text" formControlName="ReTaxIdNumber" class="form-control show-tick" required
                                 (focusout)="GetClientByTaxIDNumberChange($event)"
                                 [(ngModel)]="this.UserContactInfo.ReTypeTaxID_AN"
                                 [readonly]="!NewCompanyflag "
                                />
                            <div *ngIf="f.ReTaxIdNumber.errors&& NewCompanyflag!=false && (f.ReTaxIdNumber.touched ||f.ReTaxIdNumber.dirty) " class="invalid-feedback">
                                <span *ngIf="f.ReTaxIdNumber.errors.required"
                                    class="help-block">Re-Type Tax ID Number
                                    is Required.</span>
                                <span *ngIf="f.ReTaxIdNumber.errors.max" class="help-block">Re-Type
                                    Tax ID Number is too long</span>
                                    <span *ngIf="f.ReTaxIdNumber.errors.pattern" class="help-block">
                                        Re-type Tax ID Number is not in the correct US format.</span>
                                        <div *ngIf="f.ReTaxIdNumber.errors.mustMatch" class="help-block">Your Tax ID numbers do not match, please check your Tax ID.</div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.companyName.errors  && (f.companyName.touched ||f.companyName.dirty) }" >
                            <span style="color:red">*</span><label>Company Name</label>
                            <input type="text" formControlName="companyName" class="form-control show-tick" required
                                [(ngModel)]="this.UserContactInfo.ClientName" 
                                [readonly]="!NewCompanyflag||disableflag"
                                />
                            <div *ngIf="f.companyName.errors && NewCompanyflag!=false && (f.companyName.touched ||f.companyName.dirty)" class="invalid-feedback">
                                <span *ngIf="f.companyName.errors.required" class="help-block">Company
                                    Name is
                                    Required.</span>
                                <span *ngIf="f.companyName.errors.maxlength"
                                    class="help-block">Company Name is too
                                    long.</span>
                            </div>
                        </div> 
                       <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.CompanyType.errors  && (f.CompanyType.touched ||f.CompanyType.dirty) }" >
                            <span style="color:red">*</span><label>Company Type</label>
                            <select formControlName="CompanyType" [attr.disabled]="!NewCompanyflag ||disableflag? '' : null" (change)="changeCompanyType($event)"                                 
                                 class="form-control show-tick" [(ngModel)]="selectedClientTypeId"  required>
                                <option value="0" >--- Please select ---</option>
                                <option *ngFor="let c of clientTypedd" [value]="c.ClientTypeId" >
                                    {{ c.ClientTypeDescription }}
                                </option>
                            </select>
                            <div *ngIf="f.CompanyType.errors && NewCompanyflag!=false && (f.CompanyType.touched ||f.CompanyType.dirty)" class="invalid-feedback">
                                <span *ngIf="f.CompanyType.errors.required" class="help-block">Company
                                    Name is Required.</span>  
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-md-12" [ngClass] = "{ 'has-error' : f.address1.errors  && (f.address1.touched ||f.address1.dirty) }">
                            <span style="color:red">*</span><label>Address 1</label>
                            <input type="text" formControlName="address1" class="form-control show-tick" required
                                 [(ngModel)]="this.UserContactInfo.Address_Line1_DS"
                                 [readonly]="!NewCompanyflag"
                                 />
                            <div *ngIf="f.address1.errors && NewCompanyflag!=false && (f.address1.touched ||f.address1.dirty)" class="invalid-feedback">
                                <span *ngIf="f.address1.errors.required" class="help-block">Address 1
                                    is Required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Address2 -->
                        <div class="form-group col-md-12">
                           <label>Address 2</label>                           
                            <input type="text" formControlName="address2" class="form-control show-tick"
                            [(ngModel)]="this.UserContactInfo.Address_Line2_DS"  [readonly]="!NewCompanyflag"  />                        
                        </div>
                    </div>

                    <div class="row">
                        <!-- Countries -->
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.countries.errors  && (f.countries.touched ||f.countries.dirty) }">
                            <span style="color:red">*</span><label>Country</label>
                            <select formControlName="countries" [attr.disabled]="!NewCompanyflag ? '' : null" (change)="changeCountries($event)"
                            class="form-control show-tick"  [(ngModel)]="selectedCountry.CountryId"
                               required>
                                <option value="0"  >--- Please select ---</option>
                                <option *ngFor="let c of countries" [value]="c.CountryId"  >
                                    {{ c.CountryName }}
                                </option>
                            </select>
                            <div *ngIf="f.countries.errors && NewCompanyflag!=false && (f.countries.touched ||f.countries.dirty)" class="invalid-feedback">
                                <span *ngIf="f.countries.errors.required" class="help-block">Country is Required.</span>  </div>
                        </div>
                        
                        <!-- States --> 
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : (stateValidate || (f.states.errors && (f.states.touched ||f.states.dirty))) }" >
                            <span style="color:red">*</span><label>State/Province</label>
                            <select formControlName="states" [attr.disabled]="!NewCompanyflag ? '' : null" (change)="changeStates($event)"
                                 class="form-control show-tick" [(ngModel)]="selectedState.StateId" required>
                                <option  value="" >--- Please select ---</option>
                                <option *ngFor="let c of states" [value]="c.StateId">
                                    {{ c.StateName }}
                                </option>
                            </select>
                            <div *ngIf="stateValidate || (f.states.errors && (f.states.touched ||f.states.dirty))" class="invalid-feedback">
                                <span *ngIf="stateValidate || (f.states.errors && (f.states.touched ||f.states.dirty))" class="help-block">State is Required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- City/Location -->
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.cityLocation.errors  && (f.cityLocation.touched ||f.cityLocation.dirty) }" >
                            <span style="color:red">*</span><label>City/Location</label>
                            <input type="text" formControlName="cityLocation" class="form-control show-tick" required
                                [(ngModel)]="this.UserContactInfo.City_DS"
                                [readonly]="!NewCompanyflag"
                                />
                            <div *ngIf="f.cityLocation.errors && NewCompanyflag!=false && (f.cityLocation.touched ||f.cityLocation.dirty)" class="invalid-feedback">
                                <span *ngIf="f.cityLocation.errors.required"
                                    class="help-block">City/Location is
                                    Required.</span>
                            </div>
                        </div>

                        <!-- Zip -->
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.zip.errors  && (f.zip.touched ||f.zip.dirty) }" >
                            <span style="color:red">*</span><label>Zip</label>
                            <input type="text" formControlName="zip" maxlength=20 class="form-control" required
                                [(ngModel)]="this.UserContactInfo.Postal_Code_AN"
                                [readonly]="!NewCompanyflag"
                                >
                            <span *ngIf="f.zip.errors &&  (f.zip.touched ||f.zip.dirty)">
                                <span *ngIf="f.zip.errors.required" class="help-block">Zip is
                                    Required.</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Phone -->
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.phone.errors  && (f.phone.touched ||f.phone.dirty) }" >
                            <span style="color:red">*</span><label>Phone</label> <button type="button" class="btn btn-info btn-xs" (click)="ShowPhoneNumHelpPrompt()" style="margin-left: 10px">?</button>
                            <input type="text" formControlName="phone" class="form-control" minlength="10" required
                                maxlength="20" 
                                [(ngModel)]="this.UserContactInfo.Phone_Number_AN"
                                [readonly]="!NewCompanyflag"
                                />

                            <span *ngIf="f.phone.errors &&  (f.phone.touched ||f.phone.dirty)">
                                <span *ngIf="f.phone.errors.required" class="help-block">Phone is
                                    Required.</span>
                                <span  *ngIf="f.phone.errors.pattern" class="help-block">Phone is
                                    Invalid.</span>
                            </span>

                        </div>
                        <!-- Ext -->
                        <div class="form-group col-md-2 col-sm-6">
                            <label>Ext</label>
                            <input type="text" formControlName="ext" class="form-control show-tick" 
                                [(ngModel)]="this.UserContactInfo.Phone_Extension_AN"
                                [readonly]="!NewCompanyflag"
                                />
                            <!-- <div *ngIf="f.ext.errors &&  (f.ext.touched ||f.ext.dirty) " class="invalid-feedback">
                                  <span *ngIf="f.ext.errors.pattern" class="help-block">Extension is Invalid.</span>
                            </div> -->
                        </div>

                        <!-- International Phone -->
                        <div class="form-group col-md-4 col-sm-6" style="margin-top:30px">
                            <label class="checkbox-inline">
                                <input type="checkbox" [readonly]="!NewCompanyflag" formControlName="chkIntPhone" />
                                International Phone Number
                            </label>
                        </div>

                    </div>

                    <div class="row">
                        <!-- Fax -->
                        <div class="form-group col-sm-6"  [ngClass] = "{ 'has-error' : f.fax.errors  && (f.fax.touched ||f.fax.dirty) }" >
                            <label>Fax</label>
                            <input type="text" formControlName="fax" class="form-control show-tick" 
                                [(ngModel)]="this.UserContactInfo.Fax_Number_AN"
                                [readonly]="!NewCompanyflag"
                                />
                            <span *ngIf="f.fax.errors &&  (f.fax.touched ||f.fax.dirty) ">
                                <span *ngIf="f.fax.errors.pattern"
                                    class="help-block">Fax Is Invalid</span>
                            </span>
                        </div>
                        <!-- Billing Email -->
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.email.errors  && (f.email.touched ||f.email.dirty) }" >
                            <span style="color:red">*</span><label>Billing Email</label>
                            <input type="email" formControlName="email" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors}" 
                                [(ngModel)]="this.UserContactInfo.E_Mail_DS"
                                required [readonly]="!NewCompanyflag"
                                />
                            <span *ngIf="f.email.errors &&  (f.email.touched ||f.email.dirty) ">
                                <span *ngIf="f.email.errors.required" class="help-block">Billing Email is
                                    Required.</span>
                                <span *ngIf="f.email.errors.email"
                                    class="help-block">Enter a
                                    valid Email Address.</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Contact First Name -->
                        <div class="form-group col-md-4"  [ngClass] = "{ 'has-error' : f.firstName.errors  && (f.firstName.touched ||f.firstName.dirty) }" >
                            <span style="color:red">*</span><label>Contact First Name</label>
                            <input type="text" formControlName="firstName" class="form-control"
                                [(ngModel)]="UserContactInfo.First_Name_DS" required
                                [readonly]="!NewCompanyflag" (change)="UpdateContactInfo(true)"
                                />
                            <span *ngIf="f.firstName.errors &&  (f.firstName.touched ||f.firstName.dirty)">
                                <span *ngIf="f.firstName.errors && f.firstName.errors.required"
                                    class="help-block">Contact First Name is Required.</span>
                            </span>
                        </div>
                        <!-- Contact Last Name -->
                        <div class="form-group col-md-4"  [ngClass] = "{ 'has-error' : f.lastName.errors  && (f.lastName.touched ||f.lastName.dirty) }" >
                            <span style="color:red">*</span><label>Contact Last Name</label>
                            <input type="text" formControlName="lastName" class="form-control"
                                [(ngModel)]="UserContactInfo.Last_Name_DS" required
                                [readonly]="!NewCompanyflag" (change)="UpdateContactInfo(true)"
                                />
                            <span *ngIf="f.lastName.errors &&  (f.lastName.touched ||f.lastName.dirty)" >
                                <span *ngIf="f.lastName.errors.required" class="help-block">Contact
                                    Last Name is Required.</span>
                            </span>
                        </div>
                        <!-- Contact Title -->
                        <div class="form-group col-md-4">
                            <label>Contact Title</label>
                            <input type="text" formControlName="Title" class="form-control"
                            [(ngModel)]="UserContactInfo.Title_DS"
                            [readonly]="!NewCompanyflag" (change)="UpdateContactInfo(true)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <!-- Login Name -->
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.loginName.errors  && (f.loginName.touched ||f.loginName.dirty) }" >
                            <span style="color:red">*</span><label>Login Name</label>
                            <button type="button" class="btn btn-info btn-xs" (click)="ShowLoginNameHelpPrompt()" style="margin-left: 10px">?</button>
                            <input type="text" formControlName="loginName" class="form-control" 
                                [(ngModel)]="UserContactInfo.Login_DS" required
                                [readonly]="this.topsUserKey!=''"
                                />
                            <span *ngIf="f.loginName.errors && NewCompanyflag!=false  && (f.loginName.touched ||f.loginName.dirty)">
                                <span *ngIf="f.loginName.errors.pattern" class="help-block">Login Name
                                    can only contain alphanumeric characters.</span>
                                    <span *ngIf="f.loginName.errors.required" class="help-block">Login Name
                                        Required</span>
                                        <span *ngIf="f.loginName.errors.minlength" class="help-block">Login Name is too short.</span>
                                        
                                </span>
                            
                        </div>

                        <!-- Security Email -->
                        
                        <div class="form-group col-md-6"  [ngClass] = "{ 'has-error' : f.SecurityEmail.errors  && (f.SecurityEmail.touched ||f.SecurityEmail.dirty) }" >
                            <span style="color:red">*</span><label>Contact Email</label>
                            <input type="email" formControlName="SecurityEmail" class="form-control"
                                [(ngModel)]="this.UserContactInfo.Security_Email_DS" required
                                [ngClass]="{ 'is-invalid': submitted && f.SecurityEmail.errors}" 
                                [readonly]="!NewCompanyflag" (change)="UpdateContactInfo(true)"
                                />
                            <span *ngIf="f.SecurityEmail.errors &&  (f.SecurityEmail.touched ||f.SecurityEmail.dirty)">
                                <span *ngIf="f.SecurityEmail.errors.required" class="help-block">Contact Email is
                                    Required.</span>
                                <span *ngIf="f.SecurityEmail.errors.email"
                                    class="help-block">Enter a valid Email Address.</span>
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="this.topsUserKey==''" [ngClass] = "{ 'has-error' : f.password.errors  && (f.password.touched ||f.password.dirty) }" >
                        <!-- Password -->
                        <div class="form-group col-md-6" >
                            <span style="color:red" *ngIf=" hidePwdAndConfirmpwd!=false ">*</span>
                             <label *ngIf="NewCompanyflag!=false  && hidePwdAndConfirmpwd!=false">Password</label>
                             <button type="button" *ngIf=" hidePwdAndConfirmpwd!=false"(click)="ShowHelpPrompt()" class="btn btn-info btn-xs" style="margin-left: 10px">?</button>
                             <input type="password" formControlName="password"
                                class="form-control"
                                [(ngModel)]="this.UserContactInfo.Password_DS" required
                                *ngIf="NewCompanyflag!=false  && hidePwdAndConfirmpwd!=false"
                                />
                            <div *ngIf="f.password.errors && NewCompanyflag!=false  && hidePwdAndConfirmpwd!=false && (f.password.touched ||f.password.dirty)" class="invalid-feedback">
                                <!-- <div *ngIf="f.password.errors.pattern">Password must
                                    contain more than 8 characters, 1 upper case letter, and 1 special character</div> -->
                                    <div *ngIf="f.password.errors.required" class="help-block">Password is Required</div>
                            </div>
                        </div>
                        <!-- Confirm Password -->
                        <div class="form-group col-md-6" [ngClass] = "{ 'has-error' : f.confirmPassword.errors  && (f.confirmPassword.touched ||f.confirmPassword.dirty) }">
                            <span style="color:red" *ngIf="NewCompanyflag!=false && hidePwdAndConfirmpwd!=false &&  this.topsUserKey==''">*</span>
                            <label *ngIf="NewCompanyflag!=false && hidePwdAndConfirmpwd!=false">Confirm Password</label>
                            <button type="button" *ngIf="NewCompanyflag!=false && hidePwdAndConfirmpwd!=false"(click)="ShowHelpPrompt()" class="btn btn-info btn-xs" style="margin-left: 10px">?</button>
                            <input type="password" formControlName="confirmPassword" class="form-control"
                            [(ngModel)]="this.UserContactInfo.ConfirmPassword_DS" required
                                *ngIf="NewCompanyflag!=false && hidePwdAndConfirmpwd!=false" 
                                />
                            <div *ngIf="f.confirmPassword.errors && NewCompanyflag!=false && hidePwdAndConfirmpwd!=false  && (f.confirmPassword.touched ||f.confirmPassword.dirty)" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required" class="help-block">Confirm
                                    Password is Required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch" class="help-block">Passwords do not match</div>
                            </div>
                        </div>
                    </div>
                    <div class="row col-md-12 col-sm-12 text-right">
                        <div class="form-group">
                            <!-- BACK BUTTON -->
                            <button type="reset" (click)="onReset()" class="btn btn-primary btn-site" style="text-align: center;padding-left: 25px;
                            padding-right: 25px;">
                                <span class="glyphicon glyphicon-arrow-left btnStyle" aria-hidden="true"  style="font-size:20px;"></span>&nbsp;Back
                            </button> <div class="col-md-2 col-sm-1"></div>

                            <!-- SUBMIT BUTTON -->
                            <button type="submit" (click)="onSubmit(registrationForm.value,'submit')"
                             *ngIf="this.topsUserKey==''" [disabled]="!registrationForm.valid|| registrationForm.pristine" class="btn btn-primary btn-site btn-margin" >
                                <span class="glyphicon glyphicon-registration-mark" aria-hidden="true" style="font-size:20px; text-align: center;padding-right:10px"></span>Submit Registration
                                
                            </button>
                            <button (click)="onSubmit(registrationForm.value,'update')" 
                            class="btn btn-primary btn-site btn-margin" *ngIf="this.topsUserKey!=''" [disabled]="!registrationForm.valid || registrationForm.pristine">
                                <span class="glyphicon glyphicon-registration-mark" aria-hidden="true" style="font-size:20px;padding: 0px 10px 10px 10px;text-align: center;"></span><span>Update Registration</span>
                            </button>
                        </div>
                    </div>

                </form>
            </div>
            <div *ngIf="NewRegSuccess == true">
                <div class="row">
                    <br /><br />
                    <div class="alert alert-success col-md-8 col-md-offset-2" role="alert">
                        <div class="row">
                            <div class="col-md-12"><div style="font-size:36px;font-weight:bold"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Registration Success!</div></div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>
                                    Thank you for registering.  Your account has been activated, please log in to continue.
                                </h2>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <a routerLink="/"> <div class="col-md-3"><button type="button" class="btn btn-block btn-site btn-primary" ><span class="glyphicon glyphicon-log-in" aria-hidden="true" style="font-size:20px"></span> &nbsp;Log In </button></div></a>
                        </div>
                        <br />
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
        <button (click)="findInvalidControls()" name="invalids"></button>
