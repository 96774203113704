import { Component, OnInit } from '@angular/core';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import { ExportchargesService } from 'src/app/core/services/exportcharges.service';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import { VGMServiceResultsComponent } from './vgmservice-results/vgmservice-results.component';
@Component({
  selector: 'app-vgmservicesearch',
  templateUrl: './vgmservicesearch.component.html',
  styleUrls: ['./vgmservicesearch.component.css']
})
export class VgmservicesearchComponent implements OnInit {    
  VGMServiceSearchRefData: ExportChargesSearchModel;
  submitted=false;
  ShowLoader=false;
  Terminals: any;
  ShippingLines:any;

  VGMServiceSearchForm: FormGroup; 
  Messages: ResponseMessage[]=[];
  constructor(private exportchargesService: ExportchargesService,
              private trackandtraceService: TrackandtraceService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) {  this.VGMServiceSearchRefData = new ExportChargesSearchModel(); }

  ngOnInit(): void {
    
    var tChargeCd = "V";
    let ExportChargesSearchRefData = { "TerminalChargeCode": tChargeCd };
    this.ShowLoader=true;
    this.exportchargesService.GetExportChargesSearchRefData(ExportChargesSearchRefData).subscribe((result) => {
        // result.TerminalId  =  result.TerminalId == null ?  '' : result.TerminalId;
        this.VGMServiceSearchRefData = result;
        this.Terminals = result.TChargeTerminals.Terminals;
        this.ShippingLines = result.ShippingLines;
        this.Messages = result.generalMessages;
        this.ShowLoader=false;
    });

    this.VGMServiceSearchForm = this.formBuilder.group({
      terminals: ['', Validators.required],
      shippingLine:  ['', Validators.required],
      bKNumbers:  ['', Validators.required],
    });
    
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.VGMServiceSearchForm.controls;
  }

  SearchVGMServiceCharges(){
    this.submitted=true;
    this.Messages =[];
    if (!this.VGMServiceSearchForm.invalid) {
      this.ShowLoader=true;
      this.VGMServiceSearchRefData.HoldType_Cd = 'V';//'VGMSVC';
      this.trackandtraceService.GetBookingDetails(this.VGMServiceSearchRefData).subscribe((result)=> {
        this.ShowLoader=false;
          if (result.generalMessages.length > 0)
          { this.Messages = result.generalMessages; }
          else {
            
            const dialogRef = this.dialog.open(VGMServiceResultsComponent, {
              data: { BookingInfo: result ,VGMServiceSearchRefData : this.VGMServiceSearchRefData },
              autoFocus: false,
              restoreFocus: false,
              panelClass: 'deletepanelclass'
            });
            dialogRef.afterClosed().subscribe(result => {           
            });

          }
        });
    }
  }

}
