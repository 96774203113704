import { Component, OnInit,Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddContainerFilters, SelectedEvents, TerminalInfo } from 'src/app/core/models/Notification.model';
import { LoginService } from 'src/app/shared/services/login.service';
import { NotificationlistComponent } from '../notificationlist.component';
@Component({
  selector: 'app-addnotifications',
  templateUrl: './addnotifications.component.html',
  styleUrls: ['./addnotifications.component.css']
})
export class AddnotificationsComponent implements OnInit {
  ShowLoader = false;
  submitted= false;
  CurrentCount:number=0;
  AddContainerForm: FormGroup;
  AddContainerNumber:boolean=false;
  AddTerminalId:boolean=false;
  AddEmail:boolean=false;    
  countvalidation = false;
  AddContainerFilters :AddContainerFilters;
  MaxNotificationCount:number=200;
  Email:string;
  email2:string='';
  email3:string='';
  TerminalData:TerminalInfo;
  constructor( private formBuilder:FormBuilder,
               private loginService:LoginService,
               @Inject(MAT_DIALOG_DATA) public AddNotificationdata: any,
               public dialogRef: MatDialogRef<NotificationlistComponent>,) { 
               this.TerminalData = new TerminalInfo();
               this.loginService.GetAllTerminals().subscribe((result) => {  
                  this.TerminalData = result;                
                });
               }

  ngOnInit(): void {

    this.AddContainerFilters = this.AddNotificationdata.AddContainerFilters;   
    this.CurrentCount = this.AddNotificationdata.CurrentCount;
    this.AddContainerFilters.TerminalId ='';        

    this.AddContainerForm = this.formBuilder.group({
      terminals: ['', [Validators.required]],
      containernumbers:['',Validators.required],
      emailId:['', {
        // validators:[Validators.pattern('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$')]
      }],
      email2:[],
      email3:[],
    });

  } 
  
  AddContainers(){
    this.submitted = true;
    if((this.CurrentCount) > this.MaxNotificationCount)    {
      this.countvalidation = true;
      return ;
    }
    else if(this.AddContainerFilters.Email)  {
    }   

    if(this.inputvalidation() == true && this.AddContainerFilters.Contact_ID != 0 && this.AddContainerFilters.ContainerNumbers != undefined && this.AddContainerFilters.Email != undefined && this.AddContainerFilters.TerminalId != undefined){
      this.addEmail();
      this.ShowLoader = true;
      this.loginService.AddToContainerWatchList(this.AddContainerFilters).subscribe((result) => {                
          localStorage.setItem("AddNotificationResults",result.generalMessages);         
          this.AddTerminalId = false;
          this.AddContainerNumber = false;
          this.AddEmail = false;
          this.ShowLoader = false;
          this.dialogRef.close(result.generalMessages);  
      })    
  }
  else{  
    if(this.AddContainerFilters.TerminalId ==  undefined || this.AddContainerFilters.TerminalId ==  '') this.AddTerminalId =true; else this.AddTerminalId =false;
    if(this.AddContainerFilters.ContainerNumbers ==  undefined || this.AddContainerFilters.ContainerNumbers ==  '')     this.AddContainerNumber =true; else this.AddContainerNumber =false;
    if(this.AddContainerFilters.Email ==  undefined || this.AddContainerFilters.Email ==  '')  this.AddEmail =true; else this.AddEmail =false;
   }
 
}

closePopup() {
  this.dialogRef.close('cancel');
}

inputvalidation(){
  this.countvalidation = false;
  if (!this.AddContainerFilters) return false;
  if (this.AddContainerForm.valid === false) { return false };
  if (this.AddContainerFilters.TerminalId === '' || this.AddContainerFilters.TerminalId === undefined) return false;
  if ((this.AddContainerFilters.Email === '' || this.AddContainerFilters.Email === undefined) && (this.email2 === '' || this.email2 === undefined) && (this.email3 === '' || this.email3 === undefined)) return false;
  if (this.AddContainerFilters.ContainerNumbers === '' || this.AddContainerFilters.ContainerNumbers === undefined) return false;
  // if (this.SelectedEvents.events.length === 0) return false;
  if ((this.AddContainerFilters.Email === (this.email2 || this.email3)) || (this.email2 === (this.email3 || this.AddContainerFilters.Email)) || (this.email3 === (this.email2 || this.AddContainerFilters.Email))) return false;
  if (this.ContainerCounter(this.AddContainerFilters.ContainerNumbers) + this.CurrentCount > this.MaxNotificationCount) {
      this.countvalidation = true;
      return false;
  };
  return true;
}

ContainerCounter(value:any) {
  if (value != null || value != undefined) {
      value = value.trim().replace(/\s/g, ',').replace(/;/g, ',').replace(/\./g, ',').replace(/\r/g, ',').replace(/\n/g, ',').replace(/,+/g, ',');
      var lastChar = value.charAt(value.length - 1);
      if (lastChar === ',') { value = value.slice(0, -1); }
      return [].concat(value.split(',')).length;
  }
  return value;
};


get f(): { [key: string]: AbstractControl } {
  return this.AddContainerForm.controls;
}

addEmail () {
  if (this.AddContainerFilters.Email != "") {
    if (this.AddContainerFilters.Email != "") {
      if (this.email2 != "" || this.email3 != "") {
          if (this.email2 != "") this.AddContainerFilters.Email = this.AddContainerFilters.Email + ',' + this.email2;
          if (this.email3 != "") this.AddContainerFilters.Email = this.AddContainerFilters.Email + ',' + this.email3;
      }
  }
  if (this.AddContainerFilters.Email === '' || this.AddContainerFilters.Email === undefined) {
      if (this.email2 != "") {
          this.AddContainerFilters.Email = this.email2;
          if (this.email3 != "") this.AddContainerFilters.Email = this.AddContainerFilters.Email + ',' + this.email3;
      }
      if ((this.email2 === "" || this.email2 === undefined) && this.email3 != "") {
          this.AddContainerFilters.Email = this.email3;
      }
    }
  };
}

}
