export class StatementFilters {
    FromDate: Date;
    ToDate: Date;
    TerminalID: number;
    StatementNum:string;
    InvoiceID: string;
    invType: boolean;
    StatNum: string;
    InvoiceAncil: boolean;
    User_ID: number;

}

export class AncillaryStatementInvoice {
    InvoiceId: number;
    InvoiceNumber: string;
    ClientId: number;
    InvoiceDate: Date;
    BillingAmount: number;
    terminalid: number;
    ContainerNumber: string;
    BillLadingNumber: string;
    vesselvoyage: string;
    tops_holdname: string;
    UniqueInvoiceId: string;
    ClientName: string;
    PoNumber: string;

}

export class DemurageStatementInvoice {
    InvoiceId: number;
    InvoiceNumber: string;
    ReferenceNumber: string;
    ClientId: number;
    TerminalId: number;
    ContractId: number;
    InvoiceDate: Date;
    BillingAmount: number;
    TerminalDisbursmentAmount: number;
    EquipmentDisbursmentAmount: number;
    BillingNotes: string;
    CollectionNotes: string;
    InvoiceStatusId: number;
    CurrencyId: number;
    UserId: number;
    Status: boolean;
    InCollections: boolean;
    SupervisorReview: boolean;
    FollowupDate: Date;
    WriteOff_FL: boolean;
    SapNumber: string;
    UniqueInvoiceId: string;
    StatementNumber: string;
    BillLadingNumber: string;
    ContainerNumber: string;
    SzTp: string;
    DateTimeOfFirstAvailability: Date;
    PortLastFreeDate: Date;
    LineLastFreeDate: Date;
    Paid_Thru_DT: Date;
    PickupDate: Date;
    VesselCode: string;
    VoyageNumber: string;
    totalCharge: string;
    myTotalCharge1: string;
    Day1: string;
    myTotalCharge2: string;
    Day2: string;
    myTotalCharge3: string;
    Day3: string;
    myTotalCharge4: string;
    Day4: string;
    hasdata4: Boolean;
    hasdata3: boolean;
    hasdata2: boolean;
    hasdata1: boolean;
}