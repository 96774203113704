
<div class="row divBorder">
    <div class="col-md-12">
        <br />
        <div class="row">
            <div class="form-group col-md-6 text-primary">
                <label><strong>Pay Cargo user</strong></label>
            </div>
        </div>
        <div class="row" [formGroup]="shoppingCartPayment">
            <!-- First Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.username.errors && (f.username.touched ||f.username.dirty)}">
                <span class="required-field">*</span><label>Pay Cargo User name</label>
                <input type="text" formControlName="username" name="username" class="form-control"  [(ngModel)]="SCartPayment.PayCargoInfo.Username" maxlength="99" required autocomplete="off">
                  <span class="help-block" *ngIf="f.username.invalid && !f.username.pristine"> 
                    <span *ngIf="f.username.errors?.required" class="help-block">Please enter Pay Cargo User name.</span>
                </span>
            </div>
            <!-- Last Name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-error' : f.password.errors && (f.password.touched ||f.password.dirty)}">
                <span class="required-field">*</span><label>Pay Cargo Password</label>
                <input type="password" formControlName="password" name="password" class="form-control"  [(ngModel)]="SCartPayment.PayCargoInfo.Password" maxlength="99" required autocomplete="off">
               <span class="help-block" *ngIf="f.password.invalid && !f.password.pristine" >  
                    <span *ngIf="f.password.errors?.required" class="help-block">Please enter Pay Cargo Password.</span>
                </span>
            </div>
        </div>
    </div>
</div>


