import { ResponseMessage } from "src/app/shared/interfaces/ResponseMessages.model";

export class NotificationList{
    NotificationsList:Notification[];
    NotificationEvents:NotificationEvent[];
    MaxNotificationCount:number;
    messagedetail :ResponseMessage[];
}

export class Notification
{
    ContainerWatchListId :number;
    ContainerNumber :string;
    Gkey_an :string;
    TerminalName :string;
    TerminalId :number;
    email :string;
}

export class NotificationEvent{
    ContainerEventTypeId:number;
    EventName:string;
}


export class AddContainerFilters
{
    ContainerNumbers :string;
    TerminalId :string;
    Contact_ID :number;
    Email :string;
    EventType :string;
}

export class Terminals
{
    TerminalId					 :		number;
    TerminalName 				 :		string ;
    CompanyName 				 :		string ;
    TaxIdNumber 				 :		string ;
    ScheduleDKCode 			     :		string ;
    ContactName 				 :		string ;
    ContactDesignation 		     :		string ;
    Extension1 				     :		string ;
    PhoneNumber1 				 :		string ;
    Fax 						 :		string ;
    Address1 					 :		string ;
    Address2 					 :		string ;
    City 						 :		string ;
    Country 					 :		string ;
    State 						 :		string ;
    Zip 						 :		string ;
    UserId 					     :		number;
    Status 					     :		number ; 
    Terminal_UNLocode_AN 		 :		string ;
    Plant 						 :		string ;
    GeoCode 					 :		string ;
    SalesOrg 					 :		string ;
    Check_Pmt_FL 				 :		boolean;
    Guarantee_FL 				 :		boolean;
    Credit_Card_FL 			     :		boolean;
    Credit_Reserve_FL 			 :		boolean;
    DropDownDisplay 			 :		boolean;
    ElectronicCheck_FL 		     :		boolean;
    UseContainerDischargeAsFA 	 :		boolean;
    LogoId 					     :		number;
    CodeNameLocation 			 : 		string ;
    Import_FL: boolean;
}

export class TerminalInfo
{
    Date:Date;
    ContainerNumbers:string;
    User_Id:number;
    TerminalId:string;
    Terminals: Terminals[];
}

export class AddNotificationResults
{
    ContainerResults:ContainerResult[];
    generalMessages:string;
}

export class ContainerResult 
{
    ContainerNumber :string;
    Gkey_an :string;
}

export class DeleteContainerFilters
{
    ContainerWatchListId :string;
    generalMessages:string;
}

export class SelectedEvents{
    events:string[];
}
