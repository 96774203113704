<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<form name="changePaswordForm"  [formGroup]="changePaswordForm" style="display:block;width:600px;"  novalidate>
    <div class="modal-header custom-modal-header">       
        <h4 class="modal-title text-center">Please enter and confirm a new password</h4>
    </div>
    <div class="modal-body">
        <div *ngIf="Messages.length > 0">
            <div  *ngFor="let Msg of Messages">
                <div [ngClass]="Msg.severity==1?'alert alert-info':Msg.severity==3? 'alert alert-danger':Msg.severity==2? 'alert alert-warning' : 'alert alert-success'">
                    {{Msg.messagedetail}}
                </div>
            </div>
        </div>
        <div class="row">
            <div>
                <!-- LOGIN NAME -->
                <div class="form-group" >  <!--[ngClass]="{ 'has-error' : f.LoginName.invalid && !f.LoginName.pristine}"-->
                    <div class="row"  >
                        <div class="col-md-5 col-sm-5 text-right padding-top"><label for="LoginName">Login Name :</label></div>
                        <div class="col-md-7 col-sm-7 text-left padding-top text-primary"><label>{{LoginName}}</label></div>              
                    </div>
                </div>              
                <!-- PASSWORD -->
                <div class="form-group" [ngClass]="{ 'has-error' : f.UPassWord.invalid && !f.UPassWord.pristine}">
                    <div class="row">
                        <div class="col-md-5 col-sm-5 text-right padding-top">
                            <button type="button" class="btn btn-info btn-xs" style="margin-right: 10px" (click)="ShowHelpPrompt()">?</button><label for="UPassWord">Password :</label>
                        </div>
                        <div class="col-md-7 col-sm-7 input-group text-left">
                            <input type="password" formControlName="UPassWord"  name="UPassWord" class="form-control" [(ngModel)]="Password_DS" ng-pattern=""  required>
                            <span *ngIf="f.UPassWord.errors  && (f.UPassWord.touched ||f.UPassWord.dirty)" >    <span *ngIf="f.UPassWord.errors?.required" class="help-block">Password is required.</span></span>
                            <span class="help-block" *ngIf="f.UPassWord.errors?.pattern" >Enter a Valid Password.</span>
                        </div>
                    </div>
                </div>
                <!-- CONFIRM PASSWORD -->
                <div class="form-group" [ngClass]="{ 'has-error' : f.confirmPassWord.invalid && !f.confirmPassWord.pristine}">
                    <div class=" row">
                        <div class="col-md-5 col-sm-5 text-right padding-top">
                            <label for="confirmPassWord">Confirm Password :</label>
                        </div>
                        <div class="col-md-7 col-sm-7 input-group text-left">
                            <input type="password" formControlName="confirmPassWord"  name="confirmPassWord" class="form-control" [(ngModel)]="ConfirmPassword_DS" ng-model-options="{updateOn: 'blur', debounce: {default: 500, blur: 0} }" required>
                            <span *ngIf="f.confirmPassWord.errors  && (f.confirmPassWord.touched ||f.confirmPassWord.dirty)" >     <span *ngIf="f.confirmPassWord.errors?.required" class="help-block">Confirm Password is required.</span></span>                                
                            <span style="color:#b94a48" class="help-block" *ngIf="!f.confirmPassWord.errors?.required && !f.confirmPassWord.pristine && Password_DS != '' && Password_DS != undefined && Password_DS != ConfirmPassword_DS">Passwords do not match.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer form-horizontal">
        <div class="btn-group btn-group-lg"> 
            <button type="submit" class="btn btn-primary btn-site" [disabled]="changePaswordForm.invalid || (Password_DS != ConfirmPassword_DS) || LoginName == ''" (click)="submitChangePassword()">
                Submit
            </button>
        </div>       
    </div>
</form>
