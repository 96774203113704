
export class VGMServiceSearchRefData {
    ShippingLineId:number;
    HoldType_Cd:string;
    TerminalId:number;
    BookingNumber:string;
    
}

export class ExportFilters{
    FromDate:Date;
    ToDate:Date;
    BookingNum:string;
    InvoiceNum:string;
    InvoiceStrip:string;
    User_ID:number;
    ContainerNum:string;
    StatementNum:string;
}

export class ExportInvoiceData
{
    InvoiceId:number;
    InvoiceNumber:string;
    ClientId : number;
    TerminalId :number;
    Terminal:string;
    ContactId:number;
    InvoiceDate:Date;
    TotalAmount:string;
    StatusName:string;
    UniqueInvoiceId:any;
    StatementNumber:string;
    PoNumber:string;
    OutstandingBalance:string;
}


export class ExportInvoiceItem
{
    InvoiceData:ExportInvoiceData[];
    generalMessages:any;
}

export class BookingPrintInvoiceItems
{
      BookingPrintInvoice:InvoiceItem[];
      generalMessages:any;
}


export class InvoiceItem
{
    InvoiceItems :InvoiceItems[];
    ProductItems :ProductItems[];
    BookingInvoiceItems :BookingInvoiceItems[];
    ServiceOrderInvoiceItems :ServiceOrderInvoiceItems[];
    Client :string;
    ClientAddress :string;
    ClientCity :string;
    ClientContact :string;
    ClientCountry:string;
    TerminalLoc :string;
    TerminalAttn :string;
    TerminalAddr :string;
    TerminalCity :string;
    TerminalPhone :string;
    TerminalOverNightAddr1: string;
    TerminalOverNightAddr2: string;
    TerminalOverNightCity: string;
    TerminalOverNightAddr3: string;
    HasOverNightAddress: boolean;
    TerminalLogo :any;
    ContainerNumber :string;
    TerminalName :string;
     BillLadingNumber :string;
     VesselName :string;
     ShippingLineName :string;
     VoyageNumber :string;
     InvoiceNumber :string;
     InvoiceDate :string;
     TerminalId :string;
     SapNumber :string;
     PoNumber :string;
     CheckNumber :string;
      TotalAmt :number;
      PaymentAmt :number;
      PaymentRemain :number;
     BookingNumber :string;
     ServiceOrderNumber :string;
     generalMessages:any;    
}



 export class InvoiceItems
{
    hold_id : number;
    hold_name :string;
    hold_amount : number;      
    hold_date : Date;
    released_date :  Date;      
    tops_holdname :  string;     
}

export class ProductItems {
      productdescription :string;
      producttype :string;
      productdetail :string;
      price : number;
}


export class BookingInvoiceItems
{
    BookingChargeId : number;
    ChargeType :string
    HoldName :string
    ChargeAmount :number;
    PaymentAmt :number;
    PaidDate :Date;
    ChargeQty :number;
}
export class ServiceOrderInvoiceItems
{
      ServiceOrderDetailId :number;
      ServiceOrderTypeDescription :string;
      ChargeAmount :number;
      PaymentAmt :number;
      PaidDate :Date;
      ChargeQty :number;
}
