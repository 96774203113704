<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="container-fluid" >
  <div class="row" style="padding-bottom:10px;">
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center"><h3><strong>Import Account History</strong></h3></div>
      <div class="col-md-4"></div>
  </div>
  <div class="row col-md-12 col-sm-12 divTop-border-empty1 noPrint"></div>
  <div *ngIf="Messages.length > 0">
        <div class="row col-md-12 col-sm-12"  *ngFor="let Msg of Messages">
            <br />
            <div>
                <div [ngClass]="Msg.severity==1?'alert alert-info text-center':Msg.severity==3? 'alert alert-danger text-center':Msg.severity==2? 'alert alert-warning text-center' : 'alert alert-success text-center'">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;{{Msg.messagedetail}}
                </div>
            </div>
        </div>
    </div> 
  <div class="row">
      <br /><br />
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <form name="InvoiceHistory" [formGroup]="InvoiceHistory">
             
              <!-- @*Type of Invoices*@ -->
              <div class="row" >
                  <div class="form-group col-md-11">
                      <div class="col-sm-4 col-sm-offset-3">                          
                          <label class="btn btn-default btn-responsive col-md-12 text-left minPadding-top labelbtn">
                              <input type="radio" name="InvoiceAncil" id="InvoiceAncil"
                              [(ngModel)]="InvoiceFilters.InvoiceAncil" formControlName="InvoiceAncil"  (click)="RadioOptionSelect(true)" [value]="true"> Ancillary Account History
                          </label>
                      </div>
                      <div class="col-sm-4 ">
                          <label class="btn btn-default btn-responsive col-md-12 text-left minPadding-top labelbtn">
                              <input type="radio" name="InvoiceAncil" id="InvoiceAncil" 
                               [(ngModel)]="InvoiceFilters.InvoiceAncil" formControlName="InvoiceAncil"  (click)="RadioOptionSelect(false)" [value]="false"> Demurrage Account History
                          </label>
                      </div>
                  </div>
              </div>
              <div class="col-sm-9 col-md-offset-2">                
                  <div class="row divTop-border-silver col-md-offset-3"></div>
              </div>
                  <br />
                  <div class="row" >
                      <div class="form-group col-md-11">
                          <div class="col-md-2  text-right minPadding-top col-md-offset-2"><label>From :</label></div> 
                            <div class="col-md-3" [ngClass]="{ 'has-error' : submitted && (InvoiceFilters.FromDate === null || InvoiceFilters.FromDate===undefined) && InvoiceFilters.ContainerNum ==='ALL' && InvoiceFilters.InvoiceNum ==='ALL' && InvoiceFilters.StatementNum==='ALL'}">                          
                              <input  style="width: 130px;" [matDatepicker]="picker" formControlName="FromDate" id="FromDate" class="form-control   col-md-8" [(ngModel)]="InvoiceFilters.FromDate">
                              <mat-datepicker-toggle  class="dp" matSuffix [for]="picker">                                   
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>                                   
                              <span class="help-block"  *ngIf="submitted && f.FromDate.errors && (f.FromDate.touched ||f.FromDate.dirty)">
                                  Dates are required when Container, Invoice or Statement is not specified.</span>
                            </div>
                          <div class="col-md-2  text-right minPadding-top"><label>To :</label></div> 
                          <div class="col-md-3" [ngClass]="{ 'has-error' : submitted && (InvoiceFilters.ToDate === null || InvoiceFilters.ToDate===undefined) }">
                              <input  style="width: 130px;"  [matDatepicker]="picker1" formControlName="ToDate" id="ToDate" class="form-control  col-md-8" [(ngModel)]="InvoiceFilters.ToDate">
                              <mat-datepicker-toggle  class="dp" matSuffix [for]="picker1"> 
                                   <span class="input-group-addon">
                                  <span class="glyphicon glyphicon-calendar"></span></span>
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>                           
                              <span class="help-block"  *ngIf="submitted && f.ToDate.errors && (f.ToDate.touched ||f.ToDate.dirty)">Please enter End Date.</span>
                              <span class="help-block"  *ngIf="submitted && InvoiceHistory.hasError('notValid') && (f.ToDate.touched ||f.ToDate.dirty)">End date must be on or after start date.</span>
                          </div>
                      </div>
                  </div>                  
                  <div class="row" style=" margin-bottom: 5px !important;">
                      <div class="form-group col-md-11">
                          <div class="col-md-2  text-right minPadding-top col-md-offset-2"><label >Container :</label></div>                        
                          <div class="col-md-3">
                              <input class="form-control" formControlName="ContainerNum"  type="text" name="ContainerNum" placeholder="All"  [(ngModel)]="InvoiceFilters.ContainerNum" style="text-transform:uppercase;width:170px;" (click)="InvoiceFilters.ContainerNum=''"> 
                             <span class="help-block"  *ngIf="submitted && f.ContainerNum.errors?.required && (f.ContainerNum.touched ||f.ContainerNum.dirty)">
                                  Please enter Container Number or ALL.</span>
                           </div>
                          <div class="col-md-2  text-right minpadding-top"><label >Invoice Number:</label></div>                          
                          <div class="col-md-3">
                              <input class="form-control" formControlName="InvoiceNum" type="text" name="InvoiceNum" placeholder="All" [(ngModel)]="InvoiceFilters.InvoiceNum" style="text-transform:uppercase;width:170px;" (click)="InvoiceFilters.InvoiceNum=''">  
                              <span class="help-block"  *ngIf="submitted && f.InvoiceNum.errors?.required && (f.InvoiceNum.touched ||f.InvoiceNum.dirty)">
                                  Please enter Invoice Number or ALL.</span>
                          </div>
                      </div>
                  </div>                           
                  <div class="row" style=" margin-bottom: 5px !important;">
                      <div class="form-group col-md-11">
                          <div class="col-md-3  text-right minPadding-top col-sm-offset-1"><label >Statement Number:</label></div>                                               
                          <div class="col-md-3">
                              <input class="form-control" formControlName="StatementNum"  type="text"  id="StatementNum" placeholder="All" name="StatementNum" [(ngModel)]="InvoiceFilters.StatementNum"  style="text-transform:uppercase;width:170px;" (click)="InvoiceFilters.StatementNum=''"> 
                              <span class="help-block"  *ngIf="submitted && f.StatementNum.errors?.required && (f.StatementNum.touched ||f.StatementNum.dirty)">
                                  Please enter Statement Number or ALL.</span>
                          </div>
                      </div>
                  </div>
                  <br /> 
                  <div class="row">
                      <div class="form-group col-md-10" style="margin-left: 5%;">
                          <div class="col-md-4"></div>
                          <div class="col-md-8 col-sm-8 text-right">
                              <!-- BACK BUTTON -->
                              <button type="button" class="btn btn-primary btn-site" [routerLink]="['/landingpage']">
                                  <span class="glyphicon glyphicon-arrow-left" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Back
                              </button>
                         
                              <!-- SEARCH BUTTON -->
                              <button (click)="SearchInvoiceInfo()" class="btn btn-primary btn-site btn-margin">
                                  <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Search
                              </button>
                              
                              <!-- Export BUTTON -->
                              <button class="btn btn-primary btn-site btn-margin" name="ExportStatement" id="ExpStat"
                                      [disabled]="InvoiceFilters.StatementNum == 'ALL' || InvoiceFilters.StatementNum.length==0 || InvoiceFilters.StatementNum =='undefined'" (click)="ExportStatementReport()">
                                <span class="glyphicon glyphicon-export" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Export
                              </button>
                              <br />                           
                              
                          </div>
                          <div class="col-md-1"></div>
                      </div>
                  </div>
        </form>        
      </div>
  </div>
  <div   #TABLE class="row col-md-12 col-sm-12" style="padding: 50px 50px 0px 50px;">
    <div   *ngIf="InvoiceAncil && searchactive">
        <div >
            <!-- <div > -->
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="sortData($event)">             
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="_select" >
                        <mat-checkbox  (change)="$event ? masterToggle($event) : null" 
                                        [checked]="selection.hasValue()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="_select" >
                        <mat-checkbox  (click)="$event.stopPropagation()" 
                                        (change)="OnChange($event,row)"
                                        [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="InvoiceNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                        class="tableheadertext"> Invoice Number </th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> <a style="cursor:pointer ;" (click)="ExportInvoiceReport(element)">{{element.InvoiceNumber}} </a></td>
                    </ng-container>                    
                    <ng-container matColumnDef="StatementNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext">Statement #</th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.StatementNumber}} </td>
                    </ng-container>                    
                    <ng-container matColumnDef="PoNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext">PO Number </th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.PoNumber}}
                        </td>
                    </ng-container>                   
                    <ng-container matColumnDef="InvoiceDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext">Invoice Date </th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.InvoiceDate | date: 'MM-dd-yyyy'}}
                        </td>
                    </ng-container>                      
                    <ng-container matColumnDef="Terminal" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext">Terminal </th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.Terminal }}
                        </td>
                    </ng-container>                   
                    <ng-container matColumnDef="TotalAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext"> Billing Amount</th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.TotalAmount}} </td>
                    </ng-container>                   
                    <ng-container matColumnDef="OutstandingBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext"> Outstanding Balance</th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.OutstandingBalance}} </td>
                    </ng-container>                   
                    <ng-container matColumnDef="StatusName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="tableheadertext"> Status</th>
                        <td mat-cell *matCellDef="let element"
                            class="tablebodytext"> {{element.StatusName}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row  *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
                <div [ngClass]="{ hiddenElement: (dataSource.data.length) === 0 }">
                <mat-paginator [hidden]="!(displayClientsCollection.length==0)"
                [ngStyle]="{display: displayClientsCollection.length >0 ? 'block' : 'none'}"
                [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="10"
                    showFirstLastButtons
                    aria-label="Select page of periodic elements">
                </mat-paginator> 
            </div>
                <div *ngIf="displayClientsCollection.length==0">
                    <div class="no-records"> No Results found.</div> 
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
    <div *ngIf="!InvoiceAncil && searchactive">
            <div >            
                <div class="mat-elevation-z8">
                    <table   mat-table [dataSource]="dataSource"  matSort  (matSortChange)="sortData($event)">
                                               
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="_select"  > 
                            <mat-checkbox (change)="$event ? masterToggle($event) : null" 
                                            [checked]="selection.hasValue()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row" class="_select" >
                                <mat-checkbox  (click)="$event.stopPropagation()" 
                                                (change)="OnChange($event,row)"
                                                [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="InvoiceNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext"> Invoice Number </th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> <a style="cursor:pointer;" (click)="ExportInvoiceReport(element)">{{element.InvoiceNumber}} </a></td>
                        </ng-container>                        
                        <ng-container matColumnDef="StatementNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext">Statement #</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.StatementNumber}} </td>
                        </ng-container>                        
                        <ng-container matColumnDef="ReferenceNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext">Reference Number </th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.ReferenceNumber}}
                            </td>
                        </ng-container>                       
                        <ng-container matColumnDef="InvoiceDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext">Invoice Date </th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.InvoiceDate | date: 'MM-dd-yyyy'}}
                            </td>
                        </ng-container>                          
                        <ng-container matColumnDef="Terminal" >
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext">Terminal </th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Terminal }}
                            </td>
                        </ng-container>                                                
                        <ng-container matColumnDef="Customer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext">Customer</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.Customer}}
                            </td>
                        </ng-container>                       
                        <ng-container matColumnDef="TotalAmount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext"> Billing Amount</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.TotalAmount}} </td>
                        </ng-container>                       
                        <ng-container matColumnDef="OutstandingBalance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext"> Outstanding Balance</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.OutstandingBalance}} </td>
                        </ng-container>                       
                        <ng-container matColumnDef="StatusName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="tableheadertext"> Status</th>
                            <td mat-cell *matCellDef="let element"
                                class="tablebodytext"> {{element.StatusName}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                        <tr mat-row  *matRowDef="let row; columns: displayedColumns1;"></tr>
                    </table>
                    <div [ngClass]="{ hiddenElement: (dataSource.data.length) === 0 }">
                    <mat-paginator [hidden]="!(displayClientsCollection.length==0)"
                    [ngStyle]="{display: displayClientsCollection.length >0 ? 'block' : 'none'}"
                    [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="10"
                        showFirstLastButtons
                        aria-label="Select page of periodic elements">
                    </mat-paginator> 
                    </div>
                        <div *ngIf="displayClientsCollection.length==0">
                        <div class="no-records"> No Results found.</div> 
                        </div>
                    </div>
                           
             </div>

    </div>
  </div>

  <div class="row" *ngIf="!(displayClientsCollection.length==0)&& searchactive">
      <br /><br />
      <div class=" form-group col-md-10">
          <div class="col-md-4"></div>
          <div class="col-md-8 col-sm-8 text-right">

              <!-- Print BUTTON -->
              <button (click)="InvImportPrintPreview()"  class="btn btn-primary btn-site btn-margin"> 
                  <span class="glyphicon glyphicon-print" aria-hidden="true" style="font-size:20px;padding-right:10px"></span>Print
              </button>

          </div>
          <div class="col-md-1"></div>
      </div>
  </div>

  <br>
  <br>
  <div class="col-md-2"></div>
</div>
