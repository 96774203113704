<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div class="col-md-12 col-sm-12 grid-padding" style="padding-bottom: 10px;">
    <div data-st-table="BookingInformationCollection" data-st-safe-src="BookingInformationData.BookingInformation">
    <!-- <div class="row col-md-12 col-sm-12 no-padding"> -->
    <!-- <div class="col-md-7 col-sm-7"> -->
        <h3 class="text-left lbltitle"><strong style="color:#FF6218"> &nbsp;&nbsp;&nbsp;Booking Inquiry for : {{BookingNumber}}</strong></h3>
    <!-- </div> -->
    <div class="row col-md-12 col-sm-12">
        <div  class="mat-elevation-z8" >
            <table mat-table [dataSource]="dataSource" style ="width: 1489px;"  (matSortChange)="sortData($event)" matSort>
                          
                <!-- BOOKING Column -->
                <ng-container matColumnDef="BOOKING">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Booking # </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.BOOKING | uppercase}} </td>
                </ng-container>

                <!-- EQUIPMENTSIZE Column -->
                <ng-container matColumnDef="EQUIPMENTSIZE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Equipment Size </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.EQUIPMENTSIZE}} </td>
                </ng-container>

                <!-- QTY Column -->
                <ng-container matColumnDef="QTY">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Quantity </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.QTY}} </td>
                </ng-container>    

                <!-- HAZ Column -->
                <ng-container matColumnDef="HAZ">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Haz </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.HAZ}} </td>
                </ng-container>

                <!-- LINE Column -->
                <ng-container matColumnDef="LINE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Line </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.LINE}} </td>
                </ng-container>

                <!-- PaidQty Column -->
                <ng-container matColumnDef="PaidQty">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Total Shipping Weight </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                <span  *ngIf="element.PaidQty==0">
                <a style="cursor:pointer" (click)="Ordervgmdetails(element)">Order</a>
                </span>
                <span  *ngIf="element.PaidQty>0">
                <a style="cursor:pointer" (click)="Viewvgmdetails(element)">View</a>
                </span>
                </td>
                </ng-container> 
                <!-- VESSELNAME Column -->
                <ng-container matColumnDef="VESSELNAME">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Vessel Name </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.VESSELNAME}} </td>
                </ng-container>

                <!-- VOYAGE Column -->
                <ng-container matColumnDef="VOYAGE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Voyage </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.VOYAGE}} </td>
                </ng-container>                    
                <!-- BEGINRECEIVE Column -->
                <ng-container matColumnDef="BEGINRECEIVE">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Begin Receive </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.BEGINRECEIVE | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container> 

                <!-- CARGOCUTOFF Column -->
                <ng-container matColumnDef="CARGOCUTOFF">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Cargo Cutoff </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.CARGOCUTOFF | date:'MM-dd-yyyy HH:mm'}} </td>
                </ng-container>                    
                <!-- RECEIVED Column -->
                <ng-container matColumnDef="RECEIVED">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Received Date </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.RECEIVED}} </td>
                </ng-container> 
                <!-- EMPTIESAVAIL Column -->
                <ng-container matColumnDef="EMPTIESAVAIL">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Empties Out </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.EMPTIESAVAIL}} </td>
                </ng-container>                    
                <!-- RESERVEDONLY Column -->
                <ng-container matColumnDef="RESERVEDONLY">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Reserved Only </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.RESERVEDONLY}} </td>
                </ng-container> 
                <ng-container matColumnDef="PREADVISED">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header> Preadvised </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext"> {{element.PREADVISED}} </td>
                </ng-container>                    
                <!-- TMFHOLD Column -->
                <ng-container matColumnDef="TMFHOLD">
                <th mat-header-cell *matHeaderCellDef  class="tableheadertext"  mat-sort-header>Holds </th>
                <td mat-cell *matCellDef="let element" class="tablebodytext">
                <span  *ngIf="element.TMFHOLD!=''" style="font-size:16px;font-weight:bold;color:red;">{{element.TMFHOLD}} </span>
                <span  *ngIf="element.TMFHOLD==''"> {{element.TMFHOLD}} </span>
                </td>
                </ng-container>                 
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
                <div *ngIf="this.BookingInformationlist.length == 0" class="no-records"> No Results found.</div>  
            </div>
    </div>
    <!-- </div> -->
    </div>
</div>

