<div class="divHeaderContainer" style="position:relative">
    <div class="row header">
    <!--Navigation bar-->
        <nav class="navbar navbar-expand-lg navbar-static-top no-margin navfont" role="navigation">
            <div class="col-md-3 col-xs-3" style ="padding-left: 15px;">             
                <div style="margin-top: -26px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                <div class="logotext">Powered by <strong>TOPS</strong></div>            
            </div>
            <div class="col-md-9 col-xs-9"   >
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>                  
               </div>               
                <div class="collapse navbar-collapse" style="float:right; margin-right:-15px;margin-top: 15px;">               
                <ul class="nav navbar-nav" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" > 
                    <li><a  style ="color: white;text-decoration: none;cursor:pointer;" [routerLink]="['/examrates']" routerLinkActive="active-link">Exam Rates</a></li>
                    <li *ngIf ="this.loggedIn==true"><a  style ="color: white;text-decoration: none;cursor:pointer;" routerLinkActive="active-link" [routerLink]="['/paymentoptions']">Payment Info</a></li>
                    <li> <a  style ="color: white;text-decoration: none;cursor:pointer;"[routerLink]="['/faq']" routerLinkActive="active-link">FAQ</a></li>
                    <li><a  style ="color: white;text-decoration: none;cursor:pointer;" [routerLink]="['/contact']" routerLinkActive="active-link">Contact us</a></li>
                   
                    <li class="welcome navfont" *ngIf ="this.loggedIn==false"> <a style ="color: white;text-decoration: none;cursor:pointer;" [routerLink]="['/home']" routerLinkActive="active-link"><span class="glyphicon glyphicon-user" ></span>&nbsp;&nbsp;<strong style="font-size:24px"> Welcome!</strong></a></li>
                     
                  <li class="dropdown welcome" *ngIf ="this.loggedIn==true"> <a class="dropdown-toggle" data-toggle="dropdown" routerLinkActive="active-link"  #t="matMenuTrigger" [matMenuTriggerFor]="menu" style="color: white;cursor:pointer;" > <strong style="font-size:20px">Hi, <span > {{UserNm | slice:0:15}}</span>!</strong>&nbsp;<span [ngClass]="t.menuOpen == false ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up' " id="arrowposition" aria-hidden="true"></span></a>  </li>
                    
                    <mat-menu #menu="matMenu" class="topnav">                     
                      <button mat-menu-item (click)="myaccount()" style = "width: 250px;color: white;" >                      
                        <span class="glyphicon glyphicon-user" style="padding:0px 10px 0px 30px"></span>
                          <span>&nbsp;&nbsp;My Account</span>
                        </button>
                        <button mat-menu-item style = "width: 250px;color: white;" >
                          <span class="glyphicon glyphicon-pencil" style="padding:0px 10px 0px 30px;"></span>
                          <span (click)="openPopup()" >&nbsp;&nbsp;Update Password</span>
                        </button>
                        <button mat-menu-item (click)="logOut()" style = "width: 250px;color: white;" >                        
                          <span class="glyphicon glyphicon-log-out" style="padding:0px 10px 0px 30px;"></span>
                          <span >&nbsp;&nbsp;Sign out</span>
                        </button>                   
                      </mat-menu>  
                      </ul>
                </div>
            </div>
        </nav>        
    </div>
</div>
