<div class="col-md-12 col-sm-12">
    <div class="col-md-2 col-sm-2"></div>
    <div class="col-md-8 col-sm-8" style="padding: 0px !important;" data-st-table="LineItemsCollection" data-st-safe-src="ShoppingCart.LineItems">
        <div class="row col-md-12 col-sm-12 table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th colspan="3">Port(s) of Discharge: {{ShoppingCart.MerchantData.TerminalName}}</th>
                <!--<th colspan="3">Port(s) of Discharge: {{ShoppingCart.LineItems[0].Product.ProductType}}</th>-->
                <th colspan="4" *ngIf="ShoppingCart.LineItems[0].Product.ProductType == 1">Demurrage</th>
                <!--<th data-st-ratio="35"></th>
  <th data-st-ratio="35">Demurrage</th>-->
              </tr>
            </thead>
            <thead>
              <tr>
                <th data-st-ratio="50">Item Description</th>
                <th data-st-ratio="35">Product</th>
                <th data-st-ratio="15">Item Price</th>
                <th data-st-ratio="50" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">Tier No.</th>
                <th data-st-ratio="35" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">$/Day per Tariff Rule</th>
                <th data-st-ratio="15" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)"># of Days</th>
                <th data-st-ratio="50" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of LineItemsCollection ; let i = index">
                <td data-st-ratio="50" style="white-space:pre">{{row.Product.ProductDescription}}</td>
                <td data-st-ration="35">
                  {{row.Product.ProductName}}
                  <label *ngIf="IsFieldNotBlank(row.Product.HoldTypeFriendlyName)">&nbsp;-&nbsp;{{row.Product.HoldTypeFriendlyName}}</label>
                  <label *ngIf="IsFieldNotBlank(row.Product.Bols)">&nbsp;-&nbsp;BOL: {{row.Product.Bols}}</label>
                </td>
                <td data-st-ration="15">{{row.Product.ProductPrice | currency}}</td>
                <!--<table class="table table-bordered table-striped">
                  <tr>-->
                    <td data-st-ration="15" style="white-space:pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{row.Product.OSRATier}}</td>
                    <td data-st-ration="15" style="white-space:pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{row.Product.OSRArate}}</td>
                    <td data-st-ration="15" style="white-space:pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{row.Product.OSRAquantity}}</td>
                    <td data-st-ration="15" style="white-space:pre-wrap" *ngIf="(ShoppingCart.LineItems[0].Product.ProductType == 1 || ShoppingCart.LineItems[0].Product.ProductType == 8)">{{row.Product.OSRAextendedPrice}}</td>
                  <!--</tr>
                </table>-->
              </tr>
              <tr *ngIf="!LineItemsCollection.length">
                <td colspan="5">
                  Shopping Cart is Empty.
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="ShoppingCart.HoldType != 'E' && ShoppingCart.HoldType != 'S'">
              <tr>
                <td colspan="3" class="text-right" style="padding-right:40px;">Total : {{ShoppingCart.TotalPrice | currency}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <br />
    </div>
    <br />
    <div class="col-md-2 col-sm-2">
    </div>
</div>
