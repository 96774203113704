<div class="col-md-12 col-sm-12">
    <div class="col-md-2 col-sm-2"></div>
    <div class="col-md-8 col-sm-8" *ngIf="ShoppingCart.PaymentData !== null">
        <div class="row">
            <div class="col-md-12 col-sm-12 text-center no-padding" >
                <h1>
                    <div style="margin-top: -41px;"><img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive_NEW.png"/></div>
                    <!-- <img class="TOPSlogo" src="assets/Images/Primary_Logo_Positive.png"/> -->
                    <!-- <img src="{{Logo}}" /> -->
                    <!-- <img src="data:image/jpg;base64,{{this.ShoppingCart.TerminalLogo}}" /> -->
                </h1>
            </div>           
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 text-center text-primary no-padding">
                <h2>
                    {{ShoppingCart.MerchantData.RegionName}}
                </h2>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-12">
                <div class="col-md-3 text-right"><label>Date :</label></div>
                <div class="col-md-9 text-left"><label style="font-weight:bold">{{ todayDate | date : 'MM/dd/yyyy' }}</label></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="col-md-3 text-right"><label>Receipt Number :</label></div>
                <div class="col-md-9 text-left"><label style="font-weight:bold">{{ShoppingCart.PaymentData.TransactionReferenceId}}</label></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="col-md-3 text-right"><label>PO Number :</label></div>
                <div class="col-md-9 text-left">
                    <label style="font-weight:bold">{{ShoppingCart.PaymentData.PurchaseOrder}}</label>
                </div>
            </div>
        </div>
        <br />
        <div class="row no-padding">
            <div class="col-md-6 text-left" style="padding-left:0px">
                <div class="panel panel-default" style="min-height:240px">
                    <div class="panel-heading">
                        <h4>Payee: <label class="text-primary">{{ShoppingCart.Client}}</label></h4>
                    </div>
                    <div class="panel-body">
                        <!-- <p> -->
                            <strong>Address:</strong><br />
                            <address>
                                {{ShoppingCart.ClientAddress}} <br />
                                {{ShoppingCart.ClientCity}}
                            </address>
                            <strong>Contact:</strong> {{ShoppingCart.ClientContact}}
                        <!-- </p> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-left" style="padding-right:0px">
                <div class="panel panel-default" style="min-height:240px">
                    <div class="panel-heading">
                        <h4>Paid to the Order of : <label class="text-primary">{{ShoppingCart.TerminalLoc}}</label></h4>
                    </div>
                    <div class="panel-body">
                        <!-- <p> -->
                            <strong>
                                Address:
                            </strong> <br />
                            <address>
                                {{ShoppingCart.TerminalAttn}} <br />
                                {{ShoppingCart.TerminalAddr}} <br />
                                {{ShoppingCart.TerminalCity}} <br />
                            </address>
                        <!-- </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-sm-8 alert alert-danger" *ngIf="ShoppingCart.PaymentData === null">
        <p>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;There is
            currently no receipt to display. If you feel you have reached this page in
            error, please contact APM Terminals.
        </p>
    </div>
</div>

