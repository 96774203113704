<app-data-loading-spinner *ngIf="ShowLoader"></app-data-loading-spinner>
<div id="TnT" class="container-fluid" >
    <div class="row" style="padding-bottom: 10px;">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
            <h3><strong>Track and Trace</strong></h3>
        </div>
    </div>
    <div class="row col-md-12 col-sm-12 divTop-border-empty1"></div>
    <div class="row" >
        <mat-accordion>   
            <mat-expansion-panel [expanded]="true" (opened)="clicked = true" 
                                 (closed)="clicked = false">
            <mat-expansion-panel-header>
                <mat-panel-title style="float:right;">
                   
                </mat-panel-title>
                <mat-panel-description style="display: flow-root;">  
                    <span style="float: right;color:#428bca">
                    {{ clicked == true ? 'Hide' : 'Show'  }} Search Criteria         
                   </span>     
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="clicked" class="col-md-12  stylepading1" style="padding-right: 10px; padding-left: 10px;">
                <h3 style="padding-left: 110px; color: #333333;"><strong>Search Parameters for Track and Trace</strong></h3>
                <form name="TrackandtraceForm" [formGroup]="TrackandtraceForm" (ngSubmit)="TrackandandTraceSearch();" style="font-family: Calibri !important;font-size:16px;" novalidate>
                    <div class="row" style="padding-left: 95px">
                        <div class="form-group col-sm-2" style="width: 350px">
                            <div class="text-left minPadding-top"><span style="color: red">*</span><label><strong>Terminal :</strong></label></div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && f.terminals.errors }">
                                <select name="terminals"  formControlName="terminals" class="form-control show-tick" required data-select-picker data-selectpicker-options="TrackFilter.TerminalId"  [(ngModel)]="TrackFilter.TerminalId" (ngModelChange)="LoadTerminalOptions(TrackFilter.TerminalId)">
                                    <option [ngValue]="undefined" value="">Choose Terminal ...</option>
                                    <option *ngFor="let option of TerminalData.Terminals" ngValue="{{option.TerminalId}}">{{option.CodeNameLocation}}</option>
                                </select>
                                <span *ngIf="submitted && f.terminals.errors?.required" class="help-block">Terminal is Required.</span>
                            </div>
                        </div> 
                        <div class="form-group col-sm-2" style="width: 350px" *ngIf="TrackFilter.searches.length" ><!---->
                            <div class="text-left minPadding-top"><span style="color: red">*</span><label><strong>Search Type:</strong></label></div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && f.searches.errors }">
                                <select name="searches" formControlName="searches" class="form-control show-tick" required data-select-picker data-selectpicker-options="TrackFilter.searches" [(ngModel)]="TrackFilter.Scheduletrip" (ngModelChange)="onChange()">
                                    <option value="">Choose Search...</option>
                                    <option *ngFor="let option of TrackFilter.searches" value="{{option.SearchId}}">{{option.SearchName}}</option>
                                </select>
                                <span *ngIf ="submitted && f.searches.errors?.required" class="help-block">You have to select one.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" style="padding-left: 110px;">
                        <div *ngIf="TrackFilter.Scheduletrip=='6'">
                            <div class="text-left minPadding-top">
                                <label style="font-size: 20px"><strong>Filter Criteria:</strong></label>
                            </div>
                            <div class="row" style="width: auto" [ngClass]="{ 'has-error' : submitted && (TrackFilter.GateContainerNumber==='' ||TrackFilter.GateContainerNumber===undefined) && (TrackFilter.GateBookingNumber==='' || TrackFilter.GateBookingNumber===undefined) && (TrackFilter.GateBillNumber==='' || TrackFilter.GateBillNumber===undefined) }">
                                <div class="row">
                                    <div class="text-left minPadding-top">
                                        <label><strong>Container Numbers:</strong></label>
                                    </div>
                                    <textarea class="form-control row" style="width:315px;" rows="5" oninput="this.value = this.value.toUpperCase()" formControlName="GateContainerNumber" name="GateContainerNumber" placeholder=" Enter Container Numbers (Limit {{GateContainerLimit}} Entries)" [(ngModel)]="TrackFilter.GateContainerNumber" (focus)="this.TrackFilter.GateContainerNumberfocus=true;" (keypress)="emptyotherfield('GateContainerNumber');" (change)="emptyotherfield('GateContainerNumber');" (blur)="enableAllFeilds('GateContainerNumberfocus');" [disabled]="TrackFilter.GateBillNumberfocus==true || TrackFilter.GateBookingNumberfocus==true"></textarea>
                                </div>
                                <div class="row" style="padding-top: 10px">
                                    <div class="text-left minPadding-top">
                                        <label><strong>Booking Number:</strong></label>
                                    </div>
                                    <input class="form-control row" style="width:315px" type="text" oninput="this.value = this.value.toUpperCase()" formControlName="GateBookingNumber" name="GateBookingNumber" placeholder=" Enter Booking Number" [(ngModel)] ="TrackFilter.GateBookingNumber" (focus)="this.TrackFilter.GateBookingNumberfocus=true;" (keypress)="emptyotherfield('GateBookingNumber');" (change)="emptyotherfield('GateBookingNumber');" (blur)="enableAllFeilds('GateBookingNumberfocus');" [disabled]="(this.TrackFilter.GateContainerNumberfocus==true || this.TrackFilter.GateBillNumberfocus==true)" />
                                </div>
                                <div class="row" style="padding-top: 10px;">
                                    <div class="text-left minPadding-top">
                                        <label><strong>Bill of Lading Number:</strong></label>
                                    </div>
                                    <input class="form-control row" style="width:315px" type="text" oninput="this.value = this.value.toUpperCase()" formControlName="GateBillNumber" name="GateBillNumber" placeholder=" Enter Bill Number" [(ngModel)]="TrackFilter.GateBillNumber" (focus)="this.TrackFilter.GateBillNumberfocus=true;" (keypress)="emptyotherfield('GateBillNumber');" (change)="emptyotherfield('GateBillNumber');" (blur)="enableAllFeilds('GateBillNumberfocus');" [disabled]="(this.TrackFilter.GateBookingNumberfocus==true || this.TrackFilter.GateContainerNumberfocus==true)" /> <!--{{this.TrackFilter.GateBookingNumberfocus}} - {{ this.TrackFilter.GateContainerNumberfocus}}-->
                                </div>
                                <span class="help-block" *ngIf="submitted && (TrackFilter.GateContainerNumber==='' ||TrackFilter.GateContainerNumber===undefined) && (TrackFilter.GateBookingNumber==='' || TrackFilter.GateBookingNumber===undefined) && (TrackFilter.GateBillNumber==='' || TrackFilter.GateBillNumber===undefined)">Please give one Input.</span>
                            </div>
                            <div class="row" style="padding-top: 10px; width: 600px;">
                                <div class="col-sm-3" style="padding-left: 0px; width: 157px;">
                                    <div class="text-left minPadding-top">
                                        <label><strong>From Date:</strong></label>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="text-left minPadding-top">
                                        <label><strong>To Date:</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding-top: 0px; width: 600px;" [ngClass]="{'has-error': ((TrackFilter.GateFromDate===null || TrackFilter.GateFromDate===undefined) || (TrackFilter.GateToDate===null || TrackFilter.GateToDate===undefined))}"> <!---->
                                <div class="col-sm-3" style="padding-left: 0px; width: 157px;">
                                    <input   [matDatepicker]="fromdatepicker" [min]="minfromDate" [max]="maxfromDate" formControlName="gatefromdate" id="gatefromdate" class="form-control col-md-8" [(ngModel)]="TrackFilter.GateFromDate" required>
                                    <mat-datepicker-toggle  matSuffix class="dp"  [for]="fromdatepicker"> 
                                        <span class="input-group-addon">
                                        <span class="glyphicon glyphicon-calendar"></span>
                                    </span></mat-datepicker-toggle>
                                    <mat-datepicker  [dateClass]="dateClass" #fromdatepicker></mat-datepicker>
                                    <!-- <div id="gatefromdate" class='input-group date' data-date-picker [(ngModel)]="TrackFilter.GateFromDate">
                                        <input type='text' id="GateFromDate" name="gatefromdate" placeholder=" From Date" class="form-control col" required />
                                        <span class="input-group-addon">
                                            <span class="glyphicon glyphicon-calendar"></span>
                                        </span>
                                    </div> -->
                                    <span class="help-block" *ngIf="submitted && ((TrackFilter.GateFromDate===null || TrackFilter.GateFromDate===undefined) || (TrackFilter.GateToDate===null || TrackFilter.GateToDate===undefined))">Dates are required.</span> <!---->
                                </div>
                                <div class="col-sm-3" style="width: 157px; padding-right: 0px;">
                                    <input  [matDatepicker]="todatepicker"   [min]="mintoDate" [max]="maxtoDate" formControlName="GateToDate" id="GateToDate" class="form-control col-md-8" [(ngModel)]="TrackFilter.GateToDate" required>
                                    <mat-datepicker-toggle matSuffix class="dp" [for]="todatepicker"> 
                                        <span class="input-group-addon">
                                        <span class="glyphicon glyphicon-calendar"></span>
                                    </span></mat-datepicker-toggle>
                                    <mat-datepicker  [dateClass]="dateClass" #todatepicker></mat-datepicker>
                                    <!-- <div id="gatetodate" class='input-group date' data-date-picker [(ngModel)]="TrackFilter.GateToDate">
                                        <input class="form-control" type='text' id="GateToDate" name="gatetodate" placeholder=" To Date" required />
                                        <span class="input-group-addon">
                                            <span class="glyphicon glyphicon-calendar"></span>
                                        </span>
                                    </div> -->
                                </div>
                                <div class="form-group col-sm-3" style="width: 250px; padding-right: 0px; margin-top: 5px;">
                                    <div>
                                        (<strong>Note:</strong> Max date range last {{GateDaysLimit}} days)
                                    </div>
                                </div>
                            
                            </div>
                        </div>             
                        <div *ngIf="TrackFilter.Scheduletrip=='1'">
                            <div class="text-left minPadding-top">
                                <label style="font-size: 20px"><strong>Filter Criteria:</strong></label>
                            </div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && f.Contai.errors }">  <!---->
                                <div class="text-left minPadding-top">
                                    <label><strong>Container Numbers:</strong></label>
                                </div>
                                <textarea class="form-control" formControlName="Contai" style="width: 315px" rows="5" name="Contai" oninput="this.value = this.value.toUpperCase()" placeholder=" Enter Container Numbers (Limit {{ImportAvailabilityLimit}} Entries)" [(ngModel)]="TrackFilter.ImportcontainerNum" data-capitalize required></textarea>
                                <span class="help-block" *ngIf="submitted && f.Contai.errors?.required" >Please enter Container Number(s).</span> 
                            </div>
                        </div>
                        <div *ngIf="TrackFilter.Scheduletrip=='2'">
                            <div class="text-left minPadding-top">
                                <label style="font-size: 20px"><strong>Filter Criteria:</strong></label>
                            </div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && f.billnum.errors }"> <!---->
                                <div class="text-left minPadding-top">
                                    <label><strong>Bill of Lading Number:</strong></label>
                                </div>
                                <input class="form-control" type="text" formControlName="billnum" oninput="this.value = this.value.toUpperCase()" style="width: 315px" name="billnum" placeholder=" Enter Bill Number" [(ngModel)]="TrackFilter.billoflanding_nbr" data-capitalize required>
                                <span class="help-block" *ngIf="submitted && f.billnum.errors?.required" >Please enter Bill Number.</span> <!-- -->
                            </div>
                        </div>
                        <div *ngIf="TrackFilter.Scheduletrip=='5'">
                            <div class="text-left minPadding-top">
                                <label style="font-size: 20px"><strong>Filter Criteria:</strong></label>
                            </div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && f.equipnum.errors }">  <!---->
                                <div class="text-left minPadding-top">
                                    <label><strong>Equipment Number:</strong></label>
                                </div>
                                <input class="form-control" style="width: 315px" formControlName="equipnum" oninput="this.value = this.value.toUpperCase()"  type="text" name="equipnum" placeholder=" Enter Equipment Number" [(ngModel)]="TrackFilter.ContainerNumber" data-capitalize required>
                                <span class="help-block" *ngIf="submitted && f.equipnum.errors?.required">Please enter Equipment Number.</span>  <!---->
                            </div>
                        </div>
                        <div *ngIf="TrackFilter.Scheduletrip=='3'">
                            <div class="text-left minPadding-top">
                                <label style="font-size: 20px"><strong>Filter Criteria:</strong></label>
                            </div>
                            <div style="width: 315px" [ngClass]="{ 'has-error' : submitted && TrackFilter.Scheduletrip=='3' && f.bkngnum.errors }" >  <!---->
                                <div class="text-left minPadding-top">
                                    <label><strong>Booking Number:</strong></label>
                                </div>
                                <input class="form-control" style="width: 315px" formControlName="bkngnum" oninput="this.value = this.value.toUpperCase()" type="text" name="bkngnum" placeholder=" Enter Booking Number" [(ngModel)]="TrackFilter.BookingNum" data-capitalize required>
                                <span class="help-block" *ngIf="submitted && TrackFilter.Scheduletrip=='3' && f.bkngnum.errors?.required" >Please enter Booking Number.</span>  <!---->
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="form-group row" style="padding-left: 100px">
                        <!-- SEARCH BUTTON -->
                        <button type="submit" class="btn btn-primary btn-site btn-margin">
                            <span class="glyphicon glyphicon-search" aria-hidden="true" style="font-size: 20px; padding-right: 10px"></span>Search
                        </button>
                    </div>
                    <br />
                    <br />
                </form>
            </div>
           </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='3' && TrackFilter.BookingNum!='' && TrackFilter.TerminalId!=undefined && searchresult)"> <app-booking-inquiry-results [TrackFilter]="this.TrackFilter" [BookingInformationlist] = "this.BookingInformationCollection" (outputToParent)="GetOutputVal($event)" ></app-booking-inquiry-results>  </div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='4' && TrackFilter.TerminalId!=undefined  && searchresult)" ><app-vessel-schedule-results [TrackFilter]="this.TrackFilter" [VesselSchedulelist]="this.vesselScheduleCollection"></app-vessel-schedule-results> </div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='5' && TrackFilter.ContainerNumber!='' && TrackFilter.TerminalId!=undefined  && searchresult)"><app-equipment-history-results [TrackFilter]="this.TrackFilter" [ContainerHistorylist]="this.ContainerHistoryCollection"></app-equipment-history-results></div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='2' && TrackFilter.billoflanding_nbr!='' && TrackFilter.billoflanding_nbr != undefined && TrackFilter.TerminalId!=undefined && searchresult)"><app-import-availability-results [TrackFilter]="this.TrackFilter" [importAvailabilitylist]="this.ImportAvailabilityCollection" ></app-import-availability-results></div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='1' && TrackFilter.TerminalId!=undefined && searchresult)"><app-import-availability-results [importAvailabilitylist]="this.ImportAvailabilityCollection" [TrackFilter]="this.TrackFilter" ></app-import-availability-results></div>
    <div  *ngIf="searchactive" [hidden]="!(submitted && TrackFilter.Scheduletrip=='6' && TrackFilter.TerminalId!=undefined && searchresult)"><app-gate-transaction-results [TrackFilter]="this.TrackFilter" [Gatetransactionlist]="this.gatetransactiondataCollection" ></app-gate-transaction-results></div>
    <div class="row" style="padding-bottom: 10px"></div>

</div>
