import { Component, OnInit } from '@angular/core';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { ResponseMessage } from 'src/app/shared/interfaces/ResponseMessages.model';
import { ExportchargesService } from 'src/app/core/services/exportcharges.service';
import { TrackandtraceService } from 'src/app/core/services/trackandtrace.service';
import { ExportChargesSearchModel,BookingHoldCharges,ExportChargesSearchRefData } from 'src/app/core/models/trackandtrace.model';
import {
  AbstractControl,
  FormBuilder,
  FormControl ,
  FormGroup,
  Validators
} from '@angular/forms';
import { OogstripstuffresultsComponent } from './oogstripstuffresults/oogstripstuffresults.component';

@Component({
  selector: 'app-oogstripstuffsearch',
  templateUrl: './oogstripstuffsearch.component.html',
  styleUrls: ['./oogstripstuffsearch.component.css']
})
export class OogstripstuffsearchComponent implements OnInit {
  submitted=false;
  ShowLoader=false;
  Terminals: any;
  ShippingLines:any;  
  OOGStripStuffSearchForm: FormGroup; 
  OOGStripStuffSearchRefData:ExportChargesSearchModel;
  Messages: ResponseMessage[]=[];
  constructor(private exportchargesService: ExportchargesService,    
    private trackandtraceService: TrackandtraceService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog) { this.OOGStripStuffSearchRefData = new ExportChargesSearchModel(); }


  ngOnInit(): void {
 
    var tChargeCd = "W";
    let ExportChargesSearchRefData = { "TerminalChargeCode": tChargeCd };
    this.ShowLoader=true;
    this.exportchargesService.GetExportChargesSearchRefData(ExportChargesSearchRefData).subscribe((result) => {
        this.OOGStripStuffSearchRefData = result;
        this.Terminals = result.TChargeTerminals.Terminals;
        this.ShippingLines = result.ShippingLines;
        this.Messages = result.generalMessages;
        this.ShowLoader=false;
    });

    
    this.OOGStripStuffSearchForm = this.formBuilder.group({
      terminals: ['', Validators.required],
      shippingLine:  ['', Validators.required],
      bKNumbers:  ['', Validators.required],
    });
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.OOGStripStuffSearchForm.controls;
  }

  
  SearchOOGStripStuffCharges(){
    this.submitted=true;
    this.Messages =[];
    if (!this.OOGStripStuffSearchForm.invalid) {
      this.ShowLoader=true;
      this.OOGStripStuffSearchRefData.HoldType_Cd = 'W';//'EXPSTRPSTFF';
      this.trackandtraceService.GetBookingDetails(this.OOGStripStuffSearchRefData).subscribe((result)=> {
        this.ShowLoader=false;
          if (result.generalMessages.length > 0)
          { this.Messages = result.generalMessages; }
          else {
            
            const dialogRef = this.dialog.open(OogstripstuffresultsComponent, {
              data: { BookingInfo: result ,OOGStripStuffSearchRefData : this.OOGStripStuffSearchRefData },
              autoFocus: false,
              restoreFocus: false,
              panelClass: 'deletepanelclass'
            });
            dialogRef.afterClosed().subscribe(result => {           
            });

          }
        });
      }
  }

}
