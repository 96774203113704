    export class DemurageStatementInvoicesPrint
    {
        StatementInvoiceList : DemurageStatementInvoiceToPrint[] ;
        Client : string ;
        ClientAddress1 : string ;
        ClientAddress2 : string ;
        ClientPostalInfo : string ;
        TerminalCity : string ;
        TerminalAddr : string ;
        TerminalOverNightAddr1: string;
        TerminalOverNightAddr2: string;
        TerminalOverNightCity: string;
        TerminalOverNightAddr3: string;
        HasOverNightAddress: boolean;
        TerminalRemitName : string ;
        TerminalName : string ;
        TerminalLogo : string ;       
        TotalAmt : string ;      
        generalMessages :any
    }

    export class DemurageStatementInvoiceToPrint
    {
         InvoiceId : number ;
         InvoiceNumber : string ;
         ReferenceNumber : string ;
         ClientId : number ;
         ClientName:string;
         TerminalId : number ;
         PoNumber:string;
         tops_holdname:string;
         ContractId : number ;
         vesselvoyage:string;
         InvoiceDate : Date ;
         BillingAmount : number ;
         TerminalDisbursmentAmount : number ;
         EquipmentDisbursmentAmount : number ;
         terminalId : number ;
         BillingNotes : string ;
         CollectionNotes : string ;
         InvoiceStatusId : number ;
         CurrencyId : number ;
         UserId : number ;
         Status : boolean ;
         InCollections : boolean ;
         SupervisorReview : boolean ;
         FollowupDate : Date ;
         WriteOff_FL : boolean ;
         SapNumber : string ;
         UniqueInvoiceId : string ;
         StatementNumber : string ;
         BillLadingNumber : string ;
         ContainerNumber : string ;
         SzTp : string ;
         DateTimeOfFirstAvailability : Date ;
         PortLastFreeDate : Date ;
         LineLastFreeDate : Date ;
         Paid_Thru_DT : Date ;
         PickupDate : Date ;
         VesselCode : string ;
         VoyageNumber : string ;
         totalCharge : string ;
         StatementStartDt : Date ;
         StatementEndDt : Date ;
         myTotalCharge1 : string ;
         Day1 : string ;
         myTotalCharge2 : string ;
         Day2 : string ;
         myTotalCharge3 : string ;
         Day3 : string ;
         myTotalCharge4 : string ;
         Day4 : string ;
        hasdata4 : boolean ;
        hasdata3: boolean ;
        hasdata2 : boolean ;
        hasdata1 : boolean ;
    }

    export class AncillaryStatementInvoicesForPrint
    {
        StatementInvoiceList : AncillaryStatementInvoiceToPrint[] ;
         Client : string ;
         ClientAddress1 : string ;
         ClientAddress2 : string ;
         ClientPostalInfo : string ;              
         TerminalCity : string ;
         TerminalAddr : string ;
         TerminalRemitName : string ;
         TerminalName : string ;
         TerminalLogo : string ;
         TotalAmt : string ;
         TerminalOverNightAddr1: string;
         TerminalOverNightAddr2: string;
         TerminalOverNightCity: string;
         TerminalOverNightAddr3: string;
         HasOverNightAddress: boolean;
         generalMessages :any
    }

    export class AncillaryStatementInvoiceToPrint
    {
         InvoiceId : number ;
         InvoiceNumber : string ;
         ClientId : number ;
         InvoiceDate : Date ;
         BillingAmount : number ;   
         terminalid : number ;
         ContainerNumber : string ;
         BillLadingNumber : string ;
         vesselvoyage : string ;
         tops_holdname : string ;
         UniqueInvoiceId : string ;
         ClientName : string ;
         StatementStartDt : Date ;
         StatementEndDt : Date ;
         PoNumber : string ;

    }

    export class AncillaryStatementInvoicesPrint
    {
        public  StatementPrint : AncillaryStatementInvoicesForPrint[] ;
        generalMessages :any

    }
