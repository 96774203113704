import { Injectable } from '@angular/core';
import { Observable, throwError,BehaviorSubject ,Subject} from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiurls,httpOptions } from 'src/app/common/utils/globalconstants';
import { TTSearchOptions , TrackFilter } from 'src/app/core/models/trackandtrace.model';
import { TerminalInfo } from 'src/app/core/models/Notification.model';
import { ExportChargesSearchModel,ExportChargesSearchRefData ,BookingHoldCharges} from 'src/app/core/models/trackandtrace.model';
@Injectable({
  providedIn: 'root'
})
export class TrackandtraceService {
  TerminalData:TerminalInfo;
  TTSearchOptions:TTSearchOptions;
  constructor(private httpClient: HttpClient) {
  }

  GetAllTerminals():Observable<any>{
    return this.httpClient.get<TerminalInfo>(
      environment.TOPSWebApi + apiurls.GetAllTerminals).pipe(    
      tap(data => {
        this.TerminalData = data;
        // console.log("TerminalData Info",this.TerminalData);
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetSearchOptionsbyTerminalId(TerminalId:number):Observable<any>{
    return this.httpClient.get<TTSearchOptions>(
      environment.TOPSWebApi + apiurls.GetSearchOptionsbyTerminalId + "/"+ TerminalId).pipe(    
      tap(data => {
        this.TTSearchOptions = data;
        // console.log("TTSearchOptions Info",this.TTSearchOptions);
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetExportChargesSearchRefData(requestdata:ExportChargesSearchRefData):Observable<any>{
    let json_ExportCharges = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetExportChargesSearchRefData , json_ExportCharges,httpOptions ).pipe(    
      tap(data => {     
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  
  GetShoppingCartDetailsForExportBookingHolds(requestdata:BookingHoldCharges):Observable<any>{
    let json_ExportCharges = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetShoppingCartDetailsForExportBookingHolds , json_ExportCharges,httpOptions ).pipe(    
      tap(data => {     
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  
  
  GetBookingDetails(requestdata:ExportChargesSearchModel):Observable<any>{
    let json_ExportCharges = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetBookingDetails , json_ExportCharges,httpOptions).pipe(    
      tap(data => {     
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetEIRReprintDetail(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetEIRReprintDetail , json_TrackFilter,httpOptions).pipe(    
      tap(data => {     
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  GetVesselScheduleDetails(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetVesselScheduleDetails , json_TrackFilter,httpOptions).pipe(    
      tap(data => {     
        // this.data. = this.datePipe.transform(this.demographics.Birthdate, 'dd.mm.yy');
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetVesselSchedule(requestdata:TrackFilter):Observable<any>{
   
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetVesselSchedule,json_TrackFilter, httpOptions ).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  GetContainerHistory(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetContainerHistory,json_TrackFilter, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetContainerAvailabiltyByTerm(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetContainerAvailabiltyByTerm,json_TrackFilter,httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  GetGateTransaction(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetGateTransaction,json_TrackFilter,httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetBookingInformation(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetBookingInformation,json_TrackFilter, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  

  GetContainerAvailabiltyByBLByTerm(requestdata:TrackFilter):Observable<any>{
    let json_TrackFilter = JSON.stringify(requestdata);
    return this.httpClient.post<any>(
      environment.TOPSWebApi + apiurls.GetContainerAvailabiltyByBLByTerm,json_TrackFilter, httpOptions).pipe(    
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }  
  
  private handleError(err: HttpErrorResponse) {
    return throwError(() => new Error('Error Occured')); //throwError('Error Occured');
 }

}
